import {DownloadIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useRef} from "react"
import {CSVLink} from "react-csv"
import {useRouteMatch} from "react-router-dom"
import AppLogo from "../../components/base/AppLogo"
import PageBody from "../../components/page/PageBody"
import PageContainer from "../../components/page/PageContainer"
import {useRetreatAttendees} from "../../utils/attendeeUtils"
import {useAttendeeReportGuid} from "../../utils/retreatUtils"
import {ShareableAttendeesTable} from "../app/attendees/ShareableAttendeesTable"

const MainBody = styled("div", {
  backgroundColor: theme.colors.white,
  display: "flex",
  flexDirection: "column",
  flex: 1,
  paddingInline: "20px",
  paddingTop: "30px",
  overflow: "hidden",
  height: "100%",
  gap: "20px",
  paddingBottom: "20px",
})

const Footer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
})

const PoweredBy = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})
const StyledTableContainer = styled("div", {
  width: "100%",
  overflow: "hidden",
  heigth: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
})

const HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
})
export default function ShareAttendeeReportPage() {
  let router = useRouteMatch<{guid: string}>()
  let {guid} = router.params
  let [report] = useAttendeeReportGuid(guid)
  let [attendees] = useRetreatAttendees(report?.retreat_id ?? -1)
  let exportRef = useRef<
    CSVLink & HTMLAnchorElement & {link: HTMLAnchorElement}
  >(null)

  return (
    <PageContainer>
      <PageBody>
        <MainBody>
          <HeaderContainer>
            <Text variant={"heading-lg"}>{report?.name}</Text>
            <Button
              text="Export to CSV"
              onClick={() => {
                if (exportRef.current?.link) exportRef.current.link.click()
              }}
              startIcon={<DownloadIcon />}
            />
          </HeaderContainer>

          <StyledTableContainer>
            <ShareableAttendeesTable
              exportRef={exportRef}
              activeView={report?.id ? report?.id.toString() : "all-attendees"}
              attendees={attendees}
              retreatId={report?.retreat_id ?? -1}
            />
          </StyledTableContainer>
          <Footer>
            <GrayText variant={"text-sm"}>
              Notice: this information is illegal to share
            </GrayText>
            <PoweredBy>
              <GrayText variant={"text-sm"}>Powered by</GrayText>
              <AppLogo noBackground height={15} withText />
            </PoweredBy>
          </Footer>
        </MainBody>
      </PageBody>
    </PageContainer>
  )
}
