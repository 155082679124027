import {styled} from "@summtech/flok-base/stitches.config"
import {
  Children,
  ComponentProps,
  isValidElement,
  PropsWithChildren,
} from "react"
import {ScrollSync, ScrollSyncPane} from "react-scroll-sync"
const StyledTableOverflowWrapper = styled("div", {
  flex: 1,
  width: "100%",
  overflow: "auto",
  marginBottom: "50px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
})
const StyledTableHeadWrapper = styled("div", {
  position: "absolute",
  width: "100%",
  overflow: "inherit",
  "&::-webkit-scrollbar": {
    display: "none",
  },
})

const StyledTable = styled("table", {
  minWidth: "100%",
  borderCollapse: "collapse",
  borderSpacing: 2,
  borderLeft: "none",
  borderRight: "none",
  whiteSpace: "nowrap",
  textAlign: "left",
})

export default function AppScrollSyncTable(props: PropsWithChildren<{}>) {
  const arrayChildren = Children.toArray(props.children)
  const Header = arrayChildren.find((child) => {
    return isValidElement(child) && child.props.__TYPE === "ScrollHeader"
  })
  const Body = arrayChildren.find((child) => {
    return isValidElement(child) && child.props.__TYPE === "ScrollBody"
  })
  const Footer = arrayChildren.find((child) => {
    return isValidElement(child) && child.props.__TYPE === "ScrollFooter"
  })
  return (
    <ScrollSync>
      <ScrollSyncPane>
        <StyledTableOverflowWrapper>
          <ScrollSyncPane>
            <StyledTableHeadWrapper>
              <StyledTable>{Header}</StyledTable>
            </StyledTableHeadWrapper>
          </ScrollSyncPane>
          <StyledTable>
            {Header} {Body}
          </StyledTable>
          {Footer}
        </StyledTableOverflowWrapper>
      </ScrollSyncPane>
    </ScrollSync>
  )
}

interface HeaderProps {
  __TYPE?: "ScrollHeader"
}

interface BodyProps {
  __TYPE?: "ScrollBody"
}

interface FooterProps {
  __TYPE?: "ScrollFooter"
}

const AppScrollSyncHeader: React.FC<HeaderProps & ComponentProps<"thead">> = (
  props: ComponentProps<"thead">
) => {
  return <thead {...props}>{props.children}</thead>
}

AppScrollSyncTable.Header = AppScrollSyncHeader

AppScrollSyncTable.Header.defaultProps = {
  __TYPE: "ScrollHeader",
}

const AppScrollSyncBody: React.FC<BodyProps & ComponentProps<"tbody">> = (
  props: PropsWithChildren<{}>
) => {
  return <tbody>{props.children}</tbody>
}
AppScrollSyncTable.Body = AppScrollSyncBody
AppScrollSyncTable.Body.defaultProps = {
  __TYPE: "ScrollBody",
}

const AppScrollSyncFooter: React.FC<FooterProps> = (
  props: PropsWithChildren<{}>
) => {
  return <>{props.children}</>
}
AppScrollSyncTable.Footer = AppScrollSyncFooter
AppScrollSyncTable.Footer.defaultProps = {
  __TYPE: "ScrollFooter",
}
