import {EditorState} from "draft-js"
import {useEffect} from "react"
import {
  AppWysiwygEditor,
  AppWysiwygViewer,
  createEditorState,
} from "../../../components/base/AppWysiwyg"
import {
  AttendeeLandingWebsiteBlockModel,
  WYSIWYGBlockContentModel,
  WYSIWYGBlockModel,
} from "../../../models/retreat"

type WYSIWYGBlockEditorProps = {
  block: WYSIWYGBlockModel
  formik: any
}

function WYSIWYGBlockEditor(props: WYSIWYGBlockEditorProps) {
  let {resetForm} = props.formik
  useEffect(() => {
    resetForm({
      values: {
        ...props.formik.values,
        [props.block.id]: {
          content: createEditorState(props.block.content),
          type: "WYSIWYG",
        },
      },
    })
  }, [props.block, resetForm])

  return (
    <AppWysiwygEditor
      editorState={
        props.formik.values[props.block.id]?.content ??
        EditorState.createEmpty()
      }
      onEditorStateChange={(val) => {
        props.formik.setFieldValue(`${props.block.id}.content`, val)
      }}
      unsaved={false}
    />
  )
}
export default WYSIWYGBlockEditor

type WYSIWYGBlockRendererProps = {
  block: AttendeeLandingWebsiteBlockModel
}
export function WYSIWYGBlockRenderer(props: WYSIWYGBlockRendererProps) {
  return props.block.content != null ? (
    <AppWysiwygViewer
      content={(props.block.content as WYSIWYGBlockContentModel)!}
    />
  ) : (
    <></>
  )
}
