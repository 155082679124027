import {CaretDownIcon, CaretUpIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useState} from "react"
import {Link} from "react-router-dom"

const CardContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  border: `1px solid ${theme.colors.gray7}`,
  gap: "12px",
  padding: "8px 16px",
  flex: 1,
  background: theme.colors.white,
  borderRadius: theme.shape.borderRadius,
  alignItems: "flex-start",
  ["a"]: {
    textDecoration: "none",
  },
})
const CardInnerContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  flex: 1,
})
const GrayText = styled(Text, {
  color: theme.colors.gray11,
})
const CaretUp = styled(CaretUpIcon, {
  width: "20px",
  height: "20px",
})
const CaretDown = styled(CaretDownIcon, {
  width: "20px",
  height: "20px",
})

const TitleText = styled(Text, {
  variants: {
    done: {
      true: {
        textDecoration: "line-through",
      },
      false: {},
    },
  },
})

export type OverviewTaskCardProps = {
  done: boolean
  title: string
  description?: string
  link?: {displayName: string; to: string}
}
export default function OverviewTaskCard(props: OverviewTaskCardProps) {
  let [open, setOpen] = useState(false)
  return (
    <CardContainer
      onClick={() => {
        setOpen((open) => !open)
      }}>
      <CardInnerContainer>
        <TitleText variant="text-sm-plus" done={props.done}>
          {props.title}
        </TitleText>
        {props.description && open && (
          <GrayText variant="text-sm">{props.description}</GrayText>
        )}
        {props.link && (open || !props.done) && (
          <Link to={props.link.to}>
            <Button
              variant={"inline"}
              color="brand"
              text={props.link.displayName}
            />
          </Link>
        )}
      </CardInnerContainer>

      {open ? <CaretUp /> : <CaretDown />}
    </CardContainer>
  )
}
