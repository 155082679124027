import {ArrowLeftIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {styled} from "@summtech/flok-base/stitches.config"
import {ComponentProps} from "react"
import AppLinkButton from "../AppLinkButton"

export default function FullPage(props: ComponentProps<typeof StyledFullPage>) {
  return <StyledFullPage {...props} />
}

export const StyledFullPage = styled("div", {
  width: "100%",
  height: "100vh",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "$gray2",
})

FullPage.Header = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "16px 32px",
  gap: "8px",
  background: "$white",
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: "$gray6",
  width: "100%",
})
FullPage.HeaderLeft = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "8px",
  flexGrow: 1,
})
FullPage.HeaderBackButton = (props: ComponentProps<typeof Button>) => (
  <Button
    variant={"outline"}
    text={"Back"}
    startIcon={<ArrowLeftIcon />}
    {...props}
  />
)
FullPage.HeaderBackLink = (props: ComponentProps<typeof AppLinkButton>) => (
  <AppLinkButton
    variant={"outline"}
    text={"Back"}
    startIcon={<ArrowLeftIcon />}
    {...props}
  />
)
FullPage.HeaderCenter = styled("div", {flexGrow: 0})
FullPage.HeaderRight = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "8px",
  flexGrow: 1,
})
FullPage.DoublePane = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  padding: "20px 32px",
  gap: "16px",
  flexGrow: 1,
  minHeight: 0,
})
FullPage.DoublePaneAside = styled("div", {
  width: "288px",
  background: "$white",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "$gray6",
  borderRadius: "6px",
  flexGrow: 0,
  height: "100%",
  overflow: "auto",
})

FullPage.DoublePaneMain = styled("div", {
  height: "100%",
  background: "#FFFFFF",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "$gray6",
  borderRadius: "6px",
  flexGrow: 1,
})

FullPage.SinglePane = styled("div")
