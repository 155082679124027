import {
  CheckCircledIcon,
  CircleIcon,
  DotsHorizontalIcon,
  DragHandleHorizontalIcon,
} from "@radix-ui/react-icons"
import {Badge} from "@summtech/flok-base/components/Badge"
import {RawDraftContentState} from "draft-js"
import {FileModel} from "./retreat"
export type LabelModel = {
  id: number
  retreat_id: number
  text: string
  color?: string
}

export type LabelToTaskModel = {
  id: number
  label_id: number
  task_id: number
}

export type TaskModel = {
  id: number
  title: string
  status: TaskStatus
  priority?: TaskPriority
  description: RawDraftContentState
  due_date?: string
  assignees: number[]
  retreat_id: number
  files: FileModel[]
  labels: number[]
  created_at?: Date
  comment_thread_id?: number
}

export enum TaskStatusEnum {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  TO_DO = "TO_DO",
  BACKLOG = "BACKLOG",
}

export const TaskStatusName = {
  [TaskStatusEnum.TO_DO]: "To Do",
  [TaskStatusEnum.IN_PROGRESS]: "In Progress",
  [TaskStatusEnum.COMPLETED]: "Completed",
  [TaskStatusEnum.BACKLOG]: "Backlog",
}
export type TaskStatus = `${TaskStatusEnum}`
export const TaskStatusValues: TaskStatus[] = Object.values(TaskStatusEnum)

export const TaskStatusIcon = {
  [TaskStatusEnum.TO_DO]: <CircleIcon />,
  [TaskStatusEnum.IN_PROGRESS]: <DotsHorizontalIcon />,
  [TaskStatusEnum.COMPLETED]: <CheckCircledIcon />,
  [TaskStatusEnum.BACKLOG]: <DragHandleHorizontalIcon />,
}
export enum TaskPriorityEnum {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export const TaskPriorityName = {
  [TaskPriorityEnum.HIGH]: "High",
  [TaskPriorityEnum.MEDIUM]: "Medium",
  [TaskPriorityEnum.LOW]: "Low",
}

export const TaskPriorityBadge = {
  [TaskPriorityEnum.HIGH]: (
    <Badge
      label={TaskPriorityName[TaskPriorityEnum.HIGH]}
      color="purple"
      textVariant="text-sm-plus"
    />
  ),
  [TaskPriorityEnum.MEDIUM]: (
    <Badge
      label={TaskPriorityName[TaskPriorityEnum.MEDIUM]}
      color="purple"
      textVariant="text-sm-plus"
    />
  ),
  [TaskPriorityEnum.LOW]: (
    <Badge
      label={TaskPriorityName[TaskPriorityEnum.LOW]}
      color="purple"
      textVariant="text-sm-plus"
    />
  ),
}
export type TaskPriority = `${TaskPriorityEnum}`
export const TaskPriorityValues: TaskPriority[] =
  Object.values(TaskPriorityEnum)

export const taskSortingOptions = [
  {
    key: "created_at" as keyof TaskModel,
    label: "Created On ",
  },
  {
    key: "due_date" as keyof TaskModel,
    label: "Due Date",
  },
  {
    key: "status" as keyof TaskModel,
    label: "Status ",
  },
  {
    key: "priority" as keyof TaskModel,
    label: "Priority ",
  },
  {
    key: "title" as keyof TaskModel,
    label: "Alphabetical ",
  },
]
export const SortingTaskPriorityInteger = {
  [TaskPriorityEnum.HIGH]: 3,
  [TaskPriorityEnum.MEDIUM]: 2,
  [TaskPriorityEnum.LOW]: 1,
}
export const SortingTaskPriorityValues: TaskPriority[] =
  Object.values(TaskPriorityEnum)

export const taskFilteringOptions = {
  //Commented all taskFilteringOptions that are not in use

  // showAll: {
  //   id: undefined,
  //   label: undefined,
  //   optionsArray: [],
  // },
  status: {
    id: "status",
    label: "Status",
    optionsArray: [
      // {
      //   optionValue: undefined,
      //   optionLabel: undefined,
      // },
      {
        optionValue: "TO_DO",
        optionLabel: "To Do",
      },
      {
        optionValue: "IN_PROGRESS",
        optionLabel: "In Progress",
      },
      {
        optionValue: "COMPLETED",
        optionLabel: "Completed",
      },
      {
        optionValue: "BACKLOG",
        optionLabel: "Backlog",
      },
    ],
  },
  priority: {
    id: "priority",
    label: "Priority",
    optionsArray: [
      // {
      //   optionValue: undefined,
      //   optionLabel: undefined,
      // },
      {
        optionValue: TaskPriorityEnum.HIGH,
        optionLabel: "High",
      },
      {
        optionValue: TaskPriorityEnum.MEDIUM,
        optionLabel: "Medium",
      },
      {
        optionValue: TaskPriorityEnum.LOW,
        optionLabel: "Low",
      },
    ],
  },
  // due_date: {
  //   id: "due_date",
  //   label: "Due Date",
  //   optionsArray: [
  //     {
  //       optionValue: undefined,
  //       optionLabel: undefined,
  //     },
  //     {
  //       optionValue: "Due before today",
  //       optionLabel: "Due before today",
  //     },
  //     {
  //       optionValue: "Due today",
  //       optionLabel: "Due today ",
  //     },
  //     {
  //       optionValue: "Due tomorrow",
  //       optionLabel: "Due tomorrow ",
  //     },
  //     {
  //       optionValue: "Due next 7 days",
  //       optionLabel: "Due next 7 days",
  //     },
  //     {
  //       optionValue: "Due next 14 days",
  //       optionLabel: "Due next 14 days",
  //     },
  //   ],
  // },
}

export type TaskTemplateGroupModel = {
  id: number
  title: string
  description?: string
  tasks: number[]
}
