import {
  BoxIcon,
  CalendarIcon,
  DotsVerticalIcon,
  DragHandleDots2Icon,
  Pencil2Icon,
  PlusIcon,
  RadiobuttonIcon,
  TrashIcon,
} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Text} from "@summtech/flok-base/components/Text"
import {css, styled} from "@summtech/flok-base/stitches.config"
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import {ComponentProps, useState} from "react"
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd"
import {
  deleteFormQuestion,
  getForm,
  getFormQuestion,
  postFormQuestion,
  postFormQuestionReorder,
} from "../../../api/form"
import {
  FormModel,
  FormQuestionModel,
  FormQuestionType,
  FormQuestionTypeName,
  FormQuestionTypeValues,
} from "../../../models/form"
import ConfirmationModal from "../../app/ConfirmationModal"
import {SwitchCases} from "../../app/SwitchCases"

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "16px 12px",
  gap: "12px",
})

const Questions = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  gap: "4px",
  flexGrow: 1,
  minHeight: 0,
  width: "100%",
})

/**
 * This component creates a questions list. Each item shows:
 *    - question title
 *    - three dot menu with option to delete
 *    - drag handle with option to re-order
 */
export function FormQuestionsListAside(
  props: {formId: number} & Exclude<
    ComponentProps<typeof Container>,
    "children"
  >
) {
  let queryClient = useQueryClient()
  let formQuery = useQuery({
    queryFn: () => getForm(props.formId),
    queryKey: ["forms", props.formId],
  })

  let questionsQueries = useQueries({
    queries:
      formQuery.data?.form?.questions.map((questionId) => ({
        queryFn: () => getFormQuestion(questionId),
        queryKey: ["forms-questions", questionId],
      })) ?? [],
  })

  const postQuestionMutation = useMutation({
    mutationFn: postFormQuestion,
    onSuccess: (data) => {
      queryClient.setQueryData(["forms-questions", data.form_question.id], data)
      queryClient.setQueryData(
        ["forms", props.formId],
        (oldData?: {form: FormModel}) => {
          if (oldData) {
            return {
              form: {
                ...oldData.form,
                questions: [...oldData.form.questions, data.form_question.id],
              },
            }
          }
        }
      )
    },
  })

  const questionsUpdater = (newQuestions: number[]) => {
    return (data?: {form: FormModel}) => {
      if (data) {
        return {
          form: {
            ...data?.form,
            questions: newQuestions,
          },
        }
      }
    }
  }

  let reorderQuestionsMutation = useMutation({
    mutationFn: (vars: {
      formId: number
      questionId: number
      prevQuestionId: number
      optimistic: {
        update: number[]
        original: number[]
      }
    }) =>
      postFormQuestionReorder(
        vars.formId,
        vars.questionId,
        vars.prevQuestionId
      ),
    onMutate: (vars) => {
      queryClient.setQueryData(
        ["forms", props.formId],
        questionsUpdater(vars.optimistic.update)
      )
    },
    onError: (error, vars) => {
      queryClient.setQueryData<{form: FormModel}>(
        ["forms", props.formId],
        questionsUpdater(vars.optimistic.original)
      )
    },
  })

  return (
    <Container {...props}>
      <Text css={{padding: "0px 8px"}} variant="heading-sm">
        Questions
      </Text>
      <Questions>
        <SwitchCases value={formQuery.status}>
          <SwitchCases.Case value={"success"}>
            <DragDropContext
              onDragEnd={async (result) => {
                if (result && result.destination && formQuery.data) {
                  const originalItems = [
                    ...(formQuery.data?.form?.questions ?? []),
                  ]
                  const items = [...originalItems]
                  const [questionId] = items.splice(result.source.index, 1)
                  items.splice(result.destination.index, 0, questionId)
                  let prevQuestionId: number | undefined =
                    items[result.destination.index - 1]
                  if (questionId !== prevQuestionId) {
                    reorderQuestionsMutation.mutate({
                      formId: formQuery.data.form.id,
                      questionId,
                      prevQuestionId,
                      optimistic: {
                        update: items,
                        original: originalItems,
                      },
                    })
                  }
                }
              }}>
              <Droppable droppableId="questions">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      width: "100%",
                    }}>
                    {questionsQueries
                      .filter((query) => query.status === "success")
                      .map((query, index) => {
                        let question = query.data!.form_question
                        return (
                          <Draggable
                            key={question.id}
                            draggableId={question.id.toString()}
                            index={index}>
                            {(provided, snapshot) => (
                              <div
                                {...provided.draggableProps}
                                ref={provided.innerRef}>
                                <QuestionListItem
                                  question={question}
                                  key={question.id}
                                  {...provided.dragHandleProps}
                                />
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Dropdown
              button={
                <Button
                  fullWidth
                  variant="ghost"
                  startIcon={<PlusIcon />}
                  text="Add Question"
                />
              }>
              {FormQuestionTypeValues.map((type) => (
                <DropdownItem
                  key={type}
                  text={FormQuestionTypeName[type] ?? type}
                  startIcon={<Icon questionType={type} />}
                  onClick={() =>
                    postQuestionMutation.mutate({form_id: props.formId, type})
                  }
                />
              ))}
            </Dropdown>
          </SwitchCases.Case>
        </SwitchCases>
      </Questions>
    </Container>
  )
}

function QuestionListItem(
  props: {question: FormQuestionModel} & Exclude<
    ComponentProps<typeof Item>,
    "children"
  >
) {
  let queryClient = useQueryClient()
  const deleteQuestionMutation = useMutation({
    mutationFn: (vars: {questionId: number}) =>
      deleteFormQuestion(vars.questionId),
    onMutate: (vars) => {
      queryClient.setQueryData(
        ["forms", props.question.form_id],
        (data?: {form: FormModel}) => {
          if (data) {
            return {
              form: {
                ...data.form,
                questions: data.form.questions.filter(
                  (id) => id !== vars.questionId
                ),
              },
            }
          }
        }
      )
      queryClient.removeQueries(["forms-questions", vars.questionId])
    },
    onError: (errors, vars) => {
      queryClient.invalidateQueries(["forms-questions", vars.questionId])
    },
  })
  let {question, ...otherProps} = props
  let [confirmDelete, setConfirmDelete] = useState(false)
  return (
    <Item {...otherProps}>
      <DragHandleDots2Icon className={DragHandleCss()} />
      <Icon questionType={question.type} />
      <Text variant="text-sm-plus" color="$gray12">
        {question.title}
      </Text>
      <Dropdown
        button={
          <IconButton variant="ghost" size="xs">
            <DotsVerticalIcon className={IconCss()} />
          </IconButton>
        }>
        <DropdownItem
          startIcon={<TrashIcon />}
          css={{color: "$red11"}}
          text={"Delete"}
          onClick={() => {
            setConfirmDelete(true)
          }}
        />
      </Dropdown>
      {/* Confirmation modals */}
      <ConfirmationModal
        open={confirmDelete}
        title="Are you sure that you want to delete this question?"
        subtitle="This action cannot be undone."
        confirmText="Yes, delete"
        onConfirm={() => {
          deleteQuestionMutation.mutate({questionId: props.question.id})
        }}
        onClose={() => {
          setConfirmDelete(false)
        }}
      />
    </Item>
  )
}

const DragHandleCss = css({
  position: "absolute",
  width: "16px",
  height: "20px",
  left: "-12px",
  top: "calc(50% - 20px/2)",
})

const Item = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "6px 8px",
  gap: "8px",
  width: "100%",
  borderRadius: "6px",
  [`& > ${IconButton}, & > .${DragHandleCss()}`]: {
    opacity: "0",
    alignSelf: "center",
    marginLeft: "auto",
  },
  "&:hover": {
    cursor: "grab",
    background: "$gray4",
    [`& > ${IconButton}, & > .${DragHandleCss()}`]: {
      opacity: "1",
    },
  },
})

function Icon(props: {questionType: FormQuestionType}) {
  let iconProps = {className: IconCss()}
  switch (props.questionType) {
    case "SINGLE_SELECT":
      return <RadiobuttonIcon {...iconProps} />
    case "MULTI_SELECT":
      return <BoxIcon {...iconProps} />
    case "DATE":
    case "DATETIME":
      return <CalendarIcon {...iconProps} />
    case "SHORT_ANSWER":
    case "LONG_ANSWER":
      return <Pencil2Icon {...iconProps} />
  }
}
const IconCss = css({
  width: "16px",
  height: "20px",
  color: "$gray11",
  flexShrink: 0,
})
