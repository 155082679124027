import {Button} from "@summtech/flok-base/components/Button"
import {styled} from "@summtech/flok-base/stitches.config"
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import {useFormik} from "formik"
import * as yup from "yup"
import {Awaited} from "../../api"
import {
  getForm,
  getFormQuestion,
  getFormQuestionOption,
  getFormQuestionRule,
  postFormResponse,
} from "../../api/form"
import {FormResponseModel} from "../../models/form"
import {FormHeader} from "./headers"
import {FormQuestionViewer} from "./questions"

type FormViewerProps = {
  formId: number
  onSuccess: (formResponse: FormResponseModel) => void
}
export default function FormViewer(props: FormViewerProps) {
  let queryClient = useQueryClient()
  let formQuery = useQuery({
    queryFn: () => getForm(props.formId),
    queryKey: ["forms", props.formId],
  })

  let questionsQueries = useQueries({
    queries:
      formQuery.data?.form.questions.map((questionId) => ({
        queryFn: () => getFormQuestion(questionId),
        queryKey: ["forms-questions", questionId],
      })) ?? [],
  })

  // LOAD IN QUESTION RULES FOR USE LATER ON
  useQueries({
    queries: questionsQueries
      .filter((questionQuery) => questionQuery.status === "success")
      .flatMap((questionQuery) => {
        return questionQuery.data!.form_question.form_question_rules.map(
          (ruleId) => ({
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            queryFn: () => getFormQuestionRule(ruleId),
            queryKey: ["questions-rules", ruleId],
          })
        )
      }),
  })

  // LOAD IN QUESTION OPTIONS FOR USE LATER ON (fixes instance where questions are hidden based on another hidden question)
  useQueries({
    queries: questionsQueries
      .filter((questionQuery) => questionQuery.status === "success")
      .flatMap((questionQuery) => {
        return questionQuery.data!.form_question.select_options.map(
          (optionId) => ({
            queryFn: () => getFormQuestionOption(optionId),
            queryKey: ["questions-options", optionId],
          })
        )
      }),
  })

  // FORM RELATED

  let questionAnswers = questionsQueries
    .filter((questionQuery) => questionQuery.status === "success")
    .reduce(
      (prev, questionQuery) => ({
        ...prev,
        [questionQuery.data!.form_question.id.toString()]: "",
      }),
      {} as {[key: string]: string}
    )

  let postFormResponseMutation = useMutation({
    mutationFn: postFormResponse,
    onSuccess: (data) => props.onSuccess(data.form_response),
  })

  let formFormik = useFormik({
    initialValues: questionAnswers,
    onSubmit: (values) => {
      let answers = questionsQueries
        .filter((query) => query.isSuccess)
        .map((query) => ({
          form_question_id: query.data!.form_question.id,
          answer: values[query.data!.form_question.id] ?? "",
        }))
      postFormResponseMutation.mutate({form_id: props.formId, answers})
    },
    validationSchema: () => yup.object(questionValidation),
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
  })
  // END FORM RELATED
  let visibleQuestionsQueries = questionsQueries
    .filter((questionQuery) => questionQuery.status === "success")
    .filter((questionQuery) => {
      let visible = true
      let question = questionQuery.data?.form_question
      if (question && question.form_question_rules) {
        question.form_question_rules
          .map((ruleId) =>
            queryClient.getQueryState<
              Awaited<ReturnType<typeof getFormQuestionRule>>
            >(["questions-rules", ruleId])
          )
          .forEach((ruleQuery) => {
            if (!ruleQuery || ruleQuery.status !== "success") {
              visible = false
            } else {
              let dependsOnQuestionId =
                ruleQuery!.data!.form_question_rule.depends_on_form_question_id
              let dependsOnOptionId =
                ruleQuery!.data!.form_question_rule.depends_on_select_option_id
              let dependsOnOptionQuery = queryClient.getQueryState<
                Awaited<ReturnType<typeof getFormQuestionOption>>
              >(["questions-options", dependsOnOptionId])
              if (
                !dependsOnOptionQuery ||
                !(dependsOnOptionQuery.status === "success")
              ) {
                visible = false
              } else {
                let dependsOnOption = dependsOnOptionQuery.data
                let currentQuestionAnswer =
                  formFormik.values[dependsOnQuestionId.toString()]
                if (dependsOnOption && dependsOnOption.select_option) {
                  if (
                    currentQuestionAnswer !==
                    dependsOnOption.select_option.option
                  ) {
                    visible = false
                  }
                }
              }
            }
          })
      }
      return visible
    })

  let questionValidation = visibleQuestionsQueries
    .filter((questionQuery) => questionQuery.status === "success")
    .reduce((prev, questionQuery) => {
      if (questionQuery.data?.form_question.required) {
        return {
          ...prev,
          [questionQuery.data!.form_question.id.toString()]: yup
            .mixed()
            .required("This field is required!"),
        }
      } else {
        return prev
      }
    }, {} as {[key: string]: yup.AnySchema})

  return (
    <FormViewerContainer>
      {formQuery.status === "success" && !!formQuery.data!.form.title && (
        <FormHeader
          title={formQuery.data.form.title}
          description={formQuery.data.form.description}
        />
      )}
      <QuestionsContainer onSubmit={formFormik.handleSubmit}>
        {visibleQuestionsQueries.map((questionQuery) => {
          return (
            questionQuery.status === "success" && (
              <FormQuestionViewer
                key={questionQuery.data.form_question.id.toString()}
                question={questionQuery.data.form_question}
                errorMessage={
                  formFormik.errors[
                    questionQuery.data.form_question.id.toString()
                  ]
                }
                value={
                  formFormik.values[
                    questionQuery.data.form_question.id.toString()
                  ] ?? ""
                }
                onChange={(newVal: string) => {
                  formFormik.setFieldValue(
                    questionQuery.data.form_question.id.toString(),
                    newVal
                  )
                }}
              />
            )
          )
        })}
        <Button type="submit" text="Submit" color="brand" variant="solid" />
      </QuestionsContainer>
    </FormViewerContainer>
  )
}

export const FormViewerContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "32px",
})

export const QuestionsContainer = styled("form", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px 0px 32px",
  gap: "16px",
  width: "100%",
})
