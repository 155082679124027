import {Pencil1Icon, PlusIcon, TrashIcon} from "@radix-ui/react-icons"
import {Banner} from "@summtech/flok-base/components/Banner"
import {Button} from "@summtech/flok-base/components/Button"
import {FormField} from "@summtech/flok-base/components/FormField"
import {FormLabel} from "@summtech/flok-base/components/FormLabel"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Switch} from "@summtech/flok-base/components/Switch"
import {Textfield} from "@summtech/flok-base/components/Textfield"
import {styled} from "@summtech/flok-base/stitches.config"
import {useMutation, useQueries, useQueryClient} from "@tanstack/react-query"
import {useFormik} from "formik"
import {useState} from "react"
import {
  deleteFormQuestion,
  deleteFormQuestionOption,
  getFormQuestionOption,
  patchFormQuestion,
  patchFormQuestionOption,
  postFormQuestionOption,
} from "../../../api/form"
import {
  FormQuestionModel,
  FormQuestionSelectOptionModel,
  FormQuestionTypeEnum,
  FormQuestionTypeName,
  FormQuestionTypeValues,
} from "../../../models/form"
import ConfirmationModal from "../../app/ConfirmationModal"
import {SwitchCases} from "../../app/SwitchCases"
import {FormQuestionRulesModal} from "../rules"
import FormQuestionBase, {QuestionCardBase} from "./FormQuestionBase"

export default function FormQuestionBuilder(props: {
  question: FormQuestionModel
}) {
  let queryClient = useQueryClient()
  let [editing, setEditing] = useState(false)
  let questionOptionQueries = useQueries({
    queries: props.question.select_options.map((optionId) => ({
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      queryFn: () => getFormQuestionOption(optionId),
      queryKey: ["questions-options", optionId],
    })),
  })

  let patchQuestionMutation = useMutation({
    mutationFn: (args: Parameters<typeof patchFormQuestion>[1]) =>
      patchFormQuestion(props.question.id, args),
    onSuccess: (data) => {
      queryClient.setQueryData(["forms-questions", props.question.id], data)
    },
  })

  let deleteQuestionMutation = useMutation({
    mutationFn: () => deleteFormQuestion(props.question.id),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ["forms-questions", props.question.id],
      })
      queryClient.invalidateQueries({
        queryKey: ["forms", props.question.form_id],
      })
      setDeleteModal(false)
    },
  })

  let postOptionMutation = useMutation({
    mutationFn: postFormQuestionOption,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["forms-questions", props.question.id],
      })
      queryClient.setQueryData(
        ["questions-options", data.select_option.id],
        data
      )
    },
  })

  // FORMS
  // NOTE: probably don't need all these forms. Could likely just handle everything in the onBlur or onChange
  // of the formfield
  let titleFormik = useFormik({
    initialValues: {
      title: props.question.title,
    },
    onSubmit: (values) => {
      let patchData: {title?: string} =
        values.title !== props.question.title ? {title: values.title} : {}
      if (Object.keys(patchData).length) {
        patchQuestionMutation.mutate((props.question.id, values))
      }
    },
    enableReinitialize: true,
  })
  let descriptionFormik = useFormik({
    initialValues: {
      description: props.question.description,
    },
    onSubmit: (values) => {
      let patchData: {description?: string} =
        values.description !== props.question.description
          ? {description: values.description}
          : {}
      if (Object.keys(patchData).length) {
        patchQuestionMutation.mutate((props.question.id, values))
      }
    },
    enableReinitialize: true,
  })
  let questionTypeFormik = useFormik({
    initialValues: {
      type: props.question.type,
    },
    onSubmit: (values) => {
      patchQuestionMutation.mutate((props.question.id, values))
    },
    enableReinitialize: true,
  })
  let requiredFormik = useFormik({
    initialValues: {
      required: props.question.required,
    },
    onSubmit: (values) => {
      patchQuestionMutation.mutate((props.question.id, values))
    },
    enableReinitialize: true,
  })
  let minDateFormik = useFormik({
    initialValues: {
      min_date: props.question.min_date,
    },
    onSubmit: (values) => {
      patchQuestionMutation.mutate((props.question.id, values))
    },
    enableReinitialize: true,
  })

  let maxDateFormik = useFormik({
    initialValues: {
      max_date: props.question.max_date,
    },
    onSubmit: (values) => {
      patchQuestionMutation.mutate((props.question.id, values))
    },
    enableReinitialize: true,
  })
  // END FORMS

  let options = questionOptionQueries
    .filter((query) => query.status === "success")
    .map((optionQuery) => optionQuery.data!.select_option)
  const optionsFormFieldProps = {
    fullWidth: true,
    label: "Options",
    options: options.map((option) => {
      return {
        label: (
          <FormQuestionOptionBuilder
            option={option}
            disableEdit={props.question.non_editable}
          />
        ),
        value: option.id.toString(),
      }
    }),
  }

  // MODALS
  let [rulesModalOpen, setRulesModalOpen] = useState(false)
  let [deleteModal, setDeleteModal] = useState(false)

  let numRulesText = `${props.question.form_question_rules.length} ${
    props.question.form_question_rules.length === 1 ? "Rule" : "Rules"
  }`

  return editing ? (
    <>
      <QuestionCardBase noPadding>
        <QuestionCardBase noBorder>
          {props.question.non_editable && (
            <Banner
              fullWidth
              variant="warning"
              message="Some fields in Flok required questions aren't editable"
            />
          )}
          <FormField
            fullWidth
            id="title"
            type="textfield"
            label="Title"
            placeholder="Title"
            disabled={props.question.non_editable}
            errorMessage={titleFormik.errors.title}
            value={titleFormik.values.title}
            onChange={titleFormik.handleChange}
            onBlur={() => titleFormik.handleSubmit()}
          />
          <FormField
            fullWidth
            id="description"
            type="textarea"
            label="Description"
            placeholder="Description"
            errorMessage={descriptionFormik.errors.description}
            value={descriptionFormik.values.description}
            onChange={descriptionFormik.handleChange}
            onBlur={() => descriptionFormik.handleSubmit()}
            hint="Optional"
          />
          <FormField
            value={questionTypeFormik.values.type}
            fullWidth
            disabled={props.question.non_editable}
            type="select"
            label="Type"
            onChange={async (value) => {
              await questionTypeFormik.setFieldValue("type", value)
              questionTypeFormik.handleSubmit()
            }}>
            {FormQuestionTypeValues.map((value) => {
              return (
                <SelectItem label={FormQuestionTypeName[value]} value={value} />
              )
            })}
          </FormField>
          <SwitchCases value={props.question.type}>
            <SwitchCases.Case
              value={[
                FormQuestionTypeEnum.DATE,
                FormQuestionTypeEnum.DATETIME,
              ]}>
              <FormField
                hint="Optional"
                fullWidth
                type="datetime"
                time={props.question.type === FormQuestionTypeEnum.DATETIME}
                value={minDateFormik.values.min_date}
                maxDate={maxDateFormik.values.max_date}
                label="Earliest Date"
                onChange={async (value) => {
                  await minDateFormik.setFieldValue("min_date", value)
                  minDateFormik.handleSubmit()
                }}
              />
              <FormField
                hint="Optional"
                fullWidth
                minDate={minDateFormik.values.min_date}
                type="datetime"
                time={props.question.type === FormQuestionTypeEnum.DATETIME}
                label="Latest Date"
                value={maxDateFormik.values.max_date}
                onChange={async (value) => {
                  await minDateFormik.setFieldValue("max_date", value)
                  minDateFormik.handleSubmit()
                }}
              />
            </SwitchCases.Case>
            <SwitchCases.Case value={FormQuestionTypeEnum.MULTI_SELECT}>
              <FormField
                {...optionsFormFieldProps}
                type="checkbox"
                value={[]}
              />
            </SwitchCases.Case>
            <SwitchCases.Case value={FormQuestionTypeEnum.SINGLE_SELECT}>
              <FormField {...optionsFormFieldProps} value={""} type="radio" />
            </SwitchCases.Case>
            <SwitchCases.Case
              value={[
                FormQuestionTypeEnum.SINGLE_SELECT,
                FormQuestionTypeEnum.MULTI_SELECT,
              ]}>
              <Button
                disabled={
                  props.question.non_editable ||
                  postOptionMutation.status === "loading"
                }
                variant="inline"
                text={
                  postOptionMutation.status === "loading"
                    ? "Loading ..."
                    : "Add Option"
                }
                color="gray"
                startIcon={<PlusIcon />}
                onClick={() => {
                  postOptionMutation.mutate({
                    option: "",
                    form_question_id: props.question.id,
                  })
                }}
              />
            </SwitchCases.Case>
          </SwitchCases>
        </QuestionCardBase>
        <QuestionCardFooter>
          <QuestionCardFooterLeft>
            <RequiredSwitch>
              <Switch
                disabled={props.question.non_editable}
                checked={requiredFormik.values.required}
                onChange={async (checked) => {
                  await requiredFormik.setFieldValue("required", checked)
                  requiredFormik.handleSubmit()
                }}
              />
              <FormLabel
                text={props.question.required ? "Required" : "Not Required"}
              />
            </RequiredSwitch>
            <Button
              disabled={props.question.non_editable}
              onClick={() => {
                setRulesModalOpen(true)
              }}
              variant="ghost"
              text={numRulesText}
              startIcon={<Pencil1Icon />}
            />
          </QuestionCardFooterLeft>
          <QuestionCardFooterRight>
            <IconButton
              disabled={props.question.non_editable}
              variant="ghost"
              onClick={(e) => {
                e.preventDefault()
                setDeleteModal(true)
              }}>
              <TrashIcon />
            </IconButton>
            <Button
              color="brand"
              text="Done"
              onClick={() => {
                setEditing(false)
              }}
            />
          </QuestionCardFooterRight>
        </QuestionCardFooter>
      </QuestionCardBase>
      {/* MODALS */}
      <FormQuestionRulesModal
        open={rulesModalOpen}
        onClose={() => setRulesModalOpen(false)}
        question={props.question}
      />
      <ConfirmationModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        onConfirm={() => deleteQuestionMutation.mutate()}
        subtitle="This action cannot be undone"
        title="Are you sure you want to delete this question?"
      />
    </>
  ) : (
    <FormQuestionBase
      title={props.question.title}
      description={props.question.description}
      type={props.question.type}
      required={props.question.required}
      options={options.map((option) => option.option)}
      disabled
      onClick={() => setEditing(true)}
      css={{cursor: "pointer"}}
      hintPrefix={
        props.question.form_question_rules.length ? numRulesText : undefined
      }
      displayOptional
    />
  )
}

export const QuestionCardFooter = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "12px 20px",
  gap: "16px",
  width: "100%",
  borderTop: "1px solid $gray5",
})

export const QuestionCardFooterLeft = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0px",
  gap: "12px",
})
export const QuestionCardFooterRight = styled("div", {
  marginLeft: "auto",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0px",
  gap: "16px",
})

const RequiredSwitch = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0px",
  gap: "12px",
})

function FormQuestionOptionBuilder(props: {
  option: FormQuestionSelectOptionModel
  disableEdit?: boolean
}) {
  let queryClient = useQueryClient()
  let patchOptionMutation = useMutation({
    mutationFn: (args: Parameters<typeof patchFormQuestionOption>[1]) =>
      patchFormQuestionOption(props.option.id, args),
    onSuccess: (data) => {
      queryClient.setQueryData(["questions-options", props.option.id], data)
    },
  })

  let deleteOptionMutation = useMutation({
    mutationFn: () => deleteFormQuestionOption(props.option.id),
    onSuccess: () => {
      queryClient.removeQueries(["questions-options", props.option.id])
      queryClient.invalidateQueries({
        queryKey: ["forms-questions", props.option.form_question_id],
      })
    },
  })
  let formik = useFormik({
    initialValues: {
      option: props.option.option ?? "",
    },
    onSubmit: (values) => {
      patchOptionMutation.mutate(values)
    },
    enableReinitialize: true,
  })
  return (
    <QuestionOptionBuilder>
      <Textfield
        disabled={props.disableEdit}
        placeholder="Option"
        value={formik.values.option}
        onChange={formik.handleChange}
        id="option"
        fullWidth
        onBlur={() => {
          formik.handleSubmit()
        }}
      />
      <IconButton
        disabled={props.disableEdit}
        variant="ghost"
        onClick={() => {
          if (props.option) {
            deleteOptionMutation.mutate()
          }
        }}>
        <TrashIcon />
      </IconButton>
    </QuestionOptionBuilder>
  )
}

const QuestionOptionBuilder = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  gap: "8px",
})
