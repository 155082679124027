import {useQuery} from "@tanstack/react-query"
import {push} from "connected-react-router"
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import {getRetreat} from "../../../api/retreat"
import {RetreatModel} from "../../../models/retreat"
import WrongSignInPage from "../../../pages/auth/WrongSignInPage"
import LoadingPage from "../../../pages/misc/LoadingPage"
import {AppRoutes} from "../../../Stack"
import {RootState} from "../../../store"
import OopsPage from "../../pages/OopsPage"

const RetreatContext = createContext<
  {retreat: RetreatModel; retreatIdx: number} | undefined
>(undefined)

export function useRetreatV2() {
  const retreatContext = useContext(RetreatContext)
  if (retreatContext === undefined) {
    throw Error(
      "useRetreatV2 must be used within a RetreatProviderV2 component"
    )
  }
  return [retreatContext.retreat, retreatContext.retreatIdx] as const
}

export default function RetreatProviderV2(props: PropsWithChildren<{}>) {
  let dispatch = useDispatch()
  let router = useRouteMatch<{
    retreatIdx: string
  }>()
  let user = useSelector((state: RootState) => state.user.user)
  if (!user) {
    throw Error("retreat provider needs to be in a ProtectedRoute")
  }
  let retreatIdx = parseInt(router.params.retreatIdx)
  let [retreatId, setRetreatId] = useState(
    !isNaN(retreatIdx) && retreatIdx >= 0
      ? user.retreat_ids[retreatIdx]
      : undefined
  )

  useEffect(() => {
    setRetreatId(
      !isNaN(retreatIdx) && retreatIdx >= 0
        ? user!.retreat_ids[retreatIdx]
        : undefined
    )
  }, [retreatIdx, user])

  let retreatQuery = useQuery({
    enabled: retreatId != null,
    queryKey: ["retreats", retreatId],
    queryFn: () => getRetreat(retreatId!),
  })

  useEffect(() => {
    if (retreatIdx !== 0 && retreatId === undefined) {
      dispatch(push(AppRoutes.getPath("RetreatHomePage", {retreatIdx: "0"})))
    }
  }, [retreatIdx, dispatch, retreatId])

  return !user.retreat_ids || !user.retreat_ids.length ? (
    <WrongSignInPage attendeeIds={user.attendee_ids} />
  ) : retreatQuery.status === "loading" ? (
    <LoadingPage />
  ) : retreatQuery.status === "error" ? (
    <OopsPage />
  ) : (
    <RetreatContext.Provider
      value={{retreat: retreatQuery.data.retreat, retreatIdx}}>
      {props.children}
    </RetreatContext.Provider>
  )
}
