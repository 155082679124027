import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {Link} from "react-router-dom"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../Stack"

const MainBody = styled("div", {
  backgroundColor: theme.colors.white,
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingLeft: "20px",
  paddingTop: "30px",
  justifyContent: "center",
  alignItems: "center",
  gap: "24px",
})

const StyledImage = styled("img", {
  width: "320px",
  height: "144px",
  borderRadius: theme.shape.borderRadius,
})

const TextSection = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  width: "320px",
  gap: "12px",
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

const ButtonSection = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
})

const StyledLink = styled(Link, {
  textDecoration: "none",
})

export default function NoProposalsPage() {
  let [, retreatIdx] = useRetreat()
  return (
    <MainBody>
      <StyledImage src="https://flok-b32d43c.s3.amazonaws.com/NoProposalsImage.png" />
      <TextSection>
        <Text variant={"heading-lg"}>Waiting for Proposals</Text>
        <GrayText variant={"text-sm"}>
          We're currently working on collecting hotel proposals on your behalf
          and will let you know they are ready to view!
        </GrayText>
      </TextSection>
      <ButtonSection>
        <StyledLink
          to={AppRoutes.getPath("RetreatRfpPage", {
            retreatIdx: retreatIdx.toString(),
          })}>
          <Button text="View Event Profile" variant={"outline"} color="gray" />
        </StyledLink>
        <StyledLink
          to={AppRoutes.getPath("RetreatHotelSearchPage", {
            retreatIdx: retreatIdx.toString(),
          })}>
          <Button text="Explore Hotels" variant={"solid"} color="brand" />
        </StyledLink>
      </ButtonSection>
    </MainBody>
  )
}
