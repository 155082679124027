import {CircularProgress} from "@material-ui/core"
import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useEffect, useState} from "react"
import {useCommentThread} from "../../utils/retreatUtils"
import Comment from "./Comment"

const FlexColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "15px",
})

export const BottomButton = styled(Button, {
  [`${Text}`]: {
    color: theme.colors.gray11,
  },
  color: theme.colors.gray11,
})
type CommentListProps = {
  commentThreadId: number
  startCommentId?: number
  startingNumber: number
  alt?: boolean
  onShowMore?: () => void
  writing?: boolean
  showAll?: boolean
}

export default function CommentsList(props: CommentListProps) {
  let [thread, threadLoading] = useCommentThread(props.commentThreadId)
  let startIndex =
    thread?.comment_ids && props.startCommentId
      ? thread?.comment_ids.indexOf(props.startCommentId)
      : -1

  let [showNumber, setShowNumber] = useState(
    !props.startCommentId || !thread?.comment_ids
      ? props.startingNumber
      : Math.max(thread.comment_ids.length - startIndex, props.startingNumber)
  )
  useEffect(() => {
    if (props.startCommentId) {
      setShowNumber(
        !props.startCommentId || !thread?.comment_ids
          ? props.startingNumber
          : Math.max(
              thread.comment_ids.length - startIndex,
              props.startingNumber
            )
      )
    }
  }, [
    props.startCommentId,
    startIndex,
    thread?.comment_ids,
    props.startingNumber,
  ])

  const ShowMoreButton = thread ? (
    <BottomButton
      color="gray"
      text={`${thread.comment_ids.length - showNumber} more comment${
        thread.comment_ids.length - showNumber === 1 ? "" : "s"
      }`}
      variant="inline"
      onClick={() => {
        setShowNumber((showNumber) => showNumber + 4)
        props.onShowMore && props.onShowMore()
      }}></BottomButton>
  ) : (
    <></>
  )

  return (
    <FlexColumn>
      {!threadLoading ? (
        <>
          {thread &&
            showNumber < thread.comment_ids.length &&
            !props.alt &&
            !props.showAll && <>{ShowMoreButton}</>}
          {thread &&
            thread.comment_ids &&
            !props.showAll &&
            (
              thread.comment_ids.filter((id, i) => {
                return (
                  id && thread && i >= thread.comment_ids.length - showNumber
                )
              }) as number[]
            ).map((commentId, i) => {
              return (
                commentId && (
                  <Comment id={commentId} key={commentId} icon={!props.alt} />
                )
              )
            })}

          {thread &&
            props.showAll &&
            thread.comment_ids.map((commentId, i) => {
              return (
                <Comment id={commentId} key={commentId} icon={!props.alt} />
              )
            })}
          {thread &&
            showNumber < thread.comment_ids.length &&
            props.alt &&
            !props.showAll && <>{ShowMoreButton}</>}
          {!(thread && showNumber < thread.comment_ids.length) &&
            props.alt &&
            !props.writing && (
              <BottomButton
                color="gray"
                text={"Write comment"}
                variant="inline"
                onClick={() => {
                  setShowNumber((showNumber) => showNumber + 4)
                  props.onShowMore && props.onShowMore()
                }}
              />
            )}
        </>
      ) : (
        <div>
          <CircularProgress />
        </div>
      )}
    </FlexColumn>
  )
}
