import {CircularProgress} from "@material-ui/core"
import {PlusIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Checkbox} from "@summtech/flok-base/components/Checkbox"
import {Text} from "@summtech/flok-base/components/Text"
import {Textfield} from "@summtech/flok-base/components/Textfield"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table"
import {useFormik} from "formik"
import {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {
  FilterOperatorType,
  LocalFilterModel,
  RetreatEmailModel,
} from "../../models/communication"
import {RetreatAttendeeModel} from "../../models/retreat"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {ApiAction} from "../../store/actions/api"
import {
  deleteAudienceToAttendee,
  patchRetreatAudience,
  patchRetreatEmail,
  postAudienceToAttendee,
  postRetreatAudience,
  previewAudienceAttendees,
} from "../../store/actions/retreat"
import {useRetreatAttendees} from "../../utils/attendeeUtils"
import {useAttendeeColumns, useRetreatAudience} from "../../utils/retreatUtils"
import AppModal, {AppModalProps} from "../app/AppModal"
import {TableFooter} from "../app/attendees/AttendeesTable"
import {FilterColumnModel, FilterRule} from "../app/attendees/FilterRule"

const AudienceModalBody = styled("div", {
  display: "flex",
  flexDirection: "row",
  minWidth: "960px",
  minHeight: "560px",
})
const AudienceModalBodyLeft = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  padding: "10px 8px",
  borderRight: `1px solid ${theme.colors.gray6}`,
  width: "240px",
})
const AudienceModalBodyRight = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "16px 20px",
  width: "100%",
})

const StyledTabButton = styled(Button, {
  width: "100%",
})
const GroupRow = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: "30px",
})
const GroupRowLeft = styled("div", {
  width: "48px",
  padding: "12px",
})

const AttendeesCounter = styled("div", {
  display: "flex",
  marginTop: "auto",
  marginRight: "auto",
})
export default function AudienceModal(
  props: AppModalProps & {email: RetreatEmailModel}
) {
  let [retreat] = useRetreat()
  async function createAudience(selectionType: "ATTENDEES" | "FILTERS") {
    let response = (await dispatch(
      postRetreatAudience(retreat.id, selectionType)
    )) as unknown as ApiAction
    if (!response.error) {
      await dispatch(
        patchRetreatEmail(props.email.id, {
          audience_id: response.payload.retreat_audience.id,
        })
      )
    }
    return response
  }
  let dispatch = useDispatch()

  let [attendees, loadingAttendees] = useRetreatAttendees(retreat.id)
  let [audience] = useRetreatAudience(props.email.audience_id ?? -1)
  const [audienceType, setAudienceType] = useState<"ATTENDEES" | "FILTERS">(
    audience?.selection_type ?? "FILTERS"
  )

  useEffect(() => {
    if (audience?.selection_type) {
      setAudienceType(audience.selection_type)
    }
  }, [audience])

  let attendeeFormik = useFormik({
    initialValues: {
      attendee_ids: audience?.attendee_ids ?? [],
    },
    onSubmit: (values) => {},
    enableReinitialize: true,
  })

  let [attendeesSelected, setAttendeesSelected] = useState<number[]>([])
  useEffect(() => {
    async function updateAttendees() {
      let updated = (await dispatch(
        previewAudienceAttendees(audience?.id ?? -1)
      )) as unknown as ApiAction
      if (!updated.error) {
        setAttendeesSelected(updated.payload.attendee_ids)
      }
    }
    updateAttendees()
  }, [])

  let numberOfAttendees =
    audienceType === "FILTERS"
      ? attendeesSelected.length
      : audience?.attendee_ids.length ?? 0

  return (
    <AppModal open={props.open} onClose={props.onClose}>
      <AppModal.Header>Custom Recipients</AppModal.Header>
      <AudienceModalBody>
        <AudienceModalBodyLeft>
          <StyledTabButton
            variant={audienceType === "FILTERS" ? "solid" : "ghost"}
            color={"gray"}
            text="Select with filter"
            onClick={() => {
              setAudienceType("FILTERS")
              if (audience) {
                dispatch(
                  patchRetreatAudience(audience.id, {
                    selection_type: "FILTERS",
                  })
                )
              } else {
                createAudience("FILTERS")
              }
            }}
          />
          <StyledTabButton
            variant={audienceType === "ATTENDEES" ? "solid" : "ghost"}
            color={"gray"}
            text="Select with list"
            onClick={() => {
              setAudienceType("ATTENDEES")
              if (audience) {
                dispatch(
                  patchRetreatAudience(audience.id, {
                    selection_type: "ATTENDEES",
                  })
                )
              } else {
                createAudience("ATTENDEES")
              }
            }}
          />
        </AudienceModalBodyLeft>
        <AudienceModalBodyRight>
          <Text>
            {audienceType === "FILTERS"
              ? "Send email to all attendees..."
              : "Send email to..."}
          </Text>
          {/* {audienceType === "FILTERS" && (
            <AudienceFilterBuilder
              // audienceId={props.email.audience_id ?? -1}
              createAudience={createAudience}
              setNewAttendees={setAttendeesSelected}
            />
          )} */}
          {audienceType === "ATTENDEES" &&
            (loadingAttendees ? (
              <LoadingTable />
            ) : (
              <AttendeeSelectorTable
                attendees={attendees}
                onChangeSelectedAttendee={async (attendeeId, checked) => {
                  if (!checked && audience) {
                    let response = (await dispatch(
                      deleteAudienceToAttendee(audience.id, attendeeId)
                    )) as unknown as ApiAction
                    if (!response.error) {
                      attendeeFormik.setFieldValue(
                        "attendee_ids",
                        [...attendeeFormik.values.attendee_ids].filter(
                          (id) => id !== attendeeId
                        )
                      )
                    }
                  } else if (checked) {
                    let currentAudience =
                      audience ??
                      (await createAudience("ATTENDEES")).payload
                        .retreat_audience

                    dispatch(
                      postAudienceToAttendee(currentAudience.id, attendeeId)
                    )
                    attendeeFormik.setFieldValue("attendee_ids", [
                      ...attendeeFormik.values.attendee_ids,
                      attendeeId,
                    ])
                  }
                }}
                selectedAttendees={attendeeFormik.values.attendee_ids}
              />
            ))}
          <AttendeesCounter>
            <GrayText variant={"text-sm-plus"}>
              {numberOfAttendees} recipients selected
            </GrayText>
          </AttendeesCounter>
        </AudienceModalBodyRight>
      </AudienceModalBody>
      <AppModal.Footer>
        <Button text="Done" onClick={props.onClose} color="brand" />
      </AppModal.Footer>
    </AppModal>
  )
}

const StyledAttendeeGroupButton = styled("div", {
  width: "100%",
  border: `1px solid ${theme.colors.gray6}`,
  borderRadius: theme.shape.borderRadius,
  background: theme.colors.gray2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px",
  cursor: "pointer",
})
const StyledAttendeeGroupButtonContent = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

const StyledAddButton = styled(Button, {
  color: theme.colors.gray11,
})

const FilterSelectorWrapper = styled("div", {
  background: theme.colors.gray2,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.colors.gray6}`,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "12px",
  padding: "12px",
  // "& input": {
  //   maxWidth: "210px",
  // },
  // "& button": {
  //   maxWidth: "210px",
  // },
})

function AttendeeGroupFiltersSelector(props: {
  groupFilterState: LocalFilterModel[]
  onChangeFilterState: (newFilters: LocalFilterModel[]) => void
  setNewAttendees?: (attendeeIds: number[]) => void
  attendeeGroupId: number
}) {
  let [retreat] = useRetreat()
  let columns = useAttendeeColumns(retreat)
  let dispatch = useDispatch()
  let formik = useFormik({
    initialValues: {
      filters: props.groupFilterState,
    },
    onSubmit: () => {},
    enableReinitialize: true,
  })

  return (
    <FilterSelectorWrapper>
      <GrayText variant={"text-sm-plus"}>
        All of these conditions are true
      </GrayText>
      {formik.values.filters.map((filter, index) => {
        let isArray = ["MULTI_SELECT", "SINGLE_SELECT"].includes(
          columns.find((column) => {
            return column.id === filter.field
          })?.type ?? ""
        )
        return (
          <FilterRule
            columnValue={filter.field}
            columns={columns as FilterColumnModel[]}
            filterValue={
              filter.value && !isArray && typeof filter.value === "object"
                ? filter.value[0]
                : filter.value
            }
            onChangeColumn={async (newColumn) => {
              isArray = ["MULTI_SELECT", "SINGLE_SELECT"].includes(
                columns.find((column) => {
                  return column.id === newColumn
                })?.type ?? ""
              )
              let operator: FilterOperatorType = isArray
                ? "arrIncludesAll"
                : "includesString"

              let formikCopy = [...formik.values.filters]
              formikCopy[index] = {
                ...formikCopy[index],
                field: newColumn,
                operator_type: operator,
                value: isArray ? [] : [""],
              }
              // let response = (await dispatch(
              //   patchAttendeeGroupFilter(filter.id, formikCopy[index])
              // )) as unknown as ApiAction
              // if (!response.error && attendeeGroup && props.setNewAttendees) {
              //   let updated = (await dispatch(
              //     previewAudienceAttendees(attendeeGroup.audience_id)
              //   )) as unknown as ApiAction
              //   if (!updated.error) {
              //     props.setNewAttendees(updated.payload.attendee_ids)
              //   }
              // }

              await formik.setFieldValue("filters", formikCopy)
              props.onChangeFilterState(formik.values.filters)
            }}
            onChangeValue={async (newValue) => {
              let formikCopy = [...formik.values.filters]
              formikCopy[index] = {
                ...formikCopy[index],
                value: typeof newValue === "string" ? [newValue] : newValue,
              }
              formik.setFieldValue("filters", formikCopy)
            }}
            onValueBlur={async (newValue) => {
              let updatedValue =
                newValue !== undefined
                  ? typeof newValue === "string"
                    ? [newValue]
                    : newValue
                  : formik.values.filters[index].value
              let formikCopy = [...formik.values.filters]
              formikCopy[index] = {
                ...formikCopy[index],
                value: updatedValue,
              }
              props.onChangeFilterState(formikCopy)
            }}
            onDelete={async () => {
              let formikCopy = [...formik.values.filters]
              formikCopy.splice(index, 1)
              formik.setFieldValue("filters", formikCopy)
              props.onChangeFilterState(formikCopy)
            }}
          />
        )
      })}
      <StyledAddButton
        variant={"ghost"}
        text="Add Filter"
        onClick={async () => {
          let newFilters = [
            ...formik.values.filters,
            {
              attendee_group_id: props.attendeeGroupId,
              field: "",
              value: [""],
              operator_type: undefined,
            },
          ]
          formik.setFieldValue("filters", newFilters)
          // @ts-ignore
          props.onChangeFilterState(newFilters)
        }}
      />
    </FilterSelectorWrapper>
  )
}

const StyledTable = styled("table", {
  width: "100%",
  borderCollapse: "collapse",
})

const StyledTableWrapper = styled("div", {
  border: `1px solid ${theme.colors.gray6}`,
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
})

const StyledTableWrapperHead = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "12px",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.colors.gray6}`,
})
const StyledTableRow = styled("tr", {
  borderTop: `1px solid ${theme.colors.gray6}`,
})

const TableHead = styled("thead", {
  background: theme.colors.gray2,
  padding: "15.5px 12px",
  gap: "12px",
  alignItems: "center",
  width: "100%",
  // borderTopLeftRadius: theme.shape.borderRadius,
  // borderTopRightRadius: theme.shape.borderRadius,
})

const StyledTD = styled("td", {
  padding: "12px",
})
const StyledTH = styled("th", {
  padding: "12px",
  textAlign: "left",
})

export function AttendeeSelectorTable(props: {
  attendees: RetreatAttendeeModel[]
  selectedAttendees: number[]
  onChangeSelectedAttendee: (attendeeId: number, checked: boolean) => void
}) {
  const [searchTerm, setSearchTerm] = useState("")
  let columnHelper = createColumnHelper<RetreatAttendeeModel>()
  let options: TableOptions<RetreatAttendeeModel> = {
    defaultColumn: {
      enableGlobalFilter: false,
      enableSorting: false,
      size: 150,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    state: {
      globalFilter: searchTerm,
    },
    globalFilterFn: "includesString",
    columns: [
      columnHelper.accessor("info_status", {
        header: "Name",
        cell: (cellProps) => {
          return (
            <>
              <Checkbox
                checked={
                  props.selectedAttendees.indexOf(cellProps.row.original.id) !==
                  -1
                }
                onCheckedChange={(checked) => {
                  props.onChangeSelectedAttendee(
                    cellProps.row.original.id,
                    checked === true ? true : false
                  )
                }}
              />
            </>
          )
        },
      }),
      columnHelper.accessor("first_name", {
        header: "Name",
        cell: (props) => {
          return (
            props.row.original.first_name + " " + props.row.original.last_name
          )
        },
      }),
      columnHelper.accessor("email_address", {
        header: "Email",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
    ],
    data: props.attendees,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: true,
  }
  const table = useReactTable(options)
  return (
    <StyledTableWrapper>
      <StyledTableWrapperHead>
        <Text variant={"text-sm-plus"}>Attendees</Text>
        <Textfield
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </StyledTableWrapperHead>
      <StyledTable>
        <TableHead>
          <StyledTH>
            <Checkbox checked={false} />
          </StyledTH>
          <StyledTH>
            <GrayText variant={"text-sm-plus"}>Name</GrayText>
          </StyledTH>
          <StyledTH>
            <GrayText variant={"text-sm-plus"}>Email</GrayText>
          </StyledTH>
        </TableHead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <StyledTableRow>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <StyledTD>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </StyledTD>
                  )
                })}
              </StyledTableRow>
            )
          })}
        </tbody>
      </StyledTable>
      <TableFooter
        rowCount={table.getFilteredRowModel().rows.length}
        currentPage={table.getState().pagination.pageIndex + 1}
        pageCount={table.getPageCount()}
        pageSize={table.getState().pagination.pageSize}
        onNextPage={table.getCanNextPage() ? table.nextPage : undefined}
        onPreviousPage={
          table.getCanPreviousPage() ? table.previousPage : undefined
        }
      />
    </StyledTableWrapper>
  )
}

const LoadingTableContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: "25px",
})

function LoadingTable() {
  return (
    <LoadingTableContainer>
      <CircularProgress />
    </LoadingTableContainer>
  )
}

const FilterBuilderContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
})

export function AudienceFilterBuilder(props: {
  filterState: LocalFilterModel[][]
  onChangeFilterState: (filterState: LocalFilterModel[][]) => void
  setNewAttendees?: (attendeeIds: number[]) => void
}) {
  return (
    <FilterBuilderContainer>
      {props.filterState?.map((groupFilterState, i) => {
        return (
          <GroupRow>
            <GroupRowLeft>
              <GrayText variant={"text-sm-plus"}>
                {i === 0 ? "Where" : "Or"}
              </GrayText>
            </GroupRowLeft>
            <AttendeeGroupFiltersSelector
              attendeeGroupId={groupFilterState[0]?.attendee_group_id}
              groupFilterState={groupFilterState}
              onChangeFilterState={(newFilters) => {
                let filterStateCopy = [...props.filterState]
                if (newFilters.length === 0) {
                  filterStateCopy.splice(i, 1)
                } else {
                  filterStateCopy[i] = newFilters
                }
                props.onChangeFilterState(filterStateCopy)
              }}
              setNewAttendees={props.setNewAttendees}
            />
          </GroupRow>
        )
      })}
      <GroupRow>
        <GroupRowLeft />
        <StyledAttendeeGroupButton
          onClick={() => {
            props.onChangeFilterState([...props.filterState, []])
          }}>
          <StyledAttendeeGroupButtonContent>
            <PlusIcon />
            <GrayText variant={"text-sm-plus"}>Add Condition Group</GrayText>
          </StyledAttendeeGroupButtonContent>
        </StyledAttendeeGroupButton>
      </GroupRow>
    </FilterBuilderContainer>
  )
}

export function PreviewAttendeesTable(props: {
  attendees: RetreatAttendeeModel[]
}) {
  let columnHelper = createColumnHelper<RetreatAttendeeModel>()
  let options: TableOptions<RetreatAttendeeModel> = {
    defaultColumn: {
      enableGlobalFilter: false,
      enableSorting: false,
      size: 150,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },

    globalFilterFn: "includesString",
    columns: [
      columnHelper.accessor("first_name", {
        header: "Name",
        cell: (props) => {
          return (
            props.row.original.first_name + " " + props.row.original.last_name
          )
        },
      }),
      columnHelper.accessor("email_address", {
        header: "Email",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
    ],
    data: props.attendees,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: true,
  }
  const table = useReactTable(options)
  return (
    <StyledTableWrapper>
      <StyledTable>
        <TableHead>
          <StyledTH>
            <GrayText variant={"text-sm-plus"}>Name</GrayText>
          </StyledTH>
          <StyledTH>
            <GrayText variant={"text-sm-plus"}>Email</GrayText>
          </StyledTH>
        </TableHead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <StyledTableRow>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <StyledTD>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </StyledTD>
                  )
                })}
              </StyledTableRow>
            )
          })}
        </tbody>
      </StyledTable>
      <TableFooter
        rowCount={table.getFilteredRowModel().rows.length}
        currentPage={table.getState().pagination.pageIndex + 1}
        pageCount={table.getPageCount()}
        pageSize={table.getState().pagination.pageSize}
        onNextPage={table.getCanNextPage() ? table.nextPage : undefined}
        onPreviousPage={
          table.getCanPreviousPage() ? table.previousPage : undefined
        }
      />
    </StyledTableWrapper>
  )
}
