import {Button} from "@summtech/flok-base/components/Button"
import {FormField} from "@summtech/flok-base/components/FormField"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useFormik} from "formik"
import {useState} from "react"
import {useDispatch} from "react-redux"
import * as yup from "yup"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {ApiAction} from "../../store/actions/api"
import {postInitialWebsite} from "../../store/actions/retreat"

const StyledForm = styled("form", {
  display: "flex",
  flexDirection: "column",
  width: "320px",
})

const StyledImage = styled("img", {
  width: "100%",
  marginBottom: "32px",
})
const StyledHeader = styled(Text, {
  marginBottom: "12px",
})
const StyledSubHeader = styled(Text, {
  marginBottom: "20px",
  color: theme.colors.gray11,
})
const StyledButton = styled(Button, {
  marginTop: "32px",
  marginLeft: "auto",
  marginRight: "auto",
})
type CreateAttendeeWebsiteProps = {
  onSuccessfulSubmit: () => void
}
export default function CreateAttendeeWebsite(
  props: CreateAttendeeWebsiteProps
) {
  let dispatch = useDispatch()
  let [retreat, retreatIdx] = useRetreat()
  let [loadingCreateWebsite, setLoadingCreateWebsite] = useState(false)
  let formik = useFormik({
    initialValues: {
      name: retreat.company_name,
    },
    onSubmit: (values) => {
      async function postNewWebsite() {
        setLoadingCreateWebsite(true)
        let response = (await dispatch(
          postInitialWebsite({
            name: values.name,
            retreat_id: retreat.id,
          })
        )) as unknown as ApiAction
        setLoadingCreateWebsite(false)
        if (!response.error) {
          props.onSuccessfulSubmit()
        }
      }
      postNewWebsite()
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .required()
        .matches(
          /^[aA-zZ\s0-9]+$/,
          "Can only contain letters, numbers, or spaces"
        ),
    }),
  })
  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <StyledImage src="https://flok-b32d43c.s3.us-east-1.amazonaws.com/create-website.png" />
      <StyledHeader variant="heading-lg">Create Event Website</StyledHeader>
      <StyledSubHeader>
        The event website is the place where the guests sign up for the retreat
        and get their information.
      </StyledSubHeader>
      <FormField
        value={formik.values.name}
        onChange={formik.handleChange}
        id="name"
        type="textfield"
        label="Website Name"
        fullWidth
        errorMessage={formik.errors.name}
      />
      <StyledButton
        text="Create Website"
        color="brand"
        type="submit"
        disabled={loadingCreateWebsite}
      />
    </StyledForm>
  )
}
