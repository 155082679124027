import {Badge} from "@summtech/flok-base/components/Badge"
import {ItineraryEventLabel, LabelToColor} from "../../models/retreat"

export function EventBadge(props: {label: ItineraryEventLabel}) {
  return (
    <Badge
      label={props.label[0].toUpperCase() + props.label.slice(1).toLowerCase()}
      colorString={`${LabelToColor[props.label].main}`}
    />
  )
}
