import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {ComponentProps} from "react"
import AppLinkButton from "../AppLinkButton"

/**
 * Sample USAGE:
 *
 * ```
 * <CenterMessage>
 *  <CenterMessage.Image src="" />
 *  <CenterMessage.Body>
 *    <CenterMessage.Title>Title</CenterMessage.Title>
 *    <CenterMessage.Description>Title</CenterMessage.Description>
 *   </CenterMessage.Body>
 *   <CenterMessage.Actions>
 *    <Button .../>
 *   </CenterMessage.Actions>
 * </CenterMessage>
 * ```
 *
 * This component consists of an image, title, description and actions container.
 * Reference of what it looks like: https://www.figma.com/file/npI9aWHiOZQXLKZjtgN0Qc/App?node-id=2138-36240&t=gZSxG4UOd1P35WeA-4
 */
export default function CenterMessage(props: ComponentProps<typeof Container>) {
  return <Container {...props} />
}
const Container = styled("div", {
  maxWidth: "340px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "32px",
})

CenterMessage.Image = styled("img", {
  width: "100%",
  height: "144px",
  borderRadius: "6px",
})

CenterMessage.Body = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
})

CenterMessage.Title = (props: ComponentProps<typeof Text>) => {
  return (
    <Text variant="heading-lg" {...props} css={{width: "100%", ...props.css}} />
  )
}

CenterMessage.Description = (props: ComponentProps<typeof Text>) => {
  return (
    <Text
      variant="text-sm"
      {...props}
      css={{color: "$gray11", width: "100%", ...props.css}}
    />
  )
}

CenterMessage.Actions = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
})

CenterMessage.Button = (props: ComponentProps<typeof Button>) => {
  return <Button variant="solid" color="brand" {...props} />
}

CenterMessage.LinkButton = (props: ComponentProps<typeof AppLinkButton>) => {
  return <AppLinkButton variant="solid" color="brand" {...props} />
}
