import {PlusIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {styled} from "@summtech/flok-base/stitches.config"
import React, {useState} from "react"
import {FormQuestionModel} from "../../../models/form"
import AppModal from "../../app/AppModal"
import {EditQuestionRule, NewQuestionRule} from "./FormQuestionRules"

export function FormQuestionRulesModal(props: {
  question: FormQuestionModel
  open: boolean
  onClose: () => void
}) {
  let [newRuleActive, setNewRuleActive] = useState(false)
  return (
    <AppModal open={props.open} onClose={props.onClose}>
      <AppModal.Header>Question Rules</AppModal.Header>
      <AppModal.Body>
        <ModalBody>
          {props.question.form_question_rules.map((ruleId, index) => (
            <EditQuestionRule ruleId={ruleId} index={index + 1} />
          ))}
          {newRuleActive || !props.question.form_question_rules.length ? (
            <NewQuestionRule
              index={props.question.form_question_rules.length + 1}
              questionId={props.question.id}
              onDelete={() => setNewRuleActive(false)}
              onAddSuccess={() => setNewRuleActive(false)}
            />
          ) : undefined}
          <Button
            onClick={() => setNewRuleActive(true)}
            disabled={newRuleActive}
            text="Add rule"
            variant="inline"
            startIcon={<PlusIcon />}></Button>
        </ModalBody>
      </AppModal.Body>
      <AppModal.Footer>
        <Button color="brand" text="Done" onClick={props.onClose} />
      </AppModal.Footer>
    </AppModal>
  )
}
const ModalBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
})
