import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {ComponentProps} from "react"

export function WebsitePortal(
  props: ComponentProps<typeof StyledWebsitePortal>
) {
  return <StyledWebsitePortal {...props} />
}

const StyledWebsitePortal = styled("div", {
  width: "100%",
  height: "100%",
  overflow: "auto",
  background: "$white",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})

const PortalHeader = styled("div", {
  height: "52px",
  width: "100%",
  borderBottomStyle: "solid",
  borderBottomWidth: "1px",
  borderBottomColor: "$gray6",
  display: "flex",
  flexDirection: "row",
  padding: "12px 20px",
  alignItems: "center",
  justifyContent: "space-between",
})

const PortalHeaderButtons = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
})

const PortalHeaderBalancer = styled("div", {
  width: "46px",
})

const PortalHeaderUrl = styled("div", {
  width: "400px",
  background: "$gray3",
  borderRadius: "6px",
  display: "flex",
  justifyContent: "center",
  textOverflow: "ellipsis",
  overflow: "hidden",
  padding: "0px 5px",
  [`${Text}`]: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
})

const PortalHeaderButton = styled("div", {
  height: "10px",
  width: "10px",
  borderRadius: "60px",
  borderStyle: "solid",
  borderWidth: "0.5px",
  variants: {
    color: {
      red: {
        background: "#ED6A5E",
        borderColor: "#CF594E",
      },
      yellow: {
        background: "#F6C351",
        borderColor: "#D79F3E",
      },
      green: {
        background: "#61C455",
        borderColor: "#52A63D",
      },
    },
  },
})

WebsitePortal.Header = (props: {url: string}) => (
  <PortalHeader>
    <PortalHeaderButtons>
      <PortalHeaderButton color="red" />
      <PortalHeaderButton color="yellow" />
      <PortalHeaderButton color="green" />
    </PortalHeaderButtons>
    <PortalHeaderUrl>
      <Text variant="text-sm">{props.url}</Text>
    </PortalHeaderUrl>
    <PortalHeaderBalancer />
  </PortalHeader>
)

const BodyContainer = styled("div", {
  width: "100%",
  overflow: "auto",
  flex: 1,
})
const Body = styled("div", {
  maxWidth: "800px",
  padding: "32px",
  marginInline: "auto",
})

WebsitePortal.Body = (props: ComponentProps<typeof Body>) => (
  <BodyContainer>
    <Body {...props} />
  </BodyContainer>
)
