import {Box, CircularProgress, Typography} from "@material-ui/core"
import {createRef, useEffect, useState} from "react"
import TagManager from "react-gtm-module"
import {useDispatch} from "react-redux"
import {RouteComponentProps, withRouter} from "react-router-dom"
import AppLoadingScreen from "../../components/base/AppLoadingScreen"
import AppPageSpotlightImage from "../../components/lodging/AppPageSpotlightImage"
import PageBody from "../../components/page/PageBody"
import PageContainer from "../../components/page/PageContainer"
import PageOverlay from "../../components/page/PageOverlay"
import {ResourceNotFound} from "../../models"
import {RetreatModel} from "../../models/retreat"
import {convertGuid, useScript} from "../../utils"
import {useRetreatByGuid} from "../../utils/retreatUtils"

type RetreatPreferencesFormPageProps = RouteComponentProps<{
  retreatGuid: string
}>
function DeprecatedRetreatCalendlyBookPage(
  props: RetreatPreferencesFormPageProps
) {
  // Setup
  let dispatch = useDispatch()
  let calendlyRef = createRef<HTMLDivElement>()

  // Path params
  let retreatGuid = convertGuid(props.match.params.retreatGuid)
  let [retreat] = useRetreatByGuid(retreatGuid)

  useEffect(() => {
    if (retreat && retreat !== ResourceNotFound) {
      // @ts-ignore
      window.email = retreat.contact_email
    }
  }, [retreat])

  const [calendlyLoaded] = useScript(
    "https://assets.calendly.com/assets/external/widget.js"
  )

  function getCookie(name: string) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts && parts.length === 2) {
      return parts.pop()!.split(";").shift()
    }
  }

  useEffect(() => {
    if (retreat && retreat !== "RESOURCE_NOT_FOUND" && calendlyLoaded) {
      //@ts-ignore
      Calendly.initInlineWidget({
        url: "https://calendly.com/flok_sales/flok-intro-call-1?hide_gdpr_banner=1",
        parentElement: calendlyRef.current,
        prefill: {
          email: (retreat as RetreatModel).contact_email,
          name: retreat.contact_name,
          customAnswers: {
            a1: (retreat as RetreatModel).company_name ?? "",
          },
        },
        utm: {
          utmContent: (retreat as RetreatModel).id.toString(),
          utmCampaign: getCookie("utm_campaign"),
          utmSource: getCookie("utm_source"),
          utmMedium: getCookie("utm_medium"),
        },
      })
    }
  }, [calendlyLoaded, retreat])

  let [showSpinner, setShowSpinner] = useState(true)
  function onMessage(ev: MessageEvent<any>): any {
    if (ev && ev.data && ev.data.event === "calendly.event_type_viewed") {
      setShowSpinner(false)
    }
    if (ev && ev.data && ev.data.event === "calendly.event_scheduled") {
      TagManager.dataLayer({
        dataLayer: {
          event: "CALENDLY_EVENT_SCHEDULED",
        },
      })
    }
  }
  useEffect(() => {
    window.addEventListener("message", onMessage)
    return () => window.removeEventListener("message", onMessage)
  }, [])

  return (
    <PageContainer>
      <PageBody>
        {retreat === ResourceNotFound ? (
          <Box
            height={"100%"}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Typography variant="h4">Retreat not found</Typography>
          </Box>
        ) : (
          <PageOverlay
            right={
              <AppPageSpotlightImage
                imageUrl="https://flok-b32d43c.s3.amazonaws.com/hotels/fairmont_sidebar.png"
                imageAlt="Fairmont Austin pool overview in the evening"
                imagePosition="bottom-right"
              />
            }>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              ref={calendlyRef}>
              {showSpinner ? (
                <Box
                  css={{
                    position: "absolute",
                    my: "auto",
                    mx: "auto",
                  }}>
                  <CircularProgress />
                </Box>
              ) : undefined}
            </div>
          </PageOverlay>
        )}
      </PageBody>
    </PageContainer>
  )
}

export default withRouter(DeprecatedRetreatCalendlyBookPage)
