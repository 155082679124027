import {TrashIcon} from "@radix-ui/react-icons"
import {DatetimePicker} from "@summtech/flok-base/components/DatetimePicker"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {MultiSelect} from "@summtech/flok-base/components/MultiSelect"
import {Select} from "@summtech/flok-base/components/Select"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Text} from "@summtech/flok-base/components/Text"
import {Textfield} from "@summtech/flok-base/components/Textfield"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {ReactNode} from "react"
import {FormQuestionType} from "../../../models/form"

const TimeContainer = styled("div", {
  width: "360px",
})
const FilterRuleContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
})

const FilterRuleMiddle = styled("div", {
  width: "150px",
  color: theme.colors.gray11,
  justifyContent: "center",
  display: "flex",
})

export type FilterColumnModel = {
  type?: FormQuestionType
  options?: {value: string; label: ReactNode}[]
  title: string
  id: string
}
export function FilterRule(props: {
  onChangeColumn: (newColumn: string) => void
  onChangeValue: (newValue: string | string[]) => void
  filterValue: string | undefined | string[]
  columnValue: string | undefined
  columns: FilterColumnModel[]
  onDelete: () => void
  disabledDelete?: boolean
  onValueBlur?: (newValue?: string | string[]) => void
}) {
  let selectedColumn = props.columns.find(
    (column) => column.id === props.columnValue
  )

  function renderValueField() {
    if (selectedColumn?.type === "MULTI_SELECT") {
      let filterValue =
        typeof props.filterValue === "object" ? props.filterValue : []
      return (
        <MultiSelect
          fullWidth
          csv={props.columnValue ? !isNaN(parseInt(props.columnValue)) : false}
          options={
            selectedColumn.options
              ? selectedColumn.options.map((option) => {
                  return {value: option.value, label: option.label}
                }, {})
              : []
          }
          onChange={(newValue) => {
            props.onChangeValue(newValue)
            props.onValueBlur && props.onValueBlur(newValue)
          }}
          selectedValues={filterValue ?? []}></MultiSelect>
      )
    } else if (selectedColumn?.type === "SINGLE_SELECT") {
      let filterValue =
        typeof props.filterValue === "object"
          ? props.filterValue[0]
          : props.filterValue
      return (
        <Select
          fullWidth
          onChange={(newValue) => {
            props.onChangeValue(newValue)
            props.onValueBlur && props.onValueBlur(newValue)
          }}
          value={filterValue}>
          {selectedColumn.options &&
            selectedColumn.options.map((option) => {
              return <SelectItem value={option.value} label={option.label} />
            })}
        </Select>
      )
    } else if (
      selectedColumn?.type === "LONG_ANSWER" ||
      selectedColumn?.type === "SHORT_ANSWER"
    ) {
      return (
        <Textfield
          fullWidth
          value={props.filterValue}
          onChange={(e) => props.onChangeValue(e.target.value)}
          onBlur={() => {
            props.onValueBlur && props.onValueBlur()
          }}
        />
      )
    } else if (
      selectedColumn?.type === "DATE" ||
      selectedColumn?.type === "DATETIME"
    ) {
      return (
        <TimeContainer>
          <DatetimePicker
            fullWidth
            value={props.filterValue as string}
            onChange={(newValue) => {
              props.onChangeValue(newValue)
              props.onValueBlur && props.onValueBlur(newValue)
            }}
          />
        </TimeContainer>
      )
    } else {
      return (
        <Textfield
          fullWidth
          onBlur={() => {
            props.onValueBlur && props.onValueBlur()
          }}
          value={props.filterValue}
          disabled
          onChange={(e) => props.onChangeValue(e.target.value)}
        />
      )
    }
  }

  function columnTypeToText(type: FormQuestionType, multiple = false) {
    switch (type) {
      case "SHORT_ANSWER":
      case "LONG_ANSWER":
        return "contains"
      case "DATE":
      case "DATETIME":
        return "is on"
      case "SINGLE_SELECT":
        return "is"
      case "MULTI_SELECT":
        if (multiple) {
          return "includes all of"
        } else {
          return "includes"
        }
      default:
        return "is"
    }
  }
  return (
    <FilterRuleContainer>
      <Select
        onChange={props.onChangeColumn}
        value={props.columnValue}
        fullWidth>
        {props.columns.map((column) => {
          return <SelectItem label={column.title} value={column.id} />
        })}
      </Select>
      <FilterRuleMiddle>
        <Text variant={"text-sm"}>
          {selectedColumn?.type
            ? columnTypeToText(
                selectedColumn.type,
                typeof props.filterValue === "object" &&
                  props.filterValue &&
                  props.filterValue.length > 1
              )
            : "is"}
        </Text>
      </FilterRuleMiddle>
      {renderValueField()}
      <IconButton
        variant="ghost"
        disabled={props.disabledDelete}
        onClick={props.onDelete}>
        <TrashIcon />
      </IconButton>
    </FilterRuleContainer>
  )
}
