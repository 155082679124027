import {MixerHorizontalIcon, PlusIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {styled, theme} from "@summtech/flok-base/stitches.config"

import {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {RootState} from "../../../store"
import AppModal from "../AppModal"
import FilterSelect from "./FilterSelect"

let StyledFilterModalFooter = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "right",
  alignSelf: "center",
  marginTop: "auto",
  gap: "8px",
  paddingBlock: "12px",
  paddingInline: "20px",
  borderTop: `1px solid ${theme.colors.gray6}`,
})
let StyledButton = styled(Button, {
  marginLeft: "10px",
  marginBlock: "10px",
})

export type ColumnFilterType = {id: string; value: any[]}
type FilteringPopperProps = {
  filteringState: ColumnFilterType[]
  setFilteringState: (newFilter: ColumnFilterType[]) => void
  filteringOptions: {
    [key: string]: {
      key?: string
      label?: string
      optionsArray: {optionValue?: string | number; optionLabel?: string}[]
    }
  }
  showBadge: boolean
}
export function FilteringPopper(props: FilteringPopperProps) {
  let {filteringState, setFilteringState, filteringOptions} = props
  let [openModal, setOpenModal] = useState(false)
  let [filtersArray, setFiltersArray] = useState([] as ColumnFilterType[])
  let labelOptions = useSelector((state: RootState) => {
    return state.retreat.labels
  })
  let assigneeOptions = useSelector((state: RootState) => {
    return state.retreat.users
  })

  filteringOptions = {
    ...filteringOptions,
    labels: {
      key: "label",
      label: "Label",
      optionsArray: Object.values(labelOptions).map((label) => {
        return {
          optionValue: label?.id,
          optionLabel: label?.text,
        }
      }),
    },
    assignees: {
      key: "assignees",
      label: "Assignee",
      optionsArray: Object.values(assigneeOptions).map((user) => {
        return {
          optionValue: user?.id,
          optionLabel: `${user?.first_name} ${user?.last_name}`,
        }
      }),
    },
  }

  function handleFilterKeyReplace(oldFilterKey: string, newFilterKey: string) {
    let indexOf = filtersArray.findIndex((filter) => filter.id === oldFilterKey)
    let newFilters = [...filtersArray]
    newFilters[indexOf] = {id: newFilterKey, value: []}
    return setFiltersArray(newFilters)
  }
  function handleFilterChange(newFilter: {
    id: string
    value: string | number | Date
  }) {
    if (filtersArray.find((filter) => filter.id === newFilter.id)) {
      let indexOf = filtersArray.findIndex(
        (filter) => filter.id === newFilter.id
      )
      let newFilters = [...filtersArray]
      newFilters[indexOf].value.push(newFilter.value)
      return setFiltersArray(newFilters)
    } else {
      return setFiltersArray(
        [...filtersArray, {id: newFilter.id, value: [newFilter.value]}].filter(
          (filter) => !!filter.id && !!filter.value
        )
      )
    }
  }
  function handleFilterDelete(filterIdToDelete: string, filterValue?: unknown) {
    if (filterValue) {
      let indexOf = filtersArray.findIndex(
        (filter) => filter.id === filterIdToDelete
      )
      let newFilters = [...filtersArray]
      newFilters[indexOf].value = newFilters[indexOf].value.filter(
        (value) => value !== filterValue
      )
      return setFiltersArray(newFilters)
    }
    return setFiltersArray([
      ...filtersArray.filter((filter) => filter.id !== filterIdToDelete),
    ])
  }
  function handleFilterSubmit() {
    setFilteringState(
      filtersArray.filter((filter) => !!filter.id && !!filter.value)
    )
    setOpenModal(false)
  }
  function handleFilterReset() {
    setFiltersArray([])
  }
  useEffect(() => {
    if (filteringState) {
      setFiltersArray(filteringState)
    }
  }, [filteringState])

  return (
    <div>
      <Button
        type="button"
        variant="outline"
        startIcon={<MixerHorizontalIcon />}
        text="Filter"
        onClick={() => {
          setOpenModal(true)
        }}></Button>
      <AppModal open={openModal} onClose={() => setOpenModal(false)}>
        <AppModal.Header>Filter</AppModal.Header>
        <AppModal.Body>
          {filtersArray.length ? (
            filtersArray
              .map((f) => f.id)
              .map((columnFilterKey) => {
                return (
                  <FilterSelect
                    addFilter={(newFilter: {
                      id: string
                      value: string | number | Date
                    }) => handleFilterChange(newFilter)}
                    onDelete={(filterKey: string, filerValue?: unknown) =>
                      handleFilterDelete(filterKey, filerValue)
                    }
                    onKeyReplace={(
                      oldFilterKey: string,
                      newFilterKey: string
                    ) => handleFilterKeyReplace(oldFilterKey, newFilterKey)}
                    alreadySelectedFilters={filtersArray}
                    filterId={columnFilterKey}
                    filteringOptions={filteringOptions}
                  />
                )
              })
          ) : (
            <FilterSelect
              addFilter={(newFilter: {
                id: string
                value: string | number | Date
              }) => handleFilterChange(newFilter)}
              onDelete={(filterKey: string, filerValue?: unknown) =>
                handleFilterDelete(filterKey, filerValue)
              }
              onKeyReplace={(oldFilterKey: string, newFilterKey: string) =>
                handleFilterKeyReplace(oldFilterKey, newFilterKey)
              }
              alreadySelectedFilters={filtersArray}
              filterId={""}
              filteringOptions={filteringOptions}
            />
          )}
          <StyledButton
            startIcon={<PlusIcon />}
            text="Add Filter"
            variant={"ghost"}
            onClick={() =>
              setFiltersArray([...filtersArray, {id: "", value: []}])
            }
          />
        </AppModal.Body>
        <AppModal.Footer>
          <Button
            text="Reset Filters"
            variant={"outline"}
            onClick={() => handleFilterReset()}
          />
          <Button
            text="Apply"
            variant={"solid"}
            color="brand"
            onClick={() => {
              handleFilterSubmit()
            }}
          />
        </AppModal.Footer>
      </AppModal>
    </div>
  )
}
