import {
  BarChartIcon,
  CheckCircledIcon,
  CircleIcon,
  DotsVerticalIcon,
  TrashIcon,
} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Text} from "@summtech/flok-base/components/Text"
import {css, styled, theme} from "@summtech/flok-base/stitches.config"
import {useMutation, useQueries, useQueryClient} from "@tanstack/react-query"
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {createColumnHelper, TableOptions} from "@tanstack/table-core"
import {push} from "connected-react-router"
import {useState} from "react"
import {useDispatch} from "react-redux"
import {deleteForm, getForm, postForm} from "../../api/form"
import {postRetreatSurvey} from "../../api/retreat"
import PageBody from "../../components/page/PageBody"
import {
  FormCreationTypeEnum,
  FormModel,
  FormStatusEnum,
  FormStatusType,
} from "../../models/form"
import {AppRoutes} from "../../Stack"
import {useRetreatV2} from "../app/common/RetreatProviderV2"
import ConfirmationModal from "../app/ConfirmationModal"

export default function SurveysPage() {
  let [retreat, retreatIdx] = useRetreatV2()
  let dispatch = useDispatch()
  let queryClient = useQueryClient()
  let formQueries = useQueries({
    queries: retreat.surveys.map((survey) => ({
      queryKey: ["forms", survey.id],
      queryFn: () => getForm(survey.id),
    })),
  })
  let deleteFormQuery = useMutation({
    mutationFn: (variables: {id: number}) => deleteForm(variables.id),
    onSuccess: (data, variables) => {
      queryClient.removeQueries(["forms", variables.id])
      queryClient.invalidateQueries(["retreats", retreat.id])
    },
  })
  let columnHelper = createColumnHelper<FormModel>()
  let options: TableOptions<FormModel> = {
    initialState: {
      sorting: [{id: "status", desc: true}],
    },
    columns: [
      columnHelper.accessor("title", {
        header: "Name",
        size: 600,
        cell: (context) => (
          <SurveyNameCell name={context.cell.getValue() || "Untitled survey"} />
        ),
      }),
      columnHelper.accessor("status", {
        header: "Status",
        size: 150,
        cell: (context) => (
          <SurveyStatusCell status={context.cell.getValue()} />
        ),
      }),
      columnHelper.display({
        id: "actions",
        size: 150,
        cell: (context) => (
          <ActionsCell
            title={"Are you sure you want to delete this survey?"}
            subtitle="This action cannot be undone. All existing survey responses will be saved."
            onDelete={() =>
              deleteFormQuery.mutate({id: context.row.original.id})
            }
          />
        ),
      }),
    ],
    data: formQueries
      .filter((form) => form.status === "success")
      .map((form) => form.data!.form),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  }
  const table = useReactTable(options)
  function redirectSurvey(id: number) {
    dispatch(
      push(
        AppRoutes.getPath("SurveyBuilderPage", {
          retreatIdx: retreatIdx.toString(),
          surveyId: id.toString(),
        })
      )
    )
  }
  let postRetreatSurveyMutation = useMutation({
    mutationFn: (args: {surveyId: number}) =>
      postRetreatSurvey(retreat.id, args.surveyId),
    onSuccess: (data, args) => {
      queryClient
        .invalidateQueries({queryKey: ["retreats", retreat.id]})
        .then(() => redirectSurvey(args.surveyId))
    },
  })
  let postSurveyMutation = useMutation({
    mutationFn: () =>
      postForm(FormCreationTypeEnum.SURVEY, {title: "Untitled survey"}),
    onSuccess: (data) => {
      queryClient.setQueryData(["forms", data.form.id], data)
      postRetreatSurveyMutation.mutate({surveyId: data.form.id})
    },
  })
  return (
    <PageBody appBar backgroundWhite>
      <Main>
        <Header>
          <Text variant="heading-lg">Surveys</Text>
          <Button
            variant="solid"
            color="brand"
            text="New Survey"
            onClick={() => postSurveyMutation.mutate()}
          />
        </Header>
        <TableContainer>
          <Table>
            <TableHead>
              <TableHeaderRow>
                {table.getHeaderGroups().map((headerGroup) =>
                  headerGroup.headers
                    .filter((header) => header.depth === 1)
                    .map((header) => (
                      <th
                        className={HeaderCellCss()}
                        style={
                          header.column.columnDef.size != null
                            ? {width: header.column.columnDef.size}
                            : undefined
                        }>
                        <Text variant="text-sm-plus">
                          {header.isPlaceholder
                            ? "\u00A0"
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Text>
                      </th>
                    ))
                )}
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableBodyRow
                  key={row.id}
                  onClick={() => redirectSurvey(row.original.id)}>
                  {row.getVisibleCells().map((cell) => (
                    <td className={DataCellCss()}>
                      <Text variant="text-sm">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Text>
                    </td>
                  ))}
                </TableBodyRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Main>
    </PageBody>
  )
}

const Main = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px 0px 0px",
  gap: "16px",
  "& > *": {
    width: "100%",
  },
  color: "$gray12",
})

const Header = styled("div", {
  paddingInline: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
})
const TableContainer = styled("div", {paddingInline: "20px", width: "100%"})
const Table = styled("table", {
  width: "100%",
  borderCollapse: "collapse",
})
const TableRowCss = css({
  borderBottomColor: "$gray6",
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
})
const TableHeaderRow = styled("tr", TableRowCss, {
  padding: "4px",
})
const HeaderCellCss = css({
  paddingBlock: "12px",
  paddingInline: "16px",
  whiteSpace: "nowrap",
  color: "$gray11",
  textAlign: "left",
})

const DataCellCss = css({
  paddingBlock: "18px",
  paddingInline: "12px",
  whiteSpace: "nowrap",
  color: "$gray12",
  textAlign: "left",
})

const TableBodyRow = styled("tr", TableRowCss, {
  padding: "12px 0px",
  "&:hover": {
    backgroundColor: "$gray1",
    cursor: "pointer",
    [`& .actions-trigger`]: {
      opacity: 1,
    },
  },
  [`& .actions-trigger`]: {
    opacity: 0,
  },
})
const TableHead = styled("thead")
const TableBody = styled("tbody")

const SurveyNameCellContainer = styled("div", {
  display: "flex",
  gap: "12px",
  alignItems: "center",
})
const SurveyIconContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "$gray3",
  borderRadius: "6px",
  width: "32px",
  height: "32px",
  color: "$gray11",
})

const SurveyNameCell = (props: {name: string}) => (
  <SurveyNameCellContainer>
    <SurveyIconContainer>
      <BarChartIcon />
    </SurveyIconContainer>
    {props.name}
  </SurveyNameCellContainer>
)

const SurveyStatusCellContainer = styled("div", {
  display: "flex",
  gap: "6px",
  alignItems: "center",
})
export const SurveyStatusCell = (props: {status: FormStatusType}) => (
  <SurveyStatusCellContainer>
    {props.status === FormStatusEnum.DRAFT ? (
      <CircleIcon />
    ) : (
      <CheckCircledIcon color={theme.colors.blue11.toString()} />
    )}
    {props.status === FormStatusEnum.DRAFT ? "Draft" : "Live"}
  </SurveyStatusCellContainer>
)

export function ActionsCell(props: {
  onDelete: () => void
  title: string
  subtitle: string
}) {
  let [confirmDelete, setConfirmDelete] = useState(false)
  return (
    <>
      <ConfirmationModal
        open={confirmDelete}
        title={props.title}
        subtitle={props.subtitle}
        confirmText="Delete"
        onConfirm={props.onDelete}
        onClose={() => setConfirmDelete(false)}
      />
      <Dropdown
        button={
          <IconButton className={"actions-trigger"} size="sm" variant="solid">
            <DotsVerticalIcon />
          </IconButton>
        }>
        <DropdownItem
          css={{color: "$red11"}}
          startIcon={<TrashIcon />}
          text="Delete"
          onClick={(e) => {
            e.stopPropagation()
            setConfirmDelete(true)
          }}
        />
      </Dropdown>
    </>
  )
}
