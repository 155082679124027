import {DragHandleDots2Icon, TrashIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {FormField} from "@summtech/flok-base/components/FormField"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Textfield} from "@summtech/flok-base/components/Textfield"
import {styled} from "@summtech/flok-base/stitches.config"
import {useFormik} from "formik"
import {useMemo} from "react"
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd"
import {useDispatch, useSelector} from "react-redux"
import * as yup from "yup"
import {
  FormQuestionTypeName,
  FormQuestionTypeValues,
} from "../../../models/form"
import {RetreatColumnModel} from "../../../models/retreat"
import {useRetreat} from "../../../pages/misc/RetreatProvider"
import {RootState} from "../../../store"
import {ApiAction} from "../../../store/actions/api"
import {
  patchAttendeeColumn,
  postAttendeeColumn,
} from "../../../store/actions/retreat"
import AppModal, {AppModalProps} from "../AppModal"

const FlexColumnDiv = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  width: "640px",
})

const FlexForm = styled("form", {
  display: "flex",
  flexDirection: "column",
})

const OptionContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
  [`${Button}`]: {
    marginLeft: "23px",
  },
})
const OptionRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  width: "100%",
})

type AttendeeColumnFormProps = {
  onSuccess: (column: RetreatColumnModel) => void
  columnId?: number
} & AppModalProps

export default function AttendeeColumnForm(props: AttendeeColumnFormProps) {
  let column = useSelector((state: RootState) => {
    return state.retreat.attendeeColumns[props.columnId ?? -1]
  })
  let dispatch = useDispatch()
  let [retreat] = useRetreat()

  const initialOptions = useMemo(
    () =>
      column?.options
        ? column.options?.map((option, i) => ({option, id: i}))
        : [],
    [column]
  )

  let formik = useFormik({
    initialValues: {
      title: column?.title ?? "",
      type: column?.type ?? "SHORT_ANSWER",
      options: initialOptions,
    },
    validationSchema: yup.object({
      title: yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      if (props.columnId) {
        let response = (await dispatch(
          patchAttendeeColumn(
            {
              ...values,
              options: values.options.map((opt) => opt.option),
              retreat_id: retreat.id,
            } as unknown as RetreatColumnModel,
            props.columnId
          )
        )) as unknown as ApiAction

        if (!response.error) {
          props.onSuccess(response.payload.attendee_column)
        }
      } else {
        let response = (await dispatch(
          postAttendeeColumn({
            ...values,
            options: values.options.map((opt) => opt.option),
            retreat_id: retreat.id,
          } as unknown as RetreatColumnModel)
        )) as unknown as ApiAction

        if (!response.error) {
          props.onSuccess(response.payload.attendee_column)
        }
      }
    },
    enableReinitialize: true,
  })
  return (
    <AppModal open={props.open} onClose={props.onClose}>
      <AppModal.Header>
        {props.columnId ? "Edit Column" : "Create Column"}
      </AppModal.Header>

      <FlexForm onSubmit={formik.handleSubmit}>
        <AppModal.Body>
          <FlexColumnDiv>
            <FormField
              id={"title"}
              type={"textfield"}
              label={"Name"}
              placeholder={"Name"}
              fullWidth
              errorMessage={formik.errors.title ?? ""}
              onChange={formik.handleChange}
              value={formik.values.title}
            />
            <FormField
              value={formik.values.type}
              type="select"
              fullWidth
              label="Type"
              onChange={async (value) => {
                if (value) {
                  formik.setFieldValue("type", value)
                }
              }}>
              {FormQuestionTypeValues.map((value) => {
                return (
                  <SelectItem
                    label={FormQuestionTypeName[value]}
                    value={value}
                    key={value}
                  />
                )
              })}
            </FormField>
            {(formik.values.type === "SINGLE_SELECT" ||
              formik.values.type === "MULTI_SELECT") && (
              <DragDropContext
                onDragEnd={(result) => {
                  if (result.destination) {
                    const optionsCopy = [...formik.values.options]
                    const [reorderedItem] = optionsCopy.splice(
                      result.source.index,
                      1
                    )
                    optionsCopy.splice(
                      result.destination.index,
                      0,
                      reorderedItem
                    )
                    formik.setFieldValue("options", optionsCopy)
                  }
                }}>
                <Droppable droppableId="options">
                  {(provided) => (
                    <OptionContainer
                      {...provided.droppableProps}
                      ref={provided.innerRef}>
                      {formik.values.options.map((option, i) => {
                        let optionsCopy = [...formik.values.options]
                        return (
                          <Draggable
                            key={option.id}
                            draggableId={option.id.toString()}
                            index={i}>
                            {(provided) => (
                              <OptionRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}>
                                <div {...provided.dragHandleProps}>
                                  <DragHandleDots2Icon />
                                </div>

                                <Textfield
                                  fullWidth
                                  placeholder="Option title"
                                  value={formik.values.options[i].option}
                                  onChange={(e) => {
                                    optionsCopy[i] = {
                                      ...optionsCopy[i],
                                      option: e.target.value,
                                    }
                                    formik.setFieldValue("options", optionsCopy)
                                  }}
                                />
                                <IconButton
                                  variant="ghost"
                                  color="gray"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    optionsCopy.splice(i, 1)
                                    formik.setFieldValue("options", optionsCopy)
                                  }}>
                                  <TrashIcon />
                                </IconButton>
                              </OptionRow>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                      <Button
                        text="Add Option"
                        variant={"outline"}
                        color="gray"
                        onClick={(e) => {
                          e.preventDefault()
                          formik.setFieldValue("options", [
                            ...formik.values.options,
                            {option: "", id: formik.values.options.length},
                          ])
                        }}
                      />
                    </OptionContainer>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </FlexColumnDiv>
        </AppModal.Body>
        <AppModal.Footer>
          <Button text="Cancel" onClick={props.onClose} color="gray" />
          <Button
            text={props.columnId ? "Update Column" : "Create Column"}
            type={"submit"}
            color="brand"
          />
        </AppModal.Footer>
      </FlexForm>
    </AppModal>
  )
}
