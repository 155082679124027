import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"

const HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  padding: "4px 0px",
  alignItems: "center",
  width: "100%",
  borderBottom: `1px solid ${theme.colors.gray6}`,
})

const ItemContainer = styled("div", {
  padding: "8px",
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

const SubjectContainer = styled(ItemContainer, {
  flex: 3,
})

const StatusContainer = styled(ItemContainer, {
  flex: 2,
  gap: "8px",
})

const SentContainer = styled(ItemContainer, {
  flex: 1,
  gap: "8px",
})

export default function CommunicationHeader(props: {}) {
  return (
    <HeaderContainer>
      <SubjectContainer>
        <GrayText variant={"text-sm-plus"}>Subject</GrayText>
      </SubjectContainer>
      <StatusContainer>
        <GrayText variant={"text-sm-plus"}>Status</GrayText>
      </StatusContainer>

      <SentContainer>
        <GrayText variant={"text-sm-plus"}>Sent</GrayText>
      </SentContainer>
    </HeaderContainer>
  )
}
