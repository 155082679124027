import {UserModel} from "./user"

type ChangesModel = {
  changes: {field: string; new_value: any; old_value: any}[]
}

type AttendeePostActivityModel = {
  type: "ATTENDEE_POST"
  activity_vars: {attendee_id: number; attendee_name: string}
}

type AttendeePatchActivityModel = {
  type: "ATTENDEE_PATCH"
  activity_vars: {attendee_id: number; attendee_name: string} & ChangesModel
}

type AttendeeFormResponsePostActivityModel = {
  type: "ATTENDEE_FORM_RESPONSE_POST"
  activity_vars: {attendee_id: number; attendee_name: string}
}

type AttendeeFlightsPostActivityModel = {
  type: "ATTENDEE_FLIGHTS_POST"
  activity_vars: {attendee_id: number; attendee_name: string}
}

type AttendeeFlightsPatchActivityModel = {
  type: "ATTENDEE_FLIGHTS_PATCH"
  activity_vars: {attendee_id: number; attendee_name: string} & ChangesModel
}

type AttendeeLandingPagePostActivityModel = {
  type: "ATTENDEE_LANDING_PAGE_POST"
  activity_vars: {
    page_id: number
    page_name: string
  }
}

type AttendeeLandingPagePatchActivityModel = {
  type: "ATTENDEE_LANDING_PAGE_PATCH"
  activity_vars: {
    page_id: number
    page_name: string
  } & ChangesModel
}

type ItineraryEventPostActivityModel = {
  type: "ITINERARY_EVENT_POST"
  activity_vars: {
    event_id: number
    event_name: string
  }
}

type ItineraryEventPatchActivityModel = {
  type: "ITINERARY_EVENT_PATCH"
  activity_vars: {
    event_id: number
    event_name: string
  } & ChangesModel
}

type ItineraryEventDeleteActivityModel = {
  type: "ITINERARY_EVENT_DELETE"
  activity_vars: {
    event_id: number
    event_name: string
  }
}

type CommentPostActivityModel = {
  type: "COMMENT_POST"
  activity_vars: {
    comment_id: number
    users_mentioned: number[]
    event_id?: number
    task_id?: number
    comment_creator_name: string
  }
}

type TaskPostActivityModel = {
  type: "TASK_POST"
  activity_vars: {
    task_id: number
    task_name: string
  }
}

type TaskPatchActivityModel = {
  type: "TASK_PATCH"
  activity_vars: {
    task_id: number
    task_name: string
  } & ChangesModel
}

type TaskDeleteActivityModel = {
  type: "TASK_DELETE"
  activity_vars: {
    task_id: number
    task_name: string
  }
}

type TaskAssignActivityModel = {
  type: "TASK_ASSIGN"
  activity_vars: {
    task_id: number
    task_name: string
    assignee: UserModel
  } & ChangesModel
}

type RegFormQuestionPostActivityModel = {
  type: "REG_FORM_QUESTION_POST"
  activity_vars: {
    question_id: number
    question_name: string
  }
}

type RegFormQuestionPatchActivityModel = {
  type: "REG_FORM_QUESTION_PATCH"
  activity_vars: {
    question_id: number
    question_name: string
  } & ChangesModel
}

type RegFormQuestionDeleteActivityModel = {
  type: "REG_FORM_QUESTION_DELETE"
  activity_vars: {
    question_id: number
    question_name: string
  }
}
export type RetreatActivitySiteLocation =
  | "ITINERARY"
  | "TASKS"
  | "WEBSITE"
  | "ATTENDEES"
  | "REGISTRATION"

// TODO: registration form activity models

type ActivityBaseModel = {
  id: number
  created_at: string
  retreat_id: number
  user_id: number
  seen: boolean
  site_location: RetreatActivitySiteLocation
}
export type ActivityModel = ActivityBaseModel &
  (
    | AttendeePostActivityModel
    | AttendeePatchActivityModel
    | AttendeeFormResponsePostActivityModel
    | AttendeeFlightsPostActivityModel
    | AttendeeFlightsPatchActivityModel
    | AttendeeLandingPagePostActivityModel
    | AttendeeLandingPagePatchActivityModel
    | ItineraryEventPostActivityModel
    | ItineraryEventPatchActivityModel
    | ItineraryEventDeleteActivityModel
    | CommentPostActivityModel
    | TaskPostActivityModel
    | TaskPatchActivityModel
    | TaskDeleteActivityModel
    | TaskAssignActivityModel
    | RegFormQuestionPostActivityModel
    | RegFormQuestionPatchActivityModel
    | RegFormQuestionDeleteActivityModel
  )

export const ActivityToDisplay = {
  ATTENDEE_POST: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Attendee Added",
      subtitle: `${
        (activity as ActivityBaseModel & AttendeePostActivityModel)
          .activity_vars.attendee_name
      } has been added`,
    }
  },
  ATTENDEE_PATCH: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Attendee Updated",
      subtitle: `${
        (activity as ActivityBaseModel & AttendeePatchActivityModel)
          .activity_vars.attendee_name
      }'s profile has been updated`,
    }
  },
  ATTENDEE_FORM_RESPONSE_POST: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Attendee Registered",
      subtitle: `${
        (activity as ActivityBaseModel & AttendeeFormResponsePostActivityModel)
          .activity_vars.attendee_name
      } has registered for the retreat`,
    }
  },
  ATTENDEE_FLIGHTS_POST: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Attendee Added Flight",
      subtitle: `A flight has been added for ${
        (activity as ActivityBaseModel & AttendeeFlightsPostActivityModel)
          .activity_vars.attendee_name
      }`,
    }
  },
  ATTENDEE_FLIGHTS_PATCH: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Attendee Updated Flight",
      subtitle: `${
        (activity as ActivityBaseModel & AttendeeFlightsPatchActivityModel)
          .activity_vars.attendee_name
      } has updated their flights`,
    }
  },
  ATTENDEE_LANDING_PAGE_POST: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "New Page Added to Website",
      subtitle: `${
        (activity as ActivityBaseModel & AttendeeLandingPagePostActivityModel)
          .activity_vars.page_name
      } Page has been added to retreat landing page`,
    }
  },
  ATTENDEE_LANDING_PAGE_PATCH: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Website Page Updated",
      subtitle: `${
        (activity as ActivityBaseModel & AttendeeLandingPagePostActivityModel)
          .activity_vars.page_name
      } has been updated`,
    }
  },
  ITINERARY_EVENT_POST: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Itinerary Event Added",
      subtitle: `${
        (activity as ActivityBaseModel & ItineraryEventPostActivityModel)
          .activity_vars.event_name
      } has been added to the itinerary`,
    }
  },
  ITINERARY_EVENT_PATCH: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Itinerary Event Updated",
      subtitle: `${
        (activity as ActivityBaseModel & ItineraryEventPatchActivityModel)
          .activity_vars.event_name
      } has been updated`,
    }
  },
  ITINERARY_EVENT_DELETE: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Itinerary Event Deleted",
      subtitle: `${
        (activity as ActivityBaseModel & ItineraryEventPatchActivityModel)
          .activity_vars.event_name
      } has been deleted from the itinerary`,
    }
  },
  COMMENT_POST: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    let title = "New Comment"
    if (
      (
        activity as ActivityBaseModel & CommentPostActivityModel
      ).activity_vars.users_mentioned.includes(user.id)
    ) {
      title = "You were mentioned in a comment"
    }
    return {
      title: title,
      subtitle: `${createdByUser.first_name} has commented on a${
        activity.site_location === "TASKS" ? "" : "n"
      } ${activity.site_location === "TASKS" ? "task" : "itinerary event"}`,
    }
  },
  TASK_POST: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "New Task Added",
      subtitle: `${
        (activity as ActivityBaseModel & TaskPostActivityModel).activity_vars
          .task_name
      } has been added to tasks`,
    }
  },
  TASK_PATCH: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Task Updated",
      subtitle: `${
        (activity as ActivityBaseModel & TaskPatchActivityModel).activity_vars
          .task_name
      } has been updated`,
    }
  },
  TASK_DELETE: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Task Deleted",
      subtitle: `${
        (activity as ActivityBaseModel & TaskDeleteActivityModel).activity_vars
          .task_name
      } has been deleted`,
    }
  },
  TASK_ASSIGN: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    let title = `${
      (activity as ActivityBaseModel & TaskAssignActivityModel).activity_vars
        .assignee.first_name
    } was assigned to a Task`
    if (
      (activity as ActivityBaseModel & TaskAssignActivityModel).activity_vars
        .assignee.id === user.id
    ) {
      title = "You were assigned to a Task"
    }
    return {
      title: title,
      subtitle: `${createdByUser.first_name} has added a new assignee`,
    }
  },
  REG_FORM_QUESTION_POST: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Registration Question Added",
      subtitle: `${createdByUser.first_name} has added a question: ${
        (activity as ActivityBaseModel & RegFormQuestionPostActivityModel)
          .activity_vars.question_name
      }`,
    }
  },
  REG_FORM_QUESTION_PATCH: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Registration Question Updated",
      subtitle: `${createdByUser.first_name} has updated a question: ${
        (activity as ActivityBaseModel & RegFormQuestionPatchActivityModel)
          .activity_vars.question_name
      }`,
    }
  },
  REG_FORM_QUESTION_DELETE: (
    activity: ActivityModel,
    user: UserModel,
    createdByUser: UserModel
  ) => {
    return {
      title: "Registration Question Deleted",
      subtitle: `${createdByUser.first_name} has deleted a question: ${
        (activity as ActivityBaseModel & RegFormQuestionDeleteActivityModel)
          .activity_vars.question_name
      }`,
    }
  },
}
