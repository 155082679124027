import {useTheme} from "@material-ui/core"
import {Badge} from "@summtech/flok-base/components/Badge"
import {Button} from "@summtech/flok-base/components/Button"
import {FormField} from "@summtech/flok-base/components/FormField"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useQueries, useQuery} from "@tanstack/react-query"
import {useFormik} from "formik"
import {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {getColumnAnswer} from "../../api/attendee"
import {getFormResponse} from "../../api/form"
import {
  ColumnAnswerModel,
  RegistrationStatusValues,
  RetreatAttendeeModel,
  RetreatColumnModel,
  RetreatModel,
} from "../../models/retreat"
import {RootState} from "../../store"
import {patchAttendee} from "../../store/actions/retreat"
import {
  useCreateColumnAnswerMutation,
  useUpdateColumnAnswerMutation,
} from "../../utils/attendeeUtils"
import AppModal, {AppModalProps} from "../app/AppModal"
import {
  ColumnFormField,
  DIETARY_OPTIONS,
} from "../app/attendees/AttendeeProfileForm"
import {RegistrationStatusBadge} from "../app/attendees/StatusBadge"

const StyledTable = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  border: `1px solid ${theme.colors.gray6}`,
  borderRadius: theme.shape.borderRadius,
  background: theme.colors.white,
  [`${Button}`]: {
    background: "$$color",
    color: theme.colors.white,
    "&:hover": {
      background: "$$color",
      opacity: 0.9,
    },
    "&:active": {
      background: "$$color",
      opacity: 0.8,
    },
  },
})
const TopBox = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  borderBottom: `1px solid ${theme.colors.gray6}`,
  padding: "16px",
  gap: "16px",
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

const BottomRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "12px",
  gap: "16px",
  alignItems: "center",
  justifyContent: "center",
})

const Question = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
})

export default function FormResponseTable(props: {
  attendee: RetreatAttendeeModel
  retreat: RetreatModel
}) {
  let formResponseQuery = useQuery({
    enabled: props.attendee.registration_form_response_id != null,
    queryFn: () =>
      getFormResponse(props.attendee.registration_form_response_id!),
    queryKey: ["forms-response", props.attendee.registration_form_response_id],
  })

  let [editResponseOpen, setEditResponseOpen] = useState(false)
  let columns = useSelector((state: RootState) => {
    return Object.values(state.retreat.attendeeColumns).filter((column) => {
      if (column && props.retreat.column_ids.indexOf(column.id) !== -1) {
        return true
      } else {
        return false
      }
    }) as RetreatColumnModel[]
  })
  let materialTheme = useTheme()
  let answerQueries = useQueries({
    queries:
      columns
        .filter((column) => {
          return props.attendee.column_id_to_answer_id[column.id]
        })
        .map((column) => {
          let columnAnswerId = props.attendee.column_id_to_answer_id[column.id]
          return {
            queryKey: ["column-answers", columnAnswerId],
            queryFn: () => getColumnAnswer(columnAnswerId),
          }
        }) ?? [],
  })
  let answersMap = answerQueries
    .filter((answerQuery) => answerQuery.status === "success")
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr.data!.column_answer.id]: curr.data!.column_answer,
      }),
      {} as {[id: number]: ColumnAnswerModel | undefined}
    )

  return (
    <StyledTable>
      <AttendeeResponseFormModal
        answersMap={answersMap}
        open={editResponseOpen}
        onClose={() => {
          setEditResponseOpen(false)
        }}
        retreat={props.retreat}
        attendee={props.attendee}
      />
      <TopBox>
        {props.retreat.attendee_table_v2 ? (
          <>
            <Question key={"check-in"}>
              <Text variant="text-sm-plus">Hotel Check In</Text>
              <GrayText variant="text-sm">
                {props.attendee.hotel_check_in}
              </GrayText>
            </Question>
            <Question key={"check-out"}>
              <Text variant="text-sm-plus">Hotel Check Out</Text>
              <GrayText variant="text-sm">
                {props.attendee.hotel_check_out}
              </GrayText>
            </Question>
            <Question key={"dietary-prefs"}>
              <Text variant="text-sm-plus">Dietary Preferences</Text>
              <GrayText variant="text-sm">
                {props.attendee && props.attendee.dietary_prefs
                  ? props.attendee.dietary_prefs
                      .split(",")
                      .filter((val) => val)
                      .map(
                        (pref) =>
                          pref.charAt(0).toUpperCase() +
                          pref.substr(1).toLowerCase()
                      )
                      .join(", ")
                  : "--"}
              </GrayText>
            </Question>
            {columns.map((column) => {
              return (
                <Question key={column.id}>
                  <Text variant="text-sm-plus">{column.title}</Text>
                  <GrayText variant="text-sm">
                    {props.attendee.column_id_to_answer_id[column.id] &&
                    answersMap[
                      props.attendee.column_id_to_answer_id[column.id]
                    ] &&
                    answersMap[props.attendee.column_id_to_answer_id[column.id]]
                      ?.value
                      ? column.type === "MULTI_SELECT"
                        ? answersMap[
                            props.attendee.column_id_to_answer_id[column.id]
                          ]!.value.split(",")
                            .filter((val) => val)
                            .map(
                              (pref) =>
                                pref.charAt(0).toUpperCase() +
                                pref.substr(1).toLowerCase()
                            )
                            .join(", ")
                        : answersMap[
                            props.attendee.column_id_to_answer_id[column.id]
                          ]!.value
                      : "--"}
                  </GrayText>
                </Question>
              )
            })}
          </>
        ) : (
          <>
            {formResponseQuery.isSuccess &&
              formResponseQuery.data!.form_response.answers.map((answer) => {
                return (
                  <Question key={answer.id}>
                    <Text variant="text-sm-plus">
                      {answer.form_question_snapshot.title}
                    </Text>
                    <GrayText variant="text-sm">
                      {answer.answer.length > 0 ? answer.answer : "--"}
                    </GrayText>
                  </Question>
                )
              })}
          </>
        )}
      </TopBox>
      {props.retreat.attendee_table_v2 && (
        <BottomRow>
          <Button
            text="Edit Responses"
            css={{$$color: materialTheme.palette.primary.main}}
            onClick={() => {
              setEditResponseOpen(true)
            }}
          />
        </BottomRow>
      )}
    </StyledTable>
  )
}
const ModalBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
})
type AttendeeResponseFormModalProps = AppModalProps & {
  retreat: RetreatModel
  attendee: RetreatAttendeeModel
  answersMap: {[answer_id: number]: ColumnAnswerModel | undefined}
}
function AttendeeResponseFormModal(props: AttendeeResponseFormModalProps) {
  let columns = useSelector((state: RootState) => {
    return Object.values(state.retreat.attendeeColumns).filter((column) => {
      if (column && props.retreat.column_ids.indexOf(column.id) !== -1) {
        return true
      } else {
        return false
      }
    }) as RetreatColumnModel[]
  })
  let dispatch = useDispatch()
  let {attendee} = props
  let formik = useFormik({
    initialValues: {
      dietary_prefs:
        attendee && attendee.dietary_prefs ? attendee.dietary_prefs : "",
      info_status:
        attendee && attendee.info_status ? attendee.info_status : "CREATED",
      hotel_check_in:
        attendee && attendee.hotel_check_in ? attendee.hotel_check_in : "",
      hotel_check_out:
        attendee && attendee.hotel_check_out ? attendee.hotel_check_out : "",
    },
    onSubmit: (values) => {},
    enableReinitialize: true,
  })
  function returnSaveOnBlur(field: string, attendeeId: number) {
    return () => {
      if (!formik.errors[field as keyof typeof formik.errors]) {
        dispatch(
          patchAttendee(attendeeId, {
            [field]: formik.values[field as keyof typeof formik.values],
          })
        )
      }
    }
  }
  function returnSaveOnChange(field: string, attendeeId: number) {
    return (newValue: string) => {
      formik.setFieldValue(field, newValue)
      dispatch(
        patchAttendee(attendeeId, {
          [field]: newValue ? newValue : null,
        })
      )
    }
  }
  let theme = useTheme()
  let createColumnAnswerMutation = useCreateColumnAnswerMutation(attendee.id)
  let updateColumnAnswerMutation = useUpdateColumnAnswerMutation()
  return (
    <AppModal open={props.open} onClose={props.onClose}>
      <AppModal.Header>Edit Form Responses</AppModal.Header>
      <AppModal.Body>
        <ModalBody>
          <FormField
            onChange={returnSaveOnChange("info_status", props.attendee.id)}
            inline
            fullWidth
            label={"Status"}
            value={formik.values.info_status ?? ""}
            id="info_status"
            type={"select"}>
            {RegistrationStatusValues.map((status) => {
              return (
                <SelectItem
                  label={<RegistrationStatusBadge status={status} />}
                  value={status}
                />
              )
            })}
          </FormField>
          <FormField
            onChange={returnSaveOnChange("hotel_check_in", props.attendee.id)}
            inline
            fullWidth
            label={"Hotel Check In"}
            value={formik.values.hotel_check_in ?? ""}
            id="hotel_check_in"
            type={"datetime"}
            time={false}
          />
          <FormField
            onChange={returnSaveOnChange("hotel_check_out", props.attendee.id)}
            inline
            fullWidth
            label={"Hotel Check Out"}
            value={formik.values.hotel_check_out ?? ""}
            id="hotel_check_out"
            type={"datetime"}
            time={false}
          />
          <FormField
            inline
            fullWidth
            label="Dietary Preferences"
            type="multiselect"
            selectedValues={formik.values.dietary_prefs
              .split(",")
              .map((option) => option.trim().toLowerCase())}
            options={Array.from(DIETARY_OPTIONS).map((option: string) => {
              return {
                value: option.trim().toLowerCase(),
                label: <Badge label={option.trim()} />,
              }
            })}
            onChange={(newValues: string[]) => {
              let newValue = newValues.join(",")
              formik.setFieldValue("dietary_prefs", newValue)
              dispatch(
                patchAttendee(props.attendee.id, {
                  dietary_prefs: (newValue ? newValue : null) as string,
                })
              )
            }}
          />
          {columns
            .filter((column) => column.question_id)
            .map((column) => {
              let columnAnswerId: number | undefined =
                attendee.column_id_to_answer_id[column.id]
              return (
                <ColumnFormField
                  inline
                  options={column.options}
                  type={column.type}
                  title={column.title}
                  initialValue={
                    columnAnswerId
                      ? props.answersMap[columnAnswerId]?.value
                      : undefined
                  }
                  onBlur={(value) => {
                    if (!attendee.column_id_to_answer_id[column.id]) {
                      createColumnAnswerMutation.mutate({
                        columnId: column.id,
                        value: value as string,
                      })
                    } else {
                      updateColumnAnswerMutation.mutate({
                        columnAnswerId:
                          attendee.column_id_to_answer_id[column.id],
                        value: value as string,
                      })
                    }
                  }}
                />
              )
            })}
        </ModalBody>
      </AppModal.Body>
      <AppModal.Footer>
        <Button
          text="Done"
          css={{$$color: theme.palette.primary.main}}
          onClick={props.onClose}
        />
      </AppModal.Footer>
    </AppModal>
  )
}
