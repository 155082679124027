import {Badge} from "@summtech/flok-base/components/Badge"
import {
  FlightBookingStatus,
  FlightBookingStatusName,
  RegistrationStatus,
  RegistrationStatusName,
  TripLegType,
  TripLegTypeEnum,
  TripLegTypeName,
} from "../../../models/retreat"

type RegistrationStatusBadgeProps = Omit<
  React.ComponentProps<typeof Badge> & {
    status: RegistrationStatus
  },
  "label" | "color"
>
export function RegistrationStatusBadge(props: RegistrationStatusBadgeProps) {
  let {status, ...badgeProps} = props
  let label = "Unknown"
  let color: React.ComponentProps<typeof Badge>["color"] = "gray"

  switch (status) {
    case "CREATED":
      label = RegistrationStatusName.CREATED
      color = "gray"
      break
    case "INFO_ENTERED":
      label = RegistrationStatusName.INFO_ENTERED
      color = "success"
      break
    case "NOT_ATTENDING":
      label = RegistrationStatusName.NOT_ATTENDING
      color = "critical"
      break
    case "CANCELLED":
      label = RegistrationStatusName.CANCELLED
      color = "warning"
      break
  }
  return <Badge {...badgeProps} label={label} color={color} />
}

type FlightStatusBadgeProps = Omit<
  React.ComponentProps<typeof Badge> & {
    status: FlightBookingStatus
  },
  "label" | "color"
>
export function FlightStatusBadge(props: FlightStatusBadgeProps) {
  let {status, ...badgeProps} = props
  let label = "Unknown"
  let color: React.ComponentProps<typeof Badge>["color"] = "gray"

  switch (status) {
    case "PENDING":
      label = FlightBookingStatusName.PENDING
      color = "gray"
      break
    case "OPT_OUT":
      label = FlightBookingStatusName.OPT_OUT
      color = "purple"
      break
    case "BOOKED":
      label = FlightBookingStatusName.BOOKED
      color = "success"
      break
  }
  return <Badge {...badgeProps} label={label} color={color} />
}

type TripLegTypeBadgeProps = Omit<
  React.ComponentProps<typeof Badge> & {
    tripLegType: TripLegType
  },
  "label" | "color"
>
export function TripLegTypeBadge(props: TripLegTypeBadgeProps) {
  let {tripLegType, ...badgeProps} = props
  let label = TripLegTypeName.FLIGHT
  let color: React.ComponentProps<typeof Badge>["color"] = "gray"

  switch (tripLegType) {
    case TripLegTypeEnum.TRAIN:
      label = TripLegTypeName.TRAIN
      break
  }
  return <Badge {...badgeProps} label={label} color={color} />
}
