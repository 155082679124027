import {TriangleDownIcon, TriangleUpIcon} from "@radix-ui/react-icons"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {Header, Row, flexRender} from "@tanstack/react-table"
import {PropsWithChildren} from "react"
import AppScrollSyncTable from "../AppScrollSyncTable"
import {StyledCellCss, StyledSortIconCss} from "../attendees/AttendeesTable"

const StyledTableContainer = styled("div", {
  width: "100%",
  overflow: "hidden",
  position: "relative",
  display: "flex",
  flexDirection: "column",
})

export const StyledTableDataCell = styled("td", StyledCellCss, {
  padding: "10px",
  "& a": {
    display: "block",
  },
})

export const StyledRow = styled("tr", {
  variants: {
    clickable: {
      true: {
        cursor: "pointer",
      },
    },
  },
})
type AppReactTableRowProps<T> = {
  row: Row<T>
  onClick?: (row: Row<T>) => void
}

export default function AppReactTable(props: PropsWithChildren<{}>) {
  return (
    <StyledTableContainer>
      <AppScrollSyncTable>{props.children}</AppScrollSyncTable>
    </StyledTableContainer>
  )
}
function AppReactTableRow<T>(props: AppReactTableRowProps<T>) {
  return (
    <StyledRow clickable={!!props.onClick}>
      {props.row.getVisibleCells().map((cell) => {
        return (
          <StyledTableDataCell
            css={{$$width: `${cell.column.columnDef.size}px`}}
            onClick={
              props.onClick
                ? () => {
                    props.onClick!(props.row)
                  }
                : undefined
            }>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </StyledTableDataCell>
        )
      })}
    </StyledRow>
  )
}
AppReactTable.Row = AppReactTableRow
AppReactTable.Header = AppScrollSyncTable.Header
AppReactTable.Body = AppScrollSyncTable.Body

type AppReactTableHeaderProps<T> = {
  header: Header<T, unknown>
}

const StyledTableHeaderCell = styled("th", StyledCellCss, {
  padding: "10px",
  background: theme.colors.white,
  "& > span": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
})
function AppReactTableHeader<T>(props: AppReactTableHeaderProps<T>) {
  return (
    <StyledTableHeaderCell
      onClick={
        props.header.column.getCanSort()
          ? () => props.header.column.toggleSorting()
          : undefined
      }
      colSpan={props.header.colSpan}
      css={{
        $$width: `${props.header.column.columnDef.size}px`,
        cursor: props.header.column.getCanSort() ? "pointer" : "inherit",
      }}>
      <span>
        <Text variant="text-sm-plus">
          {props.header.isPlaceholder
            ? "\u00A0"
            : flexRender(
                props.header.column.columnDef.header,
                props.header.getContext()
              )}
        </Text>
        {props.header.column.getCanSort() &&
        props.header.column.getIsSorted() === "asc" ? (
          <TriangleUpIcon className={StyledSortIconCss()} />
        ) : props.header.column.getIsSorted() === "desc" ? (
          <TriangleDownIcon className={StyledSortIconCss()} />
        ) : (
          <></>
        )}
      </span>
    </StyledTableHeaderCell>
  )
}
AppReactTable.HeaderCell = AppReactTableHeader
