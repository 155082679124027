import {Box} from "@material-ui/core"
import AppLoadingScreen from "../../components/base/AppLoadingScreen"

import {
  AccordionBlockModel,
  AttendeeLandingWebsiteBlockModel,
  ImageBlockModel,
  RetreatModel,
  WYSIWYGBlockModel,
} from "../../models/retreat"
import {
  AccordionBlockEditor,
  AccordionBlockRenderer,
} from "./blocks/AccordionBlock"
import FlightsBlock from "./blocks/FlightsBlock"
import ImageBlockEditor, {ImageBlockRenderer} from "./blocks/ImageBlock"
import ItineraryBlock from "./blocks/ItineraryBlock"

import WYSIWYGBlockEditor, {WYSIWYGBlockRenderer} from "./blocks/WYSIWYGBlock"

type PageBlockProps = {
  blockId: number
  editable?: boolean
  formik: any
  block: AttendeeLandingWebsiteBlockModel
  retreat: RetreatModel
}
export function PageBlock(props: PageBlockProps) {
  return !props.block ? (
    <AppLoadingScreen />
  ) : props.block ? (
    <Box display="flex" flexDirection="column" width="100%">
      {props.block.type === "WYSIWYG" ? (
        props.editable ? (
          <WYSIWYGBlockEditor
            formik={props.formik}
            block={props.block as WYSIWYGBlockModel}
          />
        ) : (
          <WYSIWYGBlockRenderer block={props.block as WYSIWYGBlockModel} />
        )
      ) : props.block.type === "ACCORDION" ? (
        props.editable ? (
          <AccordionBlockEditor
            formik={props.formik}
            block={props.block as AccordionBlockModel}
          />
        ) : (
          <AccordionBlockRenderer block={props.block as AccordionBlockModel} />
        )
      ) : props.block.type === "IMAGE" ? (
        props.editable ? (
          <ImageBlockEditor
            formik={props.formik}
            block={props.block as ImageBlockModel}
          />
        ) : (
          <ImageBlockRenderer block={props.block as ImageBlockModel} />
        )
      ) : props.block.type === "ITINERARY" ? (
        <ItineraryBlock retreat={props.retreat} />
      ) : props.block.type === "FLIGHTS" ? (
        <FlightsBlock retreat={props.retreat} />
      ) : (
        <div>Something went wrong loading the section.</div>
      )}
    </Box>
  ) : (
    <div>Something went wrong</div>
  )
}
