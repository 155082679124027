import {createMuiTheme, CssBaseline, ThemeProvider} from "@material-ui/core"
import {PropsWithChildren} from "react"
import {FlokTheme} from "../../theme"
import {useAttendeeLandingWebsite} from "../../utils/retreatUtils"

export default function AttendeeSiteBuilderThemeProvider(
  props: PropsWithChildren<{websiteId: number}>
) {
  let [website] = useAttendeeLandingWebsite(props.websiteId)
  return (
    <ThemeProvider
      theme={(theme: FlokTheme) =>
        createMuiTheme({
          ...theme,
          palette: {
            ...theme.palette,
            primary: {
              main: website?.primary_color ?? theme.palette.primary.main,
            },
          },
        })
      }>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}
