import {Text} from "@summtech/flok-base/components/Text"
import {useQuery} from "@tanstack/react-query"
import {getForm, getFormResponse} from "../../api/form"
import {FormQuestionSelectOptionModel} from "../../models/form"
import {FormViewerContainer, QuestionsContainer} from "./FormViewer"
import {FormHeader} from "./headers"
import {FormQuestionResponseViewer} from "./questions"

type FormResponseViewerProps = {
  formResponseId: number
  hideTitle?: boolean
}
export default function FormResponseViewer(props: FormResponseViewerProps) {
  let formResponseQuery = useQuery({
    queryFn: () => getFormResponse(props.formResponseId),
    queryKey: ["forms-responses", props.formResponseId],
  })

  let formQuery = useQuery({
    enabled:
      formResponseQuery.data?.form_response?.form_id != null &&
      !props.hideTitle,
    queryFn: () => getForm(formResponseQuery.data!.form_response.form_id),
    queryKey: ["forms", formResponseQuery.data?.form_response?.form_id],
  })

  let answers = formResponseQuery.isSuccess
    ? formResponseQuery.data!.form_response.answers.reduce<{
        [id: number]: string
      }>(
        (prev, answer) => ({...prev, [answer.form_question_id]: answer.answer}),
        {}
      )
    : {}

  let selectOptions = formResponseQuery.isSuccess
    ? formResponseQuery
        .data!.form_response.answers.map(
          (answer) => answer.form_question_snapshot.select_options_snapshot
        )
        .filter((options) => options)
        .reduce((prev, options) => [...prev, ...options], [])
        .reduce<{[id: number]: FormQuestionSelectOptionModel}>(
          (prev, option) => ({...prev, [option.id]: option}),
          {}
        )
    : {}

  return (
    <FormViewerContainer>
      {!props.hideTitle &&
        formQuery.status === "success" &&
        !!formQuery.data!.form.title && (
          <FormHeader
            title={formQuery.data.form.title}
            description={formQuery.data.form.description}
          />
        )}
      <QuestionsContainer as="div">
        {formResponseQuery.status === "success" &&
          formResponseQuery
            .data!.form_response.answers.filter((answer) => {
              let show = true
              answer.form_question_snapshot.form_question_rules_snapshot?.forEach(
                (rule) => {
                  let requiredAnswer =
                    selectOptions[rule.depends_on_select_option_id].option
                  let actualAnswer = answers[rule.depends_on_form_question_id]
                  if (requiredAnswer !== actualAnswer) {
                    show = false
                  }
                }
              )
              return show
            })
            .map((answer) => {
              return (
                <FormQuestionResponseViewer
                  key={answer.id}
                  questionSnapshot={answer.form_question_snapshot}
                  value={answer.answer}
                />
              )
            })}
      </QuestionsContainer>
      {formResponseQuery.isSuccess && (
        <Text variant={"text-xs"}>
          Submitted at{" "}
          {new Intl.DateTimeFormat("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            timeZoneName: "short",
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          }).format(new Date(formResponseQuery.data!.form_response.created_at))}
        </Text>
      )}
    </FormViewerContainer>
  )
}
