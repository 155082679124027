import {Button} from "@summtech/flok-base/components/Button"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {useRouteMatch} from "react-router"
import {getForm, patchForm} from "../../api/form"
import {FormStatusEnum, FormStatusType} from "../../models/form"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../Stack"
import FullPage from "../app/layout/FullPage"
import {WebsitePortal} from "../app/layout/WebsitePortal"
import FormBuilder from "../forms/FormBuilder"
import {FormQuestionsListAside} from "../forms/questions/FormQuestionsListAside"

export default function SurveyBuilderPage() {
  let [, retreatIdx] = useRetreat()
  let router = useRouteMatch<{
    surveyId: string
  }>()
  let queryClient = useQueryClient()
  let surveyId = parseInt(router.params.surveyId)
  let form = useQuery({
    queryKey: ["forms", surveyId],
    queryFn: () => getForm(surveyId),
  })

  let updateForm = useMutation({
    mutationFn: (variables: {status: FormStatusType}) =>
      patchForm(surveyId, {status: variables.status}),
    onSuccess: (data) => {
      queryClient.setQueryData(["forms", surveyId], data)
    },
  })
  return (
    <FullPage>
      <FullPage.Header>
        <FullPage.HeaderLeft>
          <FullPage.HeaderBackLink
            to={AppRoutes.getPath("SurveysPage", {
              retreatIdx: retreatIdx.toString(),
            })}
          />
        </FullPage.HeaderLeft>
        <FullPage.HeaderCenter></FullPage.HeaderCenter>
        <FullPage.HeaderRight>
          {form.status === "success" &&
          form.data.form.status === FormStatusEnum.DRAFT ? (
            <Button
              text={updateForm.isLoading ? "Loading..." : "Publish"}
              disabled={updateForm.isLoading}
              color="brand"
              variant="solid"
              onClick={() => updateForm.mutate({status: FormStatusEnum.LIVE})}
            />
          ) : (
            <Button
              text={updateForm.isLoading ? "Loading..." : "Revert to draft"}
              disabled={updateForm.isLoading}
              color="critical"
              variant="outline"
              onClick={() => updateForm.mutate({status: FormStatusEnum.DRAFT})}
            />
          )}
        </FullPage.HeaderRight>
      </FullPage.Header>
      <FullPage.DoublePane>
        <FullPage.DoublePaneAside>
          <FormQuestionsListAside formId={surveyId} />
        </FullPage.DoublePaneAside>
        <FullPage.DoublePaneMain>
          <WebsitePortal>
            <WebsitePortal.Header url={"https://goflok.com"} />
            <WebsitePortal.Body>
              <FormBuilder formId={surveyId} />
            </WebsitePortal.Body>
          </WebsitePortal>
        </FullPage.DoublePaneMain>
      </FullPage.DoublePane>
    </FullPage>
  )
}
