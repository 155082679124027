import {Route, Switch} from "react-router-dom"
import AttendeeSiteThemeProvider from "./components/attendee-site/AttendeeSiteThemeProvider"
import RetreatHotelSearchPage from "./components/lodging/RetreatHotelSearchPage"
import RetreatRfpPage from "./components/lodging/RetreatRfpPage"
import PageContainer from "./components/page/PageContainer"
import {Constants} from "./config"
import HomeRoutingPage from "./pages/HomeRoutingPage"
import AttendeeCreateAccountPage from "./pages/attendee-site/AttendeeCreateAccountPage"
import AuthResetPage from "./pages/auth/AuthResetPage"
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage"
import SigninPage from "./pages/auth/SigninPage"
import EditEmailTemplate from "./pages/dashboard/EmailTemplatePage"
import FlightsOptionsPage from "./pages/dashboard/attendee/SiteFlightsBuilderPage"
import BudgetEstimatePage from "./pages/dashboard/budget/BudgetEstimatePage"
import ItineraryInspirationPage from "./pages/dashboard/itinerary/ItineraryInspirationPage"
import HotelProfilePage from "./pages/dashboard/lodging/HotelProfilePage"
import LodgingContractPage from "./pages/dashboard/lodging/LodgingContractPage"
import LodgingProposalPage from "./pages/dashboard/lodging/LodgingProposalPage"
import LodgingProposalsPage from "./pages/dashboard/lodging/LodgingProposalsPage"
import DeprecatedNewRetreatFormPage from "./pages/deprecated/DeprecatedNewRetreatFormPage"
import DeprecatedProposalPage from "./pages/deprecated/DeprecatedProposalPage"
import DeprecatedProposalsListPage from "./pages/deprecated/DeprecatedProposalsListPage"
import DeprecatedRetreatPreferencesFormPage from "./pages/deprecated/DeprecatedRetreatPreferencesFormPage"
import NotFound404Page from "./pages/misc/NotFound404Page"
import ProtectedRoute from "./pages/misc/ProtectedRoute"
import RetreatProvider from "./pages/misc/RetreatProvider"
import RetreatProviderV2 from "./redesign/app/common/RetreatProviderV2"
import PageSidenav from "./redesign/nav/PageSidenav"
import AttendeesPage from "./redesign/pages/AttendeesPage"
import CommunicationHomePage from "./redesign/pages/CommunicationHomePage"
import EmailGenerator from "./redesign/pages/EmailGenerator"
import EventsPage from "./redesign/pages/EventsPage"
import ItineraryBuilderPage from "./redesign/pages/ItineraryBuilderPage"
import ManageAttendeesPage from "./redesign/pages/ManageAttendeesPage"
import OverviewPage from "./redesign/pages/OverviewPage"
import RetreatSettingsPage from "./redesign/pages/RetreatSettingsPage"
import ShareAttendeeReportPage from "./redesign/pages/ShareAttendeeReportPage"
import SiteBuilderPage from "./redesign/pages/SiteBuilderPage"
import SiteRegistrationBuilderPage from "./redesign/pages/SiteRegistrationBuilderPage"
import SurveyBuilderPage from "./redesign/pages/SurveyBuilderPage"
import SurveysPage from "./redesign/pages/SurveysPage"
import TasksPage from "./redesign/pages/TasksPage"
import UserGroupsPage from "./redesign/pages/UserGroupsPage"
import AttendeeSiteMyTripPage from "./redesign/pages/sites/SiteMyTripPage"
import AttendeeSite from "./redesign/pages/sites/SitePage"
import SiteRegistrationPage from "./redesign/pages/sites/SiteRegistrationPage"
import SurveyPage from "./redesign/pages/sites/SurveyPage"
import DeprecatedRetreatCalendlyBookPage from "./pages/deprecated/DeprecatedRetreatCalendlyBookPage"

export type FlokPageName = keyof typeof AppRoutes.pages

export class AppRoutes {
  static deprecatedPages = {}
  static pages = {
    ...AppRoutes.deprecatedPages,
    HomeRoutingPage: "/",

    // Authentication
    SigninPage: "/login",
    ForgotPasswordPage: "/forgot-password",
    PasswordResetPage: "/reset-password",

    // Events
    EventsPage: "/events",
    CreateEventPage: "/events/new",

    // DASHBOARD
    RetreatHomePage: "/r/:retreatIdx",
    // Tasks
    TaskListPage: "/r/:retreatIdx/tasks",
    TaskPage: "/r/:retreatIdx/tasks/:taskId",
    // Settings
    RetreatSettingsPage: "/r/:retreatIdx/settings",
    // Lodging - event profile
    RetreatRfpPage: "/r/:retreatIdx/lodging/event-profile",
    // Lodging - explore
    RetreatHotelSearchPage: "/r/:retreatIdx/lodging/hotels",
    RetreatHotelProfilePage: "/r/:retreatIdx/lodging/hotels/:hotelGuid",
    // Lodging - proposals
    RetreatLodgingProposalsPage: "/r/:retreatIdx/lodging/proposals",
    RetreatLodgingProposalPage: "/r/:retreatIdx/lodging/proposals/:hotelGuid",
    // Lodging - contract
    RetreatLodgingContractPage: "/r/:retreatIdx/lodging/contract",
    // Planning - attendees
    RetreatManageAttendeesPage: "/r/:retreatIdx/manage",
    RetreatAttendeesPage: "/r/:retreatIdx/attendees",
    RetreatAttendeePage: "/r/:retreatIdx/attendees/:attendeeId/profile",
    RetreatAttendeeFlightsPage: "/r/:retreatIdx/attendees/:attendeeId/flights",
    RetreatAttendeeRegResponsePage:
      "/r/:retreatIdx/attendees/:attendeeId/registration",
    RetreatAttendeeColumnPage: "/r/:retreatIdx/attendees/columns/:columnId",
    UserGroupsPage: "/r/:retreatIdx/attendee-groups",
    UserGroupPage: "/r/:retreatIdx/attendee-groups/:audienceId",
    ReportAttendeePage: "/r/:retreatIdx/reports/attendees/:attendeeId/profile",
    ReportAttendeeFlightsPage:
      "/r/:retreatIdx/reports/attendees/:attendeeId/flights",
    ReportAttendeesPage: "/r/:retreatIdx/reports",
    ReportAttendeeRegResponsePage:
      "/r/:retreatIdx/reports/attendees/:attendeeId/registration",
    ReportAttendeeColumnPage: "/r/:retreatIdx/reports/columns/:columnId",
    // Planning - registration
    RetreatRegistrationFormBuilderPage: "/r/:retreatIdx/registration",
    // Planning - landing page
    LandingPageGeneratorHome: "/r/:retreatIdx/landing",
    LandingPageGeneratorPage: "/r/:retreatIdx/landing/:currentPageId",
    // Planning - itinerary
    ItineraryPage: "/r/:retreatIdx/itinerary",
    ItineraryEventPage: "/r/:retreatIdx/itinerary/:eventId",
    // Planning - deprecated
    RetreatFlightsOptionsPage: "/r/:retreatIdx/flights",
    RetreatFlightsOptionsConfig: "/r/:retreatIdx/flights/config",
    RetreatItineraryInspirationPage: "/r/:retreatIdx/itinerary/inspiration",
    EmailTemplatePage: "/r/:retreatIdx/email-templates/:templateName",
    // Planning - communications
    CommunicationHomePage: "/r/:retreatIdx/communication",
    CreateEmailPage: "/r/:retreatIdx/communication/emails/:emailId",
    // Planning - surveys
    SurveysPage: "/r/:retreatIdx/surveys",
    SurveyBuilderPage: "/r/:retreatIdx/surveys/:surveyId",

    // Not in sidebar yet
    AttendeeSiteHome: `${Constants.attendeeSitePathPrefix}/:websiteName`,
    AttendeeSitePage: `${Constants.attendeeSitePathPrefix}/:websiteName/:pageName`,
    AttendeeSiteFormPage: `${Constants.attendeeSitePathPrefix}/:websiteName/registration`,
    AttendeeSignUpPage: `${Constants.attendeeSitePathPrefix}/:websiteName/sign-up`,
    AttendeeSiteFlightsPage: `${Constants.attendeeSitePathPrefix}/:websiteName/flights`,
    AttendeeSiteMyTripPage: `${Constants.attendeeSitePathPrefix}/:websiteName/my-trip`,
    AttendeeSiteSurveyPage: `${Constants.attendeeSitePathPrefix}/:websiteName/surveys/:formId`,

    // Miscellaneous
    BudgetEstimatePage: "/budget-estimate",
    ShareReportPage: "/reports/:guid",

    // TODO, remove once dashboard release
    DeprecatedNewRetreatFormPage: "/getting-started",
    DeprecatedRetreatPreferencesFormPage: "/r/:retreatGuid/preferences",
    DeprecatedRetreatCalendlyBookPage: "/r/:retreatGuid/calendly",
    DeprecatedProposalsListPage: "/r/:retreatGuid/proposals",
    DeprecatedProposalPage: "/r/:retreatGuid/proposals/:hotelGuid",
  }

  static getPath(
    name: FlokPageName,
    pathParams: {[key: string]: string} = {},
    queryParams: {[key: string]: string} = {}
  ): string {
    let path = this.pages[name]
    Object.keys(pathParams).forEach((key) => {
      let value = pathParams[key]
      let toReplace = ":" + key
      path = path.replace(toReplace, value)
    })
    if (Object.keys(queryParams).length > 0) {
      path += "?"
    }
    let queryString = new URLSearchParams(queryParams).toString()
    path += queryString

    return path
  }
}
export const TemporarySideNavPages: FlokPageName[] = [
  "LandingPageGeneratorPage",
  "CreateEmailPage",
  "SurveyBuilderPage",
  "RetreatRegistrationFormBuilderPage",
]

export default function Stack() {
  return (
    <Switch>
      {/* Non authenticated routes */}
      <Route
        path={AppRoutes.getPath("HomeRoutingPage")}
        exact
        component={HomeRoutingPage}
      />
      <Route
        path={AppRoutes.getPath("DeprecatedNewRetreatFormPage")}
        exact
        component={DeprecatedNewRetreatFormPage}
      />
      <Route
        path={AppRoutes.getPath("DeprecatedRetreatPreferencesFormPage")}
        exact
        component={DeprecatedRetreatPreferencesFormPage}
      />
      <Route
        path={AppRoutes.getPath("DeprecatedRetreatCalendlyBookPage")}
        exact
        component={DeprecatedRetreatCalendlyBookPage}
      />
      <Route
        path={AppRoutes.getPath("DeprecatedProposalsListPage")}
        exact
        component={DeprecatedProposalsListPage}
      />
      <Route
        path={AppRoutes.getPath("DeprecatedProposalPage")}
        exact
        component={DeprecatedProposalPage}
      />
      <Route
        path={AppRoutes.getPath("ShareReportPage")}
        exact
        component={ShareAttendeeReportPage}
      />
      <Route
        path={AppRoutes.getPath("BudgetEstimatePage")}
        exact
        component={BudgetEstimatePage}
      />
      {/* Authentication routes */}
      <Route
        path={AppRoutes.getPath("SigninPage")}
        exact
        component={SigninPage}
      />
      <Route
        path={AppRoutes.getPath("PasswordResetPage")}
        exact
        component={AuthResetPage}
      />
      <Route
        path={AppRoutes.getPath("ForgotPasswordPage")}
        exact
        component={ForgotPasswordPage}
      />
      <Route path={Constants.attendeeSitePathPrefix}>
        <Switch>
          <Route path={[AppRoutes.getPath("AttendeeSiteFormPage")]} exact>
            <AttendeeSiteThemeProvider>
              <SiteRegistrationPage />
            </AttendeeSiteThemeProvider>
          </Route>
          <Route path={[AppRoutes.getPath("AttendeeSignUpPage")]} exact>
            <AttendeeCreateAccountPage />
          </Route>
          <Route path={[AppRoutes.getPath("AttendeeSiteMyTripPage")]} exact>
            <AttendeeSiteThemeProvider>
              <AttendeeSiteMyTripPage />
            </AttendeeSiteThemeProvider>
          </Route>
          <Route
            path={[
              AppRoutes.getPath("AttendeeSiteHome"),
              AppRoutes.getPath("AttendeeSitePage"),
            ]}
            exact>
            <AttendeeSiteThemeProvider>
              <AttendeeSite />
            </AttendeeSiteThemeProvider>
          </Route>
          <Route path={[AppRoutes.getPath("AttendeeSiteSurveyPage")]} exact>
            <AttendeeSiteThemeProvider>
              <SurveyPage />
            </AttendeeSiteThemeProvider>
          </Route>
        </Switch>
      </Route>
      {/* Dashboard routes */}
      {/* General Routes */}
      <ProtectedRoute
        path={[
          AppRoutes.getPath("EventsPage"),
          AppRoutes.getPath("CreateEventPage"),
        ]}
        exact>
        <PageContainer>
          <EventsPage />
        </PageContainer>
      </ProtectedRoute>
      <ProtectedRoute path="/r/:retreatIdx">
        <RetreatProviderV2>
          <RetreatProvider>
            <PageContainer>
              <PageSidenav />
              {/* Overview */}
              <Switch>
                <Route exact path={AppRoutes.getPath("RetreatHomePage")}>
                  <OverviewPage />
                </Route>
                {/* Email */}
                <Route exact path={AppRoutes.getPath("EmailTemplatePage")}>
                  <EditEmailTemplate />
                </Route>
                {/* Settings */}
                <Route exact path={AppRoutes.getPath("RetreatSettingsPage")}>
                  <RetreatSettingsPage />
                </Route>
                <Route
                  exact
                  path={AppRoutes.getPath("RetreatLodgingProposalsPage")}>
                  <LodgingProposalsPage />
                </Route>
                <Route
                  exact
                  path={AppRoutes.getPath("RetreatLodgingProposalPage")}>
                  <LodgingProposalPage />
                </Route>
                <Route
                  exact
                  path={AppRoutes.getPath("RetreatLodgingContractPage")}>
                  <LodgingContractPage />
                </Route>
                <Route
                  exact
                  path={AppRoutes.getPath("RetreatHotelProfilePage")}>
                  <HotelProfilePage />
                </Route>
                <Route exact path={AppRoutes.getPath("RetreatHotelSearchPage")}>
                  <RetreatHotelSearchPage />
                </Route>
                <Route exact path={AppRoutes.getPath("RetreatRfpPage")}>
                  <RetreatRfpPage />
                </Route>

                {/* Attendees */}
                <Route
                  exact
                  path={[
                    AppRoutes.getPath("RetreatAttendeesPage"),
                    AppRoutes.getPath("RetreatAttendeePage"),
                    AppRoutes.getPath("RetreatAttendeeFlightsPage"),
                    AppRoutes.getPath("RetreatAttendeeRegResponsePage"),
                    AppRoutes.getPath("RetreatAttendeeColumnPage"),
                  ]}>
                  <ManageAttendeesPage />
                </Route>
                <Route
                  exact
                  path={[
                    AppRoutes.getPath("ReportAttendeesPage"),
                    AppRoutes.getPath("ReportAttendeePage"),
                    AppRoutes.getPath("ReportAttendeeFlightsPage"),
                    AppRoutes.getPath("ReportAttendeeRegResponsePage"),
                    AppRoutes.getPath("ReportAttendeeColumnPage"),
                  ]}>
                  <AttendeesPage />
                </Route>

                <Route
                  exact
                  path={[
                    AppRoutes.getPath("UserGroupsPage"),
                    AppRoutes.getPath("UserGroupPage"),
                  ]}>
                  <UserGroupsPage />
                </Route>
                <Route
                  exact
                  path={[
                    AppRoutes.getPath("RetreatRegistrationFormBuilderPage"),
                  ]}>
                  <SiteRegistrationBuilderPage />
                </Route>
                <Route
                  path={[
                    AppRoutes.getPath("LandingPageGeneratorPage"),
                    AppRoutes.getPath("LandingPageGeneratorHome"),
                  ]}>
                  <SiteBuilderPage />
                </Route>
                <Route
                  exact
                  path={[
                    AppRoutes.getPath("RetreatFlightsOptionsPage"),
                    AppRoutes.getPath("RetreatFlightsOptionsConfig"),
                  ]}>
                  <FlightsOptionsPage />
                </Route>

                {/* Tasks */}
                <Route
                  exact
                  path={[
                    AppRoutes.getPath("TaskListPage"),
                    AppRoutes.getPath("TaskPage"),
                  ]}>
                  <TasksPage />
                </Route>

                {/* Itinerary */}
                <Route
                  exact
                  path={AppRoutes.getPath("RetreatItineraryInspirationPage")}>
                  <ItineraryInspirationPage />
                </Route>
                <Route
                  exact
                  path={[
                    AppRoutes.getPath("ItineraryPage"),
                    AppRoutes.getPath("ItineraryEventPage"),
                  ]}>
                  <ItineraryBuilderPage />
                </Route>

                {/* Surveys */}
                <Route exact path={AppRoutes.getPath("SurveysPage")}>
                  <SurveysPage />
                </Route>
                <Route exact path={AppRoutes.getPath("SurveyBuilderPage")}>
                  <SurveyBuilderPage />
                </Route>

                {/*  Communication */}
                <Route exact path={AppRoutes.getPath("CommunicationHomePage")}>
                  <CommunicationHomePage />
                </Route>
                <Route exact path={[AppRoutes.getPath("CreateEmailPage")]}>
                  <EmailGenerator />
                </Route>

                <Route path={"*"} component={NotFound404Page} />
              </Switch>
            </PageContainer>
          </RetreatProvider>
        </RetreatProviderV2>
      </ProtectedRoute>
      <Route path={"*"} component={NotFound404Page} />
    </Switch>
  )
}
