import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Popper,
  Select,
  TextField,
} from "@material-ui/core"
import {Cancel} from "@material-ui/icons"
import {useFormik} from "formik"
import _ from "lodash"
import {useState} from "react"
import {useDispatch} from "react-redux"
import {ImageBlockContentModel, ImageBlockModel} from "../../../models/retreat"
import AppUploadImageLargeButton from "../../../redesign/app/AppUploadImageLargeButton"
import {patchBlock} from "../../../store/actions/retreat"
import BeforeUnload from "../../base/BeforeUnload"

let useStyles = makeStyles((theme) => ({
  imagesWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(3),
    gap: theme.spacing(1),
  },
}))
type ImageBlockEditorProps = {
  block: ImageBlockModel
}

export default function ImageBlockEditor(props: ImageBlockEditorProps) {
  let classes = useStyles()
  let dispatch = useDispatch()
  let formik = useFormik({
    initialValues: {
      content: (props.block.content as ImageBlockContentModel) ?? {
        images: [undefined],
        layout: "single",
      },
      type: "IMAGE",
    },
    onSubmit: (values) => {
      dispatch(
        patchBlock(props.block.id, {
          content: values.content,
        })
      )
    },
    enableReinitialize: true,
  })

  let unsaved = !_.isEqual(formik.initialValues, formik.values)

  function getEditorImages(content: ImageBlockContentModel) {
    let returnValue = []
    for (let i = 0; i < (content.layout === "multi" ? 2 : 1); i++) {
      let img = content.images[i]
      if (img) {
        returnValue.push(
          <EditorImage
            notResizable={formik.values.content.layout !== "single"}
            src={img.src}
            maxHeight={
              formik.values.content.layout === "multi" ? `100%` : img.maxHeight
            }
            maxWidth={
              formik.values.content.layout === "multi" ? `50%` : img.maxWidth
            }
            setMaxHeight={(val) => {
              let images = [...formik.values.content.images]
              let currentImage = images[i]
              if (currentImage) {
                const unit = val.charAt(val.length - 1) === "%" ? "%" : "px"
                if (unit === "%" && parseInt(val) > 100) {
                  val = "100%"
                } else if (unit === "px" && parseInt(val) > 800) {
                  val = "800px"
                }
                images[i] = {...currentImage, maxHeight: val}
                formik.setFieldValue("content", {
                  ...formik.values.content,
                  images: images,
                })
              }
            }}
            setMaxWidth={(val) => {
              let images = [...formik.values.content.images]
              let currentImage = images[i]
              if (currentImage) {
                const unit = val.charAt(val.length - 1) === "%" ? "%" : "px"
                if (unit === "%" && parseInt(val) > 100) {
                  val = "100%"
                } else if (unit === "px" && parseInt(val) > 800) {
                  val = "800px"
                }
                images[i] = {...currentImage, maxWidth: val}
                formik.setFieldValue("content", {
                  ...formik.values.content,
                  images: images,
                })
              }
            }}
            onDelete={() => {
              let images = [...formik.values.content.images]
              images[i] = undefined
              formik.setFieldValue("content", {
                ...formik.values.content,
                images: images,
              })
            }}
          />
        )
      } else {
        returnValue.push(
          <AppUploadImageLargeButton
            id="test"
            accepts="image/png, image/gif, image/jpeg"
            handleChange={(file) => {
              let images = [...content.images]
              images[i] = {
                src: file.file_url,
                maxHeight: "100%",
                maxWidth: "100%",
              }
              formik.setFieldValue("content", {
                ...formik.values.content,
                images: [...images],
              })
            }}
          />
        )
      }
    }
    return returnValue
  }
  return (
    <div>
      <BeforeUnload
        when={!_.isEqual(formik.values, formik.initialValues)}
        message="Are you sure you wish to leave without saving your changes?"
      />
      <form onSubmit={formik.handleSubmit}>
        <Box p={2}>
          <div>
            <Select
              value={formik.values.content.layout}
              onChange={(e) => {
                formik.setFieldValue("content", {
                  ...formik.values.content,
                  layout: e.target.value,
                })
              }}>
              <option value="single">Single</option>
              <option value="multi">Multi</option>
            </Select>
            <div className={classes.imagesWrapper}>
              {getEditorImages(formik.values.content)}
            </div>
          </div>
        </Box>
        {unsaved && (
          <Box pl={1} pb={1}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Box>
        )}
      </form>
    </div>
  )
}

let useRendererStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))
type ImageBlockRendererProps = {
  block: ImageBlockModel
}
export function ImageBlockRenderer(props: ImageBlockRendererProps) {
  let classes = useRendererStyles()
  let content = props.block.content as ImageBlockContentModel
  function renderImages(content: ImageBlockContentModel) {
    let returnValue = []
    for (let i = 0; i < (content.layout === "multi" ? 2 : 1); i++) {
      let image = content.images[i]
      if (image) {
        returnValue.push(
          <RenderImage
            layout={content.layout}
            maxHeight={image.maxHeight}
            maxWidth={image.maxWidth}
            src={image.src}
          />
        )
      } else {
        returnValue.push(
          <RenderImage
            layout={content.layout}
            maxWidth="100%"
            src={
              "https://flok-b32d43c.s3.amazonaws.com/hotels/missing-spotlight-image.png"
            }
          />
        )
      }
    }
    return returnValue
  }

  return props.block.content ? (
    <div className={classes.wrapper}>{renderImages(content)}</div>
  ) : (
    <></>
  )
}

let useEditorImageStyles = makeStyles((theme) => ({
  wrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    maxWidth: (props: {maxWidth: string; maxHeight: string}) => props.maxWidth,
    maxHeight: (props: {maxWidth: string; maxHeight: string}) =>
      props.maxHeight,
  },
  popperWrapper: {
    padding: theme.spacing(2),
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  input: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    marginLeft: "auto",
    marginBottom: -40,
  },
  image: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
    maxHeight: (props: {maxWidth: string; maxHeight: string}) =>
      props.maxHeight,
  },
}))
function EditorImage(props: {
  src: string
  maxWidth: string
  maxHeight: string
  setMaxWidth: (newVal: string) => void
  setMaxHeight: (newVal: string) => void
  onDelete: () => void
  notResizable?: boolean
}) {
  let classes = useEditorImageStyles(props)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? "simple-popper" : undefined
  const widthUnit =
    props.maxWidth.charAt(props.maxWidth.length - 1) === "%" ? "%" : "px"
  const heightUnit =
    props.maxHeight.charAt(props.maxHeight.length - 1) === "%" ? "%" : "px"
  return (
    <div className={classes.wrapper}>
      <Popper id={id} open={open && !props.notResizable} anchorEl={anchorEl}>
        <Paper elevation={2} className={classes.popperWrapper}>
          <div className={classes.inputWrapper}>
            <div className={classes.input}>
              <TextField
                value={parseInt(props.maxWidth.toString())}
                type="number"
                onChange={(e) => {
                  props.setMaxWidth(`${e.target.value}${widthUnit}`)
                }}
              />
              <Select
                label="."
                value={widthUnit}
                onChange={(e) => {
                  props.setMaxWidth(
                    `${parseInt(props.maxWidth)}${e.target.value}`
                  )
                }}>
                <option>px</option>
                <option>%</option>
              </Select>
            </div>
            x
            <div className={classes.input}>
              <TextField
                value={parseInt(props.maxHeight.toString())}
                type="number"
                onChange={(e) => {
                  props.setMaxHeight(`${e.target.value}${heightUnit}`)
                }}
              />
              <Select
                label="."
                value={heightUnit}
                onChange={(e) => {
                  props.setMaxHeight(
                    `${parseInt(props.maxHeight)}${e.target.value}`
                  )
                }}>
                <option>px</option>
                <option>%</option>
              </Select>
            </div>
          </div>
        </Paper>
      </Popper>
      <IconButton className={classes.iconButton} onClick={props.onDelete}>
        <Cancel />
      </IconButton>

      <img
        onBlur={() => {
          setAnchorEl(null)
        }}
        onClick={(event) => {
          setAnchorEl(anchorEl ? null : event.currentTarget)
        }}
        src={props.src}
        alt="custom"
        className={classes.image}
      />
    </div>
  )
}
type RenderImageProps = {
  src: string
  layout: "multi" | "single"
  maxWidth: string
  maxHeight?: string
}
let useImageStyles = makeStyles((theme) => ({
  image: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: (props: RenderImageProps) =>
      props.layout !== "multi" ? props.maxWidth : "50%",
    maxHeight: (props: RenderImageProps) =>
      props.layout !== "multi" && props.maxHeight ? props.maxHeight : "100%",
  },
}))
function RenderImage(props: RenderImageProps) {
  let classes = useImageStyles(props)
  return <img src={props.src} alt="custom" className={classes.image} />
}
