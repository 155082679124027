import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckIcon,
  EyeNoneIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@radix-ui/react-icons"
import {Badge} from "@summtech/flok-base/components/Badge"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {Text} from "@summtech/flok-base/components/Text"
import {css, styled, theme} from "@summtech/flok-base/stitches.config"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table"
import {useEffect, useState} from "react"
import {CSVLink} from "react-csv"
import {useSelector} from "react-redux"
import {getColumnAnswers} from "../../../api/attendee"
import {LocalFilterModel} from "../../../models/communication"
import {
  AttendeeInfoStatus,
  ColumnAnswerModel,
  RetreatAttendeeModel,
  RetreatColumnModel,
  TripLegTypeEnum,
} from "../../../models/retreat"
import {RootState} from "../../../store"
import {formatCurrency, getDatetimeString} from "../../../utils"
import {
  useAttendeeGroupFilters,
  useAttendeeGroups,
  useRetreat,
  useRetreatAudience,
} from "../../../utils/retreatUtils"
import AppScrollSyncTable from "../AppScrollSyncTable"
import {
  getRowLabelForCsv,
  getTableFilteredRows,
  StyledSortIconCss,
  StyledTableHeaderCell,
  TableFooter,
} from "./AttendeesTable"
import {
  FlightStatusBadge,
  RegistrationStatusBadge,
  TripLegTypeBadge,
} from "./StatusBadge"

const StyledCellCss = css({
  maxWidth: "$$width",
  minWidth: "$$width",
  borderBottom: `solid 1px ${theme.colors.gray6}`,
  "& *": {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  variants: {
    gridLines: {
      true: {
        "&:not(:last-child)": {
          borderRight: `solid 1px ${theme.colors.gray6}`,
        },
      },
    },
  },
})
export type ShareableAttendeesTableProps = {
  attendees: RetreatAttendeeModel[]
  activeView?: "all-attendees" | "form-responses" | "flights" | string
  exportRef?: {
    current: (CSVLink & HTMLAnchorElement & {link: HTMLAnchorElement}) | null
  }
  retreatId: number
}

export function ShareableAttendeesTable(props: ShareableAttendeesTableProps) {
  let [retreat] = useRetreat(props.retreatId)
  let queryClient = useQueryClient()
  let columnAnswersQuery = useQuery({
    enabled: retreat?.id != null,
    queryKey: ["column-answers", {retreatId: retreat?.id}],
    queryFn: () => getColumnAnswers(retreat!.id),
    onSuccess: (data) => {
      data.column_answers.forEach((columnAnswer) => {
        queryClient.setQueryData(
          ["column-answers", columnAnswer.id],
          columnAnswer
        )
      })
    },
  })

  let attendeeReports = useSelector((state: RootState) => {
    return state.retreat.attendeeReports
  })
  let [audience] = useRetreatAudience(
    attendeeReports[parseInt(props.activeView ?? "-1")]?.audience_id ?? -1
  )
  let [attendeeGroups] = useAttendeeGroups(audience?.attendee_group_ids ?? [])
  let [allFilters] = useAttendeeGroupFilters(
    attendeeGroups.flatMap((group) => group.filter_ids)
  )
  let filters = attendeeGroups.map((group) => {
    let normalForGroup = group.filter_ids.map((id) => {
      let filter = allFilters.find((fil) => fil.id === id)
      return {
        ...filter,
        field: (filter?.column_id ?? -1).toString(),
        attendee_group_id: filter?.attendee_group_id,
      } as LocalFilterModel
    })
    let defaultForGroup = group.default_filters.map((filter) => {
      return {...filter, attendee_group_id: group.id}
    })
    return normalForGroup.concat(defaultForGroup)
  })

  let columnAnswerMap = (
    columnAnswersQuery?.data?.column_answers
      ? columnAnswersQuery.data.column_answers.reduce(
          (prev, curr) => ({...prev, [curr.id]: curr}),
          {}
        )
      : {}
  ) as {[id: number]: ColumnAnswerModel}

  let columns = useSelector((state: RootState) => {
    return Object.values(state.retreat.attendeeColumns).filter((column) => {
      if (column && retreat && retreat.column_ids.indexOf(column.id) !== -1) {
        return true
      } else {
        return false
      }
    }) as RetreatColumnModel[]
  })

  let flights = useSelector((state: RootState) => {
    return state.retreat.flights
  })

  let [tableVisibilityState, setTableVisibilityState] = useState({
    first_name: true,
    last_name: true,
    email_address: true,
    info_status: true,
    dietary_prefs: false,
    notes: false,
    flight_status: false,
    flight_arrival_time: false,
    flight_arrival_airport: false,
    flight_arrival_number: false,
    flight_arrival_airline: false,
    flight_arrival_type: false,
    flight_departure_time: false,
    flight_departure_airport: false,
    flight_departure_number: false,
    flight_departure_airline: false,
    flight_departure_type: false,
    ...(columns.length > 0
      ? columns.reduce((prev, column) => {
          return {...prev, [`column-${column.id}`]: false}
        }, {})
      : {}),
  })

  let columnHelper = createColumnHelper<RetreatAttendeeModel>()
  let [columnFilters, setColumnFilters] = useState<
    {
      id: string
      value: string | string[]
    }[]
  >([])
  let options: TableOptions<RetreatAttendeeModel> = {
    defaultColumn: {
      enableGlobalFilter: false,
      enableSorting: false,
      size: 150,
    },
    globalFilterFn: "includesString",
    initialState: {
      columnVisibility: {id: false},
      pagination: {
        pageSize: 50,
      },
    },
    state: {
      columnVisibility: tableVisibilityState,
      columnFilters: columnFilters,
    },
    columns: [
      columnHelper.accessor("first_name", {
        header: "First Name",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
        filterFn: "includesString",
      }),
      columnHelper.accessor("last_name", {
        header: "Last Name",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor("email_address", {
        header: "Email",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor("info_status", {
        cell: (props) => {
          let registrationStatus = props.getValue() as AttendeeInfoStatus
          return <RegistrationStatusBadge status={registrationStatus} />
        },
        header: "Registration Status",
        enableSorting: true,
        sortingFn: "alphanumeric",
        filterFn: "arrIncludesSome",
      }),
      columnHelper.accessor("hotel_check_in", {
        header: "Hotel Check In",
        enableSorting: true,
        sortingFn: "datetime",
      }),
      columnHelper.accessor("hotel_check_out", {
        header: "Hotel Check Out",
        enableSorting: true,
        sortingFn: "datetime",
      }),
      columnHelper.accessor("dietary_prefs", {
        header: "Dietary Preferences",
        filterFn: "includesString",
      }),
      columnHelper.accessor("notes", {
        header: "Notes",
      }),
      // Flights
      columnHelper.accessor("flight_status", {
        cell: (props) => {
          let flightStatus =
            props.getValue() as RetreatAttendeeModel["flight_status"]
          return <FlightStatusBadge status={flightStatus} />
        },
        header: "Travel status",
        enableSorting: true,
        sortingFn: "alphanumeric",
        filterFn: "arrIncludesSome",
      }),
      columnHelper.accessor((attendee) => attendee.travel?.cost, {
        id: "flight_cost",
        cell: (props) => {
          let cost = props.getValue()
          if (cost != null) {
            return formatCurrency(cost, "USD", 2)
          } else {
            return <></>
          }
        },
        header: "Travel cost",
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            return (
              flights[tripLegIds[tripLegIds.length - 1]]?.trip_leg_type ??
              TripLegTypeEnum.FLIGHT
            )
          }
        },
        {
          cell: (props) => {
            let type = props.getValue()
            if (type) {
              return <TripLegTypeBadge tripLegType={type} />
            } else {
              return <></>
            }
          },
          id: "flight_arrival_type",
          header: "Arrival type",
          sortingFn: "alphanumeric",
          filterFn: "arrIncludesSome",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            return flights[tripLegIds[tripLegIds.length - 1]]?.arr_datetime
          }
        },
        {
          id: "flight_arrival_time",
          header: "Arrival Time",
          enableSorting: true,
          sortingFn: "datetime",
          filterFn: "includesString",
          cell: (props) => {
            let time = props.getValue()
            return getDatetimeString(time as string)
          },
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            let tripLeg = flights[tripLegIds[tripLegIds.length - 1]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.arr_station
            } else {
              return tripLeg?.arr_airport
            }
          }
        },
        {
          id: "flight_arrival_airport",
          header: "Arrival Location",
          enableSorting: true,
          sortingFn: "alphanumeric",
          filterFn: "includesString",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            let tripLeg = flights[tripLegIds[tripLegIds.length - 1]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.train_num
            } else {
              return tripLeg?.flight_num
            }
          }
        },
        {
          id: "flight_arrival_number",
          header: "Arrival #",
          enableSorting: true,
          sortingFn: "alphanumeric",
          filterFn: "includesString",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            let tripLeg = flights[tripLegIds[tripLegIds.length - 1]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.railway
            } else {
              return tripLeg?.airline
            }
          }
        },
        {
          id: "flight_arrival_airline",
          header: "Arrival Airline",
          enableSorting: true,
          sortingFn: "alphanumeric",
          filterFn: "includesString",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            return (
              flights[tripLegIds[0]]?.trip_leg_type ?? TripLegTypeEnum.FLIGHT
            )
          }
        },
        {
          cell: (props) => {
            let type = props.getValue()
            if (type) {
              return <TripLegTypeBadge tripLegType={type} />
            } else {
              return <></>
            }
          },
          id: "flight_departure_type",
          header: "Departure type",
          sortingFn: "alphanumeric",
          filterFn: "arrIncludesSome",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            return flights[tripLegIds[0]]?.dep_datetime
          }
        },
        {
          id: "flight_departure_time",
          header: "Departure Time",
          enableSorting: true,
          sortingFn: "datetime",
          filterFn: "includesString",
          cell: (props) => {
            let time = props.getValue()
            return getDatetimeString(time as string)
          },
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            let tripLeg = flights[tripLegIds[0]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.dep_station
            } else {
              return tripLeg?.dep_airport
            }
          }
        },
        {
          id: "flight_departure_airport",
          header: "Departure Location",
          enableSorting: true,
          sortingFn: "alphanumeric",
          filterFn: "includesString",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            let tripLeg = flights[tripLegIds[0]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.train_num
            } else {
              return tripLeg?.flight_num
            }
          }
        },
        {
          id: "flight_departure_number",
          header: "Departure #",
          enableSorting: true,
          sortingFn: "alphanumeric",
          filterFn: "includesString",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            let tripLeg = flights[tripLegIds[0]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.railway
            } else {
              return tripLeg?.airline
            }
          }
        },
        {
          id: "flight_departure_airline",
          header: "Departure Airline",
          enableSorting: true,
          sortingFn: "alphanumeric",
          filterFn: "includesString",
        }
      ),
      // form responses
      ...columns.map((column) => {
        return columnHelper.accessor(
          (attendee) => {
            if (attendee.column_id_to_answer_id[column.id]) {
              return columnAnswerMap[attendee.column_id_to_answer_id[column.id]]
                ?.value
            }
          },
          {
            ...(column.type === "DATETIME" && {
              cell: (props) => {
                let time = props.getValue()
                return getDatetimeString(time as string)
              },
            }),
            ...(column.type === "MULTI_SELECT" && {
              cell: (props) => {
                let value = props.getValue()
                if (!value) {
                  return value ?? ""
                } else if (typeof value === "object" && value) {
                  return <MultiSelectCell value={value as string[]} />
                } else {
                  return (
                    <MultiSelectCell
                      value={(value as string).split(",") as string[]}
                    />
                  )
                }
              },
            }),
            header: column.title,
            id: column.id.toString(),
            enableSorting: true,
            sortingFn: "alphanumeric",
            filterFn:
              column.type === "SINGLE_SELECT" || column.type === "MULTI_SELECT"
                ? "arrIncludesSome"
                : "includesString",
          }
        )
      }),
    ],
    data: Object.values(columnAnswerMap).length > 0 ? props.attendees : [],
    meta: {
      columnFilters: filters.map((groupFilterState) => {
        return groupFilterState.filter((filter) => filter.field && filter.value)
      }),
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getTableFilteredRows,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: true,
  }
  const table = useReactTable(options)
  // NEED A BETTER METHOD THAN THESE TWO, IT USES TOO MUCH RESOURCES
  let rowsToExport = table.getPrePaginationRowModel().rows.map((row) => {
    let attendeeInfo: any = {}
    row
      .getVisibleCells()
      .forEach(
        (cell) =>
          (attendeeInfo[cell.column.id] = getRowLabelForCsv(
            cell.getContext().getValue() as string,
            cell.column.id
          ) as string)
      )
    return attendeeInfo
  })

  let headersToExport = table.getHeaderGroups().map((headerGroup) => {
    return headerGroup.headers
      .filter((header) => header.depth === 1 && header.column.getIsVisible())
      .map((header) => {
        let headerObject = {
          label: header.column.columnDef.header as string,
          key: header.id,
        }
        return headerObject
      })
  })[0]

  useEffect(() => {
    function defaultCol(col: string) {
      return [
        "first_name",
        "last_name",
        "email_address",
        "info_status",
      ].includes(col)
    }
    function roomingCol(col: string) {
      return (
        defaultCol(col) || ["hotel_check_in", "hotel_check_out"].includes(col)
      )
    }
    function registrationCol(col: string) {
      return defaultCol(col) || col.startsWith("column-")
    }
    function flightsCol(col: string) {
      return defaultCol(col) || col.startsWith("flight_")
    }
    let fn =
      props.activeView === "form-responses"
        ? registrationCol
        : props.activeView === "flights"
        ? flightsCol
        : roomingCol

    setTableVisibilityState(
      // @ts-ignore
      table.getAllLeafColumns().reduce<{
        [key: string]: boolean
      }>((prev, col) => {
        return {
          ...prev,
          [col.id]: fn(col.id),
        }
      }, {})
    )
    if (
      props.activeView !== "all-attendees" &&
      props.activeView !== "flights" &&
      props.activeView !== "form-responses" &&
      props.activeView
    ) {
      let report = attendeeReports[parseInt(props.activeView)]
      if (report) {
        setTableVisibilityState(
          // @ts-ignore
          table.getAllLeafColumns().reduce<{
            [key: string]: boolean
          }>((prev, col) => {
            return {
              ...prev,
              [col.id]: report!.column_visibility[col.id],
            }
          }, {})
        )
        // setColumnFilters(report.column_filters)
        if (report.column_sort) {
          table.setSorting(report.column_sort)
        }
      }
    } else {
      setColumnFilters([])
      table.setSorting([])
    }
  }, [props.activeView, attendeeReports])

  return (
    <AppScrollSyncTable>
      <AppScrollSyncTable.Header className={StyledTableHead()}>
        <CSVLink
          filename={"attendees-export.csv"}
          ref={props.exportRef}
          style={{display: "none"}}
          data={rowsToExport}
          headers={headersToExport}
        />
        <tr>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers
              .filter((header) => header.depth === 1)
              .map((header) => (
                <Dropdown
                  key={header.id}
                  button={
                    <StyledTableHeaderCell
                      colSpan={header.colSpan}
                      gridLines
                      css={{
                        $$width: `${header.column.columnDef.size}px`,
                        cursor: header.column.getCanSort()
                          ? "pointer"
                          : "inherit",
                      }}>
                      <span>
                        <Text variant="text-sm-plus">
                          {header.isPlaceholder
                            ? "\u00A0"
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Text>
                        {header.column.getCanSort() &&
                        header.column.getIsSorted() === "asc" ? (
                          <TriangleUpIcon className={StyledSortIconCss()} />
                        ) : header.column.getIsSorted() === "desc" ? (
                          <TriangleDownIcon className={StyledSortIconCss()} />
                        ) : (
                          <></>
                        )}
                      </span>
                    </StyledTableHeaderCell>
                  }>
                  {header.column.getCanSort() && (
                    <DropdownItem
                      startIcon={<ArrowUpIcon />}
                      text={"Sort Ascending"}
                      endIcon={
                        header.column.getIsSorted() === "asc" ? (
                          <CheckIcon />
                        ) : undefined
                      }
                      onClick={
                        header.column.getIsSorted() === "asc"
                          ? () => header.column.clearSorting()
                          : () => header.column.toggleSorting(false)
                      }
                    />
                  )}
                  {header.column.getCanSort() && (
                    <DropdownItem
                      startIcon={<ArrowDownIcon />}
                      text={"Sort Descending"}
                      endIcon={
                        header.column.getIsSorted() === "desc" ? (
                          <CheckIcon />
                        ) : undefined
                      }
                      onClick={
                        header.column.getIsSorted() === "desc"
                          ? () => header.column.clearSorting()
                          : () => header.column.toggleSorting(true)
                      }
                    />
                  )}

                  <DropdownItem
                    startIcon={<EyeNoneIcon />}
                    text="Hide Column"
                    onClick={() => {
                      setTableVisibilityState((state) => {
                        return {
                          ...state,
                          [header.column.id]: false,
                        }
                      })
                    }}
                  />
                </Dropdown>
              ))
          )}
        </tr>
      </AppScrollSyncTable.Header>

      <AppScrollSyncTable.Body>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <StyledTableDataCell
                gridLines
                css={{$$width: `${cell.column.columnDef.size}px`}}>
                <Text variant="text-sm">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Text>
              </StyledTableDataCell>
            ))}
          </tr>
        ))}
      </AppScrollSyncTable.Body>
      <AppScrollSyncTable.Footer>
        <TableFooter
          absolute
          rowCount={table.getFilteredRowModel().rows.length}
          currentPage={table.getState().pagination.pageIndex + 1}
          pageCount={table.getPageCount()}
          pageSize={table.getState().pagination.pageSize}
          onNextPage={table.getCanNextPage() ? table.nextPage : undefined}
          onPreviousPage={
            table.getCanPreviousPage() ? table.previousPage : undefined
          }
        />
      </AppScrollSyncTable.Footer>
    </AppScrollSyncTable>
  )
}
export const StyledTableHead = css({
  backgroundColor: theme.colors.gray2,
  color: theme.colors.gray11,
})

const StyledTableDataCell = styled("td", StyledCellCss, {
  padding: "10px",
  background: theme.colors.white,
})

const MultiSelectCellContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "2px",
  flexWrap: "nowrap",
  overflow: "auto",
  alignItems: "center",
})
function MultiSelectCell(props: {value: string[]}) {
  return (
    <MultiSelectCellContainer>
      <Badge label={props.value[0]} />
      {props.value.length - 1 ? `+ ${props.value.length - 1}` : ""}
    </MultiSelectCellContainer>
  )
}
