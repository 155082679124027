import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Constants} from "../config"
import {
  AttendeeGroupFilterModel,
  AttendeeGroupModel,
  AudienceModel,
  RetreatEmailModel,
} from "../models/communication"
import {
  AttendeeLandingWebsiteBlockModel,
  AttendeeLandingWebsitePageModel,
  RetreatColumnModel,
  RetreatModel,
  RetreatToTask,
  RetreatTripLeg,
} from "../models/retreat"
import {
  FlightStatusBadge,
  RegistrationStatusBadge,
} from "../redesign/app/attendees/StatusBadge"
import {RootState} from "../store"
import {
  getAttendee,
  getAttendeeGroup,
  getAttendeeGroupFilter,
  getBlock,
  getComment,
  getCommentThread,
  getFlight,
  getMyAttendee,
  getPage,
  getPageByName,
  getRetreat,
  getRetreatAudience,
  getRetreatByGuid,
  getRetreatEmail,
  getShareableAttendeeReport,
  getUser,
  getWebsite,
  getWebsiteByName,
} from "../store/actions/retreat"
import {getLabel, getTask, getTaskTemplateGroups} from "../store/actions/task"

/**
 * Deprecated
 * @param retreatGuid
 * @returns
 */
export function useRetreatByGuid(retreatGuid: string) {
  let dispatch = useDispatch()
  let [loading, setLoading] = useState(false)
  let retreat = useSelector(
    (state: RootState) => state.retreat.retreatsByGuid[retreatGuid]
  )
  useEffect(() => {
    async function loadRetreat() {
      setLoading(true)
      dispatch(getRetreatByGuid(retreatGuid))
      setLoading(false)
    }
    if (!retreat) {
      loadRetreat()
    }
  }, [retreat, dispatch, retreatGuid])
  return [retreat, loading] as const
}

export function parseRetreatTask(task: RetreatToTask, baseUrl: string) {
  let parsedTask = {...task}
  if (task.link) {
    parsedTask.link = task.link.replaceAll(Constants.retreatBaseUrlVar, baseUrl)
  }
  if (task.description) {
    parsedTask.description = task.description.replaceAll(
      Constants.retreatBaseUrlVar,
      baseUrl
    )
  }
  return parsedTask
}

export function useAttendeeLandingWebsite(websiteId: number) {
  let website = useSelector((state: RootState) => {
    return state.retreat.websites[websiteId]
  })
  let [loading, setLoading] = useState(!website)
  let dispatch = useDispatch()
  useEffect(() => {
    async function loadWebsite() {
      setLoading(true)
      await dispatch(getWebsite(websiteId))
      setLoading(false)
    }
    if (!website) {
      loadWebsite()
    }
  }, [website, dispatch, websiteId])

  return [website, loading] as const
}
export function useAttendeeLandingPage(pageId: number) {
  let dispatch = useDispatch()
  let page = useSelector((state: RootState) => {
    return state.retreat.pages[pageId]
  })
  let [loading, setLoading] = useState(!page)
  useEffect(() => {
    async function loadPage() {
      setLoading(true)
      await dispatch(getPage(pageId))
      setLoading(false)
    }
    if (!page) {
      loadPage()
    }
  }, [page, dispatch, pageId])

  return [page, loading] as const
}

export function useAttendeeLandingPages(pageIds: number[]) {
  let dispatch = useDispatch()
  let pages = useSelector((state: RootState) =>
    pageIds.reduce<{[id: number]: AttendeeLandingWebsitePageModel | undefined}>(
      (prev, id) => ({...prev, [id]: state.retreat.pages[id]}),
      {}
    )
  )
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadPages(pageIds: number[]) {
      setLoading(true)
      await Promise.all(pageIds.map((id) => dispatch(getPage(id))))
      setLoading(false)
    }
    if (!loading) {
      let missingPageIds = Object.keys(pages)
        .filter((id) => !pages[parseInt(id)])
        .map((id) => parseInt(id))
      if (missingPageIds.length) {
        loadPages(missingPageIds)
      }
    }
  }, [dispatch, pages, loading])
  let pageList: AttendeeLandingWebsitePageModel[] = pageIds
    .map((id) => pages[id])
    .filter((page) => page) as AttendeeLandingWebsitePageModel[]

  return [pageList, loading] as const
}

export function useAttendeeLandingPageBlock(blockId: number) {
  let dispatch = useDispatch()
  let block = useSelector((state: RootState) => {
    return state.retreat.blocks[blockId]
  })
  let [loading, setLoading] = useState(!block)
  useEffect(() => {
    async function loadBlock() {
      setLoading(true)
      await dispatch(getBlock(blockId))
      setLoading(false)
    }
    if (!block) {
      loadBlock()
    }
  }, [block, dispatch, blockId])

  return [block, loading] as const
}

export function useAttendeeLandingPageBlocks(blockIds: number[]) {
  let dispatch = useDispatch()
  let blocks = useSelector((state: RootState) =>
    blockIds.reduce<{
      [id: number]: AttendeeLandingWebsiteBlockModel | undefined
    }>((prev, id) => ({...prev, [id]: state.retreat.blocks[id]}), {})
  )
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadBlocks(blockIds: number[]) {
      setLoading(true)
      await Promise.all(blockIds.map((id) => dispatch(getBlock(id))))
      setLoading(false)
    }
    if (!loading) {
      let missingBlockIds = Object.keys(blocks)
        .filter((id) => !blocks[parseInt(id)])
        .map((id) => parseInt(id))
      if (missingBlockIds.length) {
        loadBlocks(missingBlockIds)
      }
    }
  }, [dispatch, blocks, loading])
  let blockList: AttendeeLandingWebsiteBlockModel[] = blockIds
    .map((id) => blocks[id])
    .filter((block) => block) as AttendeeLandingWebsiteBlockModel[]

  return [blockList, loading] as const
}

export function useAttendeeLandingPageName(
  websiteId: number,
  pageName: string
) {
  let dispatch = useDispatch()
  let [loading, setLoading] = useState(true)
  let page = useSelector((state: RootState) => {
    return Object.values(state.retreat.pages).find(
      (page) => page?.title.toLowerCase() === pageName.toLowerCase()
    )
  })

  useEffect(() => {
    async function loadPage() {
      setLoading(true)
      await dispatch(getPageByName(websiteId, pageName))
      setLoading(false)
    }
    if (!page) {
      loadPage()
    } else {
      setLoading(false)
    }
  }, [page, dispatch, pageName, websiteId])

  return [page, loading] as const
}

export function useAttendeeLandingWebsiteName(websiteName: string) {
  let [loading, setLoading] = useState(true)
  let website = useSelector((state: RootState) => {
    return Object.values(state.retreat.websites).find(
      (website) => website?.name.toLowerCase() === websiteName.toLowerCase()
    )
  })
  let dispatch = useDispatch()
  useEffect(() => {
    async function loadWebsite() {
      setLoading(true)
      await dispatch(getWebsiteByName(websiteName))
      setLoading(false)
    }
    if (!website) {
      loadWebsite()
    } else {
      setLoading(false)
    }
  }, [website, dispatch, websiteName])

  return [website, loading] as const
}

export function useMyAttendee(retreatId: number) {
  let dispatch = useDispatch()
  let attendeeId = useSelector((state: RootState) => {
    return state.user.myAttendeeByRetreat[retreatId]
  })
  useEffect(() => {
    if (attendeeId == null) {
      dispatch(getMyAttendee(retreatId))
    }
  }, [retreatId, dispatch, attendeeId])

  let attendee = useSelector((state: RootState) => {
    if (attendeeId != null && state.user.loginStatus !== "LOGGED_OUT")
      return state.retreat.attendees[attendeeId]
  })
  useEffect(() => {
    if (attendeeId != null && attendee == null) {
      dispatch(getAttendee(attendeeId))
    }
  }, [attendeeId, attendee, dispatch])

  return [attendee]
}

export function useRetreat(retreatId: number) {
  let [loading, setLoading] = useState(true)
  let retreat = useSelector((state: RootState) => {
    return state.retreat.retreats[retreatId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    async function loadRetreat() {
      setLoading(true)
      await dispatch(getRetreat(retreatId))
      setLoading(false)
    }
    if (!retreat) {
      loadRetreat()
    } else {
      setLoading(false)
    }
  }, [retreat, dispatch, retreatId])

  return [retreat, loading] as const
}

export function getRetreatName(retreat: RetreatModel) {
  if (retreat.retreat_name != null) {
    return retreat.retreat_name
  } else if (retreat.company_name) {
    return `${retreat.company_name}'s Event`
  } else {
    return "Untitled Event"
  }
}

export const splitFileName = function (str: string) {
  let popped = str.split("\\").pop()
  if (popped) {
    return popped.split("/").pop()
  }
}

export function useFlight(flightId: number) {
  let dispatch = useDispatch()
  let flight = useSelector((state: RootState) => {
    return state.retreat.flights[flightId]
  })
  let [loading, setLoading] = useState(false)
  useEffect(() => {
    async function loadFlight() {
      setLoading(true)
      await dispatch(getFlight(flightId))
      setLoading(false)
    }
    if (!flight) {
      loadFlight()
    }
  }, [flight, dispatch, flightId])

  return [flight, loading] as const
}

export function useFlights(flightIds: number[]) {
  let dispatch = useDispatch()
  let flights = useSelector((state: RootState) =>
    flightIds.reduce<{[id: number]: RetreatTripLeg | undefined}>(
      (prev, id) => ({...prev, [id]: state.retreat.flights[id]}),
      {}
    )
  )
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadFlights(flightIds: number[]) {
      setLoading(true)
      await Promise.all(flightIds.map((id) => dispatch(getFlight(id))))
      setLoading(false)
    }
    if (!loading) {
      let missingFlightIds = Object.keys(flights)
        .filter((id) => !flights[parseInt(id)])
        .map((id) => parseInt(id))
      if (missingFlightIds.length) {
        loadFlights(missingFlightIds)
      }
    }
  }, [dispatch, flights, loading])
  let flightList: RetreatTripLeg[] = flightIds
    .map((id) => flights[id])
    .filter((flight) => flight) as RetreatTripLeg[]

  return [flightList, loading] as const
}

export function useCommentThread(commentThreadId: number) {
  let [threadLoading, setThreadLoading] = useState(false)
  let thread = useSelector((state: RootState) => {
    return state.retreat.commentThreads[commentThreadId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    if (!thread) {
      loadThread()
    }
    async function loadThread() {
      setThreadLoading(true)
      await dispatch(getCommentThread(commentThreadId))
      setThreadLoading(false)
    }
  }, [thread, dispatch, commentThreadId])

  return [thread, threadLoading] as const
}

export function useComment(commentId: number) {
  let [commentLoading, setCommentLoading] = useState(false)
  let comment = useSelector((state: RootState) => {
    return state.retreat.comments[commentId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    if (!comment) {
      loadComment()
    }
    async function loadComment() {
      setCommentLoading(true)
      await dispatch(getComment(commentId))
      setCommentLoading(false)
    }
  }, [comment, dispatch, commentId])

  return [comment, commentLoading] as const
}

export function useUser(userId: number) {
  let [userLoading, setUserLoading] = useState(false)
  let user = useSelector((state: RootState) => {
    return state.retreat.users[userId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    if (!user) {
      loadUser()
    }
    async function loadUser() {
      setUserLoading(true)
      await dispatch(getUser(userId))
      setUserLoading(false)
    }
  }, [user, dispatch, userId])

  return [user, userLoading] as const
}

export function useUsers(userIds: number[]) {
  let users = useSelector((state: RootState) => {
    let entries = Object.entries(state.retreat.users).filter(
      ([key, user]) => userIds.indexOf(user?.id as number) !== -1
    )
    return Object.fromEntries(entries)
  })
  let [loadingUsers, setLoadingUsers] = useState(false)
  let dispatch = useDispatch()

  useEffect(() => {
    async function loadUsers(userIds: number[]) {
      setLoadingUsers(true)
      await Promise.all(userIds.map((id) => dispatch(getUser(id))))
      setLoadingUsers(false)
    }
    if (!loadingUsers) {
      let missingUsers = userIds
        .map((id) => (!users[id] ? id : undefined))
        .filter((id) => id)
      if (missingUsers.length > 0) {
        loadUsers(missingUsers as number[])
      }
    }
  }, [dispatch, userIds, users, loadingUsers])
  return [users, loadingUsers] as const
}

export function useRetreatEmail(emailId: number) {
  let dispatch = useDispatch()
  let email = useSelector((state: RootState) => {
    return state.retreat.retreatEmails[emailId]
  })
  let [loading, setLoading] = useState(false)
  useEffect(() => {
    async function loadEmail() {
      setLoading(true)
      await dispatch(getRetreatEmail(emailId))
      setLoading(false)
    }
    if (!email) {
      loadEmail()
    }
  }, [email, dispatch, emailId])

  return [email, loading] as const
}

export function useRetreatEmails(emailIds: number[]) {
  let dispatch = useDispatch()
  let emails = useSelector((state: RootState) =>
    emailIds.reduce<{
      [id: number]: RetreatEmailModel | undefined
    }>((prev, id) => ({...prev, [id]: state.retreat.retreatEmails[id]}), {})
  )
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadEmails(emailIds: number[]) {
      setLoading(true)
      await Promise.all(emailIds.map((id) => dispatch(getRetreatEmail(id))))
      setLoading(false)
    }
    if (!loading) {
      let missingEmailIds = Object.keys(emails)
        .filter((id) => !emails[parseInt(id)])
        .map((id) => parseInt(id))
      if (missingEmailIds.length) {
        loadEmails(missingEmailIds)
      }
    }
  }, [dispatch, emails, loading])
  let emailList: RetreatEmailModel[] = emailIds
    .map((id) => emails[id])
    .filter((email) => email) as RetreatEmailModel[]

  return [emailList, loading] as const
}

export function useRetreatAudience(audienceId: number) {
  let dispatch = useDispatch()
  let audience = useSelector((state: RootState) => {
    return state.retreat.retreatAudiences[audienceId]
  })
  let [loading, setLoading] = useState(false)
  useEffect(() => {
    async function loadAudience() {
      setLoading(true)
      await dispatch(getRetreatAudience(audienceId))
      setLoading(false)
    }
    if (!audience) {
      loadAudience()
    }
  }, [audience, dispatch, audienceId])

  return [audience, loading] as const
}

export function useRetreatAudiences(audienceIds: number[]) {
  let dispatch = useDispatch()
  let audiences = useSelector((state: RootState) =>
    audienceIds.reduce<{
      [id: number]: AudienceModel | undefined
    }>((prev, id) => ({...prev, [id]: state.retreat.retreatAudiences[id]}), {})
  )
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadAudiences(audienceIds: number[]) {
      setLoading(true)
      await Promise.all(
        audienceIds.map((id) => dispatch(getRetreatAudience(id)))
      )
      setLoading(false)
    }
    if (!loading) {
      let missingAudienceIds = Object.keys(audiences)
        .filter((id) => !audiences[parseInt(id)])
        .map((id) => parseInt(id))
      if (missingAudienceIds.length) {
        loadAudiences(missingAudienceIds)
      }
    }
  }, [dispatch, audiences, loading])
  let audienceList: AudienceModel[] = audienceIds
    .map((id) => audiences[id])
    .filter((audience) => audience) as AudienceModel[]

  return [audienceList, loading] as const
}

export function useAttendeeGroup(groupId: number) {
  let dispatch = useDispatch()
  let group = useSelector((state: RootState) => {
    return state.retreat.attendeeGroups[groupId]
  })
  let [loading, setLoading] = useState(false)
  useEffect(() => {
    async function loadGroup() {
      setLoading(true)
      await dispatch(getAttendeeGroup(groupId))
      setLoading(false)
    }
    if (!group) {
      loadGroup()
    }
  }, [group, dispatch, groupId])

  return [group, loading] as const
}

export function useAttendeeGroups(groupIds: number[]) {
  let dispatch = useDispatch()
  let groups = useSelector((state: RootState) =>
    groupIds.reduce<{
      [id: number]: AttendeeGroupModel | undefined
    }>((prev, id) => ({...prev, [id]: state.retreat.attendeeGroups[id]}), {})
  )
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadGroups(groupIds: number[]) {
      setLoading(true)
      await Promise.all(groupIds.map((id) => dispatch(getAttendeeGroup(id))))
      setLoading(false)
    }
    if (!loading) {
      let missingGroupIds = Object.keys(groups)
        .filter((id) => !groups[parseInt(id)])
        .map((id) => parseInt(id))
      if (missingGroupIds.length) {
        loadGroups(missingGroupIds)
      }
    }
  }, [dispatch, groups, loading])
  let groupList: AttendeeGroupModel[] = groupIds
    .map((id) => groups[id])
    .filter((group) => group) as AttendeeGroupModel[]

  return [groupList, loading] as const
}

export function useAttendeeGroupsFilters(attendeeGroups: AttendeeGroupModel[]) {
  let filters: any[] = []
  attendeeGroups
    .sort((a, b) => (a.id > b.id ? 1 : -1))
    .forEach((group) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      filters.push(useAttendeeGroupFilters(group.filter_ids))
    })
  return filters
}

export function useAttendeeGroupFilters(filterIds: number[]) {
  let dispatch = useDispatch()
  let filters = useSelector((state: RootState) =>
    filterIds.reduce<{
      [id: number]: AttendeeGroupFilterModel | undefined
    }>(
      (prev, id) => ({...prev, [id]: state.retreat.attendeeGroupFilters[id]}),
      {}
    )
  )
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadFilters(filterIds: number[]) {
      setLoading(true)
      await Promise.all(
        filterIds.map((id) => dispatch(getAttendeeGroupFilter(id)))
      )
      setLoading(false)
    }
    if (!loading) {
      let missingFilterIds = Object.keys(filters)
        .filter((id) => !filters[parseInt(id)])
        .map((id) => parseInt(id))
      if (missingFilterIds.length) {
        loadFilters(missingFilterIds)
      }
    }
  }, [dispatch, filters, loading])
  let filterList: AttendeeGroupFilterModel[] = filterIds
    .map((id) => filters[id])
    .filter((filter) => filter) as AttendeeGroupFilterModel[]

  return [filterList, loading] as const
}

export function useAttendeeColumns(retreat: RetreatModel) {
  let columns = useSelector((state: RootState) => {
    return Object.values(state.retreat.attendeeColumns).filter((column) => {
      if (column && retreat.column_ids.indexOf(column.id) !== -1) {
        return true
      } else {
        return false
      }
    }) as RetreatColumnModel[]
  })
  let attendeeColumns = [
    {
      id: "first_name",
      title: "First Name",
      type: "SHORT_ANSWER",
    },
    {
      id: "last_name",
      title: "Last Name",
      type: "SHORT_ANSWER",
    },
    {
      id: "email_address",
      title: "Email Address",
      type: "SHORT_ANSWER",
    },
    {
      id: "info_status",
      title: "Registration Status",
      type: "SINGLE_SELECT",
      options: [
        {
          value: "CREATED",
          label: <RegistrationStatusBadge status={"CREATED"} />,
        },
        {
          value: "INFO_ENTERED",
          label: <RegistrationStatusBadge status={"INFO_ENTERED"} />,
        },
        {
          value: "NOT_ATTENDING",
          label: <RegistrationStatusBadge status={"NOT_ATTENDING"} />,
        },
        {
          value: "CANCELLED",
          label: <RegistrationStatusBadge status={"CANCELLED"} />,
        },
      ],
    },
    {
      id: "dietary_prefs",
      title: "Dietary Preferences",
      type: "SHORT_ANSWER",
    },
    {
      id: "notes",
      title: "Notes",
      type: "SHORT_ANSWER",
    },
    {
      id: "flight_status",
      title: "Flight Status",
      type: "SINGLE_SELECT",
      options: [
        {
          value: "PENDING",
          label: <FlightStatusBadge status={"PENDING"} />,
        },
        {
          value: "OPT_OUT",
          label: <FlightStatusBadge status={"OPT_OUT"} />,
        },
        {
          value: "BOOKED",
          label: <FlightStatusBadge status={"BOOKED"} />,
        },
      ],
    },
    {
      id: "flight_arrival_time",
      title: "Flight Arrival Time",
      type: "DATETIME",
    },
    {
      id: "flight_arrival_airport",
      title: "Flight Arrival Airport",
      type: "SHORT_ANSWER",
    },
    {
      id: "flight_arrival_number",
      title: "Flight Arrival Number",
      type: "SHORT_ANSWER",
    },
    {
      id: "flight_arrival_airline",
      title: "Flight Arrival Airline",
      type: "SHORT_ANSWER",
    },
    {
      id: "flight_departure_time",
      title: "Flight Departure Time",
      type: "DATETIME",
    },
    {
      id: "flight_departure_airport",
      title: "Flight Departure Airport",
      type: "SHORT_ANSWER",
    },
    {
      id: "flight_departure_number",
      title: "Flight Departure Number",
      type: "SHORT_ANSWER",
    },
    {
      id: "flight_departure_airline",
      title: "Flight Departure Airline",
      type: "SHORT_ANSWER",
    },
    ...columns.map((column) => {
      return {
        ...column,
        id: column.id.toString(),
        options: [
          ...(column.options
            ? column.options.map((option) => {
                return {
                  value: option,
                  label: option,
                }
              })
            : []),
        ],
      }
    }),
  ]
  return attendeeColumns
}
export function useTasks(taskIds: number[]) {
  let tasks = useSelector((state: RootState) => {
    let entries = Object.entries(state.retreat.tasks).filter(
      ([key, task]) => taskIds.indexOf(task?.id as number) !== -1
    )
    return Object.fromEntries(entries)
  })
  let [loadingTasks, setLoadingTasks] = useState(false)
  let dispatch = useDispatch()
  useEffect(() => {
    async function loadTasks(taskIds: number[]) {
      setLoadingTasks(true)
      await Promise.all(taskIds.map((id) => dispatch(getTask(id))))
      setLoadingTasks(false)
    }
    if (!loadingTasks) {
      let missingTasks = taskIds
        .map((id) => (!tasks[id] ? id : undefined))
        .filter((id) => id)
      if (missingTasks.length > 0) {
        loadTasks(missingTasks as number[])
      }
    }
  }, [dispatch, taskIds, tasks, loadingTasks])
  return [tasks, loadingTasks] as const
}

export function useLabel(labelId: number) {
  let dispatch = useDispatch()
  let [loading, setLoading] = useState(false)
  let label = useSelector((state: RootState) => state.retreat.labels[labelId])
  useEffect(() => {
    async function loadLabel() {
      setLoading(true)
      dispatch(getLabel(labelId))
      setLoading(false)
    }
    if (!label) {
      loadLabel()
    }
  }, [label, dispatch, labelId])
  return [label, loading] as const
}

export function useLabels(labelIds: number[]) {
  let labels = useSelector((state: RootState) => {
    let entries = Object.entries(state.retreat.labels).filter(
      ([key, label]) => labelIds.indexOf(label?.id as number) !== -1
    )
    return Object.fromEntries(entries)
  })
  let [loadingLabels, setLoadingLabels] = useState(false)
  let dispatch = useDispatch()

  useEffect(() => {
    async function loadLabels(threadIds: number[]) {
      setLoadingLabels(true)
      await Promise.all(labelIds.map((id) => dispatch(getLabel(id))))
      setLoadingLabels(false)
    }
    if (!loadingLabels) {
      let missingLabels = labelIds
        .map((id) => (!labels[id] ? id : undefined))
        .filter((id) => id)
      if (missingLabels.length > 0) {
        loadLabels(missingLabels as number[])
      }
    }
  }, [dispatch, labelIds, labels, loadingLabels])
  return [labels, loadingLabels] as const
}

export function useTaskTemplateGroups() {
  let taskTemplateGroups = useSelector((state: RootState) => {
    return state.task.taskTemplateGroups
  })
  let [loadingTaskTemplateGroups, setLoadingTaskTemplateGroups] =
    useState(false)
  let dispatch = useDispatch()

  useEffect(() => {
    async function loadTaskTemplateGroups() {
      setLoadingTaskTemplateGroups(true)
      await dispatch(getTaskTemplateGroups())
      setLoadingTaskTemplateGroups(false)
    }
    loadTaskTemplateGroups()
  }, [dispatch])

  return [taskTemplateGroups, loadingTaskTemplateGroups] as const
}

export function useAttendeeReportGuid(guid: string) {
  let dispatch = useDispatch()
  let [loading, setLoading] = useState(false)
  let reportId = useSelector(
    (state: RootState) => state.retreat.attendeeReportsGuidToId[guid]
  )
  let report = useSelector(
    (state: RootState) => state.retreat.attendeeReports[reportId ?? -1]
  )
  useEffect(() => {
    async function loadReport() {
      setLoading(true)
      dispatch(getShareableAttendeeReport(guid))
      setLoading(false)
    }
    if (!report) {
      loadReport()
    }
  }, [report, dispatch, guid])
  return [report, loading] as const
}
