import {
  FormCreationType,
  FormModel,
  FormQuestionModel,
  FormQuestionRuleModel,
  FormQuestionSelectOptionModel,
  FormResponseModel,
  FormResponsePostModel,
} from "../models/form"
import {flokAxios} from "./index"

// FORMS
export function postForm(
  formType: FormCreationType,
  form?: Partial<Pick<FormModel, "title" | "description" | "status">>
) {
  let queryParams = formType
    ? `?${new URLSearchParams({type: formType}).toString()}`
    : ""
  return flokAxios<{form: FormModel}>({
    url: `/v1.0/forms${queryParams}`,
    method: "POST",
    ...(form ? {data: JSON.stringify(form)} : undefined),
  })
}

export function getForm(formId: number) {
  return flokAxios<{form: FormModel}>({
    url: `/v1.0/forms/${formId}`,
    method: "GET",
  })
}

export function patchForm(
  formId: number,
  form: Omit<Partial<FormModel>, "id" | "questions">
) {
  return flokAxios<{form: FormModel}>({
    url: `/v1.0/forms/${formId}`,
    method: "PATCH",
    data: JSON.stringify(form),
  })
}

export function deleteForm(formId: number) {
  return flokAxios<{form: FormModel}>({
    url: `/v1.0/forms/${formId}`,
    method: "DELETE",
  })
}
// END FORMS

// FORM RESPONSES
export function getFormResponses(
  userId?: number,
  formId?: number,
  ids?: number[]
) {
  let queryParams = {
    ...(userId && {user_id: userId.toString()}),
    ...(formId && {form_id: formId.toString()}),
    ...(ids?.length && {id: ids.map((id) => id.toString()).join(",")}),
  }
  return flokAxios<{form_responses: FormResponseModel[]}>({
    url: `/v1.0/form-responses${
      Object.values(queryParams).length &&
      `?${new URLSearchParams(queryParams)}`
    }`,
    method: "GET",
  })
}

export function postFormResponse(formResponse: FormResponsePostModel) {
  return flokAxios<{form_response: FormResponseModel}>({
    url: "/v1.0/form-responses",
    data: JSON.stringify(formResponse),
    method: "POST",
  })
}
export function getFormResponse(formResponseId: number) {
  return flokAxios<{form_response: FormResponseModel}>({
    url: `/v1.0/form-responses/${formResponseId}`,
    method: "GET",
  })
}
// END FORM RESPONSES

// FORM QUESTIONS
export function postFormQuestion(
  question: Pick<FormQuestionModel, "form_id" | "type">
) {
  return flokAxios<{form_question: FormQuestionModel}>({
    url: `/v1.0/questions`,
    data: JSON.stringify({...question, title: ""}),
    method: "POST",
  })
}

export function patchFormQuestion(
  questionId: number,
  question: Partial<
    Omit<FormQuestionModel, "id" | "select_options" | "form_question_rules">
  >
) {
  return flokAxios<{form_question: FormQuestionModel}>({
    url: `/v1.0/questions/${questionId}`,
    data: JSON.stringify({...question}),
    method: "PATCH",
  })
}

export function getFormQuestion(questionId: number) {
  return flokAxios<{form_question: FormQuestionModel}>({
    url: `/v1.0/questions/${questionId}`,
    method: "GET",
  })
}

export function deleteFormQuestion(questionId: number) {
  return flokAxios<string>({
    url: `/v1.0/questions/${questionId}`,
    method: "DELETE",
  })
}

export function postFormQuestionReorder(
  formId: number,
  questionId: number,
  prevQuestionId: number | undefined
) {
  return flokAxios<{questions: FormQuestionModel[]}>({
    url: `/v1.0/forms/${formId}/questions/${questionId}/move`,
    data: JSON.stringify({previousId: prevQuestionId}),
    method: "POST",
  })
}

// END FORM QUESTIONS

// FORM OPTIONS

export function postFormQuestionOption(
  questionOption: Pick<
    FormQuestionSelectOptionModel,
    "option" | "form_question_id"
  >
) {
  return flokAxios<{select_option: FormQuestionSelectOptionModel}>({
    url: `/v1.0/options`,
    data: JSON.stringify(questionOption),
    method: "POST",
  })
}

export function getFormQuestionOption(optionId: number) {
  return flokAxios<{select_option: FormQuestionSelectOptionModel}>({
    url: `/v1.0/options/${optionId}`,
    method: "GET",
  })
}

export function patchFormQuestionOption(
  optionId: number,
  questionOption: Pick<FormQuestionSelectOptionModel, "option">
) {
  return flokAxios<{select_option: FormQuestionSelectOptionModel}>({
    url: `/v1.0/options/${optionId}`,
    data: JSON.stringify(questionOption),
    method: "PATCH",
  })
}

export function deleteFormQuestionOption(optionId: number) {
  return flokAxios<{select_option: FormQuestionSelectOptionModel}>({
    url: `/v1.0/options/${optionId}`,
    method: "DELETE",
  })
}
// END FORM OPTIONS

// FORM QUESTION RULES
export function postFormQuestionRule(rule: Omit<FormQuestionRuleModel, "id">) {
  return flokAxios<{form_question_rule: FormQuestionRuleModel}>({
    url: `/v1.0/question-rules`,
    data: JSON.stringify(rule),
    method: "POST",
  })
}

export function getFormQuestionRule(ruleId: number) {
  return flokAxios<{form_question_rule: FormQuestionRuleModel}>({
    url: `/v1.0/question-rules/${ruleId}`,
    method: "GET",
  })
}

export function patchFormQuestionRule(
  ruleId: number,
  rule: Omit<FormQuestionRuleModel, "id" | "form_question_id">
) {
  return flokAxios<{form_question_rule: FormQuestionRuleModel}>({
    url: `/v1.0/question-rules/${ruleId}`,
    data: JSON.stringify(rule),
    method: "PATCH",
  })
}

export function deleteFormQuestionRule(ruleId: number) {
  return flokAxios<{form_question_rule: FormQuestionRuleModel}>({
    url: `/v1.0/question-rules/${ruleId}`,
    method: "DELETE",
  })
}

// END FORM QUESTION RULES
