import {styled, theme} from "@summtech/flok-base/stitches.config"
import {ComponentProps, useState} from "react"
import FlightDisplay from "./FlightDisplay"
import {FlightForm, FlightFormProps} from "./FlightForm"

export const InlightFlightFormContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  border: `1px solid ${theme.colors.gray6}`,
  borderRadius: theme.shape.borderRadius,
  flexGrow: 1,
})
export default function InlineFlightDisplay(
  props: ComponentProps<typeof FlightDisplay> &
    Pick<FlightFormProps, "flightId" | "tripId">
) {
  const [editing, setEditing] = useState(false)
  return editing ? (
    <InlightFlightFormContainer>
      <FlightForm
        {...props}
        onSuccess={() => setEditing(false)}
        onCancel={() => setEditing(false)}
      />
    </InlightFlightFormContainer>
  ) : (
    <FlightDisplay {...props} onClick={() => setEditing(true)} />
  )
}
