import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext"
import {
  $createParagraphNode,
  $getNodeByKey,
  $getRoot,
  createCommand,
  LexicalCommand,
} from "lexical"
import {$createButtonNode, ButtonLinkType} from "../nodes/ButtonNode"

export const BUTTON_COMMAND: LexicalCommand<{text: string; link: string}> =
  createCommand("BUTTON")

export const ALTER_BUTTON_TEXT_COMMAND: LexicalCommand<{
  text: string
  key: string
}> = createCommand("ALTER_BUTTON_TEXT")

export const ALTER_BUTTON_LINK_COMMAND: LexicalCommand<{
  link: string
  key: string
}> = createCommand("ALTER_BUTTON_LINK")

export const ALIGN_BUTTON_COMMAND: LexicalCommand<{
  alignment: "left" | "right" | "center"
  key: string
}> = createCommand("ALIGN_BUTTON")

export const DELETE_BUTTON_COMMAND: LexicalCommand<{
  key: string
}> = createCommand("DELETE_BUTTON")

export const ALTER_BUTTON_LINK_TYPE_COMMAND: LexicalCommand<{
  linkType: ButtonLinkType
  key: string
}> = createCommand("ALTER_BUTTON_LINK_TYPE")

export const ALTER_BUTTON_PAGE_ID_COMMAND: LexicalCommand<{
  pageId: string
  key: string
}> = createCommand("ALTER_BUTTON_PAGE_ID")

export function ButtonPlugin() {
  const [editor] = useLexicalComposerContext()

  editor.registerCommand(
    BUTTON_COMMAND,
    (payload: {text: string; link: string}) => {
      editor.update(() => {
        const root = $getRoot()
        const button = $createButtonNode("button")
        const paragraph = $createParagraphNode()
        root.append(button)
        root.append(paragraph)
      })
      return true
    },
    0
  )
  editor.registerCommand(
    ALTER_BUTTON_TEXT_COMMAND,
    (payload: {text: string; key: string}) => {
      editor.update(() => {
        const button = $getNodeByKey(payload.key)
        if (button) {
          button.setText(payload.text)
        }
      })
      return true
    },
    0
  )

  editor.registerCommand(
    ALTER_BUTTON_LINK_COMMAND,
    (payload: {link: string; key: string}) => {
      editor.update(() => {
        const button = $getNodeByKey(payload.key)
        if (button) {
          button.setLink(payload.link)
        }
      })
      return true
    },
    0
  )
  editor.registerCommand(
    ALIGN_BUTTON_COMMAND,
    (payload: {alignment: "left" | "right" | "center"; key: string}) => {
      editor.update(() => {
        const button = $getNodeByKey(payload.key)
        if (button) {
          button.setAlignment(payload.alignment)
        }
      })
      return true
    },
    0
  )
  editor.registerCommand(
    DELETE_BUTTON_COMMAND,
    (payload: {key: string}) => {
      editor.update(() => {
        const button = $getNodeByKey(payload.key)
        if (button) {
          button.remove()
        }
      })
      return true
    },
    0
  )

  editor.registerCommand(
    ALTER_BUTTON_LINK_TYPE_COMMAND,
    (payload: {linkType: ButtonLinkType; key: string}) => {
      editor.update(() => {
        const button = $getNodeByKey(payload.key)
        if (button) {
          button.setLinkType(payload.linkType)
        }
      })
      return true
    },
    0
  )

  editor.registerCommand(
    ALTER_BUTTON_PAGE_ID_COMMAND,
    (payload: {pageId: string; key: string}) => {
      editor.update(() => {
        const button = $getNodeByKey(payload.key)
        if (button) {
          button.setPageId(payload.pageId)
        }
      })
      return true
    },
    0
  )
  return null
}
