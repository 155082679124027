import {styled, theme} from "@summtech/flok-base/stitches.config"
import {ItineraryEventModel, ItineraryModel} from "../../models/retreat"
import {useItineraryEvents} from "../../utils/itineraryUtils"
import {getIsoDate} from "../pages/ItineraryBuilderPage"
import SiteItineraryDay from "./SiteItineraryDay"
const ItineraryContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "48px",
})

const Divider = styled("div", {
  width: "100%",
  height: "1px",
  background: theme.colors.gray6,
})
export default function SiteItinerary(props: {itinerary: ItineraryModel}) {
  let [events] = useItineraryEvents(props.itinerary.event_ids)
  function renderDays() {
    let returnValue = []
    for (let i = 0; i < props.itinerary.number_of_days; i++) {
      returnValue.push(
        <SiteItineraryDay
          events={
            Object.values(events).filter(
              (event) => event?.day === i + 1
            ) as ItineraryEventModel[]
          }
          day={
            props.itinerary.start_date
              ? new Intl.DateTimeFormat(undefined, {
                  day: "numeric",
                  month: "long",
                  weekday: "long",
                  timeZone: "UTC",
                }).format(getIsoDate(props.itinerary.start_date, i))
              : `Day ${i + 1}`
          }
        />
      )
      returnValue.push(<Divider />)
    }
    return returnValue
  }
  return <ItineraryContainer>{renderDays()}</ItineraryContainer>
}
