import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {RootState} from "../store"
import {
  getPastItineraries,
  getPastItineraryLocations,
} from "../store/actions/itinerary"
import {getItinerary, getItineraryEvent} from "../store/actions/retreat"

export function usePastItineraries() {
  let dispatch = useDispatch()
  let itineraries = useSelector((state: RootState) => {
    return state.itinerary.past_itineraries
  })

  let [loadingItineraries, setLoadingItineraries] = useState(false)
  useEffect(() => {
    async function loadItineraries() {
      setLoadingItineraries(true)
      await dispatch(getPastItineraries())
      setLoadingItineraries(false)
    }

    loadItineraries()
  }, [dispatch])

  return [itineraries, loadingItineraries] as const
}

export function usePastItineraryLocations() {
  let dispatch = useDispatch()
  let locations = useSelector((state: RootState) => state.itinerary.locations)
  let [loadingLocations, setLoadingLocations] = useState(false)
  useEffect(() => {
    async function loadLocations() {
      setLoadingLocations(true)
      await dispatch(getPastItineraryLocations())
      setLoadingLocations(false)
    }
    if (!Object.keys(locations).length) {
      loadLocations()
    }
  }, [dispatch, locations])

  return [locations, loadingLocations] as const
}

export function useItinerary(itineraryId: number) {
  let [loading, setLoading] = useState(false)
  let itinerary = useSelector((state: RootState) => {
    return state.retreat.itineraries[itineraryId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    async function loadItinerary() {
      setLoading(true)
      await dispatch(getItinerary(itineraryId))
      setLoading(false)
    }
    if (!itinerary) {
      loadItinerary()
    }
  }, [dispatch, itinerary, itineraryId])
  return [itinerary, loading] as const
}

export function useItineraryEvents(eventIds: number[]) {
  let events = useSelector((state: RootState) => {
    let entries = Object.entries(state.retreat.itineraryEvents).filter(
      ([key, event]) => event && eventIds.indexOf(event.id) !== -1
    )
    return Object.fromEntries(entries)
  })
  let [loadingEvents, setLoadingEvents] = useState(false)
  let dispatch = useDispatch()

  useEffect(() => {
    async function loadEvents(eventIds: number[]) {
      setLoadingEvents(true)
      await Promise.all(eventIds.map((id) => dispatch(getItineraryEvent(id))))
      setLoadingEvents(false)
    }
    if (!loadingEvents) {
      let missingEvents = eventIds
        .map((id) => (!events[id] ? id : undefined))
        .filter((id) => id)
      if (missingEvents.length > 0) {
        loadEvents(missingEvents as number[])
      }
    }
  }, [dispatch, eventIds, events, loadingEvents])
  return [events, loadingEvents] as const
}

export function useItineraryEvent(eventId: number) {
  let [loading, setLoading] = useState(false)
  let event = useSelector((state: RootState) => {
    return state.retreat.itineraryEvents[eventId]
  })
  let dispatch = useDispatch()
  useEffect(() => {
    async function loadEvent() {
      if (eventId !== -1) {
        setLoading(true)
        await dispatch(getItineraryEvent(eventId))
        setLoading(false)
      }
    }
    if (!event) {
      loadEvent()
    }
  }, [dispatch, event, eventId])
  return [event, loading] as const
}
