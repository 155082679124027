import {CheckIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {ReactNode} from "react"

type NotificationItemProps = {
  avatar: ReactNode
  title: string
  subtitle: string
  timeAgo: string
  onDone: () => void
}

const NotificationContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  borderRadius: theme.shape.borderRadius,
  [`${Button}`]: {
    marginLeft: "auto",
    height: "40px",
    width: "40px",
    pointerEvents: "none",
    opacity: 0,
  },
  "&:hover": {
    [`${Button}`]: {
      pointerEvents: "auto",
      opacity: 1,
    },
  },
})
const TopContainer = styled("div", {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "4px",
})
const RightContainer = styled("div", {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "6px",
})
const AvatarContainer = styled("div", {
  height: "28px",
  width: "28px",
})
const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

export default function NotificationItem(props: NotificationItemProps) {
  return (
    <NotificationContainer>
      <AvatarContainer>{props.avatar}</AvatarContainer>
      <RightContainer>
        <TopContainer>
          <Text variant={"text-sm-plus"}>{props.title}</Text>
          <GrayText variant={"text-sm"}>{props.subtitle}</GrayText>
        </TopContainer>
        <GrayText variant={"text-xs"}>{props.timeAgo}</GrayText>
      </RightContainer>
      <Button
        startIcon={<CheckIcon />}
        onClick={(e) => {
          e.stopPropagation()
          props.onDone()
        }}
        variant="outline"
      />
    </NotificationContainer>
  )
}
