import {CaretDownIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table"
import {push} from "connected-react-router"
import {useState} from "react"
import {useDispatch} from "react-redux"
import {Link, Route, Switch, useRouteMatch} from "react-router-dom"
import {
  SortingTaskPriorityInteger,
  taskFilteringOptions,
  TaskModel,
  TaskPriority,
  TaskStatus,
} from "../../models/task"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../Stack"
import {useLabels, useTasks} from "../../utils/retreatUtils"
import CenterMessage from "../app/common/CenterMessage"
import {ColumnFilterType, FilteringPopper} from "../app/tasks/FilteringPopper"
import {
  AssigneesLabel,
  DueDateLabel,
  getGroupHeaderRow,
  LabelsLabel,
  PriorityLabel,
  StatusLabel,
  TitleLabel,
} from "../app/tasks/TaskTableCells"
import TaskViewer from "../app/tasks/TaskViewer"

let MainBody = styled("div", {
  backgroundColor: theme.colors.white,
  display: "flex",
  flexDirection: "column",
  flex: 1,
  paddingInline: "20px",
  paddingTop: "30px",
  overflow: "hidden",
})
let StyledTableContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  padding: "5px",
  justifyContentL: "center",
})
let StyledTable = styled("table", {
  width: "100%",
  marginTop: "30px",
  display: "block",
  overflowX: "scroll",
  borderCollapse: "collapse",
  borderSpacing: 2,
  borderLeft: "none",
  borderRight: "none",
  whiteSpace: "nowrap",
  "& thead": {
    marginBlock: "200px",
    textAlign: "left",
    backgroundColor: theme.colors.white,
    borderInline: "none",
    color: theme.colors.gray11,
    borderBottom: `1px solid ${theme.colors.gray6}`,

    "& th": {
      paddingBottom: "20px",
      paddingInline: "10px",
      margin: "20px",
      minWidth: "160px",
      whiteSpace: "nowrap",
    },
  },
  "& tbody": {
    "& tr": {
      borderBottom: `1px solid ${theme.colors.gray6}`,
    },
  },
})
let StyledHeaderSection = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingInline: "16px",
  justifyContent: "space-between",
})
let StyledButtonContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
})

let NoTasksBody = styled(MainBody, {
  justifyContent: "center",
  alignItems: "center",
})

function TasksPage() {
  let dispatch = useDispatch()
  let [retreat, retreatIdx] = useRetreat()

  let [tasks, loadingTasks] = useTasks(retreat.task_ids)
  let route = useRouteMatch<{retreatIdx: string; taskId: string}>()
  let taskId = parseInt(route.params.taskId)

  let [retreatLabels, loadingLabels] = useLabels(retreat.labels)
  let [groupingBy, setGroupingBy] = useState("status" as keyof TaskModel)
  let [taskModal, setTaskModal] = useState(false)

  function onCellClick(taskId: number) {
    return AppRoutes.getPath("TaskPage", {
      retreatIdx: retreatIdx.toString(),
      taskId: taskId.toString(),
    })
  }

  let columnHelper = createColumnHelper<TaskModel>()
  let options: TableOptions<TaskModel> = {
    defaultColumn: {
      enableGlobalFilter: false,
      size: 150,
    },
    initialState: {
      sorting: [{id: groupingBy, desc: true}],
      columnFilters: [],
    },
    columns: [
      columnHelper.accessor("title", {
        header: "Title",
        cell: (props) => (
          <td>
            <TitleLabel title={props.getValue() as string} />
          </td>
        ),
      }),
      columnHelper.accessor("status", {
        header: "Status",
        sortingFn: "alphanumeric",
        filterFn: (row, id, filterValue) => {
          return filterValue.includes(row.getValue(id))
        },

        enableColumnFilter: true,
        cell: (props) => (
          <td style={{whiteSpace: "nowrap"}}>
            <StatusLabel status={props.getValue() as TaskStatus} />
          </td>
        ),
      }),
      columnHelper.accessor("assignees", {
        header: "Assignees",
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return filterValue.some((assignee: number) =>
            (row.getValue(id) as number[]).includes(assignee)
          )
        },
        cell: (props) => (
          <td>
            <AssigneesLabel assigneeIds={props.getValue() as number[]} />
          </td>
        ),
      }),
      columnHelper.accessor("due_date", {
        header: "Due Date",
        cell: (props) => (
          <td>
            <DueDateLabel dueDate={props.getValue() as Date} />
          </td>
        ),
      }),
      columnHelper.accessor("priority", {
        header: "Priority",
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return filterValue.includes(row.getValue(id))
        },
        sortingFn: (rowA, rowB, columnId) => {
          let rowAPriority = rowA.getValue(columnId) as TaskPriority | undefined
          let rowBPriority = rowB.getValue(columnId) as TaskPriority | undefined
          let rowAOrder =
            rowAPriority == null
              ? 100
              : SortingTaskPriorityInteger[rowAPriority]
          let rowBOrder =
            rowBPriority == null
              ? 100
              : SortingTaskPriorityInteger[rowBPriority]
          return rowBOrder - rowAOrder
        },
        cell: (props) => (
          <td>
            <PriorityLabel priority={props.getValue() as TaskPriority} />
          </td>
        ),
      }),
      columnHelper.accessor("labels", {
        header: "Labels",
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return filterValue.some((assignee: number) =>
            (row.getValue(id) as number[]).includes(assignee)
          )
        },
        cell: (props) => (
          <td>
            <LabelsLabel labelIds={props.getValue() as number[]} />
          </td>
        ),
      }),
    ],
    data: Object.values(tasks) as TaskModel[],
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  }
  const table = useReactTable(options)
  let activeGroup: any = null

  return (
    <>
      <TaskViewer
        open={taskModal}
        onClose={() => {
          setTaskModal(false)
        }}
        retreatUsers={retreat.users}
        labels={retreatLabels}
      />
      {Object.values(tasks).length === 0 && !loadingTasks ? (
        <NoTasksBody>
          <CenterMessage css={{margin: "auto", textAlign: "center"}}>
            <CenterMessage.Image src="https://flok-b32d43c.s3.amazonaws.com/NoTasksImage.png" />
            <CenterMessage.Body>
              <CenterMessage.Title>Create Your First Task</CenterMessage.Title>
              <CenterMessage.Description>
                Tasks are used to keep track of the work that needs to be done.
                Create a task to assign it to yourself or a team member, set a
                due date, and track its progress until completion.
              </CenterMessage.Description>
            </CenterMessage.Body>
            <CenterMessage.Actions>
              <CenterMessage.Button
                text="Create Task"
                onClick={() => setTaskModal(true)}
              />
            </CenterMessage.Actions>
          </CenterMessage>
        </NoTasksBody>
      ) : (
        <MainBody>
          <StyledTableContainer>
            <StyledHeaderSection>
              <Text variant="title-3xl">Tasks</Text>
              <StyledButtonContainer>
                <Dropdown
                  button={
                    <Button
                      text="Group by"
                      variant="outline"
                      endIcon={<CaretDownIcon />}
                    />
                  }>
                  <DropdownItem
                    text="Status"
                    onClick={() => {
                      table.setSorting([{id: "status", desc: true}])
                      setGroupingBy("status")
                    }}
                  />
                  <DropdownItem
                    text="Priority"
                    onClick={() => {
                      table.setSorting([{id: "priority", desc: true}])
                      setGroupingBy("priority")
                    }}
                  />
                </Dropdown>
                <FilteringPopper
                  filteringState={
                    table.getState().columnFilters as ColumnFilterType[]
                  }
                  setFilteringState={(filterObject) => {
                    table.setColumnFilters(filterObject)
                  }}
                  filteringOptions={taskFilteringOptions}
                  showBadge={false}
                />
                <Button
                  text="Add task"
                  onClick={() => {
                    setTaskModal(true)
                  }}
                  color="brand"
                  variant="solid"
                />
              </StyledButtonContainer>
            </StyledHeaderSection>
            <StyledTable>
              <thead>
                <tr>
                  {table.getHeaderGroups().map((headerGroup) =>
                    headerGroup.headers
                      .filter((header) => header.depth === 1)
                      .map((header) => (
                        <th colSpan={header.colSpan}>
                          <span>
                            <Text variant="text-sm-plus">
                              {header.isPlaceholder
                                ? "\u00A0"
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </Text>
                          </span>
                        </th>
                      ))
                  )}
                </tr>
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  let groupHeader = undefined
                  let rowGroup = row.original[groupingBy as keyof TaskModel]
                  let colSpan = table
                    .getHeaderGroups()
                    .flatMap((headerGroup) =>
                      headerGroup.headers.filter((header) => header.depth === 1)
                    ).length
                  if (activeGroup !== rowGroup) {
                    groupHeader = getGroupHeaderRow(
                      groupingBy as keyof TaskModel,
                      row.original[groupingBy as keyof TaskModel] as
                        | string
                        | number,
                      colSpan
                    )
                    activeGroup = rowGroup
                  }
                  return (
                    <>
                      {groupHeader}
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td style={{width: "100%"}}>
                              <Link
                                to={onCellClick(row.original.id)}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </Link>
                            </td>
                          )
                        })}
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </StyledTable>
            <Switch>
              <Route path={[AppRoutes.getPath("TaskPage")]} exact>
                <TaskViewer
                  key={taskId}
                  retreatUsers={retreat.users}
                  labels={retreatLabels}
                  taskId={taskId}
                  open={!!taskId}
                  onClose={() =>
                    dispatch(
                      push(
                        AppRoutes.getPath("TaskListPage", {
                          retreatIdx: retreatIdx.toString(),
                        })
                      )
                    )
                  }
                />
              </Route>
            </Switch>
          </StyledTableContainer>
        </MainBody>
      )}
    </>
  )
}

export default TasksPage
