import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"

function hourFormat(hour: number) {
  const dateFormatter = Intl.DateTimeFormat(undefined, {
    timeStyle: "short",
  })
  let date = new Date("December 14, 2026 00:00")
  date.setHours(hour)
  return dateFormatter.format(date)
}
const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

const HoursContainer = styled("div", {
  position: "relative",
})
const HoursInnerContainer = styled("div", {
  position: "relative",
  height: "100%",
  top: "0px",
  left: "0px",
  bottom: "0px",
  right: "0px",
})

const Hour = styled("div", {
  padding: "0px",
  margin: "0px",
  height: "30px",
  borderWidth: "0.5px",
  borderStyle: "solid",
  borderColor: theme.colors.gray6,
  borderLeft: "0px solid black",
  width: "70px",
  // inputs
  top: "$$top",
  borderBottom: "$$borderBottom",
  borderTop: "$$borderTop",
})

export function CalendarHours(props: {startHour: number}) {
  return (
    <HoursContainer>
      <HoursInnerContainer>
        {[...Array((24 - props.startHour) * 2)].map((u, i) => {
          return (
            <Hour
              css={{
                $$top: `${30 * i}px`,
                $$borderBottom:
                  i % 2 === 0
                    ? "0px solid black"
                    : `0.5px solid ${theme.colors.gray6}`,
                borderTop:
                  i === 0
                    ? `1px solid ${theme.colors.gray6}`
                    : i % 2 !== 0
                    ? "0px solid black"
                    : `0.5px solid ${theme.colors.gray6}`,
              }}>
              <GrayText variant={"text-sm"}>
                {i % 2 === 0 ? hourFormat(i / 2 + props.startHour) : ""}
              </GrayText>
            </Hour>
          )
        })}
      </HoursInnerContainer>
    </HoursContainer>
  )
}
