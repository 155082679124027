import {makeStyles} from "@material-ui/core"
import {
  AttendeeLandingWebsiteModel,
  RetreatModel,
} from "../../../models/retreat"
import SiteHeader from "../../../redesign/sites/SiteHeader"
import SiteFooter from "./SiteFooter"

let useStyles = makeStyles((theme) => ({
  bannerImg: {
    width: "100%",
    maxHeight: "325px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      minHeight: "130px",
    },
  },
  overallPage: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 800,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0.5),
    },
  },
}))

type AttendeeSiteProps = {
  retreat: RetreatModel
  website: AttendeeLandingWebsiteModel
  activePage: string | "registration" | "flights"
  pageBody: JSX.Element
  hideLogo?: boolean
}
export default function AttendeeSite(props: AttendeeSiteProps) {
  let classes = useStyles(props)

  return (
    <>
      <SiteHeader
        activePage={props.activePage}
        retreat={props.retreat}
        website={props.website}
      />
      {props.website.banner_image && !props.hideLogo && (
        <img
          src={props.website.banner_image.image_url}
          className={classes.bannerImg}
          alt={props.website.banner_image.alt}
        />
      )}
      <div className={classes.overallPage}>{props.pageBody}</div>
      <SiteFooter />
    </>
  )
}
