import {useQuery, useQueryClient} from "@tanstack/react-query"
import {push} from "connected-react-router"
import React, {useEffect} from "react"
import {useDispatch} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import {getForm, getFormResponses} from "../../../api/form"
import PageBody from "../../../components/page/PageBody"
import PageContainer from "../../../components/page/PageContainer"
import LoadingPage from "../../../pages/misc/LoadingPage"
import NotFound404Page from "../../../pages/misc/NotFound404Page"
import {AppRoutes} from "../../../Stack"
import {ApiAction} from "../../../store/actions/api"
import {postAttendeeSurveyResponse} from "../../../store/actions/retreat"
import {replaceDashes} from "../../../utils"
import {
  useAttendeeLandingWebsiteName,
  useMyAttendee,
  useRetreat,
} from "../../../utils/retreatUtils"
import {useLoggedInUser} from "../../../utils/userUtils"
import FormResponseViewer from "../../forms/FormResponseViewer"
import FormViewer from "../../forms/FormViewer"
import AttendeeSite from "../../sites/AttendeeSite"

export default function SurveyPage() {
  let dispatch = useDispatch()
  let router = useRouteMatch<{websiteName: string; formId: string}>()
  let {websiteName} = router.params
  let formId = parseInt(router.params.formId)
  let [website, websiteLoading] = useAttendeeLandingWebsiteName(
    replaceDashes(websiteName)
  )
  let [retreat, retreatLoading] = useRetreat(website?.retreat_id ?? -1)
  let [user, userLoading] = useLoggedInUser()
  let [attendee] = useMyAttendee(retreat != null ? retreat.id : -1)
  let queryClient = useQueryClient()

  let form = useQuery({
    queryFn: () => getForm(formId),
    queryKey: ["forms", formId],
  })

  let formResponseQuery = useQuery({
    enabled: user?.id != null,
    queryFn: () => getFormResponses(user?.id, formId),
    queryKey: ["form-responses", {user_id: user?.id, form_id: formId}],
    onSuccess: (data) => {
      data.form_responses.forEach((formResponse) => {
        queryClient.setQueryData(["form-responses", formResponse.id], {
          form_response: formResponse,
        })
      })
    },
  })

  useEffect(() => {
    if (!userLoading && !user) {
      dispatch(
        push(
          AppRoutes.getPath(
            "SigninPage",
            {
              websiteName: websiteName,
            },
            {
              next: encodeURIComponent(
                AppRoutes.getPath("AttendeeSiteMyTripPage", {
                  websiteName: websiteName,
                })
              ),
              "login-type": "attendee",
            }
          )
        )
      )
    }
  }, [user, userLoading, dispatch, websiteName])

  return websiteLoading || retreatLoading || !retreat || userLoading ? (
    <LoadingPage />
  ) : !website || !attendee ? (
    <NotFound404Page />
  ) : (
    <PageContainer>
      <PageBody>
        <AttendeeSite
          retreat={retreat}
          website={website}
          hideLogo
          activePage="surveys"
          pageBody={
            (formResponseQuery.data?.form_responses ?? []).length ? (
              <FormResponseViewer
                formResponseId={formResponseQuery.data!.form_responses[0].id}
              />
            ) : (
              <FormViewer
                formId={formId}
                onSuccess={async (response) => {
                  let attendeeSurveyResponse = (await dispatch(
                    postAttendeeSurveyResponse(attendee!.id, response.id)
                  )) as unknown as ApiAction
                  if (!attendeeSurveyResponse.error) {
                    queryClient.invalidateQueries([
                      "form-responses",
                      {user_id: user?.id, form_id: formId},
                    ])
                  }
                  return attendeeSurveyResponse
                }}
              />
            )
          }></AttendeeSite>
      </PageBody>
    </PageContainer>
  )
}
