import {Button} from "@summtech/flok-base/components/Button"
import {FileDrawerTab} from "@summtech/flok-base/components/FileDrawerTab"
import {TabsList} from "@summtech/flok-base/components/TabsList"
import {TabsWrapper} from "@summtech/flok-base/components/TabsWrapper"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {push} from "connected-react-router"
import {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Route, Switch, withRouter} from "react-router-dom"
import {AppRoutes} from "../../Stack"
import PageBody from "../../components/page/PageBody"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {RootState} from "../../store"
import {useRetreatAttendees} from "../../utils/attendeeUtils"
import {AddAttendeeModal} from "../app/attendees/AddAttendeeModal"
import {AttendeesTable} from "../app/attendees/AttendeesTable"
import {AttendeesTable as AttendeesTableV1} from "../app/attendees/AttendeesTableV1"
import {BatchUploadModal} from "../app/attendees/BatchUploadModal"
import {AttendeePage} from "../pages/AttendeePage"

let MainBody = styled("div", {
  backgroundColor: theme.colors.white,
  display: "flex",
  flexDirection: "column",
  flex: 1,
  paddingInline: "20px",
  paddingTop: "30px",
  overflow: "hidden",
})
let StyledHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "30px",
})
let StyledHeaderActions = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
})

const FakeTab = styled("div", {
  backgroundColor: theme.colors.gray3,
  color: theme.colors.gray11,
  padding: "12px",
  border: `1px solid ${theme.colors.gray6}`,
  borderBottom: "none",
  borderRadius: "6px 6px 0px 0px",
  "&:hover": {
    color: theme.colors.gray12,
  },
  '&[data-state="active"]': {
    backgroundColor: theme.colors.white,
    color: theme.colors.gray12,
    borderBottom: `1px solid ${theme.colors.white}`,
  },
})

function AttendeesPage() {
  let dispatch = useDispatch()
  let [retreat, retreatIdx] = useRetreat()
  let [attendees] = useRetreatAttendees(retreat.id)
  let [currentTab, setCurrentTab] = useState("all-attendees")

  let [addAttendeeModal, setAddAttendeeModal] = useState(false)
  let [batchUploadModal, setBatchUploadModal] = useState(false)
  let [addReportModal, setAddReportModal] = useState(false)

  let attendeeReports = useSelector((state: RootState) => {
    return state.retreat.attendeeReports
  })

  return (
    <PageBody appBar>
      <MainBody>
        <StyledHeader>
          <Text variant={"heading-lg"}>Reports</Text>
          <StyledHeaderActions>
            {retreat.attendee_table_v2 && (
              <Button
                text="New Report"
                variant={"solid"}
                color="brand"
                onClick={() => {
                  setAddReportModal(true)
                }}
              />
            )}
          </StyledHeaderActions>
        </StyledHeader>
        <TabsWrapper
          css={{overflow: "hidden", flex: 1}}
          value={currentTab}
          onValueChange={(newTab) => setCurrentTab(newTab)}>
          <TabsList css={{borderBottom: `solid 1px ${theme.colors.gray6}`}}>
            <FileDrawerTab text="All Attendees" value="all-attendees" />
            <FileDrawerTab text="Form Responses" value="form-responses" />
            <FileDrawerTab text="Flights" value="flights" />
            {retreat.attendee_table_v2 &&
              retreat.attendee_report_ids.map((id) => {
                return (
                  <FileDrawerTab
                    text={attendeeReports[id]?.name}
                    value={id.toString()}
                  />
                )
              })}
          </TabsList>
          {retreat.attendee_table_v2 ? (
            <AttendeesTable
              setActiveView={setCurrentTab}
              addReportOpen={addReportModal}
              onCloseAddReport={() => {
                setAddReportModal(false)
              }}
              attendees={attendees}
              activeView={
                currentTab as "all-attendees" | "form-responses" | "flights"
              }
              getAttendeeRedirect={(attendeeId) => {
                return AppRoutes.getPath("ReportAttendeePage", {
                  retreatIdx: retreatIdx.toString(),
                  attendeeId: attendeeId.toString(),
                })
              }}
            />
          ) : (
            <AttendeesTableV1
              attendees={attendees}
              activeView={
                currentTab as "all-attendees" | "form-responses" | "flights"
              }
              getAttendeeRedirect={(attendeeId) => {
                return AppRoutes.getPath("ReportAttendeePage", {
                  retreatIdx: retreatIdx.toString(),
                  attendeeId: attendeeId.toString(),
                })
              }}
            />
          )}
        </TabsWrapper>
        <AddAttendeeModal
          open={addAttendeeModal}
          onClose={() => setAddAttendeeModal(false)}
        />
        <BatchUploadModal
          open={batchUploadModal}
          onClose={() => setBatchUploadModal(false)}
        />
        <Switch>
          <Route
            path={[
              AppRoutes.getPath("ReportAttendeePage"),
              AppRoutes.getPath("ReportAttendeeFlightsPage"),
              AppRoutes.getPath("ReportAttendeeRegResponsePage"),
            ]}
            exact>
            <AttendeePage
              pageNames={{
                flightsPage: "ReportAttendeeFlightsPage",
                formResponsesPage: "ReportAttendeeRegResponsePage",
                profilePage: "ReportAttendeePage",
              }}
              onBack={() => {
                dispatch(
                  push(
                    AppRoutes.getPath("ReportAttendeesPage", {
                      retreatIdx: retreatIdx.toString(),
                    })
                  )
                )
              }}
            />
          </Route>
        </Switch>
      </MainBody>
    </PageBody>
  )
}
export default withRouter(AttendeesPage)
