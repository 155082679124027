import * as RadixCheckbox from "@radix-ui/react-checkbox"
import {TrashIcon} from "@radix-ui/react-icons"
import {Checkbox} from "@summtech/flok-base/components/Checkbox"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Select} from "@summtech/flok-base/components/Select"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useEffect, useMemo, useState} from "react"
import {TaskModel, TaskPriority, TaskStatus} from "../../../models/task"
import {
  AssigneesLabel,
  LabelsLabel,
  PriorityLabel,
  StatusLabel,
} from "./TaskTableCells"

let StyledFilterSelectContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  cursor: "pointer",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px",
  gap: "8px",
})
let SelectContainer = styled("div", {
  display: "flex",
  flexWrap: "wrap",
})
let StyledSelect = styled(Select, {
  paddingLeft: "10px",
})

let StyledText = styled(Text, {
  marginInline: "16px",
  color: theme.colors.gray11,
})

type FilterSelectProps = {
  addFilter: (newFilter: {id: string; value: string | number | Date}) => void
  onDelete: (filterKey: string, filerValue?: unknown) => void
  onKeyReplace: (oldFilterKey: string, newFilterKey: string) => void

  filterId: string
  alreadySelectedFilters: {id: string; value: any[]}[]
  filteringOptions: {
    [key: string]: {
      key?: string
      label?: string
      optionsArray: {optionValue?: string | number; optionLabel?: string}[]
    }
  }
}
function FilterSelect(props: FilterSelectProps) {
  let {
    addFilter,
    onDelete,
    onKeyReplace,
    filterId,
    filteringOptions,
    alreadySelectedFilters,
  } = props
  let [filterKey, setFilterKey] = useState<keyof TaskModel>(
    filterId as keyof TaskModel
  )
  let [availableFilterOptions, setAvailableFilterOptions] = useState(
    [] as {optionValue?: string | number; optionLabel?: string}[]
  )
  function handleFilterKeyChange(newFilterKey: keyof TaskModel) {
    if (
      filterKey &&
      alreadySelectedFilters.find(
        (alreadySelectedFilter) =>
          alreadySelectedFilter.id === filterKey && alreadySelectedFilter.value
      )
    ) {
      let oldFilterKey = `${filterKey}`
      onKeyReplace(oldFilterKey, newFilterKey)
      setFilterKey(newFilterKey)
      setAvailableFilterOptions(
        filteringOptions[newFilterKey as string].optionsArray
      )
    } else {
      setFilterKey(newFilterKey)
      setAvailableFilterOptions(
        filteringOptions[newFilterKey as string].optionsArray
      )
    }
  }
  function handleCheckedChange(check: boolean, value: string | number | Date) {
    if (check === false) {
      onDelete(filterKey, value)
    }
    if (check === true) {
      addFilter({id: filterKey, value: value})
    }
  }
  function getChecked(value: string | number | Date) {
    return !!alreadySelectedFilters.find(
      (alreadySelectedFilter) =>
        alreadySelectedFilter.id === filterKey &&
        alreadySelectedFilter.value.includes(value)
    )
  }

  let getPlaceholder = useMemo(() => {
    if (filterKey && availableFilterOptions) {
      return alreadySelectedFilters
        .find((alreadySelectedFilter) => alreadySelectedFilter.id === filterKey)
        ?.value.map(
          (selectedValue) =>
            availableFilterOptions.find(
              (filterOption) => filterOption.optionValue === selectedValue
            )?.optionLabel
        )
        .join(", ")
    }

    return ""
  }, [filterKey, availableFilterOptions, alreadySelectedFilters])

  useEffect(() => {
    if (filterId && !filterKey) {
      setFilterKey(filterId as keyof TaskModel)
    }
  }, [filterKey, filterId])
  useEffect(() => {
    if (filterKey) {
      setAvailableFilterOptions(
        filteringOptions[filterKey as string].optionsArray
      )
    }
  }, [filterKey, filteringOptions])
  return (
    <StyledFilterSelectContainer>
      <SelectContainer>
        <Select
          placeholder="Field"
          value={filterKey}
          onChange={(id: string) => {
            handleFilterKeyChange(id as keyof TaskModel)
          }}>
          {Object.keys(filteringOptions)
            .filter((option) => {
              return (
                filterKey === option ||
                !alreadySelectedFilters.find(
                  (alreadySelectedFilter) => alreadySelectedFilter.id === option
                )
              )
            })
            .map((filteringOption: string) => {
              return (
                <SelectItem
                  label={filteringOptions[filteringOption].label}
                  value={filteringOption}
                  key={filteringOption}
                />
              )
            })}
        </Select>
      </SelectContainer>
      <StyledText variant="text-base">is</StyledText>
      <SelectContainer>
        <StyledSelect
          placeholder={getPlaceholder}
          disabled={!filterKey}
          onChange={() => {}}>
          {availableFilterOptions ? (
            availableFilterOptions.map((availableFilterOption) => {
              return (
                <Checkbox
                  checked={getChecked(
                    availableFilterOption.optionValue as string | number | Date
                  )}
                  label={getLabelForKey(
                    filterKey,
                    availableFilterOption.optionValue as string | number,
                    availableFilterOption.optionLabel as string
                  )}
                  onCheckedChange={(checked: RadixCheckbox.CheckedState) =>
                    availableFilterOption.optionValue &&
                    handleCheckedChange(
                      checked as boolean,
                      availableFilterOption.optionValue
                    )
                  }
                />
              )
            })
          ) : (
            <></>
          )}
        </StyledSelect>
      </SelectContainer>
      <IconButton variant="ghost" onClick={() => onDelete(filterKey)}>
        <TrashIcon />
      </IconButton>
    </StyledFilterSelectContainer>
  )
}
function getLabelForKey(
  key: keyof TaskModel,
  value: string | number,
  label: string
) {
  switch (key) {
    case "status" as keyof TaskModel:
      return <StatusLabel status={value as TaskStatus} />
    case "priority" as keyof TaskModel:
      return <PriorityLabel priority={value as TaskPriority} />
    case "labels" as keyof TaskModel:
      return <LabelsLabel labelIds={[value as number]} />
    case "assignees" as keyof TaskModel:
      return <AssigneesLabel assigneeIds={[value as number]} />
    default:
      return <>{label ? label : "Not Defined"}</>
  }
}

export default FilterSelect
