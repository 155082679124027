import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ResolvedColumnFilter,
  Row,
  RowModel,
  Table,
  TableOptions,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {formatCurrency, getDatetimeString} from "."
import {
  getColumnAnswer,
  getColumnAnswers,
  patchColumnAnswer,
  postColumnAnswer,
} from "../api/attendee"
import {LocalFilterModel} from "../models/communication"
import {
  AttendeeInfoStatus,
  ColumnAnswerModel,
  RetreatAttendeeModel,
  RetreatColumnModel,
  TripLegTypeEnum,
} from "../models/retreat"
import {
  getTableFilteredRows,
  MultiSelectCell,
} from "../redesign/app/attendees/AttendeesTable"
import {
  FlightStatusBadge,
  RegistrationStatusBadge,
  TripLegTypeBadge,
} from "../redesign/app/attendees/StatusBadge"
import {useRetreatV2} from "../redesign/app/common/RetreatProviderV2"
import {RootState} from "../store"
import {
  getRetreatAttendees,
  updateAttendeeColumnToAnswers,
} from "../store/actions/retreat"

export function useRetreatAttendees(retreatId: number) {
  let dispatch = useDispatch()
  let attendeesList = useSelector(
    (state: RootState) => state.retreat.retreatAttendees[retreatId]
  )
  let attendeesListLoaded = !!attendeesList?.length
  let attendeesObject = useSelector((state: RootState) => {
    return state.retreat.attendees
  })
  let attendees = (attendeesList ? attendeesList : []).map(
    (id) => attendeesObject[id]
  )
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadAttendees() {
      setLoading(true)
      await dispatch(getRetreatAttendees(retreatId))
      setLoading(false)
    }
    if (!attendeesListLoaded) {
      loadAttendees()
    }
  }, [attendeesListLoaded, dispatch, retreatId])
  return [attendees, loading] as const
}

export const tanStackFilterRowsFn = (
  table: Table<any>,
  rowModel: RowModel<any>,
  columnFilters: any,
  globalFilter: any
) => {
  if (!rowModel.rows.length || (!columnFilters?.length && !globalFilter)) {
    for (let i = 0; i < rowModel.flatRows.length; i++) {
      rowModel.flatRows[i]!.columnFilters = {}
      rowModel.flatRows[i]!.columnFiltersMeta = {}
    }
    return rowModel
  }

  const resolvedColumnFilters: ResolvedColumnFilter<any>[] = []
  const resolvedGlobalFilters: ResolvedColumnFilter<any>[] = []

  ;(columnFilters ?? []).forEach((d: any) => {
    const column = table.getColumn(d.id)

    if (!column) {
      return
    }

    const filterFn = column.getFilterFn()

    if (!filterFn) {
      if (process.env.NODE_ENV !== "production") {
        console.warn(
          `Could not find a valid 'column.filterFn' for column with the ID: ${column.id}.`
        )
      }
      return
    }

    resolvedColumnFilters.push({
      id: d.id,
      filterFn,
      resolvedValue: filterFn.resolveFilterValue?.(d.value) ?? d.value,
    })
  })

  const filterableIds = columnFilters.map((d: any) => d.id)

  const globalFilterFn = table.getGlobalFilterFn()

  const globallyFilterableColumns = table
    .getAllLeafColumns()
    .filter((column) => column.getCanGlobalFilter())

  if (globalFilter && globalFilterFn && globallyFilterableColumns.length) {
    filterableIds.push("__global__")

    globallyFilterableColumns.forEach((column) => {
      resolvedGlobalFilters.push({
        id: column.id,
        filterFn: globalFilterFn,
        resolvedValue:
          globalFilterFn.resolveFilterValue?.(globalFilter) ?? globalFilter,
      })
    })
  }

  let currentColumnFilter
  let currentGlobalFilter

  // Flag the prefiltered row model with each filter state
  for (let j = 0; j < rowModel.flatRows.length; j++) {
    const row = rowModel.flatRows[j]!

    row.columnFilters = {}

    if (resolvedColumnFilters.length) {
      for (let i = 0; i < resolvedColumnFilters.length; i++) {
        currentColumnFilter = resolvedColumnFilters[i]!
        const id = currentColumnFilter.id
        // Tag the row with the column filter state
        row.columnFilters[id] = currentColumnFilter.filterFn(
          row,
          id,
          currentColumnFilter.resolvedValue,
          (filterMeta) => {
            row.columnFiltersMeta[id] = filterMeta
          }
        )
      }
    }

    if (resolvedGlobalFilters.length) {
      for (let i = 0; i < resolvedGlobalFilters.length; i++) {
        currentGlobalFilter = resolvedGlobalFilters[i]!
        const id = currentGlobalFilter.id
        // Tag the row with the first truthy global filter state
        if (
          currentGlobalFilter.filterFn(
            row,
            id,
            currentGlobalFilter.resolvedValue,
            (filterMeta) => {
              row.columnFiltersMeta[id] = filterMeta
            }
          )
        ) {
          row.columnFilters.__global__ = true
          break
        }
      }

      if (row.columnFilters.__global__ !== true) {
        row.columnFilters.__global__ = false
      }
    }
  }

  const filterRowsImpl = (row: Row<any>) => {
    // Horizontally filter rows through each column
    for (let i = 0; i < filterableIds.length; i++) {
      if (row.columnFilters[filterableIds[i]!] === false) {
        return false
      }
    }
    return true
  }

  const newFilteredFlatRows: Row<any>[] = []
  const newFilteredRowsById: Record<string, Row<any>> = {}
  const rows: Row<any>[] = []

  rowModel.rows.forEach((row) => {
    if (filterRowsImpl(row)) {
      rows.push(row)
      newFilteredFlatRows.push(row)
      newFilteredRowsById[row.id] = row
    }
  })

  // Filter final rows using all of the active filters
  return {
    rows: rows,
    flatRows: newFilteredFlatRows,
    rowsById: newFilteredRowsById,
  }
}
export function useCreateColumnAnswerMutation(attendeeId: number) {
  let queryClient = useQueryClient()
  let dispatch = useDispatch()
  return useMutation({
    mutationFn: (variables: {columnId: number; value: string}) =>
      postColumnAnswer(attendeeId, variables.columnId, variables.value),
    onSuccess: (data) => {
      queryClient.setQueryData(["column-answers", data.column_answer.id], data)
      dispatch(
        updateAttendeeColumnToAnswers(
          attendeeId,
          data.column_answer.column_id,
          data.column_answer.id
        )
      )
    },
  })
}

export function useUpdateColumnAnswerMutation() {
  let queryClient = useQueryClient()
  return useMutation({
    mutationFn: (variables: {columnAnswerId: number; value: string}) =>
      patchColumnAnswer(variables.columnAnswerId, variables.value),
    onSuccess: (data) => {
      queryClient.setQueryData(["column-answers", data.column_answer.id], data)
    },
  })
}

export function useAttendeesTable(variables: {
  attendees: RetreatAttendeeModel[]
  globalFilter: string
  columnVisibility: VisibilityState
  columnFilters: LocalFilterModel[][]
}) {
  let queryClient = useQueryClient()
  let [retreat] = useRetreatV2()
  let columnAnswersQuery = useQuery({
    queryKey: ["column-answers", {retreatId: retreat.id}],
    queryFn: () => getColumnAnswers(retreat.id),
    onSuccess: (data) => {
      data.column_answers.forEach((columnAnswer) => {
        queryClient.setQueryData(["column-answers", columnAnswer.id], {
          column_answer: columnAnswer,
        })
      })
    },
  })
  let columnAnswersQueries = useQueries({
    queries: variables.attendees
      .flatMap((attendee) =>
        Object.values(attendee.column_id_to_answer_id ?? {})
      )
      .map((answerId) => ({
        enabled: columnAnswersQuery.isSuccess,
        queryKey: ["column-answers", answerId],
        queryFn: () => getColumnAnswer(answerId),
      })),
  })
  let columnAnswerMap = columnAnswersQueries
    .filter((query) => query.isSuccess)
    .reduce((prev, query) => {
      return {
        ...prev,
        [query.data!.column_answer.id]: query.data!.column_answer,
      }
    }, {} as {[id: number]: ColumnAnswerModel})

  let columns = useSelector((state: RootState) => {
    return Object.values(state.retreat.attendeeColumns).filter((column) => {
      if (
        column &&
        state.retreat.retreats[retreat.id] &&
        state.retreat.retreats[retreat.id]!.column_ids.indexOf(column.id) !== -1
      ) {
        return true
      } else {
        return false
      }
    }) as RetreatColumnModel[]
  })

  let flights = useSelector((state: RootState) => {
    return state.retreat.flights
  })
  let columnHelper = createColumnHelper<RetreatAttendeeModel>()

  let options: TableOptions<RetreatAttendeeModel> = {
    defaultColumn: {
      enableGlobalFilter: false,
      enableSorting: false,
      size: 150,
    },
    globalFilterFn: "includesString",
    initialState: {
      columnVisibility: {id: false},
      pagination: {
        pageSize: 50,
      },
    },
    state: {
      globalFilter: variables.globalFilter,
      columnVisibility: variables.columnVisibility,
    },
    columns: [
      columnHelper.accessor("first_name", {
        header: "First Name",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
        filterFn: "includesString",
      }),
      columnHelper.accessor("last_name", {
        header: "Last Name",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor("email_address", {
        header: "Email",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor("info_status", {
        cell: (props) => {
          let registrationStatus = props.getValue() as AttendeeInfoStatus
          return <RegistrationStatusBadge status={registrationStatus} />
        },
        header: "Registration Status",
        enableSorting: true,
        sortingFn: "alphanumeric",
        filterFn: "arrIncludesAll",
      }),
      columnHelper.accessor("hotel_check_in", {
        header: "Hotel Check In",
        enableSorting: true,
        sortingFn: "datetime",
      }),
      columnHelper.accessor("hotel_check_out", {
        header: "Hotel Check Out",
        enableSorting: true,
        sortingFn: "datetime",
      }),
      columnHelper.accessor("dietary_prefs", {
        header: "Dietary Preferences",
        filterFn: "includesString",
      }),
      columnHelper.accessor("notes", {
        header: "Notes",
      }),
      columnHelper.accessor("registration_form_response_created_at", {
        header: "Registered At",
        cell: (props) => {
          return getDatetimeString(props.getValue(), true)
        },
        sortingFn: "datetime",
        enableSorting: true,
      }),
      // Flights
      columnHelper.accessor("flight_status", {
        cell: (props) => {
          let flightStatus =
            props.getValue() as RetreatAttendeeModel["flight_status"]
          return <FlightStatusBadge status={flightStatus} />
        },
        header: "Travel status",
        enableSorting: true,
        sortingFn: "alphanumeric",
        filterFn: "arrIncludesAll",
      }),
      columnHelper.accessor((attendee) => attendee.travel?.cost, {
        id: "flight_cost",
        cell: (props) => {
          let cost = props.getValue()
          if (cost != null) {
            return formatCurrency(cost, "USD", 2)
          } else {
            return <></>
          }
        },
        header: "Travel cost",
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            return (
              flights[tripLegIds[tripLegIds.length - 1]]?.trip_leg_type ??
              TripLegTypeEnum.FLIGHT
            )
          }
        },
        {
          cell: (props) => {
            let type = props.getValue()
            if (type) {
              return <TripLegTypeBadge tripLegType={type} />
            } else {
              return <></>
            }
          },
          id: "flight_arrival_type",
          header: "Arrival type",
          sortingFn: "alphanumeric",
          filterFn: "arrIncludesSome",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            return flights[tripLegIds[tripLegIds.length - 1]]?.arr_datetime
          }
        },
        {
          id: "flight_arrival_time",
          header: "Arrival Time",
          enableSorting: true,
          sortingFn: "datetime",
          filterFn: "includesString",
          cell: (props) => {
            let time = props.getValue()
            return getDatetimeString(time as string)
          },
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            let tripLeg = flights[tripLegIds[tripLegIds.length - 1]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.arr_station
            } else {
              return tripLeg?.arr_airport
            }
          }
        },
        {
          id: "flight_arrival_airport",
          header: "Arrival Location",
          enableSorting: true,
          sortingFn: "alphanumeric",
          filterFn: "includesString",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            let tripLeg = flights[tripLegIds[tripLegIds.length - 1]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.train_num
            } else {
              return tripLeg?.flight_num
            }
          }
        },
        {
          id: "flight_arrival_number",
          header: "Arrival #",
          enableSorting: true,
          sortingFn: "alphanumeric",
          filterFn: "includesString",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            let tripLeg = flights[tripLegIds[tripLegIds.length - 1]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.railway
            } else {
              return tripLeg?.airline
            }
          }
        },
        {
          id: "flight_arrival_airline",
          header: "Arrival Airline",
          enableSorting: true,
          sortingFn: "alphanumeric",
          filterFn: "includesString",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            return (
              flights[tripLegIds[0]]?.trip_leg_type ?? TripLegTypeEnum.FLIGHT
            )
          }
        },
        {
          cell: (props) => {
            let type = props.getValue()
            if (type) {
              return <TripLegTypeBadge tripLegType={type} />
            } else {
              return <></>
            }
          },
          id: "flight_departure_type",
          header: "Departure type",
          sortingFn: "alphanumeric",
          filterFn: "arrIncludesSome",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            return flights[tripLegIds[0]]?.dep_datetime
          }
        },
        {
          id: "flight_departure_time",
          header: "Departure Time",
          enableSorting: true,
          sortingFn: "datetime",
          filterFn: "includesString",
          cell: (props) => {
            let time = props.getValue()
            return getDatetimeString(time as string)
          },
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            let tripLeg = flights[tripLegIds[0]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.dep_station
            } else {
              return tripLeg?.dep_airport
            }
          }
        },
        {
          id: "flight_departure_airport",
          header: "Departure Location",
          enableSorting: true,
          sortingFn: "alphanumeric",
          filterFn: "includesString",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            let tripLeg = flights[tripLegIds[0]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.train_num
            } else {
              return tripLeg?.flight_num
            }
          }
        },
        {
          id: "flight_departure_number",
          header: "Departure #",
          enableSorting: true,
          sortingFn: "alphanumeric",
          filterFn: "includesString",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            let tripLeg = flights[tripLegIds[0]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.railway
            } else {
              return tripLeg?.airline
            }
          }
        },
        {
          id: "flight_departure_airline",
          header: "Departure Airline",
          enableSorting: true,
          sortingFn: "alphanumeric",
          filterFn: "includesString",
        }
      ),
      // form responses
      ...columns.map((column) => {
        return columnHelper.accessor(
          (attendee) => {
            if (attendee.column_id_to_answer_id[column.id]) {
              return columnAnswerMap[attendee.column_id_to_answer_id[column.id]]
                ?.value
            }
          },
          {
            ...(column.type === "DATETIME" && {
              cell: (props) => {
                let time = props.getValue()
                return getDatetimeString(time as string)
              },
            }),
            ...(column.type === "MULTI_SELECT" && {
              cell: (props) => {
                let value = props.getValue()
                if (!value) {
                  return value ?? ""
                } else if (typeof value === "object" && value) {
                  return <MultiSelectCell value={value as string[]} />
                } else {
                  return (
                    <MultiSelectCell
                      value={(value as string).split(",") as string[]}
                    />
                  )
                }
              },
            }),
            header: column.title,
            id: column.id.toString(),
            enableSorting: true,
            sortingFn: "alphanumeric",
            filterFn:
              column.type === "SINGLE_SELECT" || column.type === "MULTI_SELECT"
                ? "arrIncludesAll"
                : "includesString",
          }
        )
      }),
    ],
    data: variables.attendees,
    meta: {
      columnFilters: variables.columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getTableFilteredRows,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: true,
  }

  const table = useReactTable(options)
  return table
}
