import {Cross1Icon} from "@radix-ui/react-icons"
import {FormField} from "@summtech/flok-base/components/FormField"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Tab} from "@summtech/flok-base/components/Tab"
import {TabContent} from "@summtech/flok-base/components/TabContent"
import {TabsList} from "@summtech/flok-base/components/TabsList"
import {TabsWrapper} from "@summtech/flok-base/components/TabsWrapper"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {RawDraftContentState} from "draft-js"
import {useFormik} from "formik"
import {useDispatch} from "react-redux"
import {ItineraryEventModel, ItineraryModel} from "../../models/retreat"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {ApiAction} from "../../store/actions/api"
import {
  patchItinerary,
  postComment,
  postCommentThread,
} from "../../store/actions/retreat"
import CommentsTab, {ThreadObject} from "../comments/CommentsTab"
import {addToDate} from "../pages/ItineraryBuilderPage"

const SideContainer = styled("div", {
  width: "288px",
  minWidth: "288px",
  height: "100%",
  overflow: "auto",
  borderLeft: `1px solid ${theme.colors.gray6}`,
})

const SideNavSection = styled("div", {
  display: "flex",
  borderBottomStyle: "solid",
  borderBottomColor: theme.colors.gray6,
  borderBottomWidth: "1px",
})
const TabsSection = styled(SideNavSection, {
  padding: "0px 24px 0px 20px",
  flexDirection: "row",
  alignItems: "center",
})

const PublishedSection = styled(SideNavSection, {
  padding: "20px 24px",
  width: "100%",
})
const DatesSection = styled("div", {
  padding: "20px 24px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
})

function differenceInDays(dateString1: string, dateString2: string) {
  // must pass in dates in string format
  let date1 = new Date(dateString1)
  let date2 = new Date(dateString2)
  let difference = date1.getTime() - date2.getTime()
  let differenceInDays = Math.ceil(difference / (1000 * 3600 * 24))
  return differenceInDays
}
type ItineraryBuilderSideTabsProps = {
  itinerary: ItineraryModel
  tabValue: "comments" | "settings"
  setTabValue: (newValue: string) => void
  onClose: () => void
  events: ItineraryEventModel[]
}

export default function ItineraryBuilderSideTabs(
  props: ItineraryBuilderSideTabsProps
) {
  let dispatch = useDispatch()
  let [retreat] = useRetreat()
  let formik = useFormik({
    initialValues: {
      number_of_days: props.itinerary.number_of_days ?? 4,
      start_date: props.itinerary.start_date,
      end_date: props.itinerary.start_date
        ? addToDate(
            props.itinerary.start_date,
            props.itinerary.number_of_days ?? 4
          )
        : undefined,
    },
    onSubmit: async (values) => {
      let itineraryValues = {...values} as unknown as {
        number_of_days: number
        start_date: string
        end_date: string | undefined
      }
    },
    enableReinitialize: true,
  })
  return (
    <SideContainer>
      <TabsWrapper onValueChange={props.setTabValue} value={props.tabValue}>
        <TabsSection>
          <TabsList>
            <Tab value={"comments"} text={"Comments"} />
            <Tab value={"settings"} text={"Settings"} />
          </TabsList>
          <IconButton variant="ghost" onClick={props.onClose}>
            <Cross1Icon />
          </IconButton>
        </TabsSection>
        <TabContent value={"settings"}>
          <DatesSection>
            <FormField
              label="Start Date"
              fullWidth
              value={formik.values.start_date}
              onChange={(value) => {
                if (value) {
                  dispatch(
                    patchItinerary({start_date: value}, props.itinerary.id)
                  )
                  if (formik.values.number_of_days) {
                    formik.setFieldValue(
                      "end_date",
                      addToDate(value, formik.values.number_of_days)
                    )
                  }
                } else {
                  dispatch(patchItinerary({start_date: ""}, props.itinerary.id))
                  if (formik.values.start_date) {
                    formik.setFieldValue(
                      "end_date",
                      addToDate(formik.values.start_date, 1)
                    )
                  }
                }
              }}
              type="datetime"
            />
            <FormField
              label="End Date"
              fullWidth
              value={formik.values.end_date}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue("end_date", value)
                  if (formik.values.start_date) {
                    dispatch(
                      patchItinerary(
                        {
                          number_of_days:
                            differenceInDays(value, formik.values.start_date) +
                            1,
                        },
                        props.itinerary.id
                      )
                    )
                  }
                } else {
                  formik.setFieldValue("end_date", "")
                }
              }}
              type="datetime"
            />
          </DatesSection>
        </TabContent>
        <TabContent value={"comments"}>
          <CommentsTab
            threadObjects={[
              ...props.events
                .filter((event) => event && event.comment_thread_id)
                .map((event) => event as ItineraryEventModel)
                .map<ThreadObject>((event) => {
                  return {
                    title: event?.day
                      ? `Day ${event.day} - ${event.title}`
                      : event.title,
                    threadId: event.comment_thread_id,
                    onSubmit: async (values) => {
                      dispatch(
                        postComment({
                          ...values,
                          comment_thread_id: event.comment_thread_id,
                          retreat_id: retreat.id,
                          redirect: `/itinerary/${event.id}?comment=:id`,
                        })
                      )
                    },
                  }
                }),
              {
                title: "General",
                sticky: true,
                threadId: props.itinerary.comment_thread_id,
                onSubmit: async (values: {
                  comment_text: RawDraftContentState
                }) => {
                  if (props.itinerary.comment_thread_id) {
                    dispatch(
                      postComment({
                        ...values,
                        comment_thread_id: props.itinerary.comment_thread_id,
                        retreat_id: retreat.id,
                        redirect: `/itinerary?comments=open`,
                      })
                    )
                  } else {
                    let threadResponse = (await dispatch(
                      postCommentThread()
                    )) as unknown as ApiAction
                    if (!threadResponse.error) {
                      let eventResponse = (await dispatch(
                        patchItinerary(
                          {
                            comment_thread_id:
                              threadResponse.payload.comment_thread.id,
                          },
                          props.itinerary.id
                        )
                      )) as unknown as ApiAction

                      if (!eventResponse.error) {
                        dispatch(
                          postComment({
                            ...values,
                            comment_thread_id:
                              threadResponse.payload.comment_thread.id,
                            retreat_id: retreat.id,
                            redirect: `/itinerary?comments=open`,
                          })
                        )
                      }
                    }
                  }
                },
              },
            ]}
          />
        </TabContent>
      </TabsWrapper>
    </SideContainer>
  )
}
