import {useMediaQuery} from "@material-ui/core"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {FlokTheme} from "../../theme"

const HeaderContainer = styled("div", {
  width: "100%",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  alignItems: "center",
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})
export default function SitePageHeader(props: {
  title: string
  subtitle?: string
}) {
  const isSmallScreen = useMediaQuery((theme: FlokTheme) =>
    theme.breakpoints.down("sm")
  )
  return (
    <HeaderContainer>
      <Text variant={isSmallScreen ? "title-5xl" : "title-7xl"}>
        {props.title}
      </Text>
      {props.subtitle && (
        <GrayText variant="text-base-plus"> {props.subtitle}</GrayText>
      )}
    </HeaderContainer>
  )
}
