import {Button} from "@summtech/flok-base/components/Button"
import {FormField} from "@summtech/flok-base/components/FormField"
import {styled} from "@summtech/flok-base/stitches.config"
import {useFormik} from "formik"
import {useDispatch, useSelector} from "react-redux"
import * as yup from "yup"
import {AttendeeReportModel} from "../../../models/retreat"
import {RootState} from "../../../store"
import {ApiAction} from "../../../store/actions/api"
import {
  patchAttendeeReport,
  postAttendeeReport,
} from "../../../store/actions/retreat"
import AppModal, {AppModalProps} from "../AppModal"

const ModalBody = styled("div", {
  width: "480px",
})

type AttendeeReportModalProps = AppModalProps & {
  retreatId: number
  reportId?: number
  onPatch?: () => void
  onPost?: (report: AttendeeReportModel) => void
}
export default function AttendeeReportModal(props: AttendeeReportModalProps) {
  let reports = useSelector((state: RootState) => {
    return state.retreat.attendeeReports
  })
  let dispatch = useDispatch()
  let formik = useFormik({
    initialValues: {
      name:
        props.reportId && reports[props.reportId]
          ? reports[props.reportId]!.name
          : "",
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .required("This field is required")
        .max(25, "Report name can be 25 character max"),
    }),
    onSubmit: async (values, helpers) => {
      if (props.reportId) {
        let response = (await dispatch(
          patchAttendeeReport(values, props.reportId)
        )) as unknown as ApiAction
        if (!response.error) {
          props.onPatch && props.onPatch()
          helpers.resetForm()
        }
      } else {
        let response = (await dispatch(
          postAttendeeReport({
            retreat_id: props.retreatId,
            column_visibility: {
              first_name: true,
              last_name: true,
              email_address: true,
            },
            name: values.name,
          })
        )) as unknown as ApiAction
        if (!response.error) {
          props.onPost && props.onPost(response.payload.attendee_report)
          helpers.resetForm()
        }
      }
    },
    enableReinitialize: true,
  })
  return (
    <AppModal open={props.open} onClose={props.onClose}>
      <AppModal.Header>
        {props.reportId ? "Edit Report" : "New Report"}
      </AppModal.Header>
      <AppModal.Body>
        <ModalBody>
          <FormField
            type="textfield"
            label="Name"
            fullWidth
            id="name"
            onChange={formik.handleChange}
            errorMessage={formik.errors.name}
            value={formik.values.name}
          />
        </ModalBody>
      </AppModal.Body>
      <AppModal.Footer>
        <Button
          text={props.reportId ? "Done" : "Create Report"}
          type="submit"
          onClick={(e) => {
            e.preventDefault()
            formik.handleSubmit()
          }}
          color="brand"></Button>
      </AppModal.Footer>
    </AppModal>
  )
}
