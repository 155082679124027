import {FileIcon, TrashIcon, UploadIcon} from "@radix-ui/react-icons"
import {FormLabel} from "@summtech/flok-base/components/FormLabel"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useDispatch} from "react-redux"
import config, {IMAGE_SERVER_BASE_URL_KEY} from "../../config"
import {FileModel} from "../../models/retreat"
import {enqueueSnackbar} from "../../notistack-lib/actions"

const FilePickerContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
})
const StyledButton = styled("div", {
  width: "125px",
  display: "flex",
  flexDirection: "row",
  gap: "6px",
  alignItems: "center",
  borderColor: theme.colors.gray7,
  borderWidth: "1px",
  borderStyle: "solid",
  backgroundColor: theme.colors.white,
  borderRadius: theme.shape.borderRadius,
  padding: "8px 12px",
  whiteSpace: "nowrap",
  cursor: "pointer",
})
const Flex = styled("div", {
  display: "flex",

  width: "100%",
  flexDirection: "column",
  gap: "8px",
  "@bp2": {
    flexDirection: "row",
    alignItems: "center",
  },
})
const FlexColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  overflow: "hidden",
})
const FormLabelContainer = styled("div", {
  width: "160px",
})
const StyledPlaceHolder = styled("div", {
  width: "160px",
  minWidth: "160px",
})

export type MultiFilePickerProps = {
  handleChange: (file: FileModel) => void
  type?: string
  accepts?: string
  files?: {displayName: string; path: string; id: number}[]
  fullWidth?: boolean
  disabled?: boolean
  onDelete: (id: number) => void
  label?: string
}

export function AppMultipleFilePicker(props: MultiFilePickerProps) {
  let dispatch = useDispatch()
  return (
    <FilePickerContainer>
      <Flex>
        {props.label && (
          <FormLabelContainer>
            <FormLabel text={props.label} />
          </FormLabelContainer>
        )}
        <label>
          <StyledButton>
            <UploadIcon />
            <Text variant="text-sm-plus">Upload Files</Text>
          </StyledButton>

          <input
            hidden
            disabled={props.disabled}
            type="file"
            accept={props.accepts ? props.accepts : "application/pdf"}
            onChange={(e) => {
              if (e.target && e.target.files && e.target.files[0]) {
                let data = new FormData()
                data.append("file", e.target.files[0])
                fetch(
                  `${config.get(IMAGE_SERVER_BASE_URL_KEY)}/api/files${
                    props.type ? `?type=${props.type}` : ""
                  }
              `,
                  {
                    body: data,
                    method: "POST",
                    mode: "cors",
                  }
                )
                  .then((res) => res.json())
                  .then(async (resdata) => {
                    props.handleChange(resdata.file)
                  })
                  .catch(() => {
                    dispatch(
                      enqueueSnackbar({
                        message: "Oops, something went wrong",
                        options: {
                          variant: "error",
                        },
                      })
                    )
                  })
              }
            }}
          />
        </label>
      </Flex>

      <Flex>
        {props.label && <StyledPlaceHolder />}
        <FlexColumn>
          {props.files &&
            props.files.map((file) => {
              return (
                <File
                  displayName={file.displayName}
                  path={file.path}
                  onDelete={() => props.onDelete(file.id)}
                />
              )
            })}
        </FlexColumn>
      </Flex>
    </FilePickerContainer>
  )
}

const FileContainer = styled("a", {
  display: "flex",
  flexDirection: "row",
  padding: "8px 12px",
  gap: "12px",
  alignItems: "center",
  borderColor: theme.colors.gray7,
  borderWidth: "1px",
  borderStyle: "solid",
  backgroundColor: theme.colors.white,
  borderRadius: theme.shape.borderRadius,
  textDecoration: "none",
  color: theme.colors.black,
})

const IconContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "40px",
  width: "40px",
  background: theme.colors.gray3,
  borderRadius: theme.shape.borderRadius,
})
const IconButtonContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
})
const FileText = styled(Text, {
  whiteSpace: "nowrap",
  flex: 1,
  textOverflow: "ellipsis",
  overflow: "hidden",
})
function File(props: {
  displayName: string
  path: string
  onDelete: () => void
}) {
  return (
    <FileContainer
      href={props.path}
      download={props.displayName}
      target={"_blank"}>
      <IconContainer>
        <FileIcon />
      </IconContainer>
      <FileText variant="text-sm-plus">{props.displayName}</FileText>
      <IconButtonContainer>
        <IconButton
          color="gray"
          variant="ghost"
          onClick={(e) => {
            e.preventDefault()
            props.onDelete()
          }}>
          <TrashIcon />
        </IconButton>
      </IconButtonContainer>
    </FileContainer>
  )
}
