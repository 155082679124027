import {useMediaQuery} from "@material-ui/core"
import {
  CalendarIcon,
  CheckCircledIcon,
  ChevronDownIcon,
  CopyIcon,
  DesktopIcon,
  GearIcon,
  MagnifyingGlassIcon,
  Pencil2Icon,
  PersonIcon,
  PlusCircledIcon,
  PlusIcon,
  ReaderIcon,
  BarChartIcon as StackIcon,
  TableIcon,
  TokensIcon,
} from "@radix-ui/react-icons"
import {Avatar} from "@summtech/flok-base/components/Avatar"
import {Button} from "@summtech/flok-base/components/Button"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {
  NavigationItem,
  NavigationList,
  NavigationSection,
  NavigationSubSection,
} from "@summtech/flok-base/components/Navigation"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useQuery} from "@tanstack/react-query"
import {useEffect} from "react"
import {Link, matchPath} from "react-router-dom"
import {AppRoutes, FlokPageName, TemporarySideNavPages} from "../../Stack"
import {getUserHome} from "../../api/user"
import AppLogo from "../../components/base/AppLogo"
import {RetreatModel} from "../../models/retreat"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {FlokTheme} from "../../theme"
import {getRetreatName} from "../../utils/retreatUtils"
import ActivityDropdown from "../notifications/ActivityDropdown"
import {useSidebar} from "./SidebarProvider"

const StyledOverlayBackground = styled("div", {
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  opacity: 0.2,
  background: "black",
  display: "none",
  variants: {
    open: {
      true: {
        display: "block",
      },
    },
  },
})
const StyledSidebar = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "24px 12px",
  gap: "20px",
  width: "240px",
  zIndex: 1000,
  minWidth: "240px",
  maxWidth: "240px",
  backgroundColor: theme.colors.gray2,
  borderRight: `1px solid ${theme.colors.gray6}`,
  overflowY: "auto",
  // Mobile position when closed
  position: "fixed",
  left: "-240px",
  top: "50px",
  height: "calc(100% - 50px)", // topbar active
  variants: {
    open: {
      true: {},
    },
    temporary: {
      true: {
        top: "50px",
        height: "calc(100% - 50px)", // topbar active
        "@bp1": {
          position: "fixed",
          left: "-240px",
          top: "0px",
          height: "100%",
        },
      },
      false: {
        "@bp1": {
          // Desktop position
          position: "relative",
          left: "0px",
          top: "0px",
          height: "100%", // topbar active
        },
      },
    },
  },
  compoundVariants: [
    {
      open: true,
      temporary: true,
      css: {
        zIndex: 200,
        left: "0px",
      },
    },
    {
      open: false,
      temporary: true,
      css: {
        left: "-240px",
      },
    },
  ],
})

const StyledLink = styled(Link, {
  textDecoration: "none",
  width: "100%",
  color: "inherit",
})
const ShowMoreButton = styled(Button, {
  marginTop: "4px",
  marginBottom: "4px",
})

const SubContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  paddingLeft: "19px",
  gap: "10px",
  width: "100%",
})

const VerticalLine = styled("div", {
  border: `1px solid ${theme.colors.gray6}`,
  display: "flex",
  flexDirection: "column",
  flex: 1,
})

const SubItemList = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4px",
  width: "100%",
})

type NavItemModel = {
  icon?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>
  name: string
  activeRoutes: FlokPageName[]
  hide?: (retreat: RetreatModel) => boolean
  to: (
    retreat: RetreatModel,
    retreatIdx: number
  ) => {url: string; external?: boolean} | undefined
}
type NavSectionModel = {
  title: string
  items: (NavItemModel | NavSectionModel)[]
  icon?: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>
  to?: (
    retreat: RetreatModel,
    retreatIdx: number
  ) => {url: string; external?: boolean} | undefined
}
function redirectFlok(pageName: FlokPageName) {
  return (retreat: RetreatModel, retreatIdx: number) => ({
    url: AppRoutes.getPath(pageName, {retreatIdx: retreatIdx.toString()}),
  })
}

function isSection(
  obj: NavItemModel | NavSectionModel
): obj is NavSectionModel {
  // @ts-ignore
  return !!obj.title
}

const SideNavRoutes: (NavItemModel | NavSectionModel)[] = [
  {
    icon: TokensIcon,
    name: "Overview",
    to: redirectFlok("RetreatHomePage"),
    activeRoutes: ["RetreatHomePage"],
  },
  {
    icon: GearIcon,
    name: "Activity",
    to: redirectFlok("RetreatHomePage"),
    activeRoutes: [],
  },
  {
    icon: CheckCircledIcon,
    name: "Tasks",
    to: redirectFlok("TaskListPage"),
    activeRoutes: ["TaskListPage"],
  },
  // {
  //   icon: PaperPlaneIcon,
  //   name: "Communication",
  //   to: redirectFlok("CommunicationHomePage"),
  //   activeRoutes: [],
  // },
  {
    icon: GearIcon,
    name: "Settings",
    to: redirectFlok("RetreatSettingsPage"),
    activeRoutes: ["RetreatSettingsPage"],
  },
  {
    title: "Lodging",
    items: [
      {
        name: "Event Profile",
        icon: ReaderIcon,
        activeRoutes: ["RetreatRfpPage"],
        to: redirectFlok("RetreatRfpPage"),
      },
      {
        name: "Explore",
        icon: MagnifyingGlassIcon,
        activeRoutes: ["RetreatHotelSearchPage"],
        to: redirectFlok("RetreatHotelSearchPage"),
      },
      {
        name: "Proposals",
        icon: CopyIcon,
        activeRoutes: ["RetreatLodgingProposalsPage"],
        to: redirectFlok("RetreatLodgingProposalsPage"),
      },
      {
        name: "Contract",
        icon: Pencil2Icon,
        activeRoutes: ["RetreatLodgingContractPage"],
        to: redirectFlok("RetreatLodgingContractPage"),
      },
    ],
  },
  {
    title: "Planning",
    items: [
      {
        title: "Attendees",
        icon: PersonIcon,
        to: redirectFlok("RetreatAttendeesPage"),
        items: [
          {
            name: "Manage Attendees",
            activeRoutes: [
              "RetreatAttendeesPage",
              "RetreatAttendeePage",
              "RetreatAttendeeFlightsPage",
              "RetreatAttendeeRegResponsePage",
            ],
            to: redirectFlok("RetreatAttendeesPage"),
          },
          {
            name: "Attendee Groups",
            activeRoutes: ["UserGroupsPage"],
            to: redirectFlok("UserGroupsPage"),
          },
        ],
      },
      {
        name: "Reports",
        icon: TableIcon,
        activeRoutes: [
          "ReportAttendeesPage",
          "ReportAttendeePage",
          "ReportAttendeeFlightsPage",
          "ReportAttendeeRegResponsePage",
        ],
        to: redirectFlok("ReportAttendeesPage"),
      },

      {
        name: "Registration",
        icon: PlusCircledIcon,
        activeRoutes: ["RetreatRegistrationFormBuilderPage"],
        to: redirectFlok("RetreatRegistrationFormBuilderPage"),
      },
      {
        name: "Surveys",
        icon: StackIcon,
        activeRoutes: ["SurveysPage", "SurveyBuilderPage"],
        to: redirectFlok("SurveysPage"),
        hide: (retreat) => !retreat.attendee_table_v2,
      },
      {
        name: "Landing Page",
        icon: DesktopIcon,
        activeRoutes: ["LandingPageGeneratorHome", "LandingPageGeneratorPage"],
        to: redirectFlok("LandingPageGeneratorHome"),
      },
      {
        name: "Itinerary",
        icon: CalendarIcon,
        activeRoutes: ["ItineraryPage"],
        to: redirectFlok("ItineraryPage"),
      },
    ],
  },
]
export default function PageSidenav() {
  let [retreat, retreatIdx] = useRetreat()
  let userQuery = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserHome(),
  })
  let user = userQuery.isSuccess ? userQuery.data.user : undefined
  let {sidebarOpen, closeSidebar, openSidebar} = useSidebar()
  const isSmallScreen = useMediaQuery((theme: FlokTheme) =>
    theme.breakpoints.down("sm")
  )
  let temporary =
    TemporarySideNavPages.filter((page) => {
      return matchPath(window.location.pathname, {
        path: AppRoutes.getPath(page),
        exact: true,
      })
    }).length > 0 || isSmallScreen
  useEffect(() => {
    if (!isSmallScreen && !temporary) {
      openSidebar()
    } else {
      closeSidebar()
    }
  }, [isSmallScreen, temporary])

  function returnNavItem(subItem: NavItemModel, key: string, css?: any) {
    let activeRoutes = subItem.activeRoutes.filter((page) => {
      return matchPath(window.location.pathname, {
        path: AppRoutes.getPath(page),
        exact: true,
      })
    })
    let active = activeRoutes.length > 0
    let hidden = subItem.hide ? subItem.hide(retreat) : false
    if (hidden) {
      return <></>
    }
    return (
      <StyledLink to={subItem.to(retreat, retreatIdx)?.url ?? ""} key={key}>
        <NavigationItem
          css={css}
          onClick={closeSidebar}
          name={subItem.name}
          icon={subItem.icon}
          active={active}
        />
      </StyledLink>
    )
  }

  return (
    <>
      <StyledSidebar open={sidebarOpen} temporary={temporary}>
        <div style={{paddingInline: "12px"}}>
          <AppLogo height={25} withText noBackground />
        </div>
        <Dropdown
          button={
            <Button
              variant="ghost"
              text={getRetreatName(retreat)}
              startIcon={
                <Avatar
                  color="green"
                  label={getRetreatName(retreat).trim().charAt(0)}
                />
              }
              endIcon={<ChevronDownIcon style={{marginLeft: "auto"}} />}
              fullWidth
            />
          }>
          <div style={{padding: "6px 8px"}}>
            <Text css={{color: "$gray11"}} variant={"text-sm-plus"}>
              Switch Event
            </Text>
          </div>
          {user &&
            user.users_retreats &&
            user.users_retreats
              .filter((ur) => !ur.archived)
              .slice(-3)
              .map((ur) => {
                let retreat = ur.retreat
                return (
                  <DropdownItem
                    key={retreat.id}
                    onClick={() => {
                      window.location.href = AppRoutes.getPath(
                        "RetreatHomePage",
                        {
                          retreatIdx: ur.order.toString(),
                        }
                      )
                    }}
                    text={getRetreatName(retreat as RetreatModel)}
                    startIcon={
                      <Avatar
                        label={getRetreatName(retreat as RetreatModel)[0]}
                        color={"blue"}
                      />
                    }
                  />
                )
              })}
          <DropdownItem
            key={retreat.id}
            onClick={() => {
              window.location.href = AppRoutes.getPath("EventsPage")
            }}
            text={`View all ${user?.users_retreats?.length} events`}
            css={{
              color: "$blue11",
            }}
          />
          <DropdownItem
            key={retreat.id}
            onClick={() => {
              window.location.href = AppRoutes.getPath("CreateEventPage")
            }}
            text={"New Event"}
            css={{
              borderTop: "1px solid $gray7",
            }}
            startIcon={<PlusIcon />}
          />
        </Dropdown>

        <NavigationList>
          {SideNavRoutes.map((item, i) => {
            if (!isSection(item)) {
              let navItem = item
              if (navItem.name.toLowerCase() === "activity") {
                return <ActivityDropdown />
              }
              let activeRoutes = navItem.activeRoutes.filter((page) => {
                return matchPath(window.location.pathname, {
                  path: AppRoutes.getPath(page),
                  exact: true,
                })
              })
              let active = activeRoutes.length > 0
              let hidden = navItem.hide ? navItem.hide(retreat) : false
              if (hidden) {
                return <></>
              }
              return (
                <StyledLink
                  to={navItem.to(retreat, retreatIdx)?.url ?? ""}
                  key={i}>
                  <NavigationItem
                    onClick={closeSidebar}
                    name={navItem.name}
                    icon={navItem.icon}
                    active={active}
                  />
                </StyledLink>
              )
            } else {
              let navSection = item
              return (
                <NavigationSection title={navSection.title}>
                  {navSection.items.map((subItem, j) => {
                    if (!isSection(subItem)) {
                      let key = `${i}-${j}`
                      return returnNavItem(subItem, key)
                    } else {
                      let subNavIsActive = false
                      subItem.items.forEach((item) => {
                        if (!isSection(item)) {
                          let activeRoutes = item.activeRoutes.filter(
                            (page) => {
                              return matchPath(window.location.pathname, {
                                path: AppRoutes.getPath(page),
                                exact: true,
                              })
                            }
                          )
                          let active = activeRoutes.length > 0
                          if (active) {
                            subNavIsActive = true
                          }
                        }
                      })
                      return (
                        <StyledLink
                          to={
                            subItem.to
                              ? subItem.to(retreat, retreatIdx)?.url ?? ""
                              : ""
                          }
                          key={i}>
                          <NavigationSubSection
                            isActive={subNavIsActive}
                            title={subItem.title}
                            icon={subItem.icon}>
                            <SubContainer>
                              <VerticalLine />
                              <SubItemList>
                                {subItem.items.map((subItem, k) => {
                                  let key = `${j}-${k}`
                                  if (!isSection(subItem)) {
                                    return returnNavItem(subItem, key)
                                  } else {
                                    return <></>
                                  }
                                })}
                              </SubItemList>
                            </SubContainer>
                          </NavigationSubSection>
                        </StyledLink>
                      )
                    }
                  })}
                </NavigationSection>
              )
            }
          })}
        </NavigationList>
      </StyledSidebar>
      <StyledOverlayBackground
        open={sidebarOpen && temporary}
        onClick={(e) =>
          e.currentTarget === e.target ? closeSidebar() : undefined
        }
      />
    </>
  )
}
