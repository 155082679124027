import {styled} from "@summtech/flok-base/stitches.config"
import {push} from "connected-react-router"
import {useDispatch} from "react-redux"
import {
  CalendarDayEventModel,
  ItineraryEventModel,
  ItineraryModel,
} from "../../models/retreat"
import {AppRoutes} from "../../Stack"
import {getIsoDate} from "../pages/ItineraryBuilderPage"
import CalendarDay from "./CalendarDay"
import {CalendarHours} from "./CalendarHours"

export function getEarliestTime(events: CalendarDayEventModel[]) {
  let earliestTime = 24
  events.forEach((event) => {
    if (event.start_time) {
      let date = new Date(`December 14, 2026 ${event.start_time}`)
      earliestTime = Math.min(earliestTime, date.getHours())
    }
  })
  return earliestTime
}

function getMaxAllDayEvents(events: ItineraryEventModel[]) {
  let counter: {[day: number]: number} = {}
  events.forEach((event) => {
    if (event.all_day && counter[event.day]) {
      counter[event.day] += 1
    } else if (event.all_day) {
      counter[event.day] = 1
    }
  })
  return Math.max(...Object.values(counter), 0)
}

const CalendarContainer = styled("div", {
  width: "fit-content",
  display: "flex",
  flexDirection: "row",
})
const CalendarInnerContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
})
const CalendarHoursHolder = styled("div", {
  marginTop: "$$top",
})
export default function Calendar(props: {
  itinerary: ItineraryModel
  events: ItineraryEventModel[]
  retreatIdx: number
}) {
  let dispatch = useDispatch()
  let startHour = Math.min(
    getEarliestTime(
      props.events.filter((event) => {
        return (
          props.itinerary &&
          event &&
          0 < event.day &&
          event.day <= props.itinerary.number_of_days &&
          event.start_time &&
          !event.all_day
        )
      }) as ItineraryEventModel[]
    ),
    8
  )

  return (
    <CalendarContainer>
      <CalendarInnerContainer>
        <CalendarHoursHolder
          css={{$$top: `${54 + 30 * getMaxAllDayEvents(props.events)}px`}}>
          <CalendarHours startHour={startHour} />
        </CalendarHoursHolder>
        {[...new Array(props.itinerary.number_of_days)].map((u, i) => {
          return (
            <CalendarDay
              allDayEvents={getMaxAllDayEvents(props.events)}
              startHour={startHour}
              last={i === props.itinerary.number_of_days - 1}
              width={230}
              title={`Day ${i + 1}`}
              subtitle={
                props.itinerary.start_date
                  ? new Intl.DateTimeFormat(undefined, {
                      day: "numeric",
                      month: "long",
                      timeZone: "UTC",
                    }).format(getIsoDate(props.itinerary.start_date, i))
                  : undefined
              }
              events={props.events
                .filter((event) => event.day === i + 1)
                .map((event) => {
                  let calendarEvent = event as CalendarDayEventModel
                  calendarEvent.onClick = () => {
                    dispatch(
                      push(
                        AppRoutes.getPath("ItineraryEventPage", {
                          retreatIdx: props.retreatIdx.toString(),
                          eventId: event.id.toString(),
                        })
                      )
                    )
                  }
                  return calendarEvent
                })}
            />
          )
        })}
      </CalendarInnerContainer>
    </CalendarContainer>
  )
}
