import {CheckIcon} from "@radix-ui/react-icons"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import OverviewTaskCard, {OverviewTaskCardProps} from "./OverviewTaskCard"

const TaskContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "12px",
})
const OverallContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
})

const AvatarContainer = styled("div", {
  marginTop: "2px",
})

const CardAvatar = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "30px",
  width: "30px",
  borderRadius: "50%",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: theme.colors.gray6,
  backgroundColor: theme.colors.white,
  variants: {
    done: {
      true: {
        backgroundColor: theme.colors.green3,
        borderColor: theme.colors.green6,
      },
      false: {},
    },
  },
})
const CardCheck = styled(CheckIcon, {
  color: theme.colors.green11,
})

export default function OverviewTaskList(props: {
  tasks: (OverviewTaskCardProps & {index: number})[]
}) {
  return (
    <OverallContainer>
      {props.tasks.map((task) => {
        return (
          <TaskContainer>
            <AvatarContainer>
              <CardAvatar done={task.done}>
                {task.done ? (
                  <CardCheck />
                ) : (
                  <Text variant={"text-sm-plus"}>
                    {(task.index + 1).toString()}
                  </Text>
                )}
              </CardAvatar>
            </AvatarContainer>
            <OverviewTaskCard
              done={task.done}
              link={task.link}
              title={task.title}
              description={task.description}
            />
          </TaskContainer>
        )
      })}
    </OverallContainer>
  )
}
