import {ArrowLeftIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {RawDraftContentState} from "draft-js"
import {useState} from "react"
import {useSelector} from "react-redux"
import {RootState} from "../../store"
import CommentForm from "./CommentForm"
import CommentsList from "./CommentsList"

const TabContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
})

const CommentsListContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "16px 24px",
  borderBottom: `1px solid ${theme.colors.gray7}`,
  width: "100%",
  gap: "8px",
})

const FlexTitle = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  // justifyContent: "space-between",
  alignItems: "center",
  // gap: "5px",
})
const BottomButton = styled(Button, {
  [`${Text}`]: {
    color: theme.colors.gray11,
  },
  color: theme.colors.gray11,
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})
export type ThreadObject = {
  title: string
  threadId?: number
  onSubmit: (values: {comment_text: RawDraftContentState}) => void
  sticky?: true
}

export default function CommentsTab(props: {threadObjects: ThreadObject[]}) {
  let [onlyThreadId, setOnlyThreadId] = useState<number | undefined>(undefined)
  let allCommentThreads = useSelector((state: RootState) => {
    return state.retreat.commentThreads
  })
  let comments = useSelector((state: RootState) => {
    return state.retreat.comments
  })
  return (
    <TabContainer>
      {props.threadObjects
        .filter((obj) => {
          if (onlyThreadId === -1) {
            return obj.title.toLowerCase() === "general"
          } else if (onlyThreadId) {
            return obj.threadId === onlyThreadId
          } else {
            return true
          }
        })
        .sort((objA, objB) => {
          if (objA.sticky && objB.sticky) {
            return 0
          } else if (objA.sticky || objB.sticky) {
            return objA.sticky ? -1 : 1
          }
          if (objA && objB && objA.threadId && objB.threadId) {
            let threadA = allCommentThreads[objA.threadId]
            let threadB = allCommentThreads[objB.threadId]
            if (threadA && threadB) {
              let lastCommentA =
                comments[threadA.comment_ids[threadA.comment_ids.length - 1]]
              let lastCommentB =
                comments[threadB.comment_ids[threadB.comment_ids.length - 1]]
              if (lastCommentA && lastCommentB) {
                return new Date(lastCommentB.created_at) <
                  new Date(lastCommentA.created_at)
                  ? -1
                  : 1
              } else {
                return 0
              }
            } else {
              return 0
            }
          } else {
            return 0
          }
        })
        .map((obj) => {
          if (!obj.threadId) {
            return (
              <EmptyCommentList
                onClose={() => {
                  setOnlyThreadId(undefined)
                }}
                title={obj.title}
                writing={!!onlyThreadId}
                onSubmit={obj.onSubmit}
                onWriteComment={() => {
                  setOnlyThreadId(-1)
                }}
              />
            )
          }
          return (
            <CommentsListContainer>
              <FlexTitle>
                {onlyThreadId && (
                  <IconButton
                    variant={"ghost"}
                    onClick={() => {
                      setOnlyThreadId(undefined)
                    }}>
                    <ArrowLeftIcon />
                  </IconButton>
                )}
                <GrayText variant="text-sm-plus"> {obj.title}</GrayText>
              </FlexTitle>

              <CommentsList
                showAll={!!onlyThreadId}
                writing={!!onlyThreadId}
                onShowMore={() => {
                  setOnlyThreadId(obj.threadId)
                }}
                alt
                key={obj.threadId}
                commentThreadId={obj.threadId!}
                startingNumber={1}
              />
              {onlyThreadId && (
                <CommentForm onSubmit={obj.onSubmit} disableToolbar />
              )}
            </CommentsListContainer>
          )
        })}
    </TabContainer>
  )
}

function EmptyCommentList(props: {
  writing?: boolean
  onWriteComment: () => void
  title: string
  onSubmit: (values: {comment_text: RawDraftContentState}) => void
  onClose: () => void
}) {
  return (
    <CommentsListContainer>
      <FlexTitle>
        {props.writing && (
          <IconButton variant={"ghost"} onClick={props.onClose}>
            <ArrowLeftIcon />
          </IconButton>
        )}
        <GrayText variant="text-sm-plus"> {props.title}</GrayText>
      </FlexTitle>

      <GrayText variant="text-sm">No comment yet.</GrayText>
      {props.writing && (
        <CommentForm onSubmit={props.onSubmit} disableToolbar />
      )}
      {!props.writing && (
        <BottomButton
          text="Write Comment"
          color="gray"
          variant="inline"
          onClick={props.onWriteComment}
        />
      )}
    </CommentsListContainer>
  )
}
