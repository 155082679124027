import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckIcon,
  EyeNoneIcon,
  MagnifyingGlassIcon,
  Pencil2Icon,
  PlusIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {Text} from "@summtech/flok-base/components/Text"
import {Tooltip} from "@summtech/flok-base/components/Tooltip"
import {css, styled, theme} from "@summtech/flok-base/stitches.config"
import {Table, VisibilityState, flexRender} from "@tanstack/react-table"
import {Link} from "react-router-dom"
import {RetreatAttendeeModel} from "../../../models/retreat"
import AppScrollSyncTable from "../AppScrollSyncTable"
import {
  StyledSortIconCss,
  StyledTableHeaderCell,
  TableFooter,
} from "./AttendeesTable"
import {StyledTableHead} from "./ShareableAttendeesTable"

const StyledCellCss = css({
  maxWidth: "$$width",
  minWidth: "$$width",
  borderBottom: `solid 1px ${theme.colors.gray6}`,
  "& *": {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  variants: {
    gridLines: {
      true: {
        "&:not(:last-child)": {
          borderRight: `solid 1px ${theme.colors.gray6}`,
        },
      },
    },
  },
})

const DisabledDropdownWrapper = styled("div", {
  "&:active": {
    pointerEvents: "none",
  },
})
const StyledTableBodyRow = styled("tr", {
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.colors.gray2,
  },
})
const StyledTableDataCell = styled("td", StyledCellCss, {
  "& a": {
    display: "block",
    padding: "10px",
  },
})
const StyledTableContainer = styled("div", {
  width: "100%",
  overflow: "hidden",
  heigth: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
})

export default function ManageAttendeesTable(props: {
  getAttendeeRedirect: (attendeeId: number) => string
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
  table: Table<RetreatAttendeeModel>
  resetSearch: () => void
  onEditColumn: (columnId: string) => void
  addColumn?: boolean
  onAddColumn?: () => void
}) {
  return (
    <StyledTableContainer>
      <AppScrollSyncTable>
        <AppScrollSyncTable.Header className={StyledTableHead()}>
          <tr>
            {props.table.getHeaderGroups().map((headerGroup) =>
              headerGroup.headers
                .filter((header) => header.depth === 1)
                .map((header) => (
                  <Dropdown
                    key={header.id}
                    button={
                      <StyledTableHeaderCell
                        colSpan={header.colSpan}
                        gridLines
                        css={{
                          $$width: `${header.column.columnDef.size}px`,
                          cursor: header.column.getCanSort()
                            ? "pointer"
                            : "inherit",
                        }}>
                        <span>
                          <Text variant="text-sm-plus">
                            {header.isPlaceholder
                              ? "\u00A0"
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </Text>
                          {header.column.getCanSort() &&
                          header.column.getIsSorted() === "asc" ? (
                            <TriangleUpIcon className={StyledSortIconCss()} />
                          ) : header.column.getIsSorted() === "desc" ? (
                            <TriangleDownIcon className={StyledSortIconCss()} />
                          ) : (
                            <></>
                          )}
                        </span>
                      </StyledTableHeaderCell>
                    }>
                    {header.column.getCanSort() && (
                      <DropdownItem
                        startIcon={<ArrowUpIcon />}
                        text={"Sort Ascending"}
                        endIcon={
                          header.column.getIsSorted() === "asc" ? (
                            <CheckIcon />
                          ) : undefined
                        }
                        onClick={
                          header.column.getIsSorted() === "asc"
                            ? () => header.column.clearSorting()
                            : () => header.column.toggleSorting(false)
                        }
                      />
                    )}
                    {header.column.getCanSort() && (
                      <DropdownItem
                        startIcon={<ArrowDownIcon />}
                        text={"Sort Descending"}
                        endIcon={
                          header.column.getIsSorted() === "desc" ? (
                            <CheckIcon />
                          ) : undefined
                        }
                        onClick={
                          header.column.getIsSorted() === "desc"
                            ? () => header.column.clearSorting()
                            : () => header.column.toggleSorting(true)
                        }
                      />
                    )}
                    {!isNaN(parseInt(header.column.id)) ? (
                      <DropdownItem
                        startIcon={<Pencil2Icon />}
                        text="Edit Column"
                        onClick={() => {
                          props.onEditColumn(header.column.id)
                        }}
                      />
                    ) : (
                      <Tooltip text="This column cannot be edited">
                        <DisabledDropdownWrapper>
                          <DropdownItem
                            startIcon={<Pencil2Icon />}
                            text="Edit Column"
                          />
                        </DisabledDropdownWrapper>
                      </Tooltip>
                    )}

                    <DropdownItem
                      startIcon={<EyeNoneIcon />}
                      text="Hide Column"
                      onClick={() => {
                        props.setColumnVisibility((state) => {
                          return {...state, [header.column.id]: false}
                        })
                      }}
                    />
                  </Dropdown>
                ))
            )}
            {props.addColumn && (
              <StyledTableHeaderCell
                onClick={props.onAddColumn}
                gridLines
                css={{
                  $$width: `100px`,
                  cursor: "pointer",
                }}>
                <span>
                  <PlusIcon />
                </span>
              </StyledTableHeaderCell>
            )}
          </tr>
        </AppScrollSyncTable.Header>
        <AppScrollSyncTable.Body>
          {props.table.getRowModel().rows.map((row) => (
            <StyledTableBodyRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <StyledTableDataCell
                  gridLines
                  css={{$$width: `${cell.column.columnDef.size}px`}}>
                  <Link
                    to={props.getAttendeeRedirect(row.original.id)}
                    style={{textDecoration: "none", color: "inherit"}}>
                    <Text variant="text-sm">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Text>
                  </Link>
                </StyledTableDataCell>
              ))}
              {props.addColumn && (
                <StyledTableDataCell
                  onClick={props.onAddColumn}
                  gridLines></StyledTableDataCell>
              )}
            </StyledTableBodyRow>
          ))}
          {props.table.getRowModel().rows.length === 0 &&
            props.table.getPreFilteredRowModel().rows.length > 0 && (
              <NoAttendeesFoundView resetSearch={props.resetSearch} />
            )}
        </AppScrollSyncTable.Body>
        <AppScrollSyncTable.Footer>
          <TableFooter
            absolute
            rowCount={props.table.getFilteredRowModel().rows.length}
            currentPage={props.table.getState().pagination.pageIndex + 1}
            pageCount={props.table.getPageCount()}
            pageSize={props.table.getState().pagination.pageSize}
            onNextPage={
              props.table.getCanNextPage() ? props.table.nextPage : undefined
            }
            onPreviousPage={
              props.table.getCanPreviousPage()
                ? props.table.previousPage
                : undefined
            }
          />
        </AppScrollSyncTable.Footer>
      </AppScrollSyncTable>
    </StyledTableContainer>
  )
}

const NoAttendeesFoundContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "32px 0px",
  width: "100%",
})

const IconContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "6px 8px",
  background: theme.colors.gray3,
  marginBottom: "12px",
  borderRadius: theme.shape.borderRadius,
})
const StyledSearchIcon = styled(MagnifyingGlassIcon, {
  color: theme.colors.gray11,
})
const TextContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  marginBottom: "20px",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
})
function NoAttendeesFoundView(props: {resetSearch: () => void}) {
  return (
    <tr>
      <td colSpan={1000}>
        <NoAttendeesFoundContainer>
          <IconContainer>
            <StyledSearchIcon />
          </IconContainer>
          <TextContainer>
            <Text variant={"text-sm-plus"}>No attendees found</Text>
            <Text
              variant={"text-sm"}
              css={{
                color: theme.colors.gray11,
              }}>
              We couldn't find any attendees given the current search parameters
            </Text>
          </TextContainer>
          <Button
            text={"Reset Search"}
            onClick={props.resetSearch}
            variant="outline"
            color="gray"
          />
        </NoAttendeesFoundContainer>
      </td>
    </tr>
  )
}
