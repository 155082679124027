import ProposalsListPageBody from "../../../components/lodging/ProposalsListPageBody"
import PageBody from "../../../components/page/PageBody"
import {useRetreat} from "../../misc/RetreatProvider"

export default function LodgingProposalsPage() {
  // Setup

  // Path and query params
  let [retreat, retreatIdx] = useRetreat()
  return (
    <PageBody appBar>
      <ProposalsListPageBody retreat={retreat} retreatIdx={retreatIdx} />
    </PageBody>
  )
}
