import {Button} from "@summtech/flok-base/components/Button"
import {FormField} from "@summtech/flok-base/components/FormField"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useFormik} from "formik"
import {useDispatch} from "react-redux"
import * as yup from "yup"
import {enqueueSnackbar} from "../../../notistack-lib/actions"
import {useRetreat} from "../../../pages/misc/RetreatProvider"
import {ApiAction} from "../../../store/actions/api"
import {postRetreatAttendees} from "../../../store/actions/retreat"
import AppModal from "../AppModal"

let StyledFormSubheader = styled("div", {
  maxWidth: "380px",
  marginBottom: "5px",
  marginTop: "10px",
  marginInline: "20px",
  color: theme.colors.gray11,
})
let StyledFormFieldContainer = styled("div", {
  marginBlock: "15px",
  marginInline: "20px",
})

type AddAttendeeModalProps = {
  open: boolean
  onClose: () => void
}
export function AddAttendeeModal(props: AddAttendeeModalProps) {
  let {open, onClose} = props
  let [retreat, retreatIdx] = useRetreat()
  let dispatch = useDispatch()
  let formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
    validationSchema: yup.object({
      first_name: yup.string().required("This field is required"),
      last_name: yup.string().required("This field is required"),
      email: yup.string().required("This field is required"),
    }),
    onSubmit: async (values, helpers) => {
      let response = (await dispatch(
        postRetreatAttendees(
          retreat.id,
          values.first_name,
          values.last_name,
          values.email
        )
      )) as unknown as ApiAction
      if (!response.error) {
        helpers.resetForm()
        onClose()
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Oops, something went wrong",
            options: {
              variant: "error",
            },
          })
        )
      }
    },
  })
  return (
    <AppModal open={open} onClose={onClose}>
      <AppModal.Header>Add New Attendee</AppModal.Header>
      <AppModal.Body>
        <StyledFormSubheader>
          <Text variant="text-sm">
            To add a new attendee to your retreat please enter their full name
            and email address below. They will be automatically emailed to
            access their attendee registration form.
          </Text>
        </StyledFormSubheader>
        <StyledFormFieldContainer>
          <FormField
            type="textfield"
            label="First Name"
            placeholder="First Name"
            id="first_name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            fullWidth
          />
          <FormField
            type="textfield"
            label="Last Name"
            placeholder="Last Name"
            id="last_name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            fullWidth
          />
          <FormField
            type="textfield"
            label="Email Address"
            placeholder="Email Address"
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            fullWidth
          />
        </StyledFormFieldContainer>
      </AppModal.Body>
      <AppModal.Footer>
        <Button
          text={"Cancel"}
          variant={"outline"}
          color={"brand"}
          onClick={onClose}
        />
        <Button
          text={"Add Attendee"}
          variant={"solid"}
          color={"brand"}
          onClick={() => formik.handleSubmit()}
        />
      </AppModal.Footer>
    </AppModal>
  )
}
