import {Action} from "redux"
import {TaskTemplateGroupModel} from "../../models/task"
import {
  APPLY_TASK_TEMPLATE_GROUP_SUCCESS,
  GET_TASK_TEMPLATE_GROUPS_SUCCESS,
} from "../actions/task"

export type TaskState = {
  taskTemplateGroups: {
    [key: number]: TaskTemplateGroupModel
  }
}

const initialState: TaskState = {
  taskTemplateGroups: {},
}

export default function taskReducer(
  state: TaskState = initialState,
  action: Action
): TaskState {
  var payload
  switch (action.type) {
    case GET_TASK_TEMPLATE_GROUPS_SUCCESS:
      payload = (action as any).payload as {
        task_template_groups: TaskTemplateGroupModel[]
      }
      return {
        ...state,
        taskTemplateGroups: {
          ...payload.task_template_groups.reduce(
            (prev, curr) => ({...prev, [curr.id]: curr}),
            state.taskTemplateGroups
          ),
        },
      }
    case APPLY_TASK_TEMPLATE_GROUP_SUCCESS:
    default:
      return state
  }
}
