import {
  ArchiveIcon,
  CheckCircledIcon,
  DotsVerticalIcon,
  GearIcon,
} from "@radix-ui/react-icons"
import {Avatar} from "@summtech/flok-base/components/Avatar"
import {Badge} from "@summtech/flok-base/components/Badge"
import {Button} from "@summtech/flok-base/components/Button"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {FormField} from "@summtech/flok-base/components/FormField"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import SearchBar from "@summtech/flok-base/components/SearchBar"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {TableOptions, createColumnHelper} from "@tanstack/table-core"
import {push} from "connected-react-router"
import {useFormik} from "formik"
import {useState} from "react"
import {useDispatch} from "react-redux"
import {Route, Switch} from "react-router-dom"
import {AppRoutes} from "../../Stack"
import {getHotels} from "../../api/lodging"
import {postRetreat} from "../../api/retreat"
import {
  getUserHome,
  patchUser,
  patchUserToRetreat,
  postUser,
} from "../../api/user"
import PageBody from "../../components/page/PageBody"
import {HotelModel} from "../../models/lodging"
import {RetreatModel} from "../../models/retreat"
import {getRetreatName} from "../../utils/retreatUtils"
import AppModal from "../app/AppModal"
import AppReactTable, {
  StyledRow,
  StyledTableDataCell,
} from "../app/common/AppReactTable"

function dateFormat(date: Date | undefined, noYear?: boolean) {
  if (date === undefined) {
    return ""
  }
  let dateFormatter = Intl.DateTimeFormat(undefined, {
    dateStyle: "medium",
    timeZone: "UTC",
  })
  return dateFormatter.format(date)
}

let MainBody = styled("div", {
  backgroundColor: theme.colors.white,
  display: "flex",
  flexDirection: "column",
  flex: 1,
  paddingInline: "20px",
  paddingTop: "30px",
  overflow: "hidden",
  minWidth: "100%",
})

let StyledHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "30px",
})
let StyledHeaderActions = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
})
let StyledSearchBar = styled(SearchBar, {
  width: "240px",
})
const NameContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  padding: "8px 12px",
})
export default function EventsPage() {
  let [searchString, setSearchString] = useState("")
  let [showArchived, setShowArchived] = useState(false)
  let dispatch = useDispatch()
  let queryClient = useQueryClient()
  let userQuery = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserHome(),
  })
  let user = userQuery.isSuccess ? userQuery.data.user : undefined
  let patchUserMutation = useMutation({
    mutationFn: (variables: {default_retreat_id: number}) =>
      patchUser(user?.id as number, variables),
    onSuccess: (data) => {
      queryClient.setQueryData(["user"], data)
    },
  })
  let patchUserToRetreatMutation = useMutation({
    mutationFn: (variables: {archived: boolean; retreatId: number}) =>
      patchUserToRetreat(user?.id as number, variables.retreatId, {
        archived: variables.archived,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      })
    },
  })
  let hotelIds = user?.users_retreats
    .map((r) => r.retreat.lodging_final_hotel_id)
    .filter((id) => id) as number[]
  let hotelsQuery = useQuery({
    enabled: !!hotelIds?.length,
    queryKey: [
      "hotels",
      {
        hotel_ids: hotelIds,
      },
    ],
    queryFn: () => getHotels(hotelIds),
  })
  let hotelsMap: {[key: number]: HotelModel} = hotelsQuery.isSuccess
    ? hotelsQuery.data.hotels.reduce((prev, hotel) => {
        return {...prev, [hotel.id]: hotel}
      }, {})
    : {}

  let columnHelper = createColumnHelper<Partial<RetreatModel>>()
  let options: TableOptions<Partial<RetreatModel>> = {
    defaultColumn: {
      enableGlobalFilter: false,
      size: undefined,
    },
    state: {
      globalFilter: searchString,
    },
    globalFilterFn: "includesString",
    columns: [
      columnHelper.accessor("company_name", {
        header: "Name",
        enableGlobalFilter: true,
        size: 300,
        cell: (props) => {
          let userToRetreat = user?.users_retreats.find((userRetreat) => {
            return userRetreat.retreat.id === props.row.original.id
          })
          let archived = userToRetreat?.archived
          return (
            <NameContainer>
              <Avatar
                label={getRetreatName(props.row.original as RetreatModel)[0]}
                color={"blue"}
              />
              <Text variant={"text-sm-plus"}>
                {getRetreatName(props.row.original as RetreatModel)}
              </Text>
              {props.row.original.id === user?.default_retreat_id && (
                <Badge label="Default" />
              )}
              {archived && <Badge label="Archived" />}
            </NameContainer>
          )
        },
      }),
      columnHelper.accessor("lodging_final_start_date", {
        sortingFn: (rowA, rowB) => {
          return (
            (rowA.original.lodging_final_start_date
              ? new Date(rowA.original.lodging_final_start_date).getTime()
              : 0) -
            (rowB.original.lodging_final_start_date
              ? new Date(rowB.original.lodging_final_start_date).getTime()
              : 0)
          )
        },
        header: "Dates",
        enableColumnFilter: true,
        enableSorting: true,
        size: 300,
        cell: (context) =>
          context.row.original.lodging_final_start_date &&
          context.row.original.lodging_final_end_date ? (
            <Text css={{color: theme.colors.gray11}} variant="text-sm">
              {dateFormat(
                new Date(context.row.original.lodging_final_start_date)
              )}{" "}
              -{" "}
              {dateFormat(
                new Date(context.row.original.lodging_final_end_date)
              )}
            </Text>
          ) : (
            <Text css={{color: theme.colors.gray11}} variant="text-sm">
              -
            </Text>
          ),
      }),
      columnHelper.display({
        id: "actions",
        header: "Lodging",
        size: 300,
        cell: (context) =>
          context.row.original.lodging_final_hotel_id &&
          hotelsMap[context.row.original.lodging_final_hotel_id] ? (
            <Text css={{color: theme.colors.gray11}} variant="text-sm">
              {hotelsMap[context.row.original.lodging_final_hotel_id].name}
            </Text>
          ) : (
            <Text css={{color: theme.colors.gray11}} variant="text-sm">
              -
            </Text>
          ),
      }),
      columnHelper.accessor("lodging_final_start_date", {
        sortingFn: (rowA, rowB) => {
          return (
            new Date(rowA.original.created_at as string).getTime() -
            new Date(rowB.original.created_at as string).getTime()
          )
        },
        header: "Created",
        enableColumnFilter: true,
        enableSorting: true,
        size: 300,
        cell: (context) => (
          <Text css={{color: theme.colors.gray11}} variant="text-sm">
            {dateFormat(new Date(context.row.original.created_at as string))}
          </Text>
        ),
      }),
      columnHelper.display({
        id: "actions",
        size: 100,
        cell: (context) => {
          let userToRetreat = user?.users_retreats.find((userRetreat) => {
            return userRetreat.retreat.id === context.row.original.id
          })
          let archived = userToRetreat?.archived
          return (
            <div
              onClick={(e) => {
                e.stopPropagation()
              }}
              style={{
                marginLeft: "auto",
                display: "flex",
                justifyContent: "flex-end",
              }}>
              <EventActionsCell
                archived={!!archived}
                onArchive={() => {
                  patchUserToRetreatMutation.mutate({
                    archived: !archived,
                    retreatId: context.row.original.id as number,
                  })
                }}
                onDefault={() => {
                  patchUserMutation.mutate({
                    default_retreat_id: context.row.original.id as number,
                  })
                }}
                onSettings={() => {
                  let idx = user!.retreats.findIndex(
                    (retreat) => retreat.id === context.row.original.id
                  )
                  dispatch(
                    push(
                      AppRoutes.getPath("RetreatSettingsPage", {
                        retreatIdx: idx.toString(),
                      })
                    )
                  )
                }}
              />
            </div>
          )
        },
      }),
    ],
    data:
      (user?.users_retreats
        .filter((userRetreat) => {
          if (showArchived) {
            return true
          } else {
            return (
              !userRetreat.archived ||
              userRetreat.retreat.id === user!.default_retreat_id
            )
          }
        })
        .sort((urA, urB) => {
          if (urB.retreat.id === user!.default_retreat_id) {
            return 1
          } else if (urA.retreat.id === user!.default_retreat_id) {
            return -1
          } else if (!urB.archived && urA.archived) {
            return 1
          } else if (urB.archived === urA.archived) {
            return 0
          } else {
            return -1
          }
        })
        .map((userRetreat) => {
          return userRetreat.retreat
        }) as Partial<RetreatModel>[]) ?? [],
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  }
  const table = useReactTable(options)

  let postUserMutation = useMutation({
    mutationFn: (variables: {retreat_id: number}) =>
      postUser({
        retreat_id: variables.retreat_id,
        first_name: user?.first_name ?? "",
        last_name: user?.last_name ?? "",
        email: user?.email ?? "",
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(["user"], {
        user: data.user,
      })
      dispatch(push(AppRoutes.getPath("EventsPage")))
    },
  })

  let postRetreatMutation = useMutation({
    mutationFn: (variables: {retreat_name: string}) =>
      postRetreat(variables.retreat_name),
    onSuccess: (data) => {
      postUserMutation.mutate({retreat_id: data.retreat.id})
    },
  })

  let formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: (values, helpers) => {
      postRetreatMutation.mutate({retreat_name: values.name})
      helpers.resetForm()
    },
    enableReinitialize: true,
  })
  return (
    <PageBody>
      <MainBody>
        <StyledHeader>
          <Text variant={"heading-lg"}>Events</Text>
          <StyledHeaderActions>
            <StyledSearchBar
              onChange={(e) => setSearchString(e.target.value)}
            />
            <Button
              text="New Event"
              variant={"solid"}
              color={"brand"}
              onClick={() => {
                dispatch(push(AppRoutes.getPath("CreateEventPage")))
              }}
            />
          </StyledHeaderActions>
        </StyledHeader>
        {user && (
          <AppReactTable>
            <AppReactTable.Header>
              <tr>
                {table.getHeaderGroups().map((headerGroup) =>
                  headerGroup.headers
                    .filter((header) => header.depth === 1)
                    .map((header) => {
                      return (
                        <AppReactTable.HeaderCell
                          header={header}
                          key={header.id}
                        />
                      )
                    })
                )}
              </tr>
            </AppReactTable.Header>
            <AppReactTable.Body>
              {table.getRowModel().rows.map((row) => {
                return (
                  <AppReactTable.Row
                    key={row.original.id}
                    row={row}
                    onClick={() => {
                      let idx = user!.retreats.findIndex(
                        (retreat) => retreat.id === row.original.id
                      )
                      dispatch(
                        push(
                          AppRoutes.getPath("RetreatHomePage", {
                            retreatIdx: idx.toString(),
                          })
                        )
                      )
                    }}
                  />
                )
              })}
              <StyledRow
                clickable
                onClick={() => {
                  setShowArchived((shown) => !shown)
                }}>
                <StyledTableDataCell colSpan={1000}>
                  <div style={{padding: "8px 12px"}}>
                    <Text variant={"text-sm-plus"}>
                      {showArchived ? "Hide" : "Show"} Archived Events
                    </Text>
                  </div>
                </StyledTableDataCell>
              </StyledRow>
            </AppReactTable.Body>
          </AppReactTable>
        )}
        <Switch>
          <Route path={[AppRoutes.getPath("CreateEventPage")]} exact>
            <AppModal
              open={true}
              onClose={() => {
                dispatch(push(AppRoutes.getPath("EventsPage")))
              }}>
              <AppModal.Header>Create New Event</AppModal.Header>
              <AppModal.Body>
                <FormField
                  type="textfield"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  id="name"
                  label="Name"
                />
              </AppModal.Body>
              <AppModal.Footer>
                <Button
                  text="Create Event"
                  variant="solid"
                  color="brand"
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                />
              </AppModal.Footer>
            </AppModal>
          </Route>
        </Switch>
      </MainBody>
    </PageBody>
  )
}

function EventActionsCell(props: {
  onDefault: () => void
  onArchive: () => void
  onSettings: () => void
  archived: boolean
}) {
  return (
    <>
      <Dropdown
        button={
          <IconButton className={"actions-trigger"} size="sm" variant="solid">
            <DotsVerticalIcon />
          </IconButton>
        }>
        <DropdownItem
          startIcon={<CheckCircledIcon />}
          text="Make Default"
          onClick={(e) => {
            e.stopPropagation()
            props.onDefault()
          }}
        />
        <DropdownItem
          startIcon={<GearIcon />}
          text="Event Settings"
          onClick={(e) => {
            e.stopPropagation()
            props.onSettings()
          }}
        />
        <DropdownItem
          css={{borderTop: "1px solid $gray7"}}
          startIcon={<ArchiveIcon />}
          text={props.archived ? "Unarchive" : "Archive"}
          onClick={(e) => {
            e.stopPropagation()
            props.onArchive()
          }}
        />
      </Dropdown>
    </>
  )
}
