import PageBody from "../../components/page/PageBody"
import PageContainer from "../../components/page/PageContainer"
import CenterMessage from "../app/common/CenterMessage"

export default function OopsPage() {
  return (
    <PageContainer>
      <PageBody>
        <CenterMessage css={{margin: "auto", textAlign: "center"}}>
          <CenterMessage.Body>
            <CenterMessage.Title variant="title-5xl">Oops!</CenterMessage.Title>
            <CenterMessage.Description>
              It looks like something went wrong. <br />
              Refresh the page or check back again later.
            </CenterMessage.Description>
          </CenterMessage.Body>
        </CenterMessage>
      </PageBody>
    </PageContainer>
  )
}
