import {QueryClient} from "@tanstack/react-query"
import axios, {AxiosRequestConfig} from "axios"
import Pqueue from "p-queue/dist"
import config, {APP_VERSION_KEY, SERVER_BASE_URL_KEY} from "../config"

const FIVE_MINUTES_IN_MILLIS = 300000 // 5 minutes in milliseconds
export const queryClient = new QueryClient({
  defaultOptions: {queries: {staleTime: FIVE_MINUTES_IN_MILLIS}},
})
// All flokFetch calls will be added to the queue so that we don't spam our API with too many api calls
const networkQueue = new Pqueue({concurrency: 30})

// AXIOS setup
let api = axios.create({
  baseURL: config.get(SERVER_BASE_URL_KEY),
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "x-flok-user-agent": `Flok Web/${config.get(APP_VERSION_KEY)}`,
  },
})

export function flokAxios<Response>(
  axiosConfig: AxiosRequestConfig
): Promise<Response> {
  let options = axiosConfig.method !== "GET" ? {priority: 100} : {}
  return networkQueue.add<Response>(
    () => api.request<Response>(axiosConfig).then((resp) => resp.data),
    options
  ) as Promise<Response>
}

// https://stackoverflow.com/questions/48011353/how-to-unwrap-the-type-of-a-promise
// This can be used so that we can do something like Awaited<ReturnType typeof flokAxios<{test: string}>> and we'd get the type {test: string}.
// This is useful because we define the response types of api calls inline when we call flokAxios
export type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T
