import {Pencil1Icon} from "@radix-ui/react-icons"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {ItineraryEventLabel, LabelToColor} from "../../models/retreat"

const EventContainer = styled("div", {
  height: "$$height",
  top: "$$top",
  width: "$$width",
  background: theme.colors.gray3,
  marginLeft: "$$marginLeft",
  position: "absolute",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  gap: "2px",
  padding: "4px 6px",
  cursor: "pointer",
  outline: `1px solid ${theme.colors.white}`,
  "&:hover": {
    background: theme.colors.gray6,
    height: "unset",
    minHeight: "$$height",
  },
  overflow: "auto",
  variants: {
    relative: {
      true: {
        position: "relative",
      },
      false: {},
    },
    label: {
      TRANSPORTATION: {
        background: LabelToColor["TRANSPORTATION"].main,
        "&:hover": {
          background: LabelToColor["TRANSPORTATION"].dark,
        },
      },
      MEAL: {
        background: LabelToColor["MEAL"].main,
        "&:hover": {
          background: LabelToColor["MEAL"].dark,
        },
      },
      ACTIVITY: {
        background: LabelToColor["ACTIVITY"].main,
        "&:hover": {
          background: LabelToColor["ACTIVITY"].dark,
        },
      },
      MEETING: {
        background: LabelToColor["MEETING"].main,
        "&:hover": {
          background: LabelToColor["MEETING"].dark,
        },
      },
    },
  },
})

const TitleContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  overFlow: "hidden",
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

export default function CalendarEvent(props: {
  height: string
  title: string
  top: string
  location?: string
  commentNumber?: number
  onClick?: () => void
  marginLeft?: number
  width: number
  relative?: boolean
  label?: ItineraryEventLabel
}) {
  return (
    <EventContainer
      label={props.label}
      relative={props.relative}
      css={{
        $$height: props.height,
        $$top: props.top,
        $$width: `${props.width}px`,
        $$marginLeft: `${props.marginLeft}px`,
      }}
      onClick={props.onClick}>
      <TitleContainer>
        <Text variant={"text-sm-plus"}>{props.title}</Text>
        {props.commentNumber && (
          <>
            <Pencil1Icon style={{color: `${theme.colors.gray11}`}} />
            <GrayText variant="text-sm">{props.commentNumber}</GrayText>
          </>
        )}
      </TitleContainer>
      <GrayText variant={"text-sm-plus"}>{props.location}</GrayText>
    </EventContainer>
  )
}
