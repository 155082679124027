import {TaskModel} from "../../models/task"
import {createApiAction} from "./api"

export const POST_FILE_TO_TASK_REQUEST = "POST_FILE_TO_TASK_REQUEST"
export const POST_FILE_TO_TASK_SUCCESS = "POST_FILE_TO_TASK_SUCCESS"
export const POST_FILE_TO_TASK_FAILURE = "POST_FILE_TO_TASK_FAILURE"
export function postFileToTask(values: {task_id: number; file_id: number}) {
  let endpoint = `/v1.0/task-files`
  return createApiAction(
    {
      method: "POST",
      endpoint,
      body: JSON.stringify(values),
      types: [
        {type: POST_FILE_TO_TASK_REQUEST},
        {type: POST_FILE_TO_TASK_SUCCESS},
        {type: POST_FILE_TO_TASK_FAILURE},
      ],
    },
    {
      errorMessage: "Something went wrong.",
    }
  )
}

export const DELETE_FILE_TO_TASK_REQUEST = "DELETE_FILE_TO_TASK_REQUEST"
export const DELETE_FILE_TO_TASK_SUCCESS = "DELETE_FILE_TO_TASK_SUCCESS"
export const DELETE_FILE_TO_TASK_FAILURE = "DELETE_FILE_TO_TASK_FAILURE"
export function deleteFileToTask(fileId: number, taskId: number) {
  let endpoint = `/v1.0/retreat-tasks/${taskId}/files/${fileId}`
  return createApiAction({
    method: "DELETE",
    endpoint,
    types: [
      {type: DELETE_FILE_TO_TASK_REQUEST, meta: {fileId, taskId}},
      {type: DELETE_FILE_TO_TASK_SUCCESS, meta: {fileId, taskId}},
      {type: DELETE_FILE_TO_TASK_FAILURE, meta: {fileId, taskId}},
    ],
  })
}

export const POST_ASSIGNEE_TO_TASK_REQUEST = "POST_ASSIGNEE_TO_TASK_REQUEST"
export const POST_ASSIGNEE_TO_TASK_SUCCESS = "POST_ASSIGNEE_TO_TASK_SUCCESS"
export const POST_ASSIGNEE_TO_TASK_FAILURE = "POST_ASSIGNEE_TO_TASK_FAILURE"
export function postAssigneeToTask(values: {task_id: number; user_id: number}) {
  let endpoint = `/v1.0/retreat-tasks/${values.task_id}/assignees`
  return createApiAction(
    {
      method: "POST",
      endpoint,
      body: JSON.stringify(values),
      types: [
        {type: POST_ASSIGNEE_TO_TASK_REQUEST},
        {type: POST_ASSIGNEE_TO_TASK_SUCCESS},
        {type: POST_ASSIGNEE_TO_TASK_FAILURE},
      ],
    },
    {
      errorMessage: "Something went wrong.",
    }
  )
}

export const DELETE_ASSIGNEE_TO_TASK_REQUEST = "DELETE_ASSIGNEE_TO_TASK_REQUEST"
export const DELETE_ASSIGNEE_TO_TASK_SUCCESS = "DELETE_ASSIGNEE_TO_TASK_SUCCESS"
export const DELETE_ASSIGNEE_TO_TASK_FAILURE = "DELETE_ASSIGNEE_TO_TASK_FAILURE"
export function deleteAssigneeToTask(values: {
  task_id: number
  user_id: number
}) {
  let endpoint = `/v1.0/retreat-tasks/${values.task_id}/assignees`
  return createApiAction(
    {
      method: "DELETE",
      endpoint,
      body: JSON.stringify(values),
      types: [
        {type: DELETE_ASSIGNEE_TO_TASK_REQUEST},
        {type: DELETE_ASSIGNEE_TO_TASK_SUCCESS},
        {type: DELETE_ASSIGNEE_TO_TASK_FAILURE},
      ],
    },
    {
      errorMessage: "Something went wrong.",
    }
  )
}

export const GET_LABEL_REQUEST = "GET_LABEL_REQUEST"
export const GET_LABEL_SUCCESS = "GET_LABEL_SUCCESS"
export const GET_LABEL_FAILURE = "GET_LABEL_FAILURE"
export function getLabel(labelId: number) {
  let endpoint = `/v1.0/labels/${labelId}`
  return createApiAction({
    method: "GET",
    endpoint,
    types: [
      {type: GET_LABEL_REQUEST},
      {type: GET_LABEL_SUCCESS},
      {type: GET_LABEL_FAILURE},
    ],
  })
}

export const POST_LABEL_TO_TASK_REQUEST = "POST_LABEL_TO_TASK_REQUEST"
export const POST_LABEL_TO_TASK_SUCCESS = "POST_LABEL_TO_TASK_SUCCESS"
export const POST_LABEL_TO_TASK_FAILURE = "POST_LABEL_TO_TASK_FAILURE"
export function postLabelToTask(values: {task_id: number; label_id: number}) {
  let endpoint = `/v1.0/task-labels`
  return createApiAction(
    {
      method: "POST",
      endpoint,
      body: JSON.stringify(values),
      types: [
        {type: POST_LABEL_TO_TASK_REQUEST},
        {type: POST_LABEL_TO_TASK_SUCCESS},
        {type: POST_LABEL_TO_TASK_FAILURE},
      ],
    },
    {
      errorMessage: "Something went wrong.",
    }
  )
}

export const POST_LABEL_REQUEST = "POST_LABEL_REQUEST"
export const POST_LABEL_SUCCESS = "POST_LABEL_SUCCESS"
export const POST_LABEL_FAILURE = "POST_LABEL_FAILURE"
export function postLabel(values: {
  text: string
  color?: string
  retreat_id: number
}) {
  let endpoint = `/v1.0/labels`
  return createApiAction(
    {
      method: "POST",
      endpoint,
      body: JSON.stringify(values),
      types: [
        {type: POST_LABEL_REQUEST},
        {type: POST_LABEL_SUCCESS},
        {type: POST_LABEL_FAILURE},
      ],
    },
    {
      errorMessage: "Something went wrong.",
    }
  )
}

export const DELETE_LABEL_TO_TASK_REQUEST = "DELETE_LABEL_TO_TASK_REQUEST"
export const DELETE_LABEL_TO_TASK_SUCCESS = "DELETE_LABEL_TO_TASK_SUCCESS"
export const DELETE_LABEL_TO_TASK_FAILURE = "DELETE_LABEL_TO_TASK_FAILURE"
export function deleteLabelToTask(values: {taskId: number; labelId: number}) {
  let endpoint = `/v1.0/retreat-tasks/${values.taskId}/labels/${values.labelId}`
  return createApiAction(
    {
      method: "DELETE",
      endpoint,
      body: JSON.stringify(values),
      types: [
        {
          type: DELETE_LABEL_TO_TASK_REQUEST,
          meta: {labelId: values.labelId, taskId: values.taskId},
        },
        {
          type: DELETE_LABEL_TO_TASK_SUCCESS,
          meta: {labelId: values.labelId, taskId: values.taskId},
        },
        {
          type: DELETE_LABEL_TO_TASK_FAILURE,
          meta: {labelId: values.labelId, taskId: values.taskId},
        },
      ],
    },
    {
      errorMessage: "Something went wrong.",
    }
  )
}

export const POST_TASK_REQUEST = "POST_TASK_REQUEST"
export const POST_TASK_SUCCESS = "POST_TASK_SUCCESS"
export const POST_TASK_FAILURE = "POST_TASK_FAILURE"
export function postTask(values: Partial<TaskModel>, retreat_id: number) {
  let endpoint = `/v1.0/retreat-tasks`
  return createApiAction(
    {
      method: "POST",
      endpoint,
      body: JSON.stringify({...values, retreat_id}),
      types: [
        {type: POST_TASK_REQUEST},
        {type: POST_TASK_SUCCESS},
        {type: POST_TASK_FAILURE},
      ],
    },
    {
      errorMessage: "Something went wrong",
    }
  )
}

export const GET_TASK_REQUEST = "GET_TASK_REQUEST"
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS"
export const GET_TASK_FAILURE = "GET_TASK_FAILURE"

export function getTask(task_id: number) {
  let endpoint = `/v1.0/retreat-tasks/${task_id}`
  return createApiAction(
    {
      method: "GET",
      endpoint,
      types: [
        {type: GET_TASK_REQUEST},
        {type: GET_TASK_SUCCESS},
        {type: GET_TASK_FAILURE},
      ],
    },
    {
      errorMessage: "Something went wrong",
    }
  )
}

export const PATCH_TASK_REQUEST = "PATCH_TASK_REQUEST"
export const PATCH_TASK_SUCCESS = "PATCH_TASK_SUCCESS"
export const PATCH_TASK_FAILURE = "PATCH_TASK_FAILURE"

export function patchTask(values: Partial<TaskModel>, task_id: number) {
  let endpoint = `/v1.0/retreat-tasks/${task_id}`
  return createApiAction(
    {
      method: "PATCH",
      endpoint,
      body: JSON.stringify(values),
      types: [
        {type: PATCH_TASK_REQUEST},
        {type: PATCH_TASK_SUCCESS},
        {type: PATCH_TASK_FAILURE},
      ],
    },
    {
      errorMessage: "Something went wrong",
    }
  )
}

export const DELETE_TASK_REQUEST = "DELETE_TASK_REQUEST"
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS"
export const DELETE_TASK_FAILURE = "DELETE_TASK_FAILURE"
export function deleteTask(taskId: number, retreatId: number) {
  let endpoint = `/v1.0/retreat-tasks/${taskId}`
  return createApiAction({
    method: "DELETE",
    endpoint,
    types: [
      {
        type: DELETE_TASK_REQUEST,
      },
      {
        type: DELETE_TASK_SUCCESS,
        meta: {taskId, retreatId},
      },
      {
        type: DELETE_TASK_FAILURE,
        meta: {taskId, retreatId},
      },
    ],
  })
}

export const GET_TASK_TEMPLATE_GROUPS_REQUEST =
  "GET_TASK_TEMPLATE_GROUPS_REQUEST"
export const GET_TASK_TEMPLATE_GROUPS_SUCCESS =
  "GET_TASK_TEMPLATE_GROUPS_SUCCESS"
export const GET_TASK_TEMPLATE_GROUPS_FAILURE =
  "GET_TASK_TEMPLATE_GROUPS_FAILURE"
export function getTaskTemplateGroups() {
  let endpoint = "/v1.0/task-template-groups"
  return createApiAction({
    method: "GET",
    endpoint,
    types: [
      GET_TASK_TEMPLATE_GROUPS_REQUEST,
      GET_TASK_TEMPLATE_GROUPS_SUCCESS,
      GET_TASK_TEMPLATE_GROUPS_FAILURE,
    ],
  })
}

export const APPLY_TASK_TEMPLATE_GROUP_REQUEST =
  "APPLY_TASK_TEMPLATE_GROUP_REQUEST"
export const APPLY_TASK_TEMPLATE_GROUP_SUCCESS =
  "APPLY_TASK_TEMPLATE_GROUP_SUCCESS"
export const APPLY_TASK_TEMPLATE_GROUP_FAILURE =
  "APPLY_TASK_TEMPLATE_GROUP_FAILURE"
export function applyTaskTemplateGroup(
  retreatId: number,
  taskTemplateGroupId: number
) {
  let endpoint = `/v1.0/retreats/${retreatId}/task-template-groups/apply`
  return createApiAction(
    {
      method: "POST",
      body: JSON.stringify({task_template_group_id: taskTemplateGroupId}),
      endpoint,
      types: [
        APPLY_TASK_TEMPLATE_GROUP_REQUEST,
        APPLY_TASK_TEMPLATE_GROUP_SUCCESS,
        APPLY_TASK_TEMPLATE_GROUP_FAILURE,
      ],
    },
    {
      successMessage: "Succesfully applied template group",
    }
  )
}
