import {FilePicker} from "@summtech/flok-base/components/FilePicker"
import {FormField} from "@summtech/flok-base/components/FormField"
import {useState} from "react"
import {useDispatch} from "react-redux"
import config, {IMAGE_SERVER_BASE_URL_KEY} from "../../config"
import {ImageModel} from "../../models"
import {enqueueSnackbar} from "../../notistack-lib/actions"

type AppImageFilePickerProps = {
  handleChange: (image: ImageModel) => void
  id: string
  handleClear: () => void
  fullWidth?: boolean
  label?: string
  file?: string
}
export default function AppImageFilePicker(props: AppImageFilePickerProps) {
  const [loading, setLoading] = useState(false)
  let dispatch = useDispatch()
  return props.label ? (
    <FormField
      file={props.file}
      type="file"
      label={props.label}
      fullWidth={props.fullWidth}
      accepts={"image/*"}
      id={props.id}
      handleClear={props.handleClear}
      handleChange={(e) => {
        if (e.target && e.target.files && e.target.files[0]) {
          let data = new FormData()
          data.append("file", e.target.files[0])
          setLoading(true)
          fetch(`${config.get(IMAGE_SERVER_BASE_URL_KEY)}/api/images`, {
            body: data,
            method: "POST",
            mode: "cors",
          })
            .then((res) => res.json())
            .then(async (resdata) => {
              await props.handleChange(resdata.image)

              setLoading(false)
            })
            .catch((error) => {
              setLoading(false)
              dispatch(
                enqueueSnackbar({
                  message: "Oops, something went wrong",
                  options: {
                    variant: "error",
                  },
                })
              )
            })
        }
      }}
    />
  ) : (
    <FilePicker
      file={props.file}
      fullWidth={props.fullWidth}
      accepts={"image/*"}
      id={props.id}
      handleClear={props.handleClear}
      handleChange={(e) => {
        if (e.target && e.target.files && e.target.files[0]) {
          let data = new FormData()
          data.append("file", e.target.files[0])
          setLoading(true)
          fetch(`${config.get(IMAGE_SERVER_BASE_URL_KEY)}/api/images`, {
            body: data,
            method: "POST",
            mode: "cors",
          })
            .then((res) => res.json())
            .then(async (resdata) => {
              await props.handleChange(resdata.image)

              setLoading(false)
            })
            .catch((error) => {
              setLoading(false)
              dispatch(
                enqueueSnackbar({
                  message: "Oops, something went wrong",
                  options: {
                    variant: "error",
                  },
                })
              )
            })
        }
      }}
    />
  )
}
