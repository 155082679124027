import {Box, makeStyles} from "@material-ui/core"
import {RetreatModel} from "../../../models/retreat"
import NotFound404Page from "../../../pages/misc/NotFound404Page"
import {useAttendeeLandingPage} from "../../../utils/retreatUtils"
import AppLoadingScreen from "../../base/AppLoadingScreen"
import {AddBlockButton, PageBlock} from "../block/PageBlock"

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 800,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    // space between blocks
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(2),
    },
  },
}))

type SitePageProps = {
  pageId: number
  editable?: boolean
  retreat: RetreatModel
}

function SitePage(props: SitePageProps) {
  let [page, loadingPage] = useAttendeeLandingPage(props.pageId)
  let classes = useStyles()

  return loadingPage ? (
    <AppLoadingScreen />
  ) : !page ? (
    <NotFound404Page />
  ) : (
    <div className={classes.root}>
      {page.block_ids.map((blockId) => (
        <PageBlock
          key={blockId}
          blockId={blockId}
          editable={props.editable}
          retreat={props.retreat}
        />
      ))}
      {props.editable && (
        <Box display="flex" justifyContent="center">
          <AddBlockButton pageId={props.pageId} />
        </Box>
      )}
    </div>
  )
}
export default SitePage
