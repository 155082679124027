import {Cross1Icon} from "@radix-ui/react-icons"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {createContext, PropsWithChildren, useContext} from "react"
import ReactDOM from "react-dom"

const PageBackground = styled("div", {
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  right: 0,
  position: "fixed",
  bottom: 0,
  // TODO, fix this once we have a better idea what our zIndex patterns should be
  zIndex: 1000000000,
  backgroundColor: "rgba(0, 0, 0, 0.56)",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  padding: "64px 32px",
})
const ModalContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.colors.gray6,
  borderStyle: "solid",
  borderWidth: "1px",
  background: theme.colors.white,
  maxHeight: "100%",
  overflow: "hidden",
  maxWidth: "850px",
  variants: {
    fullWidth: {
      true: {
        width: "100%",
      },
    },
  },
})

const ModalFooter = styled("div", {
  display: "flex",
  flexDirection: "row",
  padding: "12px 20px",
  gap: "12px",
  justifyContent: "flex-end",
  borderTopColor: theme.colors.gray6,
  borderTopStyle: "solid",
  borderTopWidth: "1px",
})
const ModalHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  borderBottomColor: theme.colors.gray6,
  borderBottomStyle: "solid",
  borderBottomWidth: "1px",
  padding: "16px 20px",
  justifyContent: "space-between",
  alignItems: "center",
})

const WithPortal = (props: PropsWithChildren<{portal?: boolean}>) => {
  return props.portal ? (
    ReactDOM.createPortal(
      props.children,
      document.getElementsByTagName("body")[0]
    )
  ) : (
    <>{props.children}</>
  )
}

export type AppModalProps = PropsWithChildren<{
  open: boolean
  onClose?: () => void
  noPortal?: boolean
  fullWidth?: boolean
}>

const AppModalBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflowY: "auto",
  minHeight: "0px",
  padding: "16px 20px",
  variants: {
    noPadding: {
      true: {
        padding: "0px",
      },
    },
  },
})
const CloseContext = createContext<(() => void) | undefined>(undefined)

export default function AppModal(props: PropsWithChildren<AppModalProps>) {
  let portal = props.noPortal !== true

  return props.open ? (
    <CloseContext.Provider value={props.onClose}>
      <WithPortal portal={portal}>
        <PageBackground
          onClick={(e) => {
            e.stopPropagation()
            props.onClose && props.onClose()
          }}>
          <ModalContainer
            fullWidth={props.fullWidth}
            onClick={(e) => {
              e.stopPropagation()
            }}>
            {props.children}
          </ModalContainer>
        </PageBackground>
      </WithPortal>
    </CloseContext.Provider>
  ) : (
    <></>
  )
}

function AppModalHeader(props: PropsWithChildren<{}>) {
  const onClose = useContext(CloseContext)
  const isText = typeof props.children === "string"
  return (
    <ModalHeader>
      {isText ? (
        <Text variant={"heading-sm"}>{props.children} </Text>
      ) : (
        props.children
      )}
      {onClose && (
        <IconButton variant="ghost" onClick={onClose}>
          <Cross1Icon />
        </IconButton>
      )}
    </ModalHeader>
  )
}

AppModal.Header = AppModalHeader
AppModal.Body = AppModalBody
AppModal.Footer = ModalFooter
