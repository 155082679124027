import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {RetreatModel} from "../../../models/retreat"
import {useRetreatAttendees} from "../../../utils/attendeeUtils"
import AttendeesFlightsTable from "../AttendeesFlightsTable"

const AttendeesPlaceHolder = styled("div", {
  width: "100%",
  height: "200px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})
export default function FlightsBlock(props: {retreat?: RetreatModel}) {
  let [attendees, loading] = useRetreatAttendees(props.retreat?.id ?? -1)
  return !loading ? (
    <AttendeesFlightsTable attendees={attendees} />
  ) : (
    <AttendeesPlaceHolder>
      <Text>Loading flight info...</Text>
    </AttendeesPlaceHolder>
  )
}
