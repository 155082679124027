import {TrashIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {FormField} from "@summtech/flok-base/components/FormField"
import {Tab} from "@summtech/flok-base/components/Tab"
import {TabContent} from "@summtech/flok-base/components/TabContent"
import {TabsList} from "@summtech/flok-base/components/TabsList"
import {TabsWrapper} from "@summtech/flok-base/components/TabsWrapper"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useFormik} from "formik"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import * as yup from "yup"
import PageBody from "../../components/page/PageBody"
import {UserModel} from "../../models/user"
import {enqueueSnackbar} from "../../notistack-lib/actions"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {RootState} from "../../store"
import {ApiAction} from "../../store/actions/api"
import {
  deleteUser,
  getUser,
  patchRetreat,
  postUser,
} from "../../store/actions/retreat"
import {getRetreatName} from "../../utils/retreatUtils"
import AppModal from "../app/AppModal"
import AppTable from "../app/AppTable"
import ConfirmationModal from "../app/ConfirmationModal"

let MainBody = styled("div", {
  backgroundColor: theme.colors.white,
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingLeft: "20px",
  paddingTop: "30px",
})

let StyledHeader = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  paddingLeft: "20px",
})
let StyledTabListContainer = styled("div", {
  borderBottom: `1px solid ${theme.colors.gray6}`,
})
let StyledForm = styled("form", {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
})
let StyledTabsBody = styled("div", {
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
})
let StyledTabsContainer = styled("div", {
  margin: "20px",
})
let StyledRetreatNameFormField = styled("div", {
  marginTop: "25px",
})
let StyledSaveButton = styled("div", {
  marginBlock: "20px",
})
let StyledUsersHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  marginBlock: "20px",
  flexWrap: "wrap",
  justifyContent: "space-between",
  gap: "10px",
  alignItems: "center",
})

let StyledUsersSubHeaderText = styled(Text, {
  marginTop: "10px",
})

let StyledAddUserForm = styled("form", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "10px",
})
let ModalBody = styled("div", {
  display: "flex",
  minWidth: "650px",
  flexDirection: "column",
  gap: "12px",
})

let StyledAddUserFooter = styled("div", {
  display: "flex",
  flexDirection: "row",
  marginTop: "10px",
  marginLeft: "auto",
  gap: "15px",
})

export default function RetreatSettingsPage() {
  let dispatch = useDispatch()
  let [retreat] = useRetreat()
  let [addUserDialogOpen, setAddUserDialogOpen] = useState(false)

  let [activeTab, setActiveTab] = useState("general")

  // Add new user
  let formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
    onSubmit: async (values, helpers) => {
      let postValues = {...values, retreat_id: retreat.id}
      let response = (await dispatch(
        postUser(postValues, retreat.id)
      )) as unknown as ApiAction
      if (!response.error) {
        helpers.resetForm()
        setAddUserDialogOpen(false)
        dispatch(
          enqueueSnackbar({
            message: "Successfully added user",
            options: {
              variant: "success",
            },
          })
        )
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Oops, something went wrong",
            options: {
              variant: "error",
            },
          })
        )
      }
    },
    validationSchema: yup.object({
      email: yup.string().required().email(),
      first_name: yup.string().required(),
      last_name: yup.string().required(),
    }),
  })

  // Update retreat details
  let formikRetreatDetails = useFormik({
    enableReinitialize: true,
    initialValues: {
      retreat_name: getRetreatName(retreat),
    },
    onSubmit: async (values) => {
      dispatch(patchRetreat(retreat.id, values))
    },
    validationSchema: yup.object({
      retreat_name: yup.string().required(),
    }),
  })

  // Users (add, delete, get)
  let [confirmDeleteUser, setConfirmDeleteUser] = useState<number | undefined>(
    undefined
  )
  async function handleDeleteConfirm() {
    if (confirmDeleteUser != null) {
      let response = (await dispatch(
        deleteUser(confirmDeleteUser, retreat.id)
      )) as unknown as ApiAction
      if (!response.error) {
        setConfirmDeleteUser(undefined)
        dispatch(
          enqueueSnackbar({
            message: "Successfully removed user",
            options: {
              variant: "success",
            },
          })
        )
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Oops, something went wrong",
            options: {
              variant: "error",
            },
          })
        )
      }
    }
  }
  let users = useSelector((state: RootState) => {
    return state.retreat.users
  })

  let [loadingUsers, setLoadingUsers] = useState(false)
  useEffect(() => {
    async function gatherUsers(missingUsers: number[]) {
      setLoadingUsers(true)
      await Promise.all(
        missingUsers.map((userId) => {
          return dispatch(getUser(userId))
        })
      )
      setLoadingUsers(false)
    }
    if (retreat.users) {
      let missingUsers = retreat.users.filter((userId) => users[userId] == null)
      if (missingUsers.length > 0 && !loadingUsers) {
        gatherUsers(missingUsers)
      }
    }
  }, [dispatch, retreat.users, users, loadingUsers])

  useEffect(() => {
    if (addUserDialogOpen === undefined) {
      setAddUserDialogOpen(false)
    }
  }, [addUserDialogOpen])

  return (
    <PageBody appBar>
      <MainBody>
        <StyledHeader>
          <Text variant="title-2xl">Settings</Text>
        </StyledHeader>
        <AppModal open={addUserDialogOpen}>
          <StyledAddUserForm onSubmit={formik.handleSubmit}>
            <AppModal.Header>Add New User</AppModal.Header>
            <AppModal.Body>
              <ModalBody>
                <FormField
                  id="first_name"
                  label="First Name"
                  value={formik.values.first_name}
                  error={!!formik.errors.first_name}
                  onChange={formik.handleChange}
                  fullWidth
                  type="textfield"
                />
                <FormField
                  id="last_name"
                  label="Last Name"
                  value={formik.values.last_name}
                  error={!!formik.errors.last_name}
                  onChange={formik.handleChange}
                  fullWidth
                  type="textfield"
                />
                <FormField
                  id="email"
                  label="Email Address"
                  value={formik.values.email}
                  error={!!formik.errors.email}
                  onChange={formik.handleChange}
                  fullWidth
                  type="textfield"
                />
              </ModalBody>
            </AppModal.Body>
            <AppModal.Footer>
              <StyledAddUserFooter>
                <Button
                  variant="outline"
                  color="brand"
                  onClick={() => setAddUserDialogOpen(false)}
                  text="Cancel"
                />
                <Button
                  type="submit"
                  variant="solid"
                  color="brand"
                  text="Add User"
                />
              </StyledAddUserFooter>
            </AppModal.Footer>
          </StyledAddUserForm>
        </AppModal>
        <StyledTabsContainer>
          <TabsWrapper
            onValueChange={(value) => setActiveTab(value)}
            value={activeTab}>
            <StyledTabListContainer>
              <TabsList>
                <Tab text="General" value="general" />
                <Tab text="Users" value="users" />
              </TabsList>
            </StyledTabListContainer>
            <TabContent value="general">
              <StyledForm onSubmit={formikRetreatDetails.handleSubmit}>
                <StyledRetreatNameFormField>
                  <FormField
                    type={"textfield"}
                    label={"Retreat Name"}
                    value={formikRetreatDetails.values.retreat_name}
                    id="retreat_name"
                    onChange={formikRetreatDetails.handleChange}
                  />
                </StyledRetreatNameFormField>

                <StyledSaveButton>
                  <Button
                    type="submit"
                    text={"Save Changes"}
                    color={"brand"}
                    variant={"solid"}
                  />
                </StyledSaveButton>
              </StyledForm>
            </TabContent>

            <TabContent value="users">
              <StyledTabsBody>
                <StyledUsersHeader>
                  <div>
                    <Text variant="heading-lg">Users</Text>
                    <StyledUsersSubHeaderText variant="text-sm">
                      Manage who can access {retreat.company_name}'s dashboard
                    </StyledUsersSubHeaderText>
                  </div>
                  <div>
                    <Button
                      color={"brand"}
                      variant={"solid"}
                      text={"Add User"}
                      onClick={() => {
                        setAddUserDialogOpen(true)
                      }}
                    />
                  </div>
                </StyledUsersHeader>
                <AppTable
                  columns={[
                    {
                      id: "first_name",
                      name: "First Name",
                    },
                    {
                      id: "last_name",
                      name: "Last Name",
                    },
                    {
                      id: "email",
                      name: "Email",
                    },
                  ]}
                  rows={retreat.users
                    .map((userId) => users[userId] as UserModel)
                    .filter((user) => user)}
                  getRowActions={(row) => (
                    <DropdownItem
                      onClick={() => {
                        setConfirmDeleteUser(row.id)
                      }}
                      startIcon={<TrashIcon fontSize="small" />}
                      text="Delete User"
                    />
                  )}
                />
                <>
                  <ConfirmationModal
                    open={confirmDeleteUser != null}
                    onClose={() => {
                      setConfirmDeleteUser(undefined)
                    }}
                    onConfirm={handleDeleteConfirm}
                    title={`Are you sure you wish to remove this user?`}
                    subtitle="This action cannot be undone."
                  />
                </>
              </StyledTabsBody>
            </TabContent>
          </TabsWrapper>
        </StyledTabsContainer>
      </MainBody>
    </PageBody>
  )
}
