import {CheckIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {FormField} from "@summtech/flok-base/components/FormField"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Tab} from "@summtech/flok-base/components/Tab"
import {TabsList} from "@summtech/flok-base/components/TabsList"
import {TabsWrapper} from "@summtech/flok-base/components/TabsWrapper"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import {useFormik} from "formik"
import {useEffect, useState} from "react"
import {
  deleteAttendeeGroup,
  deleteAttendeeGroupFilter,
  deleteAudienceToAttendee,
  getAttendeeGroup,
  getAttendeeGroupFilter,
  getAttendeesFromFilters,
  getAudience,
  patchAttendeeGroup,
  patchAttendeeGroupFilter,
  patchAudience,
  postAttendeeGroup,
  postAttendeeGroupFilter,
  postAudience,
  postAudienceToAttendee,
} from "../../../api/communication"
import {
  AttendeeGroupFilterModel,
  AttendeeGroupModel,
  AudienceModel,
  LocalFilterModel,
} from "../../../models/communication"
import {RetreatAttendeeModel} from "../../../models/retreat"
import {useRetreatAttendees} from "../../../utils/attendeeUtils"
import {
  AttendeeSelectorTable,
  AudienceFilterBuilder,
  PreviewAttendeesTable,
} from "../../communications/AudienceModal"
import {useRetreatV2} from "../common/RetreatProviderV2"
import SideDrawer, {SideDrawerFooter} from "../SideDrawer"

let StyledHeaderContainer = styled("div", {
  padding: "16px 20px 0px 20px",
  borderBottom: `1px solid ${theme.colors.gray6}`,
})
let StyledForm = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  gap: "20px",
  borderBottom: `1px solid ${theme.colors.gray6}`,
})

let SelectorContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  padding: "16px 20px",
})
export default function UserGroupOverlay(props: {
  open: boolean
  audienceId?: number
  onClose: () => void
}) {
  let [tabValue, setTabValue] = useState("DETAILS")
  let [retreat] = useRetreatV2()
  let [audienceId, setAudienceId] = useState(props.audienceId)
  useEffect(() => {
    setAudienceId(props.audienceId)
  }, [props.audienceId])
  let audienceQuery = useQuery({
    enabled: !isNaN(audienceId ?? NaN),
    queryFn: () => getAudience(audienceId as number),
    queryKey: ["audiences", audienceId],
  })
  let attendeeGroupsQueries = useQueries({
    queries:
      audienceQuery.data?.retreat_audience.attendee_group_ids.map(
        (group_id) => ({
          queryKey: ["attendee-groups", group_id],
          queryFn: () => getAttendeeGroup(group_id),
        })
      ) ?? [],
  })

  let deleteAttendeeGroupFilterMutation = useMutation({
    mutationFn: (variables: {filterId: number}) =>
      deleteAttendeeGroupFilter(variables.filterId),
  })

  let deleteAttendeeGroupMutation = useMutation({
    mutationFn: (variables: {groupId: number}) =>
      deleteAttendeeGroup(variables.groupId),
  })

  let allFiltersQueries = useQueries({
    queries: attendeeGroupsQueries
      .filter((query) => query.isSuccess)
      .flatMap((attendeeGroupQuery) => {
        return attendeeGroupQuery.data!.attendee_group.filter_ids.map(
          (filterId) => ({
            queryFn: () => getAttendeeGroupFilter(filterId),
            queryKey: ["filters", filterId],
          })
        )
      }),
  })
  let postAttendeeGroupFilterMutation = useMutation({
    mutationFn: postAttendeeGroupFilter,
  })
  let patchAttendeeGroupFilterMutation = useMutation({
    mutationFn: (variables: {
      attendeeGroupFilterId: number
      attendeeGroupFilter: Partial<AttendeeGroupFilterModel>
    }) =>
      patchAttendeeGroupFilter(
        variables.attendeeGroupFilterId,
        variables.attendeeGroupFilter
      ),
  })

  let [saved, setSaved] = useState(false)
  let [buttonTimeout, setButtonTimeout] = useState<NodeJS.Timeout | undefined>(
    undefined
  )

  let [filteredAttendeeIds, setFilteredAttendeeIds] = useState<number[]>([])

  let postAttendeeGroupMutation = useMutation({
    mutationFn: (variables: {
      attendeeGroup: Partial<AttendeeGroupModel>
      index: number
    }) => postAttendeeGroup(variables.attendeeGroup),
    onSuccess: (data, variables) => {
      let filtersToCreate = formik.values.filters[variables.index]
      filtersToCreate
        .filter((filter) => !isNaN(parseInt(filter.field)))
        .forEach((filter) => {
          if (filter.id) {
            patchAttendeeGroupFilterMutation.mutate({
              attendeeGroupFilter: {
                column_id: parseInt(filter.field),
                value: filter.value,
                operator_type: filter.operator_type,
              },
              attendeeGroupFilterId: filter.id,
            })
          } else {
            postAttendeeGroupFilterMutation.mutate({
              column_id: parseInt(filter.field),
              value: filter.value,
              operator_type: filter.operator_type,
              attendee_group_id: data.attendee_group.id,
            })
          }
        })
    },
  })
  let patchAttendeeGroupMutation = useMutation({
    mutationFn: (variables: {
      groupId: number
      attendeeGroup: Partial<AttendeeGroupModel>
      index: number
    }) => patchAttendeeGroup(variables.groupId, variables.attendeeGroup),
    onSuccess: (data, variables) => {
      let filtersToCreate = formik.values.filters[variables.index]
      filtersToCreate
        .filter((filter) => !isNaN(parseInt(filter.field)))
        .forEach((filter) => {
          if (filter.id) {
            patchAttendeeGroupFilterMutation.mutate({
              attendeeGroupFilter: {
                column_id: parseInt(filter.field),
                value: filter.value,
                operator_type: filter.operator_type,
              },
              attendeeGroupFilterId: filter.id,
            })
          } else {
            postAttendeeGroupFilterMutation.mutate({
              column_id: parseInt(filter.field),
              value: filter.value,
              operator_type: filter.operator_type,
              attendee_group_id: data.attendee_group.id,
            })
          }
        })
    },
  })

  let postAudienceMutation = useMutation({
    mutationFn: (variables: {audience: Partial<AudienceModel>}) =>
      postAudience(variables.audience),
    onSuccess: async (data) => {
      queryClient.invalidateQueries({
        queryKey: ["retreats", data.retreat_audience.retreat_id],
      })
      if (data.retreat_audience.selection_type === "FILTERS") {
        formik.values.filters.forEach((filterGroup, i) => {
          let groupId = filterGroup[0]?.attendee_group_id
          let defaultFilters = filterGroup.filter((filter) => {
            return isNaN(parseInt(filter.field))
          })
          if (!groupId) {
            postAttendeeGroupMutation.mutate({
              attendeeGroup: {
                audience_id: data.retreat_audience.id,
                default_filters: defaultFilters,
              },
              index: i,
            })
          } else {
            patchAttendeeGroupMutation.mutate({
              groupId: groupId,
              attendeeGroup: {default_filters: defaultFilters},
              index: i,
            })
          }
        })
      } else if (data.retreat_audience.selection_type === "ATTENDEES") {
        let formikAttendees: {[id: number]: boolean} =
          formik.values.attendee_ids.reduce((prev, currId) => {
            return {...prev, [currId]: true}
          }, {})
        let audienceAttendees: {[id: number]: boolean} =
          audience?.attendee_ids.reduce((prev, currId) => {
            return {...prev, [currId]: true}
          }, {}) ?? {}
        let allApiList = [
          ...formik.values.attendee_ids.map((id) => {
            if (!audienceAttendees[id]) {
              return postAudienceToAttendeeMutation.mutateAsync({
                attendeeId: id,
                audienceId: data.retreat_audience.id,
              })
            }
            return undefined
          }),
          ...(audience?.attendee_ids
            ? audience?.attendee_ids.map((id) => {
                if (!formikAttendees[id]) {
                  return deleteAudienceToAttendeeMutation.mutateAsync({
                    attendeeId: id,
                    audienceId: data.retreat_audience.id,
                  })
                }
                return undefined
              })
            : []),
        ]
        await Promise.all(allApiList)
        setAudienceId(data.retreat_audience.id)
      }
    },
  })

  let getAttendeesFromFiltersMutation = useMutation({
    mutationFn: (variables: {
      filters: LocalFilterModel[][]
      retreatId: number
    }) => getAttendeesFromFilters(variables.filters, variables.retreatId),
    onSuccess: (data) => {
      setFilteredAttendeeIds(data.attendee_ids)
    },
  })

  let patchAudienceMutation = useMutation({
    mutationFn: (variables: {
      audienceId: number
      audience: Partial<AudienceModel>
    }) => patchAudience(variables.audienceId, variables.audience),
    onSuccess: (data) => {
      queryClient.setQueryData(["audiences", data.retreat_audience.id], {
        retreat_audience: data.retreat_audience,
      })
      if (data.retreat_audience.selection_type === "FILTERS") {
        formik.values.filters.forEach((filterGroup, i) => {
          let groupId = filterGroup[0]?.attendee_group_id
          let defaultFilters = filterGroup.filter((filter) => {
            return isNaN(parseInt(filter.field))
          })
          if (!groupId) {
            postAttendeeGroupMutation.mutate({
              attendeeGroup: {
                audience_id: data.retreat_audience.id,
                default_filters: defaultFilters,
              },
              index: i,
            })
          } else {
            patchAttendeeGroupMutation.mutate({
              groupId: groupId,
              attendeeGroup: {default_filters: defaultFilters},
              index: i,
            })
          }
        })
      } else if (data.retreat_audience.selection_type === "ATTENDEES") {
        let formikAttendees: {[id: number]: boolean} =
          formik.values.attendee_ids.reduce((prev, currId) => {
            return {...prev, [currId]: true}
          }, {})
        let audienceAttendees: {[id: number]: boolean} =
          audience?.attendee_ids.reduce((prev, currId) => {
            return {...prev, [currId]: true}
          }, {}) ?? {}

        formik.values.attendee_ids.forEach((id) => {
          if (!audienceAttendees[id]) {
            postAudienceToAttendeeMutation.mutate({
              attendeeId: id,
              audienceId: data.retreat_audience.id,
            })
          }
        })
        audience?.attendee_ids &&
          audience?.attendee_ids.forEach((id) => {
            if (!formikAttendees[id]) {
              deleteAudienceToAttendeeMutation.mutate({
                attendeeId: id,
                audienceId: data.retreat_audience.id,
              })
            }
          })
      }
    },
  })

  let postAudienceToAttendeeMutation = useMutation({
    mutationFn: (variables: {attendeeId: number; audienceId: number}) =>
      postAudienceToAttendee(variables.audienceId, variables.attendeeId),
  })

  let deleteAudienceToAttendeeMutation = useMutation({
    mutationFn: (variables: {attendeeId: number; audienceId: number}) =>
      deleteAudienceToAttendee(variables.audienceId, variables.attendeeId),
  })

  let [attendees] = useRetreatAttendees(retreat.id)
  let queryClient = useQueryClient()
  let filters = attendeeGroupsQueries
    .filter((query) => query.isSuccess)
    .map((query) => {
      let normalForGroup = query.data!.attendee_group.filter_ids.map((id) => {
        let filter = allFiltersQueries
          .filter((query) => query.isSuccess)
          .map((query) => query.data!.attendee_group_filter)
          .find((fil) => fil.id === id)
        return {
          ...filter,
          field: (filter?.column_id ?? -1).toString(),
          attendee_group_id: filter?.attendee_group_id,
        } as LocalFilterModel
      })
      let defaultForGroup = query.data!.attendee_group.default_filters.map(
        (filter) => {
          return {...filter, attendee_group_id: query.data!.attendee_group.id}
        }
      )
      return normalForGroup.concat(defaultForGroup)
    })

  let audience = audienceQuery.data?.retreat_audience
  let formik = useFormik({
    initialValues: {
      name: audience?.name ?? "",
      description: audience?.description ?? "",
      selection_type: audience?.selection_type ?? "FILTERS",
      filters: filters,
      attendee_ids: audience?.attendee_ids ?? [],
    },
    onSubmit: async (values) => {
      if (!audience) {
        postAudienceMutation.mutateAsync({
          audience: {
            is_user_group: true,
            name: values.name,
            description: values.description,
            selection_type: values.selection_type,
            retreat_id: retreat.id,
          },
        })
      } else {
        await patchAudienceMutation.mutateAsync({
          audience: {
            is_user_group: true,
            name: values.name,
            description: values.description,
            selection_type: values.selection_type,
            retreat_id: retreat.id,
          },
          audienceId: audience.id,
        })
      }
      let filtersLeft = formik.values.filters.flat()
      allFiltersQueries
        .filter((query) => query.isSuccess)
        .map((filter) => filter.data!.attendee_group_filter)
        .forEach((filter) => {
          let item = filtersLeft.find(
            (localFilter) => localFilter.id === filter.id
          )
          if (!item) {
            deleteAttendeeGroupFilterMutation.mutate({filterId: filter.id})
          }
        })
      attendeeGroupsQueries
        .filter((group) => group.isSuccess)
        .map((group) => group.data!.attendee_group)
        .forEach((group) => {
          let item = filtersLeft.find(
            (filter) => filter.attendee_group_id === group.id
          )
          if (!item) {
            deleteAttendeeGroupMutation.mutate({groupId: group.id})
          }
        })
    },
    enableReinitialize: true,
  })

  return (
    <SideDrawer open={props.open} onClose={props.onClose}>
      <StyledHeaderContainer>
        <Text variant={"heading-sm"}>
          {audienceId ? "Edit Attendee Group" : "New Attendee Group"}
        </Text>
        <TabsWrapper onValueChange={setTabValue} value={tabValue}>
          <TabsList>
            <Tab text="Details" value="DETAILS" />
            <Tab text="Attendees" value="ATTENDEES" />
          </TabsList>
        </TabsWrapper>
      </StyledHeaderContainer>
      {tabValue === "DETAILS" ? (
        <>
          <StyledForm>
            <FormField
              inline
              type="textfield"
              label="Name"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              id="name"
            />
            <FormField
              inline
              type="textfield"
              label="Description"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              id="description"
            />
            <FormField
              type="select"
              inline
              value={formik.values.selection_type}
              onChange={(newVal) =>
                formik.setFieldValue("selection_type", newVal)
              }
              label="Type"
              fullWidth>
              <SelectItem label="Select with filters" value="FILTERS" />
              <SelectItem label="Select from list" value="ATTENDEES" />
            </FormField>
          </StyledForm>
          <SelectorContainer>
            <Text variant={"text-sm-plus"}>
              {formik.values.selection_type === "ATTENDEES"
                ? "Select attendees"
                : "Include all attendees..."}
            </Text>
            {formik.values.selection_type === "FILTERS" && (
              <AudienceFilterBuilder
                filterState={formik.values.filters}
                onChangeFilterState={(filterState) => {
                  formik.setFieldValue("filters", filterState)
                  getAttendeesFromFiltersMutation.mutate({
                    filters: filterState.map((group) => {
                      return group.map((filter) => {
                        if (!isNaN(parseInt(filter.field))) {
                          return {...filter, column_id: parseInt(filter.field)}
                        } else return filter
                      })
                    }),
                    retreatId: retreat.id,
                  })
                }}
              />
            )}
            {formik.values.selection_type === "ATTENDEES" && (
              <AttendeeSelectorTable
                attendees={attendees}
                onChangeSelectedAttendee={(attendeeId, checked) => {
                  if (!checked) {
                    let attendeeIdsCopy = [...formik.values.attendee_ids]
                    let index = attendeeIdsCopy.indexOf(attendeeId)
                    if (index !== -1) {
                      attendeeIdsCopy.splice(index, 1)
                      formik.setFieldValue("attendee_ids", attendeeIdsCopy)
                    }
                  } else if (checked) {
                    formik.setFieldValue("attendee_ids", [
                      ...formik.values.attendee_ids,
                      attendeeId,
                    ])
                  }
                }}
                selectedAttendees={formik.values.attendee_ids}
              />
            )}
            <Text css={{color: `$gray11`}} variant={"text-sm"}>
              {formik.values.selection_type === "FILTERS"
                ? filteredAttendeeIds.length
                : formik.values.attendee_ids.length}{" "}
              Attendees Selected
            </Text>
          </SelectorContainer>
        </>
      ) : (
        <SelectorContainer>
          <Text variant={"text-sm"}>
            {formik.values.selection_type === "FILTERS"
              ? filteredAttendeeIds.length
              : formik.values.attendee_ids.length}{" "}
            Attendees Selected
          </Text>
          {((formik.values.selection_type === "FILTERS" &&
            filteredAttendeeIds.length > 0) ||
            (formik.values.selection_type === "ATTENDEES" &&
              formik.values.attendee_ids.length > 0)) && (
            <PreviewAttendeesTable
              attendees={
                (formik.values.selection_type === "FILTERS"
                  ? filteredAttendeeIds
                      .map((id) => {
                        return attendees.find((attendee) => attendee.id === id)
                      })
                      .filter((attendee) => attendee)
                  : formik.values.attendee_ids
                      .map((id) => {
                        return attendees.find((attendee) => attendee.id === id)
                      })
                      .filter((attendee) => attendee)) as RetreatAttendeeModel[]
              }
            />
          )}
        </SelectorContainer>
      )}
      <SideDrawerFooter>
        <Button
          text={
            saved
              ? "Saved"
              : audienceId
              ? "Update Attendee Group"
              : "Create Attendee Group"
          }
          startIcon={saved ? <CheckIcon /> : undefined}
          variant={saved ? "outline" : "solid"}
          color="brand"
          onClick={() => {
            formik.handleSubmit()
            setSaved(true)
            if (buttonTimeout) {
              clearTimeout(buttonTimeout)
              setButtonTimeout(undefined)
            }
            setButtonTimeout(setTimeout(() => setSaved(false), 2000))
          }}
        />
      </SideDrawerFooter>
    </SideDrawer>
  )
}
