import {
  DotsVerticalIcon,
  DragHandleDots2Icon,
  MixIcon,
} from "@radix-ui/react-icons"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useState} from "react"
import {DraggableProvidedDragHandleProps} from "react-beautiful-dnd"
import {useDispatch} from "react-redux"
import {deleteBlock} from "../../store/actions/retreat"
import {useAttendeeLandingPageBlock} from "../../utils/retreatUtils"
import ConfirmationModal from "../app/ConfirmationModal"

const BlockContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "6px 8px",
  justifyContent: "space-between",
  borderRadius: theme.shape.borderRadius,
  flex: 1,
  marginRight: "12px",
})
const BlockLeftSide = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
})
const DragHandle = styled("div", {
  marginLeft: "4px",
  marginRight: "4px",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  opacity: 0,
})
const DropdownWrapper = styled("div", {
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  opacity: 0,
})
const BlockWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "&:hover": {
    [`${BlockContainer}`]: {
      background: theme.colors.gray4,
    },
    [`${DragHandle}`]: {
      opacity: 1,
    },
    [`${DropdownWrapper}`]: {
      opacity: 1,
    },
  },
})

type PageBuilderSideNavBlockProps = {
  dragHandleProps: DraggableProvidedDragHandleProps | undefined
  isDragging: boolean
  blockId: number
}
const blockTypeDisplayNameMap = {
  WYSIWYG: "Text",
  ACCORDION: "Accordion",
  IMAGE: "Image",
  ITINERARY: "Itinerary",
  FLIGHTS: "Flights table",
}
const DeleteDropdownItemWrapper = styled("div", {
  color: theme.colors.red11,
})
export default function PageBuilderSideNavBlock(
  props: PageBuilderSideNavBlockProps
) {
  let [block] = useAttendeeLandingPageBlock(props.blockId)
  let [deleteModalOpen, setDeleteModalOpen] = useState(false)
  let dispatch = useDispatch()
  return block ? (
    <BlockWrapper>
      <ConfirmationModal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false)
        }}
        title="Are you sure you wish to delete this section?"
        subtitle="This action cannot be undone."
        confirmText="Yes, delete"
        onConfirm={() => {
          dispatch(deleteBlock(props.blockId))
        }}
      />
      <DragHandle {...props.dragHandleProps}>
        <DragHandleDots2Icon />
      </DragHandle>
      <BlockContainer>
        <BlockLeftSide>
          <div style={{height: "15px", width: "15px"}}>
            <MixIcon />
          </div>
          <Text variant="text-sm-plus">
            {blockTypeDisplayNameMap[block.type]}
          </Text>
        </BlockLeftSide>
        <Dropdown
          align="end"
          button={
            <DropdownWrapper>
              <DotsVerticalIcon />
            </DropdownWrapper>
          }>
          <DeleteDropdownItemWrapper>
            <DropdownItem
              text={"Delete"}
              onClick={() => {
                setDeleteModalOpen(true)
              }}
            />
          </DeleteDropdownItemWrapper>
        </Dropdown>
      </BlockContainer>
    </BlockWrapper>
  ) : (
    <></>
  )
}
