import {SquareIcon, ViewVerticalIcon} from "@radix-ui/react-icons"
import {Select} from "@summtech/flok-base/components/Select"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Textfield} from "@summtech/flok-base/components/Textfield"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useEffect, useState} from "react"
import {ImageModel} from "../../../models"
import {ImageBlockContentModel, ImageBlockModel} from "../../../models/retreat"
import {splitFileName} from "../../../utils/retreatUtils"
import AppImageFilePicker from "../../app/AppImageFilePicker"
import AppUploadImageLargeButton from "../../app/AppUploadImageLargeButton"

type ImageBlockEditorProps = {
  block: ImageBlockModel
  formik: any
}

const ImageContainer = styled("div", {
  padding: "16px",
})
const ImagesWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  marginTop: "24px",
  gap: "8px",
})

export default function ImageBlockEditor(props: ImageBlockEditorProps) {
  let {resetForm} = props.formik
  useEffect(() => {
    resetForm({
      values: {
        ...props.formik.values,
        [props.block.id]: {
          content: {
            images: props.block?.content?.images
              ? [...props.block.content.images]
              : [undefined],
            layout: props.block?.content?.layout ?? "single",
          },
          type: "IMAGE",
        },
      },
    })
  }, [props.block, resetForm])

  function getEditorImages(content: ImageBlockContentModel | undefined) {
    if (!content) {
      return []
    }
    let returnValue = []
    for (let i = 0; i < (content.layout === "multi" ? 2 : 1); i++) {
      let img = content.images[i]
      if (img) {
        returnValue.push(
          <EditorImage
            layout={props.formik.values[props.block.id]?.content.layout}
            onChangeLayout={(newValue) => {
              props.formik.setFieldValue(`${props.block.id}.content`, {
                ...props.formik.values[props.block.id].content,
                layout: newValue,
              })
            }}
            onChange={(image) => {
              let images = [...content.images]
              images[i] = {
                src: image.image_url,
                maxHeight: "100%",
                maxWidth: "100%",
              }
              props.formik.setFieldValue(`${props.block.id}.content`, {
                ...props.formik.values[props.block.id].content,
                images: [...images],
              })
            }}
            notResizable={
              props.formik.values[props.block.id].content.layout !== "single"
            }
            src={img.src}
            maxWidth={
              props.formik.values[props.block.id].content.layout === "multi"
                ? `50%`
                : img.maxWidth
            }
            setMaxWidth={(val) => {
              let images = [
                ...props.formik.values[props.block.id].content.images,
              ]
              let currentImage = images[i]
              if (currentImage) {
                const unit = val.charAt(val.length - 1) === "%" ? "%" : "px"
                if (unit === "%" && parseInt(val) > 100) {
                  val = "100%"
                } else if (unit === "px" && parseInt(val) > 800) {
                  val = "800px"
                }
                images[i] = {...currentImage, maxWidth: val}
                props.formik.setFieldValue(`${props.block.id}.content`, {
                  ...props.formik.values[props.block.id].content,
                  images: images,
                })
              }
            }}
            onDelete={() => {
              let images = [
                ...props.formik.values[props.block.id].content.images,
              ]
              images[i] = undefined
              props.formik.setFieldValue(`${props.block.id}.content`, {
                ...props.formik.values[props.block.id].content,
                images: images,
              })
            }}
          />
        )
      } else {
        returnValue.push(
          <AppUploadImageLargeButton
            id="test"
            accepts="image/png, image/gif, image/jpeg"
            handleChange={(file) => {
              let images = [...content.images]
              images[i] = {
                src: file.file_url,
                maxHeight: "100%",
                maxWidth: "100%",
              }
              props.formik.setFieldValue(`${props.block.id}.content`, {
                ...props.formik.values[props.block.id].content,
                images: [...images],
              })
            }}
          />
        )
      }
    }
    return returnValue
  }
  return (
    <div>
      <ImageContainer>
        <div>
          <ImagesWrapper>
            {getEditorImages(props.formik.values[props.block.id]?.content)}
          </ImagesWrapper>
        </div>
      </ImageContainer>
    </div>
  )
}

const RenderImagesWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  marginTop: "16px",
  gap: "8px",
  marginBottom: "16px",
})
type ImageBlockRendererProps = {
  block: ImageBlockModel
}
export function ImageBlockRenderer(props: ImageBlockRendererProps) {
  let content = props.block.content as ImageBlockContentModel
  function renderImages(content: ImageBlockContentModel) {
    let returnValue = []
    for (let i = 0; i < (content.layout === "multi" ? 2 : 1); i++) {
      let image = content.images[i]
      if (image) {
        returnValue.push(
          <RenderImage
            layout={content.layout}
            maxHeight={image.maxHeight}
            maxWidth={image.maxWidth}
            src={image.src}
          />
        )
      } else {
        returnValue.push(
          <RenderImage
            layout={content.layout}
            maxWidth="100%"
            src={
              "https://flok-b32d43c.s3.amazonaws.com/hotels/missing-spotlight-image.png"
            }
          />
        )
      }
    }
    return returnValue
  }

  return props.block.content ? (
    <RenderImagesWrapper>{renderImages(content)}</RenderImagesWrapper>
  ) : (
    <></>
  )
}

const StyledImage = styled("img", {
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "100%",
  maxHeight: "$$maxHeight",
})
const ToolbarRoot = styled("div", {
  display: "flex",
  flexDirection: "row",
  height: "52px",
  width: "100%",
  justifyContent: "center",
  position: "absolute",
  bottom: "-26px",
})
const ToolbarContainer = styled("div", {
  background: theme.colors.gray2,
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.colors.gray6,
  borderWidth: "1px",
  borderStyle: "solid",
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  padding: "8px 12px",
  alignItems: "center",
  boxShadow:
    "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)",
})
const ImageEditorContainer = styled("div", {
  position: "relative",

  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  flexDirection: "column",
  maxWidth: "$$maxWidth",
  maxHeight: "$$maxHeight",
  [`${ToolbarContainer}`]: {
    display: "none",
  },
  "&:hover, &:focus-within": {
    [`${ToolbarContainer}`]: {
      display: "flex",
    },
  },
})

const Divider = styled("div", {
  width: "1px",
  height: "24px",
  background: theme.colors.gray5,
  borderWidth: "1px",
  borderColor: theme.colors.gray6,
  borderStyle: "solid",
})

const SizeSelector = styled("div", {
  display: "flex",
  flexDirection: "row",
  [`${Textfield}`]: {
    width: "54px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  [`${Select}`]: {
    width: "54px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderLeft: "none",
  },
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "input[type=number]": {
    MozAppearance: "textfield",
  },
})

const LayoutSelectContainer = styled("div", {
  [`${Select}`]: {
    width: "60px",
  },
})
function EditorImage(props: {
  src: string
  maxWidth: string

  setMaxWidth: (newVal: string) => void

  onDelete: () => void
  notResizable?: boolean
  onChange: (image: ImageModel) => void
  onChangeLayout: (newLayout: string) => void
  layout: "single" | "multi"
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? "simple-popper" : undefined
  const widthUnit =
    props.maxWidth.charAt(props.maxWidth.length - 1) === "%" ? "%" : "px"

  return (
    <ImageEditorContainer css={{$$maxWidth: props.maxWidth}}>
      <StyledImage
        onBlur={() => {
          setAnchorEl(null)
        }}
        onClick={(event) => {
          setAnchorEl(anchorEl ? null : event.currentTarget)
        }}
        src={props.src}
        alt="custom"></StyledImage>
      <ToolbarRoot>
        <ToolbarContainer>
          <AppImageFilePicker
            fullWidth
            handleClear={props.onDelete}
            handleChange={props.onChange}
            file={splitFileName(props.src)}
            id="image"
          />

          {!props.notResizable && (
            <>
              <Divider />
              <SizeSelector>
                <Textfield
                  value={parseInt(props.maxWidth.toString())}
                  type="number"
                  onChange={(e) => {
                    props.setMaxWidth(`${e.target.value}${widthUnit}`)
                  }}
                  fullWidth
                />
                <Select
                  fullWidth
                  value={widthUnit}
                  onChange={(newValue) => {
                    props.setMaxWidth(`${parseInt(props.maxWidth)}${newValue}`)
                  }}>
                  <SelectItem label="%" value="%" />
                  <SelectItem label="px" value="px" />
                </Select>
              </SizeSelector>
            </>
          )}
          <Divider />
          <LayoutSelectContainer>
            <Select onChange={props.onChangeLayout} value={props.layout}>
              <SelectItem icon={<SquareIcon />} value="single" />
              <SelectItem icon={<ViewVerticalIcon />} value="multi" />
            </Select>
          </LayoutSelectContainer>
        </ToolbarContainer>
      </ToolbarRoot>
    </ImageEditorContainer>
  )
}
type RenderImageProps = {
  src: string
  layout: "multi" | "single"
  maxWidth: string
  maxHeight?: string
}

const StyledRenderedImage = styled("img", {
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "$$maxWidth",
  maxHeight: "$$maxHeight",
})
function RenderImage(props: RenderImageProps) {
  return (
    <StyledRenderedImage
      src={props.src}
      alt="custom"
      css={{
        $$maxWidth: props.layout !== "multi" ? props.maxWidth : "50%",
        $$maxHeight:
          props.layout !== "multi" && props.maxHeight
            ? props.maxHeight
            : "100%",
      }}
    />
  )
}
