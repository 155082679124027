import {Badge} from "@summtech/flok-base/components/Badge"
import {Button} from "@summtech/flok-base/components/Button"
import {SegmentedControlGroup} from "@summtech/flok-base/components/SegmentedControlGroup"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useState} from "react"
import {Link} from "react-router-dom"
import PageBody from "../../components/page/PageBody"
import {RetreatAttendeeModel} from "../../models/retreat"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {AppRoutes, FlokPageName} from "../../Stack"
import {useRetreatAttendees} from "../../utils/attendeeUtils"
import {useHotelFromId} from "../../utils/lodgingUtils"
import OverviewTaskList from "../overview/OverviewTaskList"

const OverallBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",

  background: theme.colors.white,
  padding: "24px 32px",
  gap: "16px",
  flex: 1,
})
const PlanningProcess = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "24px 0px",
  gap: "16px",
})

const CardsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  width: "100%",
  ["@bp2"]: {
    flexDirection: "row",
  },
})

const PlanningProcessHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "15px",
  justifyContent: "space-between",
  alignItems: "center",
  ["@bp2"]: {
    flexDirection: "row",
  },
})

function getRegisteredAttendees(attendees: RetreatAttendeeModel[]) {
  return attendees.filter((attendee) => attendee.info_status === "INFO_ENTERED")
}
function getAttendeesToRegister(attendees: RetreatAttendeeModel[]) {
  return attendees.filter((attendee) => attendee.info_status === "CREATED")
}
function differenceInDays(arr_datetime: Date, dep_datetime: Date) {
  dep_datetime.setHours(0, 0, 0, 0)
  arr_datetime.setHours(0, 0, 0, 0)
  return Math.ceil(
    (arr_datetime.getTime() - dep_datetime.getTime()) / (1000 * 3600 * 24)
  )
}

export default function OverviewPage() {
  let [retreat, retreatIdx] = useRetreat()
  let [attendees] = useRetreatAttendees(retreat.id)
  const PlanningItems: {
    title: string
    explanation: string
    redirectPage: FlokPageName
    completed: boolean
    redirectText: string
  }[] = [
    {
      title: "Complete Event Profile Form",
      explanation:
        "Please answer a few questions so that Flok can better understand what you are looking for in a venue. We will use the information in your event profile form to request pricing and availability from hotels.",
      redirectPage: "RetreatRfpPage",
      completed: !!retreat.request_for_proposal_id,
      redirectText: "View Form",
    },
    {
      title: "Review Hotel Proposals",
      explanation:
        "Check out real-time proposals that Flok found your team. It usually takes Flok 7-10 business days from the time you fill out your Event Profile Form to have proposals ready to view.",
      redirectPage: "RetreatLodgingProposalsPage",
      completed: retreat.lodging_final_hotel_id != null,
      redirectText: "View Proposals",
    },
    {
      title: "Sign Hotel Contract",
      explanation:
        "Flok will help you negotiate your hotel contract. Once signed, please upload your hotel contract here.",
      redirectPage: "RetreatLodgingContractPage",
      completed: retreat.lodging_final_hotel_id != null,
      redirectText: "Upload Contract",
    },
    {
      title: "Create Event Website",
      explanation:
        "Use Flok's landing page builder to create a custom attendee facing website. Add your company's logo, pictures of your venue, FAQ dropdowns, and more.",
      redirectPage: "LandingPageGeneratorHome",
      completed: !!retreat.attendees_website_id,
      redirectText: "View Website",
    },
    {
      title: "Create Attendee Registration Form",
      explanation:
        "Use Flok's registration form builder to create custom questions for attendee registration. Our system can handle multiple question types, conditional logic, and has unique logic built in for events where attendees can bring additional guests.",
      redirectPage: "RetreatRegistrationFormBuilderPage",
      completed: !!retreat.attendees_registration_form_id,
      redirectText: "View Form",
    },
    {
      title: "Add Attendees to Event",
      explanation:
        "Use the Attendees tab to create your event invite list. This is where you will track who has registered.",
      redirectPage: "RetreatAttendeesPage",
      completed:
        getRegisteredAttendees(attendees).length +
          getAttendeesToRegister(attendees).length >=
        5,
      redirectText: "View Attendees",
    },
    {
      title: "Invite Attendees to the Event",
      explanation:
        "Open registration so that your attendees are notified about your upcoming event.",
      redirectPage: "RetreatAttendeesPage",
      completed: retreat.registration_live,
      redirectText: "View Attendees",
    },
    {
      title: "Create your Itinerary",
      explanation:
        "Use Flok's custom itinerary builder to create a unique itinerary. This itinerary can be added included on your Event Website for all attendees to see.",
      redirectPage: "ItineraryPage",
      completed: !!retreat.itinerary_id,
      redirectText: "View Itinerary",
    },
  ]

  let [showSteps, setShowSteps] = useState<"all" | "uncompleted">("all")
  let [hotel] = useHotelFromId(retreat.lodging_final_hotel_id ?? -1)
  let retreatDate = retreat.lodging_final_start_date
    ? new Date(retreat.lodging_final_start_date)
    : false

  let numBookedFlights = attendees
    .filter((attendee) => ["INFO_ENTERED"].includes(attendee.info_status))
    .filter(
      (attendee) =>
        attendee.flight_status &&
        ["OPT_OUT", "BOOKED"].includes(attendee.flight_status)
    ).length
  return (
    <PageBody appBar>
      <OverallBody>
        <Text variant={"heading-xl"}>Overview</Text>
        <CardsContainer>
          <OverviewLodgingCard
            hotelImg={hotel?.spotlight_img.image_url}
            hotelName={hotel?.name}
            daysToRetreat={
              retreatDate
                ? differenceInDays(retreatDate, new Date())
                : undefined
            }
            button={{
              text: retreat.lodging_final_contract_url
                ? "View Contract"
                : "Upload Contract",
              to: AppRoutes.getPath("RetreatLodgingContractPage", {
                retreatIdx: retreatIdx.toString(),
              }),
            }}
          />
          <AttendeesOverviewCard
            registrationOpen={retreat.registration_live}
            registeredAttendees={getRegisteredAttendees(attendees).length}
            totalAttendees={
              getRegisteredAttendees(attendees).length +
              getAttendeesToRegister(attendees).length
            }
            button={{
              text: "View Attendees",
              to: AppRoutes.getPath("RetreatAttendeesPage", {
                retreatIdx: retreatIdx.toString(),
              }),
            }}
          />
          <FlightsOverviewCard
            registeredFlights={numBookedFlights}
            totalFlights={getRegisteredAttendees(attendees).length}
          />
        </CardsContainer>
        <PlanningProcess>
          <PlanningProcessHeader>
            <Text variant={"heading-lg"}>Planning Process</Text>
            <SegmentedControlGroup
              value={showSteps}
              onChange={(newValue) => {
                setShowSteps(newValue as "all" | "uncompleted")
              }}
              options={[
                {label: "All Steps", value: "all"},
                {label: "Uncompleted Steps", value: "uncompleted"},
              ]}
            />
          </PlanningProcessHeader>
          <OverviewTaskList
            tasks={PlanningItems.filter((item) => {
              if (showSteps === "all") {
                return true
              } else {
                return !item.completed
              }
            }).map((item) => {
              return {
                done: item.completed,
                title: item.title,
                description: item.explanation,
                link: {
                  to: AppRoutes.getPath(item.redirectPage, {
                    retreatIdx: retreatIdx.toString(),
                  }),
                  displayName: item.redirectText,
                },
                index: PlanningItems.indexOf(item),
              }
            })}
          />
        </PlanningProcess>
      </OverallBody>
    </PageBody>
  )
}

const OverviewCard = styled("div", {
  flex: 1,
  padding: "16px 16px 12px 16px",
  border: `1px solid ${theme.colors.gray7}`,
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  gap: "12px",
})
const OverviewBottom = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "center",
  ["a"]: {
    textDecoration: "none",
    marginLeft: "auto",
  },
})

const Divider = styled("div", {
  height: "1px",
  width: "100%",
  background: theme.colors.gray6,
})
const LodgingCardTop = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  alignItems: "center",
})
const LodgingCardTopLeft = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  flex: 1,
})
const HotelImg = styled("img", {
  width: "48px",
  height: "48px",
  borderRadius: theme.shape.borderRadius,
})
const GrayText = styled(Text, {
  color: theme.colors.gray11,
})
type OverviewLodgingCardProps = {
  hotelName?: string
  hotelImg?: string
  daysToRetreat?: number
  button: {
    text: string
    to: string
  }
}
function OverviewLodgingCard(props: OverviewLodgingCardProps) {
  return (
    <OverviewCard>
      <LodgingCardTop>
        <LodgingCardTopLeft>
          <GrayText variant={"text-sm-plus"}>Lodging</GrayText>
          <Text variant={"text-base-plus"}>{props.hotelName ?? "TBD"}</Text>
        </LodgingCardTopLeft>
        <HotelImg
          src={
            props.hotelImg ??
            "https://flok-b32d43c.s3.amazonaws.com/hotels/missing-spotlight-image.png"
          }
        />
      </LodgingCardTop>
      <Divider />
      <OverviewBottom>
        {props.daysToRetreat && (
          <GrayText variant={"text-sm"}>
            {Math.abs(props.daysToRetreat)} day
            {Math.abs(props.daysToRetreat) === 1 ? "" : "s"}{" "}
            {props.daysToRetreat > 0 ? "until" : "since"} retreat
          </GrayText>
        )}
        <Link to={props.button.to}>
          <Button variant={"inline"} color={"brand"} text={props.button.text} />
        </Link>
      </OverviewBottom>
    </OverviewCard>
  )
}
const CardTop = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
})
const RegistrationDot = styled("div", {
  height: "6px",
  width: "6px",
  borderRadius: "50%",
  marginRight: "4.75px",
  variants: {
    color: {
      green: {
        backgroundColor: theme.colors.green9,
      },
      red: {
        backgroundColor: theme.colors.red9,
      },
    },
  },
})
type AttendeesOverviewCardProps = {
  registeredAttendees: number
  totalAttendees: number
  registrationOpen: boolean
  button: {
    text: string
    to: string
  }
}
function AttendeesOverviewCard(props: AttendeesOverviewCardProps) {
  return (
    <OverviewCard>
      <CardTop>
        <GrayText variant={"text-sm-plus"}>Attendees</GrayText>
        <Text variant={"text-base-plus"}>
          {props.registeredAttendees} / {props.totalAttendees} registered
        </Text>
      </CardTop>
      <Divider />
      <OverviewBottom>
        <Badge
          color={props.registrationOpen ? "success" : "critical"}
          startIcon={
            <RegistrationDot color={props.registrationOpen ? "green" : "red"} />
          }
          label={
            props.registrationOpen
              ? "Registration open"
              : "Registration inactive"
          }
        />
        <Link to={props.button.to}>
          <Button variant={"inline"} color={"brand"} text={props.button.text} />
        </Link>
      </OverviewBottom>
    </OverviewCard>
  )
}

type FlightsOverviewCardProps = {
  registeredFlights: number
  totalFlights: number
}
function FlightsOverviewCard(props: FlightsOverviewCardProps) {
  return (
    <OverviewCard>
      <CardTop>
        <GrayText variant={"text-sm-plus"}>Travel</GrayText>
        <Text variant={"text-base-plus"}>
          {props.registeredFlights} / {props.totalFlights} booked
        </Text>
      </CardTop>
      <Divider />
      <OverviewBottom>
        <GrayText variant={"text-sm"}>
          {props.totalFlights - props.registeredFlights} trip
          {props.totalFlights - props.registeredFlights === 1 ? "" : "s"}{" "}
          pending
        </GrayText>
      </OverviewBottom>
    </OverviewCard>
  )
}
