/**
 * THIS FILE IS DEPRECATED AND CAN BE REMOVED SAFELY ONCE THE app.goflok.com/sites/[website-name]/flights page is safely phased out.
 * This page was the previous flights page that have 3 tabs: flight instructions, my flight, group flights.
 * This page has been deprecated in favor of the /sites/[website-name]/my-trip flow.
 */

import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {RetreatAttendeeModel} from "../../models/retreat"
import FlightDetailsForm from "../../redesign/flights/FlightDetailsForm"
import TripDisplayInline from "../../redesign/flights/TripDisplayInline"
import {RootState} from "../../store"
import {getTrip, instantiateAttendeeTrips} from "../../store/actions/retreat"

type AttendeeFlightTabProps = {
  attendee: RetreatAttendeeModel
  hideHeader?: true
  receiptRestricted?: boolean
  demo?: boolean
}

const FlightTabContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
})
const FlightTabSection = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  padding: "16px 0px",
  borderBottom: `1px solid ${theme.colors.gray6}`,
})

function AttendeeFlightTab(props: AttendeeFlightTabProps) {
  let {attendee} = props
  let travel = attendee.travel
  let dispatch = useDispatch()

  let arrivalFlights = useSelector((state: RootState) => {
    if (travel?.arr_trip && travel?.arr_trip.id) {
      return state.retreat.trips[travel?.arr_trip.id]
    }
  })
  let departureFlights = useSelector((state: RootState) => {
    if (travel?.dep_trip && travel?.dep_trip.id) {
      return state.retreat.trips[travel?.dep_trip.id]
    }
  })

  useEffect(() => {
    travel?.arr_trip &&
      travel?.arr_trip.id &&
      !arrivalFlights &&
      dispatch(getTrip(travel?.arr_trip.id))
    travel?.dep_trip &&
      travel?.dep_trip.id &&
      !departureFlights &&
      dispatch(getTrip(travel?.dep_trip.id))
  }, [
    dispatch,
    travel?.arr_trip,
    travel?.dep_trip,
    arrivalFlights,
    departureFlights,
  ])

  return (
    <>
      {attendee && (
        <FlightTabContainer>
          <FlightTabSection>
            <Text variant={"heading-sm"}>Details</Text>
            <FlightDetailsForm
              attendee={attendee}
              receiptRestricted={props.receiptRestricted}
            />
          </FlightTabSection>
          <FlightTabSection>
            <Text variant={"heading-sm"}>Arrival</Text>
            {attendee.travel?.arr_trip && (
              <TripDisplayInline trip={attendee.travel.arr_trip} />
            )}
          </FlightTabSection>
          <FlightTabSection>
            <Text variant={"heading-sm"}>Departure</Text>
            {attendee.travel?.dep_trip && (
              <TripDisplayInline trip={attendee.travel.dep_trip} />
            )}
          </FlightTabSection>
          {!travel && (
            <Button
              css={{marginInline: "auto"}}
              variant="outline"
              text="Add flights?"
              onClick={() => {
                dispatch(instantiateAttendeeTrips(attendee.id))
              }}
            />
          )}
        </FlightTabContainer>
      )}
    </>
  )
}
export default AttendeeFlightTab
