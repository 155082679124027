import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core"
import {Add, Delete, ExpandMore} from "@material-ui/icons"
import {convertToRaw, EditorState} from "draft-js"
import {useFormik} from "formik"
import _ from "lodash"
import {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {
  AccordionBlockContentModel,
  AccordionBlockModel,
} from "../../../models/retreat"
import {patchBlock} from "../../../store/actions/retreat"
import {AppWysiwygEditor, createEditorState} from "../../base/AppWysiwyg"
import BeforeUnload from "../../base/BeforeUnload"

let useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  saveButton: {
    position: "absolute",
    boxShadow: theme.shadows[2],
    bottom: 40,
    zIndex: 10,
    right: 60,
  },
}))

type AccordionBlockEditorProps = {
  block: AccordionBlockModel
}
export function AccordionBlockEditor(props: AccordionBlockEditorProps) {
  let classes = useStyles(props)
  let dispatch = useDispatch()
  let formik = useFormik({
    initialValues: convertContentFromRaw(
      props.block.content as AccordionBlockContentModel
    ),
    onSubmit: (values) => {
      dispatch(
        patchBlock(props.block.id, {content: convertFormikToRaw(values)})
      )
    },
  })

  // TODO fix this "any"
  function convertFormikToRaw(values: any) {
    return {
      items: values.items.map((item: any) => ({
        ...item,
        body: convertToRaw(item.body.getCurrentContent()),
      })),
    }
  }

  function convertContentFromRaw(content: AccordionBlockContentModel | null) {
    if (!content) {
      return {items: []}
    } else {
      return {
        items: content.items.map((item) => {
          return {
            ...item,
            body: createEditorState(item.body),
          }
        }),
      }
    }
  }

  let {resetForm} = formik
  useEffect(() => {
    resetForm({
      values: convertContentFromRaw(
        props.block.content as AccordionBlockContentModel
      ),
    })
  }, [props.block.content, resetForm])

  let unsaved = !_.isEqual(
    convertFormikToRaw(formik.initialValues),
    convertFormikToRaw(formik.values)
  )
  return (
    <>
      <BeforeUnload
        when={unsaved}
        message="Are you sure you wish to leave without saving your changes?"
      />
      <Box clone width="100%">
        <form onSubmit={formik.handleSubmit}>
          {formik.values.items.map((item, index) => (
            <Box display="flex" alignItems="flex-start">
              <Box pt={2} pl={2} mr={1}>
                <IconButton
                  size="small"
                  onClick={() =>
                    formik.setFieldValue(
                      "items",
                      formik.values.items.filter((item, i) => index !== i)
                    )
                  }>
                  <Delete />
                </IconButton>
              </Box>
              <Box flex={1} minWidth={0}>
                <AccordionItem
                  editable
                  header={
                    <TextField
                      id={`items[${index}].header`}
                      value={item.header}
                      onChange={formik.handleChange}
                      autoComplete="off"
                      fullWidth
                      placeholder="Item header"
                    />
                  }
                  details={
                    <AppWysiwygEditor
                      editorState={item.body}
                      onEditorStateChange={(val) =>
                        formik.setFieldValue(`items[${index}].body`, val)
                      }
                      unsaved={unsaved}
                    />
                  }
                />
              </Box>
            </Box>
          ))}
          <Box width="100%" display="flex" justifyContent="flex-start" p={1}>
            {unsaved && (
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit">
                  Save
                </Button>
              </Box>
            )}
            <Button
              style={{marginLeft: "auto"}}
              onClick={() =>
                formik.setFieldValue("items", [
                  ...formik.values.items,
                  {header: "", body: EditorState.createEmpty()},
                ])
              }
              endIcon={<Add />}>
              Add item
            </Button>
          </Box>
        </form>
      </Box>
    </>
  )
}

let useAccordionItemStyle = makeStyles((theme) => ({
  headerExpanded: {
    "& *": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}))

type AccordionBlockProps = {
  header: JSX.Element
  details: JSX.Element
  editable?: boolean
}

function AccordionItem(props: AccordionBlockProps) {
  let classes = useAccordionItemStyle()
  let [expanded, setExpanded] = useState(false)
  return (
    <Accordion elevation={0} expanded={props.editable ? expanded : undefined}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        onFocus={() => setExpanded(true)}
        IconButtonProps={
          props.editable ? {onClick: () => setExpanded(!expanded)} : undefined
        }
        classes={{expanded: classes.headerExpanded}}>
        {props.header}
      </AccordionSummary>
      <AccordionDetails>{props.details}</AccordionDetails>
    </Accordion>
  )
}
