import {styled} from "@summtech/flok-base/stitches.config"
import SiteFooter from "../../components/attendee-site/website/SiteFooter"
import {AttendeeLandingWebsiteModel, RetreatModel} from "../../models/retreat"
import SiteHeader from "./SiteHeader"

const OverallPage = styled("div", {
  display: "flex",
  flexDirection: "column",
})

const InnerPage = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflow: "auto",
})

export const PageBodyContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  paddingLeft: "16px",
  paddingRight: "16px",
  margin: "16px",
  marginLeft: "auto",
  marginRight: "auto",
  // TODO, come back and fix, hack for flights page
  width: "100%",
  maxWidth: "800px",
  height: "100%",
})

const BannerImg = styled("img", {
  width: "100%",
  maxHeight: "325px",
  objectFit: "cover",
})

type AttendeeSiteProps = {
  retreat: RetreatModel
  website: AttendeeLandingWebsiteModel
  activePage: string | "registration" | "flights"
  pageBody: JSX.Element
  hideLogo?: boolean
}
export default function AttendeeSite(props: AttendeeSiteProps) {
  return (
    <OverallPage>
      <SiteHeader
        activePage={props.activePage}
        retreat={props.retreat}
        website={props.website}
      />
      <InnerPage>
        {props.website.banner_image && !props.hideLogo && (
          <BannerImg
            src={props.website.banner_image.image_url}
            alt={props.website.banner_image.alt}
          />
        )}
        <PageBodyContainer>{props.pageBody}</PageBodyContainer>
        <SiteFooter />
      </InnerPage>
    </OverallPage>
  )
}
