import {Cross1Icon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {FormField} from "@summtech/flok-base/components/FormField"
import {FormLabel} from "@summtech/flok-base/components/FormLabel"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {convertFromRaw, convertToRaw, EditorState} from "draft-js"
import {useFormik} from "formik"
import {PropsWithChildren, useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import * as yup from "yup"
import {AppWysiwygEditor} from "../../components/base/AppWysiwyg"
import {
  FileModel,
  ItineraryEventLabel,
  ItineraryEventModel,
  ITINERARY_EVENT_LABELS,
} from "../../models/retreat"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {ApiAction} from "../../store/actions/api"
import {
  deleteFileToEvent,
  deleteItineraryEvent,
  patchItineraryEvent,
  postComment,
  postCommentThread,
  postFileToEvent,
  postItineraryEvent,
} from "../../store/actions/retreat"
import {useItineraryEvent} from "../../utils/itineraryUtils"
import {splitFileName} from "../../utils/retreatUtils"
import {AppMultipleFilePicker} from "../app/AppMultipleFilePicker"
import CommentForm from "../comments/CommentForm"
import CommentsList from "../comments/CommentsList"
import {getIsoDate} from "../pages/ItineraryBuilderPage"
import {EventBadge} from "./EventBadge"

type ItineraryEventFormProps = {
  itineraryId: number
  eventId?: number
  onSubmit: () => void
  startDate?: string
  days: number
} & SideDrawerProps

function fileDifference(fileArr1: FileModel[], fileArr2: FileModel[]) {
  let fileMap: {[id: number]: true} = {}
  fileArr1.forEach((file) => {
    fileMap[file.id] = true
  })
  return fileArr2.filter((file) => !fileMap[file.id])
}

const FormBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "16px 20px",
  gap: "20px",
  borderTop: `1px solid ${theme.colors.gray6}`,
  flex: 1,
  marginTop: "8px",
})

const CommentsBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "16px 20px",
  gap: "20px",

  borderTop: `1px solid ${theme.colors.gray6}`,
  flex: 1,
  marginTop: "8px",
})

const LabelContainer = styled("div", {
  width: "160px",
  minWidth: "160px",
})
const WYSIWYGContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  zIndex: 0,
})
const WYSIWYGWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  flex: 1,
  border: `1px solid ${theme.colors.gray7}`,
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    border: `1px solid ${theme.colors.gray8}`,
  },
})

const FormActionsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px 20px",
  position: "sticky",
  bottom: "0px",
  background: theme.colors.white,
  borderTop: `1px solid ${theme.colors.gray6}`,
})
const FormActionsRightContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  marginLeft: "auto",
})
export default function ItineraryEventForm(props: ItineraryEventFormProps) {
  let [event] = useItineraryEvent(props.eventId ?? -1)
  let dispatch = useDispatch()
  let formik = useFormik({
    initialValues: {
      title: event?.title ?? "",
      description: event?.description
        ? EditorState.createWithContent(convertFromRaw(event.description))
        : EditorState.createEmpty(),
      day: event?.day ?? ("none" as number | undefined | "none"),
      all_day: event?.all_day ?? false,
      start_time: event?.start_time ?? "",
      end_time: event?.end_time ?? "",
      location: event?.location ?? "",
      label: event?.label ?? "",
      files: (event?.files ?? []) as FileModel[] | undefined,
    },
    onSubmit: async (values, formikHelpers) => {
      let formikFiles = [...(values.files ? values.files : [])]
      let postValues = {
        ...values,
        description: convertToRaw(values.description.getCurrentContent()),
        day: values.day !== "none" && values.day ? values.day : null,
        start_time: values.start_time || null,
        end_time: values.end_time || null,
        label: values.label !== "none" && values.label ? values.label : null,
      }
      delete postValues.files
      let response = props.eventId
        ? ((await dispatch(
            patchItineraryEvent(
              postValues as ItineraryEventModel,
              props.eventId
            )
          )) as unknown as ApiAction)
        : ((await dispatch(
            postItineraryEvent(
              postValues as ItineraryEventModel,
              props.itineraryId
            )
          )) as unknown as ApiAction)
      if (!response.error) {
        formikHelpers.resetForm()
        let filesToPost = event
          ? fileDifference(formikFiles, event?.files)
          : formikFiles
        await Promise.all(
          filesToPost.map(async (file) => {
            let fileResponse = (await dispatch(
              postFileToEvent({
                event_id: response.payload.event.id,
                file_id: file.id,
              })
            )) as unknown as ApiAction
            if (!fileResponse.error) {
              dispatch({
                type: "ADD_FILE_TO_EVENT",
                file: file,
                event_id: response.payload.event.id,
              })
            }
          })
        )
        props.onSubmit()
      }
    },
    validationSchema: yup.object({
      title: yup.string().required("Event Title required"),
    }),
  })
  let {resetForm} = formik
  let [retreat] = useRetreat()
  useEffect(() => {
    resetForm({
      values: {
        title: event?.title ?? "",
        description: event?.description
          ? EditorState.createWithContent(convertFromRaw(event.description))
          : EditorState.createEmpty(),
        day: event?.day,
        start_time: event?.start_time ?? "",
        end_time: event?.end_time ?? "",
        location: event?.location ?? "",
        label: event?.label ?? "",
        files: (event?.files ?? []) as FileModel[] | undefined,
        all_day: event?.all_day ?? false,
      },
    })
  }, [event, resetForm])

  function returnSaveOnChange(field: string, eventId: number) {
    return (e: any) => {
      formik.setFieldValue(field, e.target.value)
      dispatch(
        patchItineraryEvent(
          {[field]: e.target.value ? e.target.value : null},
          eventId
        )
      )
    }
  }
  function returnSaveOnChangeDateTime(field: string, eventId: number) {
    return (value: string) => {
      formik.setFieldValue(field, value)
      dispatch(patchItineraryEvent({[field]: value ? value : null}, eventId))
    }
  }
  function returnSaveOnChangeChecked(field: string, eventId: number) {
    return (checked: boolean) => {
      formik.setFieldValue(field, checked)
      dispatch(patchItineraryEvent({[field]: checked}, eventId))
    }
  }
  function returnSaveOnBlur(field: string, eventId: number) {
    return () => {
      if (!formik.errors[field as keyof typeof formik.errors]) {
        dispatch(
          patchItineraryEvent(
            {[field]: formik.values[field as keyof typeof formik.values]},
            eventId
          )
        )
      }
    }
  }
  function getDaysOptions(days: number) {
    let returnValue = [<SelectItem value={"none"} label="Unscheduled" />]
    for (let i = 0; i < days; i++) {
      returnValue.push(
        <SelectItem
          value={(i + 1).toString()}
          key={i + 1}
          label={
            props.startDate
              ? new Intl.DateTimeFormat("en-US", {
                  weekday: "short",
                  day: "numeric",
                  month: "long",
                  timeZone: "UTC",
                }).format(getIsoDate(props.startDate, i))
              : `Day ${i + 1}`
          }
        />
      )
    }
    return returnValue
  }

  function fileDifference(fileArr1: FileModel[], fileArr2: FileModel[]) {
    let fileMap: {[id: number]: true} = {}
    fileArr1.forEach((file) => {
      fileMap[file.id] = true
    })
    return fileArr2.filter((file) => !fileMap[file.id])
  }
  const [confirmDelete, setConfirmDelete] = useState(false)
  return (
    <form onSubmit={formik.handleSubmit}>
      <SideDrawer open={props.open} onClose={props.onClose}>
        <SideDrawerHeader
          onClose={props.onClose}
          text={
            props.eventId ? "Edit Event" : "Create Event"
          }></SideDrawerHeader>
        <FormBody>
          <FormField
            type={"textfield"}
            label="Title"
            inline
            fullWidth
            errorMessage={formik.errors.title}
            onBlur={
              props.eventId
                ? returnSaveOnBlur("title", props.eventId)
                : undefined
            }
            id="title"
            onChange={formik.handleChange}
            value={formik.values.title}
          />
          <FormField
            type={"select"}
            label="Day"
            inline
            fullWidth
            onChange={(newValue) => {
              let day = newValue !== "none" ? newValue : null
              newValue && formik.setFieldValue("day", newValue)
              props.eventId &&
                (day === null || day) &&
                dispatch(
                  patchItineraryEvent(
                    {
                      day: day as unknown as number,
                    },
                    props.eventId!
                  )
                )
            }}
            value={formik.values.day?.toString() ?? "-1"}>
            {getDaysOptions(props.days)}
          </FormField>
          <FormField
            type={"switch"}
            label="All day event?"
            inline
            fullWidth
            errorMessage={formik.errors.all_day}
            onChange={
              props.eventId
                ? returnSaveOnChangeChecked("all_day", props.eventId)
                : (value) => {
                    formik.setFieldValue("all_day", value)
                  }
            }
            checked={formik.values.all_day}
          />
          {!formik.values.all_day && (
            <>
              <FormField
                type={"time"}
                label="Start Time"
                timeStep={15}
                inline
                maxTime={formik.values.end_time}
                onChange={
                  props.eventId
                    ? returnSaveOnChangeDateTime("start_time", props.eventId)
                    : (value) => {
                        formik.setFieldValue("start_time", value)
                      }
                }
                value={formik.values.start_time}
              />
              <FormField
                type={"time"}
                timeStep={15}
                label="End Time"
                minTime={formik.values.start_time}
                inline
                onChange={
                  props.eventId
                    ? returnSaveOnChangeDateTime("end_time", props.eventId)
                    : (value) => {
                        formik.setFieldValue("end_time", value)
                      }
                }
                value={formik.values.end_time}
              />
            </>
          )}
          <FormField
            type={"textfield"}
            label="Location"
            inline
            fullWidth
            onBlur={
              props.eventId
                ? returnSaveOnBlur("location", props.eventId)
                : undefined
            }
            id="location"
            onChange={formik.handleChange}
            value={formik.values.location}
          />
          <FormField
            css={{zIndex: 1}}
            type={"select"}
            label="Type"
            placeholder="Select Type"
            inline
            fullWidth
            onChange={(newValue) => {
              newValue && formik.setFieldValue("label", newValue)
              if (props.eventId && newValue) {
                dispatch(
                  patchItineraryEvent(
                    {
                      label: (newValue !== "none"
                        ? (newValue as ItineraryEventLabel)
                        : null) as ItineraryEventLabel,
                    },
                    props.eventId
                  )
                )
              }
            }}
            value={formik.values.label}>
            {ITINERARY_EVENT_LABELS.map((label) => {
              return (
                <SelectItem
                  label={<EventBadge label={label as ItineraryEventLabel} />}
                  value={label}
                />
              )
            })}
            <SelectItem label={"No type"} value={"none"} />
          </FormField>
          <WYSIWYGContainer>
            <LabelContainer>
              <FormLabel inline text="Description" />
            </LabelContainer>
            <WYSIWYGWrapper
              onBlur={() => {
                if (!formik.errors.description && event) {
                  dispatch(
                    patchItineraryEvent(
                      {
                        description: convertToRaw(
                          formik.values.description.getCurrentContent()
                        ),
                      },
                      event.id
                    )
                  )
                }
              }}>
              <AppWysiwygEditor
                disableToolbar
                editorState={formik.values.description}
                onEditorStateChange={(val) => {
                  formik.setFieldValue("description", val)
                }}
              />
            </WYSIWYGWrapper>
          </WYSIWYGContainer>
          <AppMultipleFilePicker
            accepts="image/png, image/jpg, application/pdf, image/jpeg"
            label="Attachments"
            files={formik.values.files?.map((file) => {
              return {
                id: file.id,
                path: file.file_url,
                displayName: splitFileName(file.file_url) ?? "unknown-file",
              }
            })}
            handleChange={async (file) => {
              if (props.eventId) {
                let response = (await dispatch(
                  postFileToEvent({
                    event_id: props.eventId ?? -1,
                    file_id: file.id,
                  })
                )) as unknown as ApiAction
                if (!response.error) {
                  dispatch({
                    type: "ADD_FILE_TO_EVENT",
                    file: file,
                    event_id: props.eventId,
                  })
                }
              } else {
                formik.setFieldValue("files", [
                  ...(formik.values.files ? formik.values.files : []),
                  file,
                ])
              }
            }}
            onDelete={
              props.eventId
                ? (fileId) => {
                    dispatch(deleteFileToEvent(fileId, props.eventId as number))
                  }
                : (fileId) => {
                    if (formik.values.files) {
                      let files = [...formik.values.files]
                      let index = formik.values.files.findIndex((arrFile) => {
                        return arrFile.id === fileId
                      })
                      if (index !== -1) {
                        files.splice(index, 1)
                      }
                      formik.setFieldValue("files", files)
                    }
                  }
            }
          />
        </FormBody>
        {props.eventId && (
          <CommentsBody>
            <Text variant="heading-sm">Comments</Text>
            {event?.comment_thread_id && (
              <CommentsList
                commentThreadId={event.comment_thread_id}
                startingNumber={4}
              />
            )}
            <CommentForm
              disableToolbar
              onSubmit={async (values) => {
                if (event && event.comment_thread_id) {
                  dispatch(
                    postComment({
                      ...values,
                      comment_thread_id: event.comment_thread_id,
                      retreat_id: retreat.id,
                      redirect: `/itinerary/events/${event.id}?comment=:id`,
                    })
                  )
                } else {
                  let threadResponse = (await dispatch(
                    postCommentThread()
                  )) as unknown as ApiAction
                  if (!threadResponse.error && event) {
                    let eventResponse = (await dispatch(
                      patchItineraryEvent(
                        {
                          comment_thread_id:
                            threadResponse.payload.comment_thread.id,
                        },
                        event.id
                      )
                    )) as unknown as ApiAction

                    if (!eventResponse.error) {
                      dispatch(
                        postComment({
                          ...values,
                          comment_thread_id:
                            threadResponse.payload.comment_thread.id,
                          retreat_id: retreat.id,
                          redirect: `/itinerary/events/${event.id}?comment=:id`,
                        })
                      )
                    }
                  }
                }
              }}
            />
          </CommentsBody>
        )}
        <FormActionsContainer>
          {props.eventId && (
            <Button
              variant={confirmDelete ? "solid" : "outline"}
              color="critical"
              text={
                confirmDelete ? "Are you sure you want to delete?" : "Delete"
              }
              onClick={
                confirmDelete
                  ? () => {
                      props.onClose && props.onClose()
                      dispatch(deleteItineraryEvent(props.eventId as number))
                      setConfirmDelete(false)
                    }
                  : (e) => {
                      e.preventDefault()
                      setConfirmDelete(true)
                    }
              }
            />
          )}
          <FormActionsRightContainer>
            <Button
              variant="outline"
              color="gray"
              text="Cancel"
              onClick={props.onClose}
            />
            <Button
              variant="solid"
              color="brand"
              text={props.eventId ? "Done" : "Save"}
              type={props.eventId ? undefined : "submit"}
              onClick={props.eventId ? props.onClose : undefined}
            />
          </FormActionsRightContainer>
        </FormActionsContainer>
      </SideDrawer>
    </form>
  )
}

// TODO, remove and replace with app side drawer

let PageBackground = styled("div", {
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  right: 0,
  position: "absolute",
  bottom: 0,
  // TODO, fix this once we have a better idea what our zIndex patterns should be
  zIndex: 10000000,
  backgroundColor: "rgba(0, 0, 0, 0.56)",
  alignItems: "right",
  justifyContent: "right",
  display: "flex",
})
let DrawerContainer = styled("div", {
  width: "95%",
  maxWidth: "600px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderLeft: `1px solid ${theme.colors.gray6}`,
  background: theme.colors.white,
  overflow: "auto",
})
const DrawerHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "12px 20px 0px",
  justifyContent: "space-between",
  alignItems: "center",
})

type SideDrawerProps = PropsWithChildren<{
  open: boolean
  onClose?: () => void
}>

function SideDrawer(props: SideDrawerProps) {
  let {open, onClose, children} = props
  return open ? (
    <PageBackground onClick={onClose}>
      <DrawerContainer
        onClick={(e) => {
          e.stopPropagation()
        }}>
        {children}
      </DrawerContainer>
    </PageBackground>
  ) : (
    <></>
  )
}

type SideDrawerHeaderProps = {
  text: string
  onClose?: () => void
}
function SideDrawerHeader(props: SideDrawerHeaderProps) {
  return (
    <DrawerHeader>
      <Text variant={"heading-sm"}>{props.text} </Text>
      {props.onClose && (
        <IconButton
          variant="ghost"
          onClick={() => {
            props.onClose && props.onClose()
          }}>
          <Cross1Icon />
        </IconButton>
      )}
    </DrawerHeader>
  )
}
