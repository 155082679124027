import {useMediaQuery} from "@material-ui/core"
import {
  CheckCircledIcon,
  CircleIcon,
  ClockIcon,
  DotsVerticalIcon,
  EnvelopeClosedIcon,
  LightningBoltIcon,
  TrashIcon,
} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useDispatch} from "react-redux"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {deleteRetreatEmail} from "../../store/actions/retreat"
import {FlokTheme} from "../../theme"

const LineContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",

  borderBottom: `1px solid ${theme.colors.gray6}`,
  gap: "8px",
  alignItems: "center",
  padding: "12px 4px",
  cursor: "pointer",
  "&:hover": {
    background: theme.colors.gray3,
  },
})
const ItemContainer = styled("div", {
  padding: "6px 8px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
})

const SubjectContainer = styled(ItemContainer, {
  flex: 3,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
})
const DeleteItem = styled(DropdownItem, {
  color: theme.colors.red10,
})

const EmailIconContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  height: "32px",
  width: "32px",
  background: theme.colors.gray3,
  borderRadius: theme.shape.borderRadius,
})

const StatusContainer = styled(ItemContainer, {
  flex: 2,
  gap: "8px",
})

const StatusInnerContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
})

const TriggerContainer = styled(ItemContainer, {
  flex: 2,
  gap: "8px",
})

const SentContainer = styled(ItemContainer, {
  flex: 1,
  gap: "8px",
  justifyContent: "space-between",
})

const LiveIcon = styled(LightningBoltIcon, {
  color: theme.colors.blue11,
})

const DraftIcon = styled(CircleIcon, {
  color: theme.colors.gray11,
})

const ScheduledIcon = styled(ClockIcon, {
  color: theme.colors.yellow11,
})

const SentIcon = styled(CheckCircledIcon, {
  color: theme.colors.green11,
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

export type CommunicationStatus = "DRAFT" | "SCHEDULED" | "SENT" | "AUTOMATED"

type EmailLineItemProps = {
  subject: string
  status: CommunicationStatus
  statusDate?: string
  trigger?: string
  sent?: number
  onClick?: () => void
  emailId: number
}
function getStatusIcon(status: CommunicationStatus) {
  switch (status) {
    case "DRAFT":
      return <DraftIcon />
    case "AUTOMATED":
      return <LiveIcon />
    case "SCHEDULED":
      return <ScheduledIcon />
    case "SENT":
      return <SentIcon />
  }
}

export default function EmailLineItem(props: EmailLineItemProps) {
  const isSmallScreen = useMediaQuery((theme: FlokTheme) =>
    theme.breakpoints.down("sm")
  )
  let dispatch = useDispatch()
  let [retreat] = useRetreat()
  return (
    <LineContainer onClick={props.onClick}>
      <SubjectContainer>
        <EmailIconContainer>
          <EnvelopeClosedIcon />
        </EmailIconContainer>
        <Text variant={"text-sm-plus"}>{props.subject}</Text>
      </SubjectContainer>
      <StatusContainer>
        <StatusInnerContainer>
          {getStatusIcon(props.status)}
          <Text variant={"text-sm-plus"}>
            {props.status.charAt(0).toUpperCase()}
            {props.status.slice(1).toLowerCase()}
          </Text>
        </StatusInnerContainer>
        {props.statusDate && !isSmallScreen && (
          <GrayText variant={"text-sm"}>{props.statusDate}</GrayText>
        )}
      </StatusContainer>
      {props.trigger && (
        <TriggerContainer>
          <GrayText variant={"text-sm-plus"}>{props.trigger}</GrayText>
        </TriggerContainer>
      )}
      <SentContainer>
        {props.sent ? (
          <Text variant={"text-sm-plus"}>{props.sent}</Text>
        ) : (
          <GrayText variant={"text-sm-plus"}>-</GrayText>
        )}
        <Dropdown
          button={
            <Button variant="outline" startIcon={<DotsVerticalIcon />} />
          }>
          <DeleteItem
            startIcon={<TrashIcon />}
            text="Delete Email"
            // @ts-ignore
            onClick={async (e) => {
              e.stopPropagation()
              dispatch(deleteRetreatEmail(props.emailId, retreat.id))
            }}
          />
        </Dropdown>
      </SentContainer>
    </LineContainer>
  )
}
