import {Badge} from "@summtech/flok-base/components/Badge"
import {FormField} from "@summtech/flok-base/components/FormField"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {useQueries} from "@tanstack/react-query"
import {useFormik} from "formik"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import * as yup from "yup"
import {getColumnAnswer} from "../../../api/attendee"
import {FormQuestionType} from "../../../models/form"
import {
  ColumnAnswerModel,
  RegistrationStatusValues,
  RetreatAttendeeModel,
  RetreatColumnModel,
} from "../../../models/retreat"
import {useRetreat} from "../../../pages/misc/RetreatProvider"
import {RootState} from "../../../store"
import {patchAttendee} from "../../../store/actions/retreat"
import {
  useCreateColumnAnswerMutation,
  useUpdateColumnAnswerMutation,
} from "../../../utils/attendeeUtils"
import {RegistrationStatusBadge} from "./StatusBadge"

let StyledForm = styled("form", {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
})

export const DIETARY_OPTIONS = new Set([
  "Vegetarian",
  "Vegan",
  "Kosher style",
  "Kosher certified",
  "Nut allergy",
  "Fish/shellfish allergy",
  "Gluten free",
  "Keto",
  "Dairy free",
  "Halal",
  "Pescatarian",
  "Diabetic",
  "Mediterranean",
])
const AdditionalInfoContainer = styled("div", {
  marginTop: "150x",
})

type AttendeeProfileFormProps = {
  attendee: RetreatAttendeeModel
}

export function AttendeeProfileForm(props: AttendeeProfileFormProps) {
  let {attendee} = props
  let [retreat] = useRetreat()
  let dispatch = useDispatch()
  let formik = useFormik({
    initialValues: {
      email_address:
        attendee && attendee.email_address ? attendee.email_address : "",
      first_name: attendee && attendee.first_name ? attendee.first_name : "",
      last_name: attendee && attendee.last_name ? attendee.last_name : "",
      notes: attendee && attendee.notes ? attendee.notes : "",
      dietary_prefs:
        attendee && attendee.dietary_prefs ? attendee.dietary_prefs : "",
      info_status:
        attendee && attendee.info_status ? attendee.info_status : "CREATED",
      hotel_check_in:
        attendee && attendee.hotel_check_in ? attendee.hotel_check_in : "",
      hotel_check_out:
        attendee && attendee.hotel_check_out ? attendee.hotel_check_out : "",
    },
    onSubmit: (values) => {},
    validate: (values) => {
      try {
        // If the attendee was created without an email, allow saving still
        if (attendee?.email_address || values.email_address) {
          yup.string().required().email().validateSync(values.email_address)
        }
      } catch (err) {
        return {email_address: "Please enter a valid email."}
      }
      return {}
    },
    enableReinitialize: true,
  })
  function returnSaveOnBlur(field: string, attendeeId: number) {
    return () => {
      if (!formik.errors[field as keyof typeof formik.errors]) {
        dispatch(
          patchAttendee(attendeeId, {
            [field]: formik.values[field as keyof typeof formik.values],
          })
        )
      }
    }
  }
  function returnSaveOnChange(field: string, attendeeId: number) {
    return (newValue: string) => {
      formik.setFieldValue(field, newValue)
      dispatch(
        patchAttendee(attendeeId, {
          [field]: newValue ? newValue : null,
        })
      )
    }
  }

  let columns = useSelector((state: RootState) => {
    return Object.values(state.retreat.attendeeColumns).filter((column) => {
      if (column && retreat.column_ids.indexOf(column.id) !== -1) {
        return true
      } else {
        return false
      }
    }) as RetreatColumnModel[]
  })
  let answerQueries = useQueries({
    queries:
      columns
        .filter((column) => {
          return attendee.column_id_to_answer_id[column.id]
        })
        .map((column) => {
          let columnAnswerId = attendee.column_id_to_answer_id[column.id]
          return {
            queryKey: ["column-answers", columnAnswerId],
            queryFn: () => getColumnAnswer(columnAnswerId),
          }
        }) ?? [],
  })
  let answersMap = answerQueries
    .filter((answerQuery) => answerQuery.status === "success")
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr.data!.column_answer?.id]: curr.data!.column_answer,
      }),
      {} as {[id: number]: ColumnAnswerModel | undefined}
    )

  let createColumnAnswerMutation = useCreateColumnAnswerMutation(attendee.id)
  let updateColumnAnswerMutation = useUpdateColumnAnswerMutation()
  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <FormField
        onChange={formik.handleChange}
        inline
        fullWidth
        label={"First Name"}
        value={formik.values.first_name ?? ""}
        id="first_name"
        onBlur={returnSaveOnBlur("first_name", props.attendee.id)}
        type={"textfield"}
      />
      <FormField
        onChange={formik.handleChange}
        inline
        fullWidth
        onBlur={returnSaveOnBlur("last_name", props.attendee.id)}
        label={"Last Name"}
        value={formik.values.last_name ?? ""}
        id="last_name"
        type={"textfield"}
      />
      <FormField
        onBlur={returnSaveOnBlur("email_address", props.attendee.id)}
        onChange={formik.handleChange}
        inline
        fullWidth
        label={"Email"}
        value={formik.values.email_address ?? ""}
        id="email_address"
        type={"textfield"}
      />
      <FormField
        onChange={returnSaveOnChange("info_status", props.attendee.id)}
        inline
        fullWidth
        label={"Status"}
        value={formik.values.info_status ?? ""}
        id="info_status"
        type={"select"}>
        {RegistrationStatusValues.map((status) => {
          return (
            <SelectItem
              label={<RegistrationStatusBadge status={status} />}
              value={status}
            />
          )
        })}
      </FormField>
      <FormField
        onChange={returnSaveOnChange("hotel_check_in", props.attendee.id)}
        inline
        fullWidth
        label={"Hotel Check In"}
        value={formik.values.hotel_check_in ?? ""}
        id="hotel_check_in"
        type={"datetime"}
        time={false}
      />
      <FormField
        onChange={returnSaveOnChange("hotel_check_out", props.attendee.id)}
        inline
        fullWidth
        label={"Hotel Check Out"}
        value={formik.values.hotel_check_out ?? ""}
        id="hotel_check_out"
        type={"datetime"}
        time={false}
      />
      <FormField
        inline
        fullWidth
        label="Dietary Preferences"
        type="multiselect"
        selectedValues={formik.values.dietary_prefs
          .split(",")
          .map((option) => option.trim().toLowerCase())}
        options={Array.from(DIETARY_OPTIONS).map((option: string) => {
          return {
            value: option.trim().toLowerCase(),
            label: <Badge label={option.trim()} />,
          }
        })}
        onChange={(newValues: string[]) => {
          let newValue = newValues.join(",")
          formik.setFieldValue("dietary_prefs", newValue)
          dispatch(
            patchAttendee(props.attendee.id, {
              dietary_prefs: (newValue ? newValue : null) as string,
            })
          )
        }}
      />

      <FormField
        onChange={formik.handleChange}
        onBlur={returnSaveOnBlur("notes", props.attendee.id)}
        inline
        fullWidth
        label={"Notes"}
        value={formik.values.notes ?? ""}
        id="notes"
        type={"textarea"}
      />
      {columns.length > 0 && (
        <AdditionalInfoContainer>
          <Text variant={"title-base"}>Additional Info</Text>
        </AdditionalInfoContainer>
      )}

      {columns.map((column) => {
        let columnAnswerId: number | undefined =
          attendee.column_id_to_answer_id[column.id]
        return (
          <ColumnFormField
            inline
            options={column.options}
            type={column.type}
            title={column.title}
            initialValue={
              columnAnswerId ? answersMap[columnAnswerId]?.value : undefined
            }
            onBlur={(value) => {
              if (!attendee.column_id_to_answer_id[column.id]) {
                createColumnAnswerMutation.mutate({
                  columnId: column.id,
                  value: value as string,
                })
              } else {
                updateColumnAnswerMutation.mutate({
                  columnAnswerId: attendee.column_id_to_answer_id[column.id],
                  value: value as string,
                })
              }
            }}
          />
        )
      })}
    </StyledForm>
  )
}

export function ColumnFormField(props: {
  title: string
  initialValue?: string | string[]
  onBlur: (value: string | string[]) => void
  type?: FormQuestionType
  options?: string[]
  inline?: boolean
}) {
  let [value, setValue] = useState(props.initialValue ?? "")
  useEffect(() => {
    setValue(props.initialValue ?? "")
  }, [props.initialValue])
  if (props.type === "SHORT_ANSWER") {
    return (
      <FormField
        type="textfield"
        label={props.title}
        inline={props.inline}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => props.onBlur(value)}
      />
    )
  } else if (props.type === "LONG_ANSWER") {
    return (
      <FormField
        type="textarea"
        label={props.title}
        inline={props.inline}
        value={value as string}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => props.onBlur(value)}
      />
    )
  } else if (props.type === "SINGLE_SELECT") {
    return (
      <FormField
        type="select"
        label={props.title}
        inline={props.inline}
        value={props.initialValue as string}
        onChange={(value) => props.onBlur(value)}>
        {props.options &&
          props.options.map((option) => {
            return <SelectItem value={option} label={option} />
          })}
      </FormField>
    )
  } else if (props.type === "DATE") {
    return (
      <FormField
        type="datetime"
        label={props.title}
        inline={props.inline}
        value={props.initialValue as string}
        onChange={props.onBlur}
      />
    )
  } else if (props.type === "DATETIME") {
    return (
      <FormField
        type="datetime"
        label={props.title}
        time
        inline={props.inline}
        value={props.initialValue as string}
        onChange={props.onBlur}
      />
    )
  } else if (props.type === "MULTI_SELECT") {
    let values =
      typeof props.initialValue === "object"
        ? props.initialValue
        : props.initialValue
        ? props.initialValue.split(",")
        : []
    return (
      <FormField
        type="multiselect"
        label={props.title}
        options={
          props.options
            ? props.options.map((option) => {
                return {value: option, label: <Badge label={option} />}
              })
            : []
        }
        inline={props.inline}
        selectedValues={values}
        onChange={(newValues) => props.onBlur(newValues.join(","))}
      />
    )
  }
  return (
    <FormField
      type="textfield"
      label={props.title}
      inline={props.inline}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => props.onBlur(value)}
    />
  )
}
