import {useMediaQuery} from "@material-ui/core"
import {AirplanemodeActive, TrainRounded} from "@material-ui/icons"
import {Button} from "@summtech/flok-base/components/Button"
import {FormField} from "@summtech/flok-base/components/FormField"
import {styled} from "@summtech/flok-base/stitches.config"
import {useFormik} from "formik"
import {useState} from "react"
import {useDispatch} from "react-redux"
import * as yup from "yup"
import {ApiAction} from "../../store/actions/api"
import {
  deleteFlight,
  patchFlight,
  postFlight,
} from "../../store/actions/retreat"
import {FlokTheme} from "../../theme"
import {useFlight} from "../../utils/retreatUtils"
import AppModal, {AppModalProps} from "../app/AppModal"

export type FlightFormProps = {
  flightId?: number
  tripId: number
  onCancel: () => void
  onDelete?: () => void
  onSuccess?: () => void
}

const FlightFormContainer = styled("form", {
  minWidth: "300px",
  flex: 1,
  "@bp2": {
    width: "640px",
  },
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
})

const ButtonFlex = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  variants: {
    noFlightId: {
      true: {
        marginLeft: "auto",
      },
    },
  },
})

export function FlightForm(props: FlightFormProps) {
  let dispatch = useDispatch()
  let [flight] = useFlight(props.flightId ?? -1)
  let formik = useFormik({
    validateOnChange: false,
    initialValues: {
      dep_airport: flight?.dep_airport ?? "",
      arr_airport: flight?.arr_airport ?? "",
      dep_datetime: flight?.dep_datetime ?? "",
      arr_datetime: flight?.arr_datetime ?? "",
      flight_num: flight?.flight_num ?? "",
      airline: flight?.airline ?? "",
      trip_leg_type: flight?.trip_leg_type ?? "FLIGHT",
      dep_station: flight?.dep_station ?? "",
      arr_station: flight?.arr_station ?? "",
      train_num: flight?.train_num ?? "",
      railway: flight?.railway ?? "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (props.flightId) {
        let response = (await dispatch(
          patchFlight(props.flightId, values)
        )) as unknown as ApiAction
        if (!response.error && props.onSuccess) {
          props.onSuccess()
        }
      } else {
        let response = (await dispatch(
          postFlight({...values, trip_id: props.tripId})
        )) as unknown as ApiAction
        if (!response.error && props.onSuccess) {
          props.onSuccess()
        }
      }
    },
    validationSchema: yup.object({
      dep_airport: yup.string().when("trip_leg_type", {
        is: "FLIGHT",
        then: yup.string().required("Airport is required"),
      }),
      arr_airport: yup.string().when("trip_leg_type", {
        is: "FLIGHT",
        then: yup.string().required("Airport is required"),
      }),
      dep_station: yup.string().when("trip_leg_type", {
        is: "TRAIN",
        then: yup.string().required("Station is required"),
      }),
      arr_station: yup.string().when("trip_leg_type", {
        is: "TRAIN",
        then: yup.string().required("Station is required"),
      }),
      dep_datetime: yup.string().required("Departure time is required"),
      arr_datetime: yup.string().required("Arrival time is required"),
    }),
  })
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const isSmallScreen = useMediaQuery((theme: FlokTheme) =>
    theme.breakpoints.down("sm")
  )

  const isFlight = formik.values.trip_leg_type === "FLIGHT"
  const isTrain = formik.values.trip_leg_type === "TRAIN"
  return (
    <FlightFormContainer onSubmit={formik.handleSubmit}>
      <AppModal.Body>
        <FormField
          inline={!isSmallScreen}
          fullWidth={isSmallScreen}
          color="gray"
          type="buttongroup"
          label="Type"
          value={formik.values.trip_leg_type}
          options={[
            {
              value: "FLIGHT",
              text: "Flight",
              startIcon: <AirplanemodeActive style={{height: "20px"}} />,
            },
            {
              value: "TRAIN",
              text: "Train",
              startIcon: <TrainRounded style={{height: "20px"}} />,
            },
          ]}
          onChange={(value) => {
            formik.setFieldValue("trip_leg_type", value)
          }}></FormField>

        {isFlight && (
          <FormField
            type="textfield"
            label="From"
            errorMessage={formik.errors.dep_airport}
            inline={!isSmallScreen}
            fullWidth
            id="dep_airport"
            onChange={formik.handleChange}
            value={formik.values.dep_airport}></FormField>
        )}
        {isFlight && (
          <FormField
            type="textfield"
            label="To"
            inline={!isSmallScreen}
            fullWidth
            errorMessage={formik.errors.arr_airport}
            id="arr_airport"
            onChange={formik.handleChange}
            value={formik.values.arr_airport}></FormField>
        )}
        {isTrain && (
          <FormField
            type="textfield"
            label="Departure station"
            errorMessage={formik.errors.dep_station}
            inline={!isSmallScreen}
            fullWidth
            id="dep_station"
            onChange={formik.handleChange}
            value={formik.values.dep_station}></FormField>
        )}
        {isTrain && (
          <FormField
            type="textfield"
            label="Arrival station"
            inline={!isSmallScreen}
            fullWidth
            errorMessage={formik.errors.arr_station}
            id="arr_station"
            onChange={formik.handleChange}
            value={formik.values.arr_station}></FormField>
        )}
        <FormField
          type="datetime"
          time
          label="Departure"
          errorMessage={formik.errors.dep_datetime}
          inline={!isSmallScreen}
          onChange={(value) => {
            formik.setFieldValue("dep_datetime", value)
          }}
          value={formik.values.dep_datetime}></FormField>
        <FormField
          type="datetime"
          time
          errorMessage={formik.errors.arr_datetime}
          label="Arrival"
          inline={!isSmallScreen}
          onChange={(value) => {
            formik.setFieldValue("arr_datetime", value)
          }}
          value={formik.values.arr_datetime}></FormField>
        {isFlight && (
          <FormField
            type="textfield"
            label="Flight #"
            inline={!isSmallScreen}
            id="flight_num"
            onChange={formik.handleChange}
            value={formik.values.flight_num}
            fullWidth></FormField>
        )}
        {isFlight && (
          <FormField
            id="airline"
            onChange={formik.handleChange}
            value={formik.values.airline}
            type="textfield"
            label="Airline"
            inline={!isSmallScreen}
            fullWidth></FormField>
        )}
        {isTrain && (
          <FormField
            type="textfield"
            label="Train #"
            inline={!isSmallScreen}
            id="train_num"
            onChange={formik.handleChange}
            value={formik.values.train_num}
            fullWidth></FormField>
        )}
        {isTrain && (
          <FormField
            id="railway"
            onChange={formik.handleChange}
            value={formik.values.railway}
            type="textfield"
            label="Railway company"
            inline={!isSmallScreen}
            fullWidth></FormField>
        )}
      </AppModal.Body>
      <AppModal.Footer>
        {props.flightId && (
          <Button
            color="critical"
            text={
              confirmingDelete ? "Are you sure you wish to Delete?" : "Delete"
            }
            variant={confirmingDelete ? "solid" : "outline"}
            onClick={(e) => {
              e.preventDefault()
              if (confirmingDelete) {
                dispatch(deleteFlight(props.flightId!))
                props.onDelete && props.onDelete()
              } else {
                setConfirmingDelete(true)
              }
            }}
          />
        )}
        <ButtonFlex noFlightId={!props.flightId}>
          <Button
            color="gray"
            text="Cancel"
            variant={"outline"}
            onClick={props.onCancel}
          />
          <Button color="brand" text="Save" variant={"solid"} type="submit" />
        </ButtonFlex>
      </AppModal.Footer>
    </FlightFormContainer>
  )
}

type FlightFormModalProps = AppModalProps & FlightFormProps
export function FlightFormModal(props: FlightFormModalProps) {
  return (
    <AppModal open={props.open} onClose={props.onClose}>
      <AppModal.Header>
        {props.flightId ? "Edit Flight" : "Add Flight"}
      </AppModal.Header>
      <FlightForm
        flightId={props.flightId}
        tripId={props.tripId}
        onCancel={props.onCancel}
        onDelete={props.onDelete}
        onSuccess={props.onSuccess}
      />
    </AppModal>
  )
}
