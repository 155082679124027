import {PaperPlaneIcon} from "@radix-ui/react-icons"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {convertToRaw, EditorState, RawDraftContentState} from "draft-js"
import {useFormik} from "formik"
import _ from "lodash"
import {useDispatch} from "react-redux"
import {AppWysiwygEditor} from "../../components/base/AppWysiwyg"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {useUsers} from "../../utils/retreatUtils"

const FormContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "20px",
})
const EditorWrapper = styled("div", {
  flex: 1,
  maxWidth: "calc(100% - 50px)",
  background: theme.colors.white,
  border: `1px solid ${theme.colors.gray7}`,
  borderRadius: theme.shape.borderRadius,
})

export default function CommentForm(props: {
  onSubmit: (values: {
    comment_text: RawDraftContentState
    user_ids?: number[]
  }) => void
  disableToolbar?: true
}) {
  let formik = useFormik({
    initialValues: {
      comment_text: EditorState.createEmpty(),
    },
    onSubmit: (values) => {
      props.onSubmit({
        comment_text: convertToRaw(values.comment_text.getCurrentContent()),
      })
    },
  })
  let dispatch = useDispatch()
  let [retreat] = useRetreat()
  let [users] = useUsers(retreat.users)

  return (
    <FormContainer>
      <EditorWrapper>
        <AppWysiwygEditor
          mentionSuggestions={Object.values(users)
            .filter((user) => user)
            .map((user) => {
              return {
                text: `${user?.first_name} ${user?.last_name}`,
                value: `${user?.first_name} ${user?.last_name}`,
                url: `${user?.id}`,
              }
            })}
          disableToolbar={props.disableToolbar}
          editorState={formik.values.comment_text}
          onEditorStateChange={(val) => {
            formik.setFieldValue("comment_text", val)
          }}
        />
      </EditorWrapper>
      <IconButton
        color="brand"
        disabled={_.isEqual(
          convertToRaw(formik.values.comment_text.getCurrentContent()),
          convertToRaw(formik.initialValues.comment_text.getCurrentContent())
        )}
        onClick={() => {
          let mentionsArray = Object.values(
            convertToRaw(formik.values.comment_text.getCurrentContent())
              .entityMap
          )
            .filter((entity) => entity.type === "MENTION")
            .map((entity) => entity.data)
          props.onSubmit({
            comment_text: convertToRaw(
              formik.values.comment_text.getCurrentContent()
            ),
            user_ids: mentionsArray.map((data) => data.url),
          })
          formik.resetForm()
        }}>
        <PaperPlaneIcon />
      </IconButton>
    </FormContainer>
  )
}
