import {UploadIcon} from "@radix-ui/react-icons"
import {Banner} from "@summtech/flok-base/components/Banner"
import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import React, {useState} from "react"
import {useDispatch} from "react-redux"
import {AttendeeBatchUploadApiResponse} from "../../../models/api"
import {RetreatAttendeeModel} from "../../../models/retreat"
import {enqueueSnackbar} from "../../../notistack-lib/actions"
import {useRetreat} from "../../../pages/misc/RetreatProvider"
import {ApiAction} from "../../../store/actions/api"
import {postRetreatAttendeesBatch} from "../../../store/actions/retreat"
import AppModal from "../AppModal"
import AppTable from "../AppTable"
import {FileUploadButton} from "../FileUploadButton"

let StyledAppModal = styled("div", {
  maxWidth: "700px",
})
let StyledFormSubheader = styled("div", {
  marginBottom: "5px",
  marginTop: "10px",
  marginInline: "20px",
  color: theme.colors.gray11,
})
let StyledSuccessBannerContainer = styled("div", {
  marginBottom: "20px",
})
let StyledContentContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBlock: "15px",
  marginInline: "20px",
})
let StyledForm = styled("form", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "10px",
  maxHeight: "40px",
  alignItems: "center",
})
let StyledAttendeesPreview = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})
let StyledAppTableContainer = styled("div", {
  maxWidth: "700px",
  marginBottom: "20px",
})
let StyledAppTableFooter = styled("div", {
  width: "100%",
  padding: "10px",
  minWidth: "170px",
  backgroundColor: `${theme.colors.gray2}`,
  color: `${theme.colors.gray11}`,
  textAlign: "center",
})

let StyledInputContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  alignContent: "center",
  padding: "8px 12px",
  width: "fit-content",
  background: theme.colors.white,
  color: theme.colors.black,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.colors.gray7}`,
  cursor: "pointer",
  gap: "6px",
  "&:disabled": {
    opacity: 0.5,
    cursor: "not-allowed",
  },
  minWidth: "100px",
  justifyContent: "center",
  marginBlock: "10px",
  marginInline: "20px",
})

type BatchUploadModalProps = {
  open: boolean
  onClose: () => void
}
export function BatchUploadModal(props: BatchUploadModalProps) {
  let {open, onClose} = props
  let [retreat] = useRetreat()
  let dispatch = useDispatch()
  const [batchUploadResponse, setBatchUploadResponse] = useState<
    AttendeeBatchUploadApiResponse | undefined
  >(undefined)
  let [parsedFile, setParsedFile] = useState<
    | Pick<
        RetreatAttendeeModel,
        "first_name" | "last_name" | "retreat_id" | "email_address"
      >[]
    | undefined
  >(undefined)

  function batchAttendeeResponsetoJSX(
    response: AttendeeBatchUploadApiResponse
  ) {
    if (response.errors.length === 0) {
      return (
        <StyledSuccessBannerContainer>
          <Banner
            message="All Attendees successfully added"
            variant={"success"}
            fullWidth
          />
        </StyledSuccessBannerContainer>
      )
    } else {
      response = response as unknown as AttendeeBatchUploadApiResponse
      return (
        <>
          {response.attendees.length > 0 && (
            <Text>
              {response.attendees.length} attendee
              {response.attendees.length > 1 ? "s" : ""} successfully added
            </Text>
          )}
          <Banner
            message="The following attendees could not be added"
            variant={"error"}
            fullWidth
          />
          <StyledAppTableContainer>
            <AppTable
              columns={[
                {
                  id: "email_address",
                  name: "Email",
                },
                {id: "first_name", name: "First Name"},
                {id: "last_name", name: "Last Name"},
              ]}
              rows={response.errors.slice(0, 4)}
            />
            {response.errors.length - 4 > 0 && (
              <StyledAppTableFooter>
                <Text variant={"text-xs"}>{`...and ${
                  response.errors.length - 4
                } attendees more`}</Text>
              </StyledAppTableFooter>
            )}
          </StyledAppTableContainer>
        </>
      )
    }
  }
  async function handleOnSubmit(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    if (parsedFile) {
      let response = (await dispatch(
        postRetreatAttendeesBatch({attendees: parsedFile}, retreat.id)
      )) as unknown as ApiAction

      if (!response.error) {
        setBatchUploadResponse(response.payload)
        setParsedFile(undefined)
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Oops, something went wrong",
            options: {
              variant: "error",
            },
          })
        )
      }
    }
  }

  const handleUpload = (data: string[][]) => {
    setBatchUploadResponse(undefined)
    let filteredData = data
      .map((row: string[]) => row.filter((item) => item && item.length))
      .filter((item) => item && item.length)
      .map((attendee: string[]) => {
        return {
          first_name: attendee[1],
          last_name: attendee[2],
          email_address: attendee[0],
          retreat_id: retreat.id,
        }
      })

    setParsedFile(filteredData)
  }
  return (
    <AppModal
      open={open}
      onClose={() => {
        onClose()
        setParsedFile(undefined)
        setBatchUploadResponse(undefined)
      }}>
      <StyledAppModal>
        <AppModal.Header>Batch Upload Attendees</AppModal.Header>
        <AppModal.Body>
          <StyledFormSubheader>
            <Text variant="text-sm">
              To batch upload, please upload a CSV or XLSX file. The file should
              be in the format Email, First Name, Last Name, with no headers.
            </Text>
          </StyledFormSubheader>

          <StyledContentContainer>
            {batchUploadResponse != null ? (
              batchAttendeeResponsetoJSX(batchUploadResponse)
            ) : parsedFile ? (
              <StyledAttendeesPreview>
                <StyledAppTableContainer>
                  <AppTable
                    columns={[
                      {
                        id: "email_address",
                        name: "Email",
                      },
                      {id: "first_name", name: "First Name"},
                      {id: "last_name", name: "Last Name"},
                    ]}
                    rows={parsedFile.slice(0, 4)}
                  />
                  {parsedFile.length - 4 > 0 && (
                    <StyledAppTableFooter>
                      <Text variant={"text-xs"}>{`...and ${
                        parsedFile.length - 4
                      } attendees more`}</Text>
                    </StyledAppTableFooter>
                  )}
                </StyledAppTableContainer>
              </StyledAttendeesPreview>
            ) : (
              <></>
            )}
            {parsedFile ? (
              <FileUploadButton
                onUpload={(data) => handleUpload(data)}
                hideUploadIcon={!!parsedFile}
                children={
                  <StyledInputContainer>
                    <UploadIcon />
                    <Text variant={"text-sm-plus"}>Upload New File</Text>
                  </StyledInputContainer>
                }
              />
            ) : (
              <Button
                text={"Download Sample CSV"}
                variant="outline"
                onClick={() =>
                  (window.location.href =
                    "https://flok-b32d43c.s3.us-east-1.amazonaws.com/misc/sample-batch-upload.csv")
                }
                color="gray"
              />
            )}
          </StyledContentContainer>
        </AppModal.Body>
        <AppModal.Footer>
          <StyledForm onSubmit={(e) => e.preventDefault()}>
            <Button
              text={"Cancel"}
              variant={"outline"}
              color={"gray"}
              onClick={() => {
                onClose()
                setParsedFile(undefined)
                setBatchUploadResponse(undefined)
              }}
            />
            {parsedFile ? (
              <Button
                text={"Add Attendees"}
                variant={"solid"}
                color={"brand"}
                onClick={handleOnSubmit}
              />
            ) : (
              <FileUploadButton
                onUpload={(data) => handleUpload(data)}
                hideUploadIcon={!!parsedFile}
              />
            )}
          </StyledForm>
        </AppModal.Footer>
      </StyledAppModal>
    </AppModal>
  )
}
