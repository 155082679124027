// THIS FILE IS DEPRECATED AND ONLY KEPT TO SUPPORT ATTENDEE TABLE V1
import {createApiAction} from "./api"

export const GET_FORM_QUESTION_REQUEST = "GET_FORM_QUESTION_REQUEST"
export const GET_FORM_QUESTION_SUCCESS = "GET_FORM_QUESTION_SUCCESS"
export const GET_FORM_QUESTION_FAILURE = "GET_FORM_QUESTION_FAILURE"

export function getFormQuestion(questionId: number) {
  let endpoint = `/v1.0/questions/${questionId}`
  return createApiAction({
    method: "GET",
    endpoint,
    types: [
      GET_FORM_QUESTION_REQUEST,
      GET_FORM_QUESTION_SUCCESS,
      GET_FORM_QUESTION_FAILURE,
    ],
  })
}

export const GET_FORM_RESPONSES_REQUEST = "GET_FORM_RESPONSES_REQUEST"
export const GET_FORM_RESPONSES_SUCCESS = "GET_FORM_RESPONSES_SUCCESS"
export const GET_FORM_RESPONSES_FAILURE = "GET_FORM_RESPONSES_FAILURE"
export function getFormResponses(formResponseIds: number[]) {
  if (formResponseIds.length > 200) {
    formResponseIds = formResponseIds.slice(0, 200)
  }
  let endpoint = `/v1.0/form-responses?${new URLSearchParams(
    formResponseIds.map((id) => ["id", id.toString()])
  )}`
  return createApiAction({
    method: "GET",
    endpoint,
    types: [
      {type: GET_FORM_RESPONSES_REQUEST},
      {type: GET_FORM_RESPONSES_SUCCESS},
      {type: GET_FORM_RESPONSES_FAILURE},
    ],
  })
}
