import {Paper, Table, TableBody, TableCell, TableRow} from "@material-ui/core"
import {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {FlokEngagementTypeEnum, RFPModel} from "../../models/retreat"
import {RootState} from "../../store"
import {getLodgingTags} from "../../store/actions/lodging"

export default function RFPSummaryViewer(props: {rfp?: RFPModel}) {
  let dispatch = useDispatch()
  let lodgingTags = useSelector((state: RootState) => {
    return state.lodging.lodgingTags
  })
  useEffect(() => {
    !Object.values(lodgingTags)[0] && dispatch(getLodgingTags())
  }, [dispatch, lodgingTags])

  const agendaTypeMap = {
    WORK_PLAY_SPLIT: "Work and play split",
    WORK_PLAY_ALT: "Work and play alt",
    ALL_WORK: "All work",
    ALL_PLAY: "All play",
  }
  function summaryValues(rfp?: RFPModel, flokHelp?: boolean) {
    let flokEngagement = "N/A"
    switch (rfp?.flok_engagement_type) {
      case FlokEngagementTypeEnum.SOURCING_ONLY:
        flokEngagement = "Venue Sourcing only"
        break
      case FlokEngagementTypeEnum.SOURCING_AND_PLANNING:
        flokEngagement = "Sourcing and planning"
        break
      case FlokEngagementTypeEnum.UNDECIDED:
        flokEngagement = "Undecided"
        break
    }
    let returnValues: {
      Dates?: string
      "Number of nights"?: number
      "Preferred months"?: string
      "Dates notes"?: string
      "Agenda type"?: string
      "Agenda notes"?: string
      "Number of rooms"?: number
      "Preferred budget"?: string
      "Corporate address"?: string
      "General notes"?: string
      "Preferred location"?: string
      Tags?: string
      "Sourcing Preferences"?: string
      "Flok services"?: string
      "Flok services note"?: string
    } = {}
    if (rfp?.has_exact_dates) {
      returnValues.Dates = `${rfp?.exact_dates_start} to ${rfp?.exact_dates_end}`
    } else {
      returnValues["Number of nights"] = rfp?.flexible_number_of_nights
      returnValues["Preferred months"] = rfp?.flexible_preferred_months
        ? rfp?.flexible_preferred_months.join(", ")
        : "N/A"
    }
    returnValues["Agenda type"] = rfp?.agenda_type
      ? agendaTypeMap[rfp.agenda_type]
      : undefined
    returnValues["Dates notes"] = rfp?.dates_notes
    returnValues["Agenda notes"] = rfp?.agenda_notes
    returnValues["Number of rooms"] = rfp?.number_of_rooms
    returnValues["Corporate address"] = rfp?.corporate_address
    returnValues["Preferred budget"] = rfp?.budget_notes
    returnValues["Preferred location"] = rfp?.location_notes
    returnValues.Tags = rfp?.lodging_tag_ids
      ? rfp.lodging_tag_ids.map((id) => lodgingTags[id].name).join(", ")
      : "N/A"
    returnValues["Flok services"] = flokEngagement
    returnValues["Flok services note"] = rfp?.flok_engagement_notes || "N/A"
    returnValues["General notes"] = rfp?.general_notes

    return returnValues
  }
  return (
    <Paper elevation={0}>
      <Table>
        <TableBody>
          {Object.entries(summaryValues(props.rfp)).map(([q, a]) => {
            return (
              <TableRow>
                <TableCell>
                  <strong>{q}</strong>
                </TableCell>
                <TableCell>{a || "N/A"}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}
