import {
  CalendarIcon,
  GearIcon,
  HamburgerMenuIcon,
  Pencil1Icon,
} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {SegmentedControlGroup} from "@summtech/flok-base/components/SegmentedControlGroup"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {push} from "connected-react-router"
import {useState} from "react"
import {useDispatch} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import AppLoadingScreen from "../../components/base/AppLoadingScreen"
import PageBody from "../../components/page/PageBody"
import {ItineraryEventModel, ItineraryModel} from "../../models/retreat"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../Stack"
import {useItinerary, useItineraryEvents} from "../../utils/itineraryUtils"
import Calendar from "../itinerary-builder/Calendar"
import CreateItinerary from "../itinerary-builder/CreateItinerary"
import {EventBadge} from "../itinerary-builder/EventBadge"
import ItineraryBuilderSideTabs from "../itinerary-builder/ItineraryBuilderSideTabs"
import ItineraryEventForm from "../itinerary-builder/ItineraryEventForm"

const OverallBody = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100%",
  background: theme.colors.white,
})
const ItineraryPageBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "calc(100% - 40px)",
  height: "98%",
  marginLeft: "20px",
  marginRight: "20px",
  overflow: "auto",
})
const ItineraryPageHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "32px 8px",
})
const ItineraryPageRightHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  marginRight: "32px",
})

const Rectangle5 = styled("div", {
  width: "1px",
  height: "24px",
  background: theme.colors.gray6,
})
const ItineraryPageBodyDisplay = styled("div", {
  flex: 1,
  overflow: "auto",
})
const ItineraryListHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  width: "100%",
  borderBottomStyle: "solid",
  borderBottomColor: theme.colors.gray6,
  borderBottomWidth: "1px",
  padding: "6px",
})
const ItineraryListHeaderOutsideRow = styled("div", {
  width: "180px",
  padding: "8px",
  [`${Text}`]: {
    color: theme.colors.gray11,
  },
})
const ItineraryListHeaderInnerRow = styled("div", {
  flex: 1,
  padding: "8px",
  [`${Text}`]: {
    color: theme.colors.gray11,
  },
})

const ItinerarySectionRowContainer = styled("div", {
  borderBottomStyle: "solid",
  borderBottomColor: theme.colors.gray6,
  borderBottomWidth: "1px",
  display: "flex",
  flexDirection: "row",
  width: "100%",
  background: theme.colors.gray2,
  padding: "8px 12px",
  alignItems: "center",
})
const DayContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  padding: "6px 8px",
})
const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

const ItineraryRowContainer = styled("div", {
  borderBottomStyle: "solid",
  borderBottomColor: theme.colors.gray6,
  borderBottomWidth: "1px",
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "18px 12px",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.colors.gray2,
  },
})
const TimeContainer = styled("div", {
  display: "flex",
  width: "180px",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  padding: "6px 8px",
  [`${Text}`]: {
    color: theme.colors.gray11,
  },
})
const TypeContainer = styled("div", {
  display: "flex",
  width: "180px",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  padding: "6px 8px",
})
const EventContainer = styled("div", {
  display: "flex",
  width: "160px",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  padding: "5px 6px",
  flex: 1,
})
const RenderDaysContainer = styled("div", {
  display: "flex",
  width: "100%",
  flexDirection: "column",
})

const NoSiteContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  background: theme.colors.white,
  padding: "20px 32px",
  justifyContent: "center",
  alignItems: "center",
})

export function timeToDate(time: string) {
  let date = new Date(`December 14, 2026 ${time}`)
  return date
}
export function formatTime(time: string) {
  let date = timeToDate(time)
  return new Intl.DateTimeFormat(undefined, {
    timeStyle: "short",
  }).format(date)
}
export function sortEvents(events: ItineraryEventModel[]) {
  return events.sort((a, b) => {
    if (a.all_day && b.all_day) {
      return 0
    } else if (a.all_day) {
      return -1
    } else if (b.all_day) {
      return 1
    } else if (!a.start_time && !b.start_time) {
      return 0
    } else if (!a.start_time) {
      return -1
    } else if (!b.start_time) {
      return 1
    }
    return timeToDate(a.start_time) > timeToDate(b.start_time) ? 1 : -1
  })
}

export function addToDate(dateString: string, days: number) {
  // DEPRECATED USE getIsoDate instead
  // must pass in date in string format
  let date = new Date(dateString)
  date.setDate(date.getDate() + days)
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - offset * 60 * 1000)
  return date.toISOString().split("T")[0]
}

export function getIsoDate(dateString: string, daysOffset?: number) {
  let date = new Date(`${dateString}T00:00:00.000Z`)
  if (daysOffset) {
    date.setDate(date.getDate() + daysOffset)
  }
  return date
}

function ItinerarySectionRow(props: {day: number; start_date?: string}) {
  return (
    <ItinerarySectionRowContainer>
      <DayContainer>
        <Text variant="text-sm-plus">Day {props.day}</Text>
        {props.start_date && (
          <GrayText variant="text-sm-plus">
            {new Intl.DateTimeFormat(undefined, {
              day: "numeric",
              month: "long",
              timeZone: "UTC",
            }).format(getIsoDate(props.start_date, props.day - 1))}
          </GrayText>
        )}
      </DayContainer>
    </ItinerarySectionRowContainer>
  )
}
function ItineraryEventRow(props: {
  event: ItineraryEventModel
  onClick?: () => void
}) {
  return (
    <ItineraryRowContainer onClick={props.onClick}>
      <TimeContainer>
        {props.event.start_time && !props.event.all_day && (
          <Text>
            {formatTime(props.event.start_time)}
            {props.event.end_time
              ? `- ${formatTime(props.event.end_time)}`
              : ""}
          </Text>
        )}
        {props.event.all_day && <Text>All Day</Text>}
      </TimeContainer>
      <EventContainer>
        <Text variant="text-sm">{props.event.title}</Text>
        {props.event.location && (
          <GrayText variant="text-sm">{props.event.location}</GrayText>
        )}
      </EventContainer>
      {props.event.label && (
        <TypeContainer>
          <EventBadge label={props.event.label} />
        </TypeContainer>
      )}
    </ItineraryRowContainer>
  )
}

function renderDays(
  itinerary: ItineraryModel,
  events: ItineraryEventModel[],
  onClick: (id: number) => () => void
) {
  let returnValue = [
    <ItineraryListHeader>
      <ItineraryListHeaderOutsideRow>
        <Text variant="text-sm-plus">Time</Text>
      </ItineraryListHeaderOutsideRow>
      <ItineraryListHeaderInnerRow>
        <Text variant="text-sm-plus">Item</Text>
      </ItineraryListHeaderInnerRow>
      <ItineraryListHeaderOutsideRow>
        <Text variant="text-sm-plus">Type</Text>
      </ItineraryListHeaderOutsideRow>
    </ItineraryListHeader>,
  ]
  for (let i = 0; i < itinerary.number_of_days; i++) {
    returnValue.push(
      <ItinerarySectionRow
        day={i + 1}
        key={`section-row-${i}`}
        start_date={itinerary.start_date}
      />
    )

    sortEvents(events)
      .filter((event) => event && event.day === i + 1)
      .forEach((event) =>
        returnValue.push(
          <ItineraryEventRow event={event} onClick={onClick(event.id)} />
        )
      )
    if (events.filter((event) => event && event.day === i + 1).length === 0) {
      returnValue.push(
        <ItineraryRowContainer>No events scheduled</ItineraryRowContainer>
      )
    }
  }
  if (
    events.filter((event) => event.day > itinerary.number_of_days || !event.day)
      .length > 0
  ) {
    returnValue.push(
      <ItinerarySectionRowContainer>
        <Text variant="text-sm-plus"> Unscheduled Events</Text>
      </ItinerarySectionRowContainer>
    )
    events
      .filter((event) => event.day > itinerary.number_of_days || !event.day)
      .forEach((event) =>
        returnValue.push(
          <ItineraryEventRow event={event} onClick={onClick(event.id)} />
        )
      )
  }
  return returnValue
}
export default function ItineraryBuilderPage() {
  let dispatch = useDispatch()
  let [retreat, retreatIdx] = useRetreat()
  let [itinerary, loadingItinerary] = useItinerary(retreat.itinerary_id ?? -1)
  let [events] = useItineraryEvents(itinerary?.event_ids ?? [])
  const [displayType, setDisplayType] = useState<"calendar" | "list">("list")
  const [creatingEvent, setCreatingEvent] = useState<boolean>(false)
  let router = useRouteMatch<{
    retreatIdx: string
    eventId: string
  }>()
  let eventId = router.params.eventId
  const [tabValue, setTabValue] = useState<"comments" | "settings" | false>(
    false
  )
  if (!itinerary) {
    return (
      <PageBody appBar>
        <NoSiteContainer>
          {loadingItinerary ? <AppLoadingScreen /> : <CreateItinerary />}
        </NoSiteContainer>
      </PageBody>
    )
  }
  return (
    <PageBody appBar>
      <ItineraryEventForm
        itineraryId={retreat.itinerary_id ?? -1}
        onSubmit={() => {
          dispatch(
            push(
              AppRoutes.getPath("ItineraryPage", {
                retreatIdx: retreatIdx.toString(),
              })
            )
          )
          setCreatingEvent(false)
        }}
        open={!!creatingEvent || !!eventId}
        eventId={eventId ? parseInt(eventId) : undefined}
        onClose={() => {
          dispatch(
            push(
              AppRoutes.getPath("ItineraryPage", {
                retreatIdx: retreatIdx.toString(),
              })
            )
          )
          setCreatingEvent(false)
        }}
        days={itinerary?.number_of_days ?? 4}
        startDate={itinerary?.start_date}
      />
      <OverallBody>
        <ItineraryPageBody>
          <ItineraryPageHeader>
            <Text variant="title-xl">Itinerary</Text>

            <ItineraryPageRightHeader>
              <SegmentedControlGroup
                value={displayType}
                onChange={(newValue) => {
                  setDisplayType(newValue as "calendar" | "list")
                }}
                options={[
                  {label: "", icon: <HamburgerMenuIcon />, value: "list"},
                  {label: "", icon: <CalendarIcon />, value: "calendar"},
                ]}
              />
              <Button
                variant={"solid"}
                color="brand"
                text={"Add Event"}
                onClick={() => {
                  setCreatingEvent(true)
                }}
              />
              <Rectangle5 />
              <IconButton
                variant="outline"
                color="gray"
                onClick={() => {
                  setTabValue("comments")
                }}>
                <Pencil1Icon />
              </IconButton>
              <IconButton
                variant="outline"
                color="gray"
                onClick={() => {
                  setTabValue("settings")
                }}>
                <GearIcon />
              </IconButton>
            </ItineraryPageRightHeader>
          </ItineraryPageHeader>
          <ItineraryPageBodyDisplay>
            {itinerary && displayType === "list" && (
              <RenderDaysContainer>
                {renderDays(
                  itinerary,
                  Object.values(events) as ItineraryEventModel[],
                  (id) => () => {
                    dispatch(
                      push(
                        AppRoutes.getPath("ItineraryEventPage", {
                          retreatIdx: retreatIdx.toString(),
                          eventId: id.toString(),
                        })
                      )
                    )
                  }
                )}
              </RenderDaysContainer>
            )}
            {itinerary && displayType === "calendar" && (
              <Calendar
                retreatIdx={retreatIdx}
                itinerary={itinerary}
                events={
                  Object.values(events).filter(
                    (event) => event
                  ) as ItineraryEventModel[]
                }
              />
            )}
          </ItineraryPageBodyDisplay>
        </ItineraryPageBody>

        {itinerary && tabValue && (
          <ItineraryBuilderSideTabs
            itinerary={itinerary}
            tabValue={tabValue}
            events={
              Object.values(events).filter(
                (event) => event
              ) as ItineraryEventModel[]
            }
            setTabValue={(newVal) =>
              setTabValue(newVal as false | "comments" | "settings")
            }
            onClose={() => {
              setTabValue(false)
            }}
          />
        )}
      </OverallBody>
    </PageBody>
  )
}
