import {CircularProgress} from "@material-ui/core"
import {BellIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {NavigationItem} from "@summtech/flok-base/components/Navigation"
import {Tab} from "@summtech/flok-base/components/Tab"
import {TabsList} from "@summtech/flok-base/components/TabsList"
import {TabsWrapper} from "@summtech/flok-base/components/TabsWrapper"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {ActivityModel, RetreatActivitySiteLocation} from "../../models/activity"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {RootState} from "../../store"
import {getRetreatActivities} from "../../store/actions/retreat"
import ActivityItem from "./ActivityItem"

const DropdownTopContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "16px 20px 0px 20px",
  width: "100%",
  borderBottom: `1px solid ${theme.colors.gray6}`,
})
const DropdownInnerContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  width: "100%",
  maxWidth: "466px",
  minHeight: "calc(100vh - 20px)",
  alignItems: "center",
})

export default function ActivityDropdown(props: {}) {
  let [retreat] = useRetreat()
  let dispatch = useDispatch()
  const [tabValue, setTabValue] = useState<RetreatActivitySiteLocation | "ALL">(
    "ALL"
  )
  const {offset, hasNext} = useSelector((state: RootState) => {
    return state.retreat.activitiesPagination[
      tabValue as keyof typeof state.retreat.activitiesPagination
    ]
  })

  const [loadingShowMore, setLoadingShowMore] = useState(false)
  useEffect(() => {
    if (offset === 0) {
      dispatch(
        getRetreatActivities(
          retreat.id,
          offset,
          tabValue === "ALL" ? undefined : tabValue
        )
      )
    }
  }, [retreat, dispatch, tabValue, offset])

  const activities = useSelector((state: RootState) => {
    return Object.values(state.retreat.activities).filter((activity) => {
      return activity?.retreat_id === retreat.id
    })
  })

  return (
    <Dropdown
      position={"right"}
      button={
        <NavigationItem name={"Activity"} icon={BellIcon} active={false} />
      }>
      <DropdownInnerContainer>
        <DropdownTopContainer>
          <Text variant={"heading-base"}>Activity</Text>
          <TabsWrapper
            onValueChange={(newValue) => {
              setTabValue(newValue as RetreatActivitySiteLocation | "ALL")
            }}
            value={tabValue}>
            <TabsList>
              <Tab value="ALL" text="All Activity"></Tab>
              {/* <Tab value="TASKS" text="Tasks"></Tab> */}
              <Tab value="ATTENDEES" text="Attendees" />
              <Tab value="REGISTRATION" text="Registration" />
              <Tab value="WEBSITE" text="Landing Page" />
              <Tab value="ITINERARY" text="Itinerary"></Tab>
            </TabsList>
          </TabsWrapper>
        </DropdownTopContainer>
        {(activities as ActivityModel[])
          .sort((a, b) => {
            return new Date(a.created_at) > new Date(b.created_at) ? -1 : 1
          })
          .filter((activity) => {
            if (tabValue !== "ALL") {
              return activity.site_location === tabValue
            } else {
              return true
            }
          })
          .map((activity) => {
            return <ActivityItem activity={activity} />
          })}
        {hasNext &&
          (loadingShowMore ? (
            <CircularProgress size="30px" />
          ) : (
            <Button
              text="Show more"
              onClick={async () => {
                setLoadingShowMore(true)
                await dispatch(
                  getRetreatActivities(
                    retreat.id,
                    offset + 5,
                    tabValue === "ALL" ? undefined : tabValue
                  )
                )
                setLoadingShowMore(false)
              }}
            />
          ))}
      </DropdownInnerContainer>
    </Dropdown>
  )
}
