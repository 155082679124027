import {makeStyles} from "@material-ui/core"
import {PropsWithChildren} from "react"

let useStyles = makeStyles((theme) => ({
  appStep: {
    width: "100%",
  },
}))
export default function AppStepFormStep(
  props: PropsWithChildren<{
    handleNext: () => void
    img?: string
    imgRight?: boolean
  }>
) {
  let classes = useStyles()
  return <div className={classes.appStep}>{props.children}</div>
}

AppStepFormStep.defaultProps = {
  _TYPE: "Flok-AppStepFormStep",
}
