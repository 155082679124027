import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import AppModal from "./AppModal"

const ConfirmationModalContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.colors.gray6,
  borderStyle: "solid",
  borderWidth: "1px",
  background: theme.colors.white,
  width: "400px",
})
const BodyTextWrapper = styled("div", {color: theme.colors.gray11})
const ConfirmationModalBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "24px 20px",
  gap: "8px",
  color: theme.colors.gray12,
})
type ConfirmationModalProps = {
  title: string
  subtitle: string
  open: boolean
  onClose?: () => void
  onConfirm?: () => void
  confirmText?: string
}
export default function ConfirmationModal(props: ConfirmationModalProps) {
  return (
    <AppModal onClose={props.onClose} open={props.open}>
      <AppModal.Body noPadding>
        <ConfirmationModalContainer>
          <ConfirmationModalBody>
            <Text variant="text-sm-plus">{props.title}</Text>
            <BodyTextWrapper>
              <Text variant="text-sm">{props.subtitle}</Text>
            </BodyTextWrapper>
          </ConfirmationModalBody>
          <AppModal.Footer>
            <Button variant="outline" onClick={props.onClose} text={"Cancel"} />
            <Button
              color="critical"
              onClick={props.onConfirm}
              text={props.confirmText ?? "Confirm"}
            />
          </AppModal.Footer>
        </ConfirmationModalContainer>
      </AppModal.Body>
    </AppModal>
  )
}
