import querystring from "querystring"
import {HotelModel} from "../models/lodging"
import {flokAxios} from "./index"

export function getHotels(ids: number[]) {
  return flokAxios<{hotels: HotelModel[]}>({
    url: `/v1.0/hotels?${querystring.stringify({id: ids})}`,
    method: "GET",
  })
}
