import {ClockIcon, SewingPinIcon} from "@radix-ui/react-icons"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {AppWysiwygViewer} from "../../components/base/AppWysiwyg"
import {ItineraryEventModel} from "../../models/retreat"
import {EventBadge} from "../itinerary-builder/EventBadge"
import {formatTime} from "../pages/ItineraryBuilderPage"

const EventContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  flex: 1,
})

const FlexColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
})
const FlexRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})
const WYSIWYGContainer = styled("div", {
  "& div p:first-child": {
    marginTop: 2,
  },
  "& div p:last-child": {
    marginBottom: 2,
  },
})
export default function SiteItineraryEvent(props: {
  event: ItineraryEventModel
}) {
  return (
    <EventContainer>
      <FlexRow>
        <Text variant="heading-lg">{props.event.title}</Text>
        {props.event.label && <EventBadge label={props.event.label} />}
      </FlexRow>
      <FlexColumn>
        {props.event.start_time && !props.event.all_day && (
          <FlexRow>
            <ClockIcon />
            <GrayText variant="text-base">
              {formatTime(props.event.start_time)}
              {props.event.end_time
                ? `- ${formatTime(props.event.end_time)}`
                : ""}
            </GrayText>
          </FlexRow>
        )}
        {props.event.all_day && (
          <FlexRow>
            <ClockIcon />
            <GrayText variant="text-base">All Day</GrayText>
          </FlexRow>
        )}
        {props.event.location && (
          <FlexRow>
            <SewingPinIcon />
            <GrayText variant="text-base">{props.event.location}</GrayText>
          </FlexRow>
        )}
      </FlexColumn>
      {props.event.description && (
        <WYSIWYGContainer>
          <GrayText variant="text-base">
            <AppWysiwygViewer content={props.event.description} />
          </GrayText>
        </WYSIWYGContainer>
      )}
    </EventContainer>
  )
}
