import {Tab} from "@summtech/flok-base/components/Tab"
import {TabContent} from "@summtech/flok-base/components/TabContent"
import {TabsList} from "@summtech/flok-base/components/TabsList"
import {TabsWrapper} from "@summtech/flok-base/components/TabsWrapper"
import {styled} from "@summtech/flok-base/stitches.config"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {useState} from "react"
import {getForm, postForm} from "../../api/form"
import {patchRetreat} from "../../api/retreat"
import {getSite} from "../../api/site"
import {FormCreationTypeEnum} from "../../models/form"
import {AppRoutes} from "../../Stack"
import {titleToNavigation} from "../../utils"
import AppLinkButton from "../app/AppLinkButton"
import CenterMessage from "../app/common/CenterMessage"
import {useRetreatV2} from "../app/common/RetreatProviderV2"
import FullPage from "../app/layout/FullPage"
import {WebsitePortal} from "../app/layout/WebsitePortal"
import FormBuilder from "../forms/FormBuilder"
import {FormQuestionsListAside} from "../forms/questions/FormQuestionsListAside"
import {useSidebar} from "../nav/SidebarProvider"
import RegistrationFormSettings from "../registration/RegistrationFormSettingsAside"

export default function SiteRegistrationBuilderPage() {
  let [retreat] = useRetreatV2()
  let {openSidebar} = useSidebar()
  let queryClient = useQueryClient()
  let [activeSideTab, setActiveSideTab] = useState<"form" | "settings">("form")

  let websiteQuery = useQuery({
    enabled: retreat.attendees_website_id != null,
    queryKey: ["websites", retreat.attendees_website_id],
    queryFn: () => getSite(retreat.attendees_website_id!),
  })

  let formId = retreat.attendees_registration_form_id

  let formQuery = useQuery({
    enabled: formId != null,
    queryKey: ["forms", formId],
    queryFn: () => {
      return getForm(formId!)
    },
  })

  let updateRetreatFormMutation = useMutation({
    mutationFn: (vars: {formId: number}) =>
      patchRetreat(retreat.id, {
        attendees_registration_form_id: vars.formId,
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(["retreats", data.retreat.id], data)
    },
  })

  let createFormMutation = useMutation({
    mutationFn: () => postForm(FormCreationTypeEnum.ATTENDEE_REGISTRATION),
    onSuccess: (data) => {
      queryClient.setQueryData(["forms", data.form.id], data)
      return updateRetreatFormMutation.mutate({formId: data.form.id})
    },
  })

  return (
    <FullPage>
      <FullPage.Header>
        <FullPage.HeaderLeft>
          <FullPage.HeaderBackButton onClick={openSidebar} />
        </FullPage.HeaderLeft>
        <FullPage.HeaderCenter></FullPage.HeaderCenter>
        <FullPage.HeaderRight>
          {formId && (
            <AppLinkButton
              target="_blank"
              disabled={!websiteQuery.isSuccess}
              tooltip={
                !websiteQuery.isSuccess
                  ? "You haven't created your attendee landing page yet!"
                  : undefined
              }
              to={
                websiteQuery.isSuccess
                  ? AppRoutes.getPath("AttendeeSitePage", {
                      websiteName: titleToNavigation(
                        websiteQuery.data!.website.name
                      ),
                      pageName: "registration",
                    })
                  : ""
              }
              variant="solid"
              color="brand"
              text="View Page"
            />
          )}
        </FullPage.HeaderRight>
      </FullPage.Header>
      <FullPage.DoublePane>
        <FullPage.DoublePaneAside>
          <TabsWrapper
            css={{height: "100%"}}
            value={activeSideTab}
            onValueChange={(newVal) =>
              setActiveSideTab(newVal as "settings" | "form")
            }>
            <TabsContainer>
              <TabsList>
                <Tab value="form" text="Form" />
                <Tab value="settings" text="Settings" />
              </TabsList>
            </TabsContainer>
            {formId && formQuery.status !== "error" && (
              <>
                <TabContent css={{overflow: "auto"}} value={"form"}>
                  <FormQuestionsListAside formId={formId} />
                </TabContent>
                <TabContent value={"settings"}>
                  <RegistrationFormSettings retreatId={retreat.id} />
                </TabContent>
              </>
            )}
          </TabsWrapper>
        </FullPage.DoublePaneAside>
        <FullPage.DoublePaneMain>
          <WebsitePortal>
            <WebsitePortal.Header url={"https://goflok.com"} />
            <WebsitePortal.Body>
              {formId && formQuery.status !== "error" ? (
                <FormBuilder formId={formId} />
              ) : (
                <CenterMessage css={{margin: "auto"}}>
                  <CenterMessage.Image src="https://flok-b32d43c.s3.us-east-1.amazonaws.com/create-website.png" />
                  <CenterMessage.Body>
                    <CenterMessage.Title>
                      Create Registration Form
                    </CenterMessage.Title>
                    <CenterMessage.Description>
                      Create your event registration form in order to collect
                      attendee information. We'll get you started using Flok's
                      template!
                    </CenterMessage.Description>
                  </CenterMessage.Body>
                  <CenterMessage.Actions>
                    <CenterMessage.Button
                      text="Create Registration Form"
                      onClick={() => createFormMutation.mutate()}
                    />
                  </CenterMessage.Actions>
                </CenterMessage>
              )}
            </WebsitePortal.Body>
          </WebsitePortal>
        </FullPage.DoublePaneMain>
      </FullPage.DoublePane>
    </FullPage>
  )
}

const TabsContainer = styled("div", {
  padding: "0px 24px 0px 20px",
  display: "flex",
  flexDirection: "column",
  borderBottomStyle: "solid",
  borderBottomColor: "$gray6",
  borderBottomWidth: "1px",
})
