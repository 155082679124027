import {Button} from "@summtech/flok-base/components/Button"
import {Tab} from "@summtech/flok-base/components/Tab"
import {TabsList} from "@summtech/flok-base/components/TabsList"
import {TabsWrapper} from "@summtech/flok-base/components/TabsWrapper"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {push} from "connected-react-router"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Route, Switch, useRouteMatch} from "react-router-dom"
import AttendeeFlightTab from "../../components/flights/AttendeeFlightTab"
import {AppRoutes, FlokPageName} from "../../Stack"
import {RootState} from "../../store"
import {ApiAction} from "../../store/actions/api"
import {deleteRetreatAttendees, getAttendee} from "../../store/actions/retreat"
import {AttendeeProfileForm} from "../app/attendees/AttendeeProfileForm"
import SideDrawer, {SideDrawerHeader} from "../app/SideDrawer"
import FormResponseViewer from "../forms/FormResponseViewer"

let StyledTabListContainer = styled("div", {
  paddingInline: "20px",
  borderBottom: `1px solid ${theme.colors.gray6}`,
})
let StyledFormContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginInline: "15px",
  marginTop: "15px",
})
let StyledActionFooter = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px 20px",
  position: "sticky",
  bottom: "0px",
  background: theme.colors.white,
  borderTop: `1px solid ${theme.colors.gray6}`,
})

export function AttendeePage(props: {
  pageNames: {
    profilePage: FlokPageName
    flightsPage: FlokPageName
    formResponsesPage: FlokPageName
  }
  onBack: () => void
}) {
  let dispatch = useDispatch()
  let route = useRouteMatch<{
    retreatIdx: string
    attendeeId: string
  }>()
  let attendeeId = parseInt(route.params.attendeeId)
  let retreatIdx = parseInt(route.params.retreatIdx)

  let attendee = useSelector((state: RootState) => {
    if (attendeeId != null) {
      return state.retreat.attendees[attendeeId]
    }
  })
  useEffect(() => {
    !attendee && dispatch(getAttendee(attendeeId))
  }, [attendeeId, attendee, dispatch])

  let [confirmingDelete, setConfirmingDelete] = useState(false)
  useEffect(() => {
    if (confirmingDelete) {
      setTimeout(() => setConfirmingDelete(false), 5000)
    }
  }, [confirmingDelete])

  return (
    <SideDrawer open={true} onClose={props.onBack}>
      <SideDrawerHeader text="Edit Attendee" onClose={props.onBack} />
      <TabsWrapper
        value={route.url}
        onValueChange={(value) => {
          dispatch(push(value))
        }}>
        <StyledTabListContainer>
          <TabsList>
            <Tab
              text="Profile"
              value={AppRoutes.getPath(props.pageNames.profilePage, {
                retreatIdx: retreatIdx.toString(),
                attendeeId: attendeeId.toString(),
              })}
            />
            <Tab
              text="Travel"
              value={AppRoutes.getPath(props.pageNames.flightsPage, {
                retreatIdx: retreatIdx.toString(),
                attendeeId: attendeeId.toString(),
              })}
            />
            <Tab
              text="Form Responses"
              value={AppRoutes.getPath(props.pageNames.formResponsesPage, {
                retreatIdx: retreatIdx.toString(),
                attendeeId: attendeeId.toString(),
              })}
            />
          </TabsList>
        </StyledTabListContainer>
        <Switch>
          <Route path={AppRoutes.getPath(props.pageNames.profilePage)} exact>
            <StyledFormContainer>
              {attendee && <AttendeeProfileForm attendee={attendee} />}
            </StyledFormContainer>
            <StyledActionFooter>
              {attendee && (
                <Button
                  color="critical"
                  type="button"
                  text={confirmingDelete ? "Are you sure?" : "Delete"}
                  variant={confirmingDelete ? "solid" : "outline"}
                  onClick={async (e) => {
                    e.preventDefault()
                    if (confirmingDelete) {
                      let response = (await dispatch(
                        deleteRetreatAttendees(
                          attendee!.retreat_id,
                          attendee!.id
                        )
                      )) as unknown as ApiAction
                      if (!response.error) {
                        props.onBack()
                      }
                    } else {
                      setConfirmingDelete(true)
                    }
                  }}
                />
              )}

              <Button
                text="Done"
                color={"brand"}
                onClick={props.onBack}
                variant={"solid"}
              />
            </StyledActionFooter>
          </Route>
          <Route path={AppRoutes.getPath(props.pageNames.flightsPage)} exact>
            <StyledFormContainer>
              {attendee && <AttendeeFlightTab attendee={attendee} />}
            </StyledFormContainer>
          </Route>
          <Route
            path={AppRoutes.getPath(props.pageNames.formResponsesPage)}
            exact>
            <StyledFormContainer>
              {attendee && attendee.registration_form_response_id && (
                <FormResponseViewer
                  hideTitle
                  formResponseId={attendee.registration_form_response_id}
                />
              )}
              {attendee && !attendee.registration_form_response_id && (
                <Text variant="text-base">
                  This attendee hasn't completed the registration form yet.
                </Text>
              )}
            </StyledFormContainer>
          </Route>
        </Switch>
      </TabsWrapper>
    </SideDrawer>
  )
}
