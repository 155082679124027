import {Button} from "@summtech/flok-base/components/Button"
import {FormField} from "@summtech/flok-base/components/FormField"
import {useMutation} from "@tanstack/react-query"
import {useFormik} from "formik"
import React, {useState} from "react"
import {FormHeader} from "."
import {queryClient} from "../../../api"
import {patchForm} from "../../../api/form"
import {FormModel} from "../../../models/form"
import {QuestionCardBase} from "../questions/FormQuestionBase"
import {
  QuestionCardFooter,
  QuestionCardFooterRight,
} from "../questions/FormQuestionBuilder"

type FormHeaderBuilderProps = {form: FormModel}
export default function FormHeaderBuilder(props: FormHeaderBuilderProps) {
  let [editing, setEditing] = useState(false)
  let patchFormMutation = useMutation({
    mutationFn: (args: Parameters<typeof patchForm>[1]) =>
      patchForm(props.form.id, args),
    onSuccess: (data) =>
      queryClient.setQueryData(["forms", props.form.id], data),
  })

  // FORMS
  let titleFormik = useFormik({
    initialValues: {
      title: props.form.title ?? "",
    },
    onSubmit: (values) => {
      patchFormMutation.mutate({title: values.title})
    },
    enableReinitialize: true,
  })
  let descriptionFormik = useFormik({
    initialValues: {
      description: props.form.description ?? "",
    },
    onSubmit: (values) => {
      patchFormMutation.mutate({description: values.description})
    },
    enableReinitialize: true,
  })
  // END FORMS

  return !editing ? (
    <FormHeader
      css={{cursor: "pointer"}}
      onClick={(e) => {
        e.preventDefault()
        setEditing(true)
      }}
      title={props.form.title || "Untitled form"}
      description={props.form.description || "Description"}
    />
  ) : (
    <QuestionCardBase noPadding>
      <QuestionCardBase noBorder>
        <FormField
          label="Title"
          type="textfield"
          fullWidth
          hint="Required"
          value={titleFormik.values.title}
          onChange={titleFormik.handleChange}
          onBlur={() => titleFormik.handleSubmit()}
          id="title"
        />
        <FormField
          label="Description"
          type="textarea"
          fullWidth
          value={descriptionFormik.values.description}
          onChange={descriptionFormik.handleChange}
          onBlur={() => descriptionFormik.handleSubmit()}
          id="description"
        />
      </QuestionCardBase>
      <QuestionCardFooter>
        <QuestionCardFooterRight>
          <Button
            color="brand"
            text="Done"
            onClick={() => {
              setEditing(false)
            }}
          />
        </QuestionCardFooterRight>
      </QuestionCardFooter>
    </QuestionCardBase>
  )
}
