import {PlusIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {push} from "connected-react-router"
import {useDispatch} from "react-redux"
import PageBody from "../../components/page/PageBody"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../Stack"
import {ApiAction} from "../../store/actions/api"
import {postRetreatEmail} from "../../store/actions/retreat"
import {useRetreatEmails} from "../../utils/retreatUtils"
import CommunicationHeader from "../communications/CommunicationHeader"
import EmailLineItem from "../communications/EmailLineItem"

function dateFormat(date: Date | undefined) {
  if (date === undefined) {
    return ""
  }
  let dateFormatter = Intl.DateTimeFormat("en-US", {
    dateStyle: "short",
    timeStyle: "short",
  })
  return dateFormatter.format(date)
}

const MainBody = styled("div", {
  backgroundColor: theme.colors.white,
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "20px 32px",
  gap: "16px",
})

const HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
})

const BottomContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
})

export default function CommunicationHomePage() {
  let [retreat, retreatIdx] = useRetreat()
  let [emails] = useRetreatEmails(retreat.email_ids ?? [])
  let dispatch = useDispatch()
  return (
    <PageBody appBar>
      <MainBody>
        <HeaderContainer>
          <Text variant={"heading-lg"}>Communication</Text>
          <Button
            color="brand"
            variant={"solid"}
            startIcon={<PlusIcon />}
            text="New Email"
            onClick={async () => {
              let response = (await dispatch(
                postRetreatEmail(retreat.id)
              )) as unknown as ApiAction
              if (!response.error) {
                dispatch(
                  push(
                    AppRoutes.getPath("CreateEmailPage", {
                      retreatIdx: retreatIdx.toString(),
                      emailId: response.payload.retreat_email.id,
                    })
                  )
                )
              }
            }}
          />
        </HeaderContainer>
        <BottomContainer>
          <CommunicationHeader />
          {emails.map((email, i) => {
            return (
              <EmailLineItem
                emailId={email.id}
                onClick={() => {
                  dispatch(
                    push(
                      AppRoutes.getPath("CreateEmailPage", {
                        retreatIdx: retreatIdx.toString(),
                        emailId: email.id.toString(),
                      })
                    )
                  )
                }}
                statusDate={
                  email.scheduled_date_time && email.status === "ACTIVE"
                    ? dateFormat(new Date(email.scheduled_date_time))
                    : undefined
                }
                status={
                  email.status === "DRAFT"
                    ? "DRAFT"
                    : email.type === "AUTOMATED"
                    ? "AUTOMATED"
                    : email.type === "SCHEDULED" &&
                      email.scheduled_date_time &&
                      new Date() < new Date(email.scheduled_date_time)
                    ? "SCHEDULED"
                    : "SENT"
                }
                subject={email.subject ?? "n/a"}
                key={i}
              />
            )
          })}
        </BottomContainer>
      </MainBody>
    </PageBody>
  )
}
