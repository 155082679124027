import {DotsVerticalIcon} from "@radix-ui/react-icons"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {PropsWithChildren} from "react"

const StyledTable = styled("table", {
  tableLayout: "fixed",
  textAlign: "left",
  width: "100%",
  height: "100%",
  borderCollapse: "collapse",
  borderSpacing: 2,
  "& th, td": {
    padding: "17px",
  },
  "& thead th": {
    position: "sticky",
    top: 0,
    zIndex: 999,
  },
})

const StyledTableHead = styled("thead", {
  color: theme.colors.gray11,
})

const StyledTableBody = styled("tbody", {
  overflow: "scroll",
})

const StyledTableRow = styled("tr", {
  borderBottom: `1px solid ${theme.colors.gray6}`,
  "& > td.row-actions": {
    display: "flex",
    justifyContent: "flex-end",
  },
})

export type TableCellValue = string | number
export type TableRow = {
  [key: string]: TableCellValue | any | undefined
}
export type TableColumn = {
  id: string
  name: string
  getValue?: (row: TableRow) => TableCellValue
  renderCell?: (row: TableRow) => JSX.Element
  actions?: JSX.Element[]
}

type AppTableProps = PropsWithChildren<{
  rows: TableRow[]
  getRowActions?: (row: TableRow) => JSX.Element[] | JSX.Element | undefined
  columns: TableColumn[]
}>

export default function AppTable(props: AppTableProps) {
  return (
    <StyledTable>
      <StyledTableHead>
        <StyledTableRow>
          {props.columns.map((col) => {
            return (
              <th key={col.id}>
                <Text variant="text-sm">{col.name}</Text>
              </th>
            )
          })}
          {props.getRowActions ? (
            <th>
              <Text variant="text-sm">&nbsp;</Text>
            </th>
          ) : (
            <></>
          )}
        </StyledTableRow>
      </StyledTableHead>
      <StyledTableBody>
        {props.rows.map((row, rowI) => {
          let rowId = `${rowI}`
          return (
            <StyledTableRow key={rowId}>
              {props.columns.map((col) => {
                let value = row[col.id] // default
                if (col.renderCell) {
                  value = col.renderCell(row)
                } else if (col.getValue) {
                  value = col.getValue(row)
                }
                return (
                  <td key={`${rowId}-${col.id}`}>
                    <Text variant="text-sm">{value}</Text>
                  </td>
                )
              })}
              {props.getRowActions ? (
                <td className="row-actions">
                  <AppTableRowActions>
                    {props.getRowActions(row)}
                  </AppTableRowActions>
                </td>
              ) : (
                <></>
              )}
            </StyledTableRow>
          )
        })}
      </StyledTableBody>
    </StyledTable>
  )
}

// Requires you to use DropdownItems as the children
export function AppTableRowActions(props: PropsWithChildren<{}>) {
  return (
    <Dropdown
      position="bottom"
      button={
        <div>
          {/* TODO REMOVE THE WRAPPER DIV ONCE ICON BUTTON IS A FORWARDEDREF */}
          <IconButton size="sm" variant="ghost">
            <DotsVerticalIcon />
          </IconButton>
        </div>
      }>
      {props.children}
    </Dropdown>
  )
}
