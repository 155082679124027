import {CaretDownIcon, PlusIcon, TrashIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Text} from "@summtech/flok-base/components/Text"
import {Textfield} from "@summtech/flok-base/components/Textfield"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {convertToRaw, EditorState} from "draft-js"
import {useEffect, useState} from "react"
import {
  AppWysiwygEditor,
  AppWysiwygViewer,
  createEditorState,
} from "../../../components/base/AppWysiwyg"
import {
  AccordionBlockContentModel,
  AccordionBlockModel,
} from "../../../models/retreat"

const AccordionItemContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  flex: 1,
})
const AddContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
})
type AccordionBlockEditorProps = {
  block: AccordionBlockModel
  formik: any
}

const AccordionForm = styled("form", {
  width: "100%",
})
// TODO fix this "any"
export function convertFormikToRaw(values: any) {
  if (values.items) {
    return {
      items: values.items.map((item: any) => ({
        ...item,
        body: convertToRaw(item.body.getCurrentContent()),
      })),
    }
  }
}
export function convertContentFromRaw(
  content: AccordionBlockContentModel | null
) {
  if (!content) {
    return {items: []}
  } else {
    return {
      items: content.items.map((item) => {
        return {
          ...item,
          body: createEditorState(item.body),
        }
      }),
    }
  }
}
export function AccordionBlockEditor(props: AccordionBlockEditorProps) {
  let {resetForm} = props.formik
  useEffect(() => {
    resetForm({
      values: {
        ...props.formik.values,
        [props.block.id]: {
          ...convertContentFromRaw(
            props.block.content as AccordionBlockContentModel
          ),
          type: "ACCORDION",
        },
      },
    })
  }, [props.block.content, resetForm])

  return (
    <>
      <AccordionForm>
        <AccordionItemContainer>
          {props.formik.values[props.block.id]?.items &&
            props.formik.values[props.block.id]?.items.map(
              (item: any, index: number) => (
                <AccordionItem
                  editableHeader={
                    <Textfield
                      id={`${props.block.id}.items[${index}].header`}
                      value={item.header}
                      onChange={props.formik.handleChange}
                      fullWidth
                      placeholder="Item header"
                    />
                  }
                  onDelete={() =>
                    props.formik.setFieldValue(
                      `${props.block.id}.items`,
                      props.formik.values[props.block.id].items.filter(
                        (item: any, i: number) => index !== i
                      )
                    )
                  }
                  editable
                  header={<Text variant="text-base-plus">{item.header}</Text>}
                  details={
                    <AppWysiwygEditor
                      editorState={item.body}
                      onEditorStateChange={(val) =>
                        props.formik.setFieldValue(
                          `${props.block.id}.items[${index}].body`,
                          val
                        )
                      }
                      unsaved={false}
                    />
                  }
                />
              )
            )}
          <AddContainer>
            <Button
              onClick={(e) => {
                e.preventDefault()
                props.formik.setFieldValue(`${props.block.id}.items`, [
                  ...props.formik.values[props.block.id].items,
                  {header: "", body: EditorState.createEmpty()},
                ])
              }}
              variant="outline"
              text="Add Item"
              startIcon={<PlusIcon />}
            />
          </AddContainer>
        </AccordionItemContainer>
      </AccordionForm>
    </>
  )
}

const AccordionBlockContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
})

type AccordionBlockRendererProps = {
  block: AccordionBlockModel
}

export function AccordionBlockRenderer(props: AccordionBlockRendererProps) {
  let items =
    props.block.content && props.block.content.items
      ? props.block.content.items
      : []
  return (
    <AccordionBlockContainer>
      {items.map((item) => (
        <AccordionItem
          header={<Text variant="text-base-plus">{item.header}</Text>}
          details={<AppWysiwygViewer content={item.body} />}
        />
      ))}
    </AccordionBlockContainer>
  )
}

type AccordionBlockProps = {
  header: JSX.Element
  editableHeader?: JSX.Element
  details: JSX.Element
  editable?: boolean
  onDelete?: () => void
}

const AccordionContainer = styled("div", {
  background: theme.colors.white,
  display: "flex",
  flexDirection: "column",
  borderColor: theme.colors.gray6,
  borderStyle: "solid",
  borderWidth: "1px",
  borderRadius: theme.shape.borderRadius,
})
const AccordionTitle = styled("div", {
  padding: "16px 20px",
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "10px",
  cursor: "pointer",
  variants: {
    expanded: {
      true: {
        borderBottomColor: theme.colors.gray6,
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
      },
    },
  },
})
const AccordionTitleLeft = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  alignItems: "center",
  flex: 1,
})

const AccordionBody = styled("div", {
  padding: "16px 20px",
})

function AccordionItem(props: AccordionBlockProps) {
  let [expanded, setExpanded] = useState(false)
  return (
    <AccordionContainer>
      <AccordionTitle
        expanded={expanded}
        onClick={() => {
          setExpanded((expanded) => !expanded)
        }}>
        <AccordionTitleLeft>
          <CaretDownIcon />
          {props.editableHeader && props.editable
            ? expanded
              ? props.editableHeader
              : props.header
            : props.header}
        </AccordionTitleLeft>
        {props.editable && (
          <IconButton
            variant="ghost"
            onClick={() => {
              props.onDelete && props.onDelete()
            }}>
            <TrashIcon />
          </IconButton>
        )}
      </AccordionTitle>
      {expanded && <AccordionBody>{props.details}</AccordionBody>}
    </AccordionContainer>
  )
}
