import {TriangleDownIcon, TriangleUpIcon} from "@radix-ui/react-icons"
import SearchBar from "@summtech/flok-base/components/SearchBar"
import {SegmentedControlGroup} from "@summtech/flok-base/components/SegmentedControlGroup"
import {Text} from "@summtech/flok-base/components/Text"
import {css, styled, theme} from "@summtech/flok-base/stitches.config"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table"
import _ from "lodash"
import {useEffect, useState} from "react"
import {RetreatAttendeeModel} from "../../models/retreat"
import {getDatetimeString} from "../../utils"
import {TableFooter} from "../app/attendees/AttendeesTable"

const StyledTableHead = styled("thead", {
  backgroundColor: theme.colors.gray2,
  color: theme.colors.gray11,
})

const StyledTableTop = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "16px 16px 12px 16px",
  gap: "16px",
  width: "100%",
  borderBottom: `1px solid ${theme.colors.gray6}`,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
})
const StyledTableTopRow = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  width: "100%",
  justifyContent: "space-between",
  "@bp2": {
    flexDirection: "row",
  },
})

const StyledSearchBar = styled(SearchBar, {
  width: "360px",
})

const StyledTableBody = styled("tbody", {})

const StyledCellCss = css({
  maxWidth: "$$width",
  minWidth: "$$width",
  borderBottom: `solid 1px ${theme.colors.gray6}`,
  "& *": {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  variants: {
    gridLines: {
      true: {
        "&:not(:last-child)": {
          borderRight: `solid 1px ${theme.colors.gray6}`,
        },
      },
    },
  },
})

const StyledTableFooter = styled(TableFooter, {
  posiiton: "relative",
  bottom: "0px",
  height: "60px",
})

const StyledTable = styled("table", {
  whiteSpace: "nowrap",
  textAlign: "left",
  background: theme.colors.white,
})

const StyledTableDataCell = styled("td", StyledCellCss, {
  padding: "10px",
})
const TableContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "488px",
  overflow: "auto",
  background: theme.colors.white,
  border: `1px solid ${theme.colors.gray6}`,
  borderRadius: theme.shape.borderRadius,
})
const StyledTableHeaderCell = styled("th", StyledCellCss, {
  backgroundColor: theme.colors.gray2,
  padding: "10px",
  borderTop: `solid 1px ${theme.colors.gray6}`,
  "& > span": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
})
const StyledSortIconCss = css({flexShrink: 0})

export default function AttendeesFlightsTable(props: {
  attendees: RetreatAttendeeModel[]
}) {
  let [searchString, setSearchString] = useState("")
  let columnHelper = createColumnHelper<RetreatAttendeeModel>()
  let [flightDirection, setFlightDirection] = useState<"arrival" | "departure">(
    "arrival"
  )
  let [data] = useState(props.attendees.filter((attendee) => attendee.travel))
  let options: TableOptions<RetreatAttendeeModel> = {
    defaultColumn: {
      enableGlobalFilter: false,
      enableSorting: false,
      size: 150,
    },
    globalFilterFn: "includesString",
    initialState: {
      pagination: {
        pageSize: 50,
      },
    },
    state: {
      globalFilter: searchString,
      columnVisibility:
        flightDirection === "departure"
          ? {
              flight_arrival_time: false,
              flight_arrival_airport: false,
              flight_arrival_number: false,
            }
          : {
              flight_departure_time: false,
              flight_departure_airport: false,
              flight_departure_number: false,
            },
    },
    columns: [
      columnHelper.accessor("first_name", {
        header: "First Name",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor("last_name", {
        header: "Last Name",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor(
        (attendee) => {
          let tripLegs = attendee.travel?.arr_trip?.trip_legs
          if (tripLegs && tripLegs.length) {
            return getDatetimeString(
              tripLegs[tripLegs.length - 1]?.arr_datetime
            )
          }
        },
        {
          id: "flight_arrival_time",
          header: "Arrival Time",
          enableSorting: true,
          sortingFn: "alphanumeric",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegs = attendee.travel?.arr_trip?.trip_legs
          if (tripLegs && tripLegs.length) {
            return tripLegs[tripLegs.length - 1]?.arr_airport
          }
        },
        {
          id: "flight_arrival_airport",
          header: "Arrival Airport",
          enableSorting: true,
          sortingFn: "alphanumeric",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegs = attendee.travel?.arr_trip?.trip_legs
          if (tripLegs && tripLegs.length) {
            return tripLegs[tripLegs.length - 1]?.flight_num
          }
        },
        {
          id: "flight_arrival_number",
          header: "Arrival #",
          enableSorting: true,
          sortingFn: "alphanumeric",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegs = attendee.travel?.dep_trip?.trip_legs
          if (tripLegs && tripLegs.length) {
            return getDatetimeString(tripLegs[0].dep_datetime)
          }
        },
        {
          id: "flight_departure_time",
          header: "Departure Time",
          enableSorting: true,
          sortingFn: "alphanumeric",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegs = attendee.travel?.dep_trip?.trip_legs
          if (tripLegs && tripLegs.length) {
            return tripLegs[0].dep_airport
          }
        },
        {
          id: "flight_departure_airport",
          header: "Departure Airport",
          enableSorting: true,
          sortingFn: "alphanumeric",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegs = attendee.travel?.dep_trip?.trip_legs
          if (tripLegs && tripLegs.length) {
            return tripLegs[0].flight_num
          }
        },
        {
          id: "flight_departure_number",
          header: "Departure #",
          enableSorting: true,
          sortingFn: "alphanumeric",
        }
      ),
    ],
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  }

  const table = useReactTable(options)

  return (
    <TableContainer>
      <StyledTableTop>
        <Text variant="title-base">Group Flights</Text>
        <StyledTableTopRow>
          <SegmentedControlGroup
            value={flightDirection}
            onChange={(newValue) => {
              setFlightDirection(newValue as "arrival" | "departure")
            }}
            options={[
              {label: "Arrival", value: "arrival"},
              {label: "Departure", value: "departure"},
            ]}
          />
          <StyledSearchBar
            placeholder="Search"
            onChange={_.debounce(
              async (e) => setSearchString(e.target.value),
              500
            )}
          />
        </StyledTableTopRow>
      </StyledTableTop>
      <StyledTable>
        <StyledTableHead>
          <tr>
            {table.getHeaderGroups().map((headerGroup) =>
              headerGroup.headers
                .filter((header) => header.depth === 1)
                .map((header) => (
                  <StyledTableHeaderCell
                    colSpan={header.colSpan}
                    gridLines
                    onClick={
                      header.column.getCanSort()
                        ? () => header.column.toggleSorting()
                        : undefined
                    }
                    css={{
                      $$width: `${header.column.columnDef.size}px`,
                      cursor: header.column.getCanSort()
                        ? "pointer"
                        : "inherit",
                    }}>
                    <span>
                      <Text variant="text-sm-plus">
                        {header.isPlaceholder
                          ? "\u00A0"
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Text>
                      {header.column.getCanSort() &&
                      header.column.getIsSorted() === "asc" ? (
                        <TriangleUpIcon className={StyledSortIconCss()} />
                      ) : header.column.getIsSorted() === "desc" ? (
                        <TriangleDownIcon className={StyledSortIconCss()} />
                      ) : (
                        <></>
                      )}
                    </span>
                  </StyledTableHeaderCell>
                ))
            )}
          </tr>
        </StyledTableHead>
        <StyledTableBody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <StyledTableDataCell
                  gridLines
                  css={{$$width: `${cell.column.columnDef.size}px`}}>
                  <Text variant="text-sm">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Text>
                </StyledTableDataCell>
              ))}
            </tr>
          ))}
        </StyledTableBody>
      </StyledTable>
      <StyledTableFooter
        rowCount={table.getFilteredRowModel().rows.length}
        currentPage={table.getState().pagination.pageIndex + 1}
        pageCount={table.getPageCount()}
        pageSize={table.getState().pagination.pageSize}
        onNextPage={table.getCanNextPage() ? table.nextPage : undefined}
        onPreviousPage={
          table.getCanPreviousPage() ? table.previousPage : undefined
        }
      />
    </TableContainer>
  )
}
