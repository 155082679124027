import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {ItineraryEventModel} from "../../models/retreat"
import {sortEvents} from "../pages/ItineraryBuilderPage"
import SiteItineraryEvent from "./SiteItineraryEvent"

const ItineraryDayContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  "@bp2": {
    flexDirection: "row",
    gap: "20px",
  },
})
const EventContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  flex: 1,
})
const Title = styled("div", {
  display: "flex",
  width: "320px",
})

export default function SiteItineraryDay(props: {
  events: ItineraryEventModel[]
  day: string
}) {
  return (
    <ItineraryDayContainer>
      <Title>
        <Text variant="title-2xl">{props.day}</Text>
      </Title>

      <EventContainer>
        {sortEvents(props.events).map((event) => {
          return <SiteItineraryEvent event={event} />
        })}
        {props.events.length === 0 && (
          <Text variant="heading-sm">No Events Scheduled</Text>
        )}
      </EventContainer>
    </ItineraryDayContainer>
  )
}
