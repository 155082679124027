import {push} from "connected-react-router"
import {useEffect} from "react"
import {useDispatch} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import AttendeeSite from "../../../components/attendee-site/website/AttendeeSite"
import PageBody from "../../../components/page/PageBody"
import PageContainer from "../../../components/page/PageContainer"
import {FormResponseModel} from "../../../models/form"
import {enqueueSnackbar} from "../../../notistack-lib/actions"
import LoadingPage from "../../../pages/misc/LoadingPage"
import NotFound404Page from "../../../pages/misc/NotFound404Page"
import {AppRoutes} from "../../../Stack"
import {ApiAction} from "../../../store/actions/api"
import {postAttendeeRegRequest} from "../../../store/actions/retreat"
import {replaceDashes} from "../../../utils"
import {
  useAttendeeLandingWebsiteName,
  useMyAttendee,
  useRetreat,
} from "../../../utils/retreatUtils"
import {useLoggedInUser} from "../../../utils/userUtils"
import FormResponseViewer from "../../forms/FormResponseViewer"
import FormViewer from "../../forms/FormViewer"

export default function SiteRegistrationPage() {
  let dispatch = useDispatch()
  let router = useRouteMatch<{websiteName: string; pageName: string}>()
  let {websiteName} = router.params
  let [website, websiteLoading] = useAttendeeLandingWebsiteName(
    replaceDashes(websiteName)
  )
  let [retreat, retreatLoading] = useRetreat(website?.retreat_id ?? -1)
  let [user, userLoading] = useLoggedInUser()
  let [attendee] = useMyAttendee(retreat != null ? retreat.id : -1)

  useEffect(() => {
    if (!userLoading && !user) {
      dispatch(
        push(
          AppRoutes.getPath(
            "SigninPage",
            {
              websiteName: websiteName,
            },
            {
              next: encodeURIComponent(
                AppRoutes.getPath("AttendeeSiteFormPage", {
                  websiteName: websiteName,
                })
              ),
              "login-type": "attendee",
              "login-site": websiteName,
            }
          )
        )
      )
    }
  }, [user, userLoading, dispatch, websiteName])

  async function onSubmitFormSuccess(formResponse: FormResponseModel) {
    if (attendee) {
      let response = (await dispatch(
        postAttendeeRegRequest(attendee?.id, formResponse.id)
      )) as unknown as ApiAction
      if (!response.error) {
        dispatch(
          enqueueSnackbar({
            message: "Successfully registered for the retreat",
            options: {
              variant: "success",
            },
          })
        )
        dispatch(
          push(
            AppRoutes.getPath("AttendeeSiteMyTripPage", {
              websiteName,
            })
          )
        )
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Something Went Wrong",
            options: {
              variant: "error",
            },
          })
        )
      }
      return response
    } else {
      dispatch(
        enqueueSnackbar({
          message:
            "Oops, you haven't been added as an attendee for this retreat.",
          options: {
            variant: "error",
          },
        })
      )
    }
  }

  return websiteLoading || retreatLoading || !retreat ? (
    <LoadingPage />
  ) : !website ||
    (!retreat.registration_live && !retreat.users.includes(user?.id ?? -1)) ? (
    <NotFound404Page />
  ) : (
    <PageContainer>
      <PageBody>
        <AttendeeSite
          hideLogo
          activePage="registration"
          retreat={retreat}
          website={website}
          pageBody={
            retreat.attendees_registration_form_id != null ? (
              attendee && attendee.registration_form_response_id ? (
                <FormResponseViewer
                  formResponseId={attendee.registration_form_response_id}
                />
              ) : (
                <FormViewer
                  formId={retreat.attendees_registration_form_id}
                  onSuccess={(response) => onSubmitFormSuccess(response)}
                />
              )
            ) : (
              <div>Attendee registration isn't live yet</div>
            )
          }
        />
      </PageBody>
    </PageContainer>
  )
}
