import {PersonIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useMutation, useQueries, useQueryClient} from "@tanstack/react-query"
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table"
import {push} from "connected-react-router"
import {useState} from "react"
import {useDispatch} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import {deleteAudience, getAudience} from "../../api/communication"
import PageBody from "../../components/page/PageBody"
import {AudienceModel} from "../../models/communication"
import {AppRoutes} from "../../Stack"
import AppReactTable from "../app/common/AppReactTable"
import {useRetreatV2} from "../app/common/RetreatProviderV2"
import UserGroupOverlay from "../app/user-groups/UserGroupOverlay"
import {ActionsCell} from "./SurveysPage"

let MainBody = styled("div", {
  backgroundColor: theme.colors.white,
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: "16px 32px",
  overflow: "hidden",
})

const TitleContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
})

const NameContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "12px",
})

const IconContainer = styled("div", {
  display: "flex",
  height: "32px",
  width: "32px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  background: theme.colors.gray3,
})
export default function UserGroupsPage() {
  let [retreat, retreatIdx] = useRetreatV2()
  let router = useRouteMatch<{
    retreatIdx: string
    audienceId: string
  }>()
  let audienceId = router.params.audienceId
  let queryClient = useQueryClient()
  let dispatch = useDispatch()
  let [creatingNewUserGroup, setCreatingNewUserGroup] = useState(false)
  let audienceQueries = useQueries({
    queries: retreat.audience_ids.map((audienceId) => {
      return {
        queryFn: () => getAudience(audienceId),
        queryKey: ["audiences", audienceId],
      }
    }),
  })
  let deleteAudienceMutation = useMutation({
    mutationFn: (variables: {audienceId: number; retreatId: number}) =>
      deleteAudience(variables.audienceId),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["retreats", variables.retreatId],
      })
    },
  })

  let columnHelper = createColumnHelper<AudienceModel>()
  let options: TableOptions<AudienceModel> = {
    defaultColumn: {
      enableGlobalFilter: false,
      size: undefined,
    },
    initialState: {},
    columns: [
      columnHelper.accessor("name", {
        header: "Name",
        size: 300,
        cell: (props) => (
          <NameContainer>
            <IconContainer>
              <PersonIcon />
            </IconContainer>
            <Text variant={"text-sm-plus"}>
              {props.getValue() || "Untitled Group"}
            </Text>
          </NameContainer>
        ),
      }),
      columnHelper.accessor("description", {
        header: "Description",
        enableColumnFilter: true,
        size: 300,
        cell: (props) => (
          <Text css={{color: theme.colors.gray11}} variant="text-sm">
            {props.getValue()}
          </Text>
        ),
      }),
      columnHelper.display({
        id: "actions",
        size: 100,
        cell: (context) => (
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}>
            <ActionsCell
              title={"Are you sure you want to delete this user group?"}
              subtitle="This action cannot be undone."
              onDelete={() => {
                deleteAudienceMutation.mutate({
                  audienceId: context.row.original.id,
                  retreatId: context.row.original.retreat_id,
                })
              }}
            />
          </div>
        ),
      }),
    ],
    data: audienceQueries
      .filter(
        (query) => query.isSuccess && query.data.retreat_audience.is_user_group
      )
      .map((query) => query.data!.retreat_audience),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  }
  const table = useReactTable(options)

  return (
    <PageBody appBar>
      <MainBody>
        <TitleContainer>
          <Text variant={"heading-lg"}>Attendee Groups</Text>
          <Button
            text="New Attendee Group"
            variant={"solid"}
            color="brand"
            onClick={() => {
              setCreatingNewUserGroup(true)
            }}
          />
        </TitleContainer>
        <AppReactTable>
          <AppReactTable.Header>
            <tr>
              {table
                .getHeaderGroups()
                .map((headerGroup) =>
                  headerGroup.headers
                    .filter((header) => header.depth === 1)
                    .map((header) => (
                      <AppReactTable.HeaderCell
                        header={header}
                        key={header.id}
                      />
                    ))
                )}
            </tr>
          </AppReactTable.Header>
          <AppReactTable.Body>
            {table.getRowModel().rows.map((row) => {
              return (
                <AppReactTable.Row
                  key={row.original.id}
                  row={row}
                  onClick={() => {
                    dispatch(
                      push(
                        AppRoutes.getPath("UserGroupPage", {
                          retreatIdx: retreatIdx.toString(),
                          audienceId: row.original.id.toString(),
                        })
                      )
                    )
                  }}
                />
              )
            })}
          </AppReactTable.Body>
        </AppReactTable>
        <UserGroupOverlay
          open={!!audienceId || creatingNewUserGroup}
          audienceId={parseInt(audienceId)}
          onClose={() => {
            if (!isNaN(parseInt(audienceId))) {
              dispatch(
                push(
                  AppRoutes.getPath("UserGroupsPage", {
                    retreatIdx: retreatIdx.toString(),
                  })
                )
              )
            } else {
              setCreatingNewUserGroup(false)
            }
          }}
        />
      </MainBody>
    </PageBody>
  )
}
