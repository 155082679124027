import {useEffect} from "react"
import {useRouteMatch} from "react-router-dom"
import SitePage from "../../../components/attendee-site/page/SitePage"
import App404Message from "../../../components/base/App404Message"
import AppLoadingScreen from "../../../components/base/AppLoadingScreen"
import PageBody from "../../../components/page/PageBody"
import PageContainer from "../../../components/page/PageContainer"
import AttendeeSiteFlightsPage from "../../../pages/attendee-site/AttendeeSiteFlightsPage"
import LoadingPage from "../../../pages/misc/LoadingPage"
import NotFound404Page from "../../../pages/misc/NotFound404Page"
import {replaceDashes} from "../../../utils"
import {
  useAttendeeLandingPageName,
  useAttendeeLandingWebsiteName,
  useRetreat,
} from "../../../utils/retreatUtils"
import AttendeeSite from "../../sites/AttendeeSite"
export default function AttendeeSitePage() {
  let router = useRouteMatch<{websiteName: string; pageName: string}>()
  let {websiteName, pageName} = router.params
  let activePage = replaceDashes(pageName ?? "home")
  let [website, websiteLoading] = useAttendeeLandingWebsiteName(
    replaceDashes(websiteName)
  )
  let [retreat, retreatLoading] = useRetreat(website?.retreat_id ?? -1)
  let [page, pageLoading] = useAttendeeLandingPageName(
    website?.id ?? -1,
    activePage
  )

  // NEWSELA has a custom landing page so we need these in there
  useEffect(() => {
    const NEWSELA_CUSTOM_LANDING_PAGE_URL =
      "https://sites.google.com/newsela.com/ssko2024/home"
    const NEWSELA_CUSTOM_LANDING_PAGE_RETREAT_ID = 6244
    if (retreat?.id === NEWSELA_CUSTOM_LANDING_PAGE_RETREAT_ID) {
      window.location.replace(NEWSELA_CUSTOM_LANDING_PAGE_URL)
    }
  }, [retreat])

  // To support old flights page
  if (
    pageName &&
    pageName.toLowerCase() === "flights" &&
    retreat?.flights_page_id
  ) {
    return <AttendeeSiteFlightsPage />
  }
  return websiteLoading || retreatLoading ? (
    <LoadingPage />
  ) : !website || !retreat ? (
    <NotFound404Page />
  ) : (
    <PageContainer>
      <PageBody>
        <AttendeeSite
          activePage={activePage}
          retreat={retreat}
          website={website}
          pageBody={
            pageLoading ? (
              <AppLoadingScreen />
            ) : !page ? (
              <App404Message />
            ) : (
              <SitePage pageId={page.id} retreat={retreat} />
            )
          }
        />
      </PageBody>
    </PageContainer>
  )
}
