import {useMediaQuery, useTheme} from "@material-ui/core"
import {
  CaretDownIcon,
  Cross1Icon,
  ExitIcon,
  HamburgerMenuIcon,
} from "@radix-ui/react-icons"
import {Avatar} from "@summtech/flok-base/components/Avatar"
import {Button} from "@summtech/flok-base/components/Button"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {Text} from "@summtech/flok-base/components/Text"
import {Tooltip} from "@summtech/flok-base/components/Tooltip"
import {theme as flokTheme, styled} from "@summtech/flok-base/stitches.config"
import {push} from "connected-react-router"
import {useState} from "react"
import {useDispatch} from "react-redux"
import {Link as ReactRouterLink} from "react-router-dom"
import {AppRoutes} from "../../Stack"
import {ImageModel} from "../../models"
import {
  AttendeeLandingWebsiteModel,
  RetreatAttendeeModel,
  RetreatModel,
} from "../../models/retreat"
import {ApiAction} from "../../store/actions/api"
import {deleteUserSignin, getUserHome} from "../../store/actions/user"
import {FlokTheme} from "../../theme"
import {titleToNavigation} from "../../utils"
import {ImageUtils} from "../../utils/imageUtils"
import {useAttendeeLandingPages, useMyAttendee} from "../../utils/retreatUtils"

const SiteHeaderContainer = styled("div", {
  width: "100%",
  padding: "0px 48px",
  height: "80px",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  flexShrink: 0,
})

const SiteHeaderInnerContainer = styled("div", {
  display: "flex",
  width: "100%",
  maxWidth: "1152px",
  marginLeft: "auto",
  marginRight: "auto",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  [`${Button}`]: {
    background: "$$color",
    color: flokTheme.colors.white,
    "&:hover": {
      background: "$$color",
      opacity: 0.9,
    },
    "&:active": {
      background: "$$color",
      opacity: 0.8,
    },
  },
})

const StyledLink = styled(ReactRouterLink, {
  textDecoration: "none",
})

const LogoLink = styled(StyledLink, {
  flex: 1,
})
const ButtonContainer = styled("div", {
  flex: 1,
  justifyContent: "right",
  display: "flex",
})

const LogoImage = styled("img", {
  maxWidth: "95px",
  maxHeight: "40px",
})

const LinkContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: "32px",
})

const LinkText = styled(Text, {
  color: flokTheme.colors.gray11,
  "&:hover": {
    textDecoration: "underline",
    color: flokTheme.colors.black,
  },
  variants: {
    active: {
      true: {
        color: flokTheme.colors.black,
      },
    },
  },
})

type PageNavItem = {
  to: string
  title: string
  active: boolean
}

type SiteHeaderProps = {
  retreat: RetreatModel
  website: AttendeeLandingWebsiteModel
  activePage: string | "registration" | "flights"
  buildTo?: (pageId?: number) => string
  retreatIdx?: number
}

export default function SiteHeader(props: SiteHeaderProps) {
  let theme = useTheme()
  let [pages] = useAttendeeLandingPages(props.website.page_ids)
  let dispatch = useDispatch()

  let activePage = titleToNavigation(props.activePage)
  let websiteName = titleToNavigation(props.website.name)

  // PGN_NATIONAL_HACK (includes test retreat as well)
  const PGN_NATIONAL_ID = [4300, 4573]

  // XMTP HACK
  let registrationLive =
    (props.retreat.registration_live || props.retreat.id === 3862) &&
    !PGN_NATIONAL_ID.includes(props.retreat.id)
  /* Newsela Hack */
  let flightsLive =
    props.retreat.flights_live ||
    props.retreat.id === 3817 ||
    PGN_NATIONAL_ID.includes(props.retreat.id)

  let registerLink = registrationLive
    ? AppRoutes.getPath("AttendeeSiteFormPage", {websiteName})
    : undefined

  let myTripLink = AppRoutes.getPath("AttendeeSiteMyTripPage", {websiteName})

  let homeLink = AppRoutes.getPath("AttendeeSiteHome", {
    websiteName: websiteName,
  })

  let navItems: PageNavItem[] = pages
    .filter((page) => (props.buildTo ? true : page.published))
    .map((page) => ({
      to: props.buildTo
        ? props.buildTo(page.id)
        : AppRoutes.getPath("AttendeeSitePage", {
            websiteName,
            pageName: titleToNavigation(page.title),
          }),
      title: page.title,
      active: titleToNavigation(page.title) === activePage,
    }))
  if (flightsLive) {
    navItems.push({
      to: props.buildTo
        ? AppRoutes.getPath("RetreatFlightsOptionsPage", {
            retreatIdx: props.retreatIdx ? props.retreatIdx!.toString() : "0",
          })
        : AppRoutes.getPath("AttendeeSiteFlightsPage", {websiteName}),
      title: "Flights",
      active: activePage === "flights",
    })
  }

  let [attendee] = useMyAttendee(props.retreat.id)

  let logoImage: Pick<ImageModel, "image_url" | "alt"> = props.website
    .logo_image
    ? props.website.logo_image
    : {alt: "Flok logo", image_url: ImageUtils.getImageUrl("logoIconTextTrans")}

  const isSmallScreen = useMediaQuery((theme: FlokTheme) =>
    theme.breakpoints.down("sm")
  )
  const AUTHED_PAGES = ["registration", "my-trip", "surveys"]
  return isSmallScreen ? (
    <MobileSiteHeader
      myTripLink={myTripLink}
      myTripActive={activePage === "my-trip"}
      registerLink={registerLink}
      attendee={attendee}
      activePage={props.activePage}
      homeLink={homeLink}
      logo={logoImage}
      navItems={navItems}
    />
  ) : (
    <SiteHeaderContainer>
      <SiteHeaderInnerContainer>
        <LogoLink to={props.buildTo ? props.buildTo() : homeLink}>
          <LogoImage src={logoImage.image_url} alt={logoImage.alt} />
        </LogoLink>
        <LinkContainer>
          {navItems.map((navItem) => (
            <StyledLink to={navItem.to} key={navItem.title} color="inherit">
              <LinkText variant="text-base" active={navItem.active}>
                {navItem.title}
              </LinkText>
            </StyledLink>
          ))}
        </LinkContainer>
        <ButtonContainer>
          {AUTHED_PAGES.includes(props.activePage) && attendee ? (
            <Dropdown
              button={
                <Button
                  variant={"outline"}
                  color="gray"
                  endIcon={<CaretDownIcon />}
                  startIcon={
                    <Avatar
                      label={attendee.first_name[0]}
                      round
                      color={"blue"}
                    />
                  }
                  text={attendee.email_address}
                />
              }>
              <DropdownItem
                text="Log out"
                startIcon={<ExitIcon />}
                onClick={async () => {
                  let logoutRequest = (await dispatch(
                    deleteUserSignin()
                  )) as unknown as ApiAction
                  if (!logoutRequest.error) {
                    dispatch(push(homeLink))
                  }
                }}
              />
            </Dropdown>
          ) : (
            <RegisterNowButton
              to={
                attendee?.registration_form_response_id
                  ? myTripLink
                  : registerLink
              }
              text={
                attendee?.registration_form_response_id
                  ? "My Trip"
                  : "Register Now"
              }
              color={theme.palette.primary.main}
            />
          )}
        </ButtonContainer>
      </SiteHeaderInnerContainer>
    </SiteHeaderContainer>
  )
}

function RegisterNowButton(props: {
  to?: string
  target?: string
  color: string
  text: string
  fullWidth?: boolean
}) {
  const registerText = props.text
  return props.to ? (
    <StyledLink target={props.target} to={props.to}>
      <Button
        fullWidth={props.fullWidth}
        text={registerText}
        color="brand"
        css={{
          $$color: props.color,
          justifyContent: "center",
          height: "40px",
        }}></Button>
    </StyledLink>
  ) : (
    <Tooltip text="Registration isn't live yet">
      <Button
        fullWidth={props.fullWidth}
        disabled
        text={registerText}
        color="brand"
        css={{
          $$color: props.color,
          justifyContent: "center",
          height: "40px",
        }}></Button>
    </Tooltip>
  )
}

const MobileSiteHeaderContainer = styled("div", {
  width: "100%",
  padding: "0px 20px",
  height: "80px",
  flexShrink: 0,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
})
const MobileSiteHeaderInnerContainer = styled("div", {
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  [`${Button}`]: {
    background: "$$color",
    color: flokTheme.colors.white,
    "&:hover": {
      background: "$$color",
      opacity: 0.9,
    },
    "&:active": {
      background: "$$color",
      opacity: 0.8,
    },
  },
  alignItems: "center",
})
const UserNameContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "4px",
})

const MobileMenu = styled("div", {
  position: "absolute",
  top: "79px",
  height: "calc(100% - 79px)",
  width: "100%",
  background: "$$background",
  left: "0px",
  right: "0px",
  bottom: "0px",
  // TODO, fix this once we have a better idea what our zIndex patterns should be
  zIndex: 1000000000,
  padding: "24px 16px",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  alignItems: "center",
})

const GrayDivider = styled("div", {
  width: "240px",
  height: "1px",
  background: flokTheme.colors.gray6,
  margin: "8px 0px",
})

const RegisterWrapper = styled("div", {
  marginTop: "auto",
  padding: "24px 16px",
  width: "100%",
})

const CurrentUserContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "24px 0px",
  alignItems: "center",
  gap: "4px",
  marginTop: "auto",
})

type MobileSiteHeaderProps = {
  activePage: string | "registration" | "flights"
  buildTo?: (pageId?: number) => string
  homeLink: string
  logo: Pick<ImageModel, "alt" | "image_url">
  navItems: PageNavItem[]
  attendee?: RetreatAttendeeModel
  myTripLink: string
  myTripActive: boolean
  registerLink: string | undefined
}
function MobileSiteHeader(props: MobileSiteHeaderProps) {
  let [menuOpen, setMenuOpen] = useState(false)
  let theme = useTheme()
  let dispatch = useDispatch()
  return (
    <MobileSiteHeaderContainer>
      <MobileSiteHeaderInnerContainer>
        <StyledLink to={props.buildTo ? props.buildTo() : props.homeLink}>
          <LogoImage src={props.logo.image_url} alt={props.logo.alt} />
        </StyledLink>
        <Button
          text={menuOpen ? "Close" : "Menu"}
          css={{$$color: theme.palette.primary.main}}
          endIcon={menuOpen ? <Cross1Icon /> : <HamburgerMenuIcon />}
          onClick={() => {
            setMenuOpen((open) => !open)
          }}
        />
        {menuOpen && (
          <MobileMenu css={{$$background: theme.palette.background.default}}>
            {props.navItems.map((navItem) => (
              <StyledLink
                to={navItem.to}
                key={navItem.title}
                color="inherit"
                onClick={() => {
                  setMenuOpen(false)
                }}>
                <LinkText variant="text-base-plus" active={navItem.active}>
                  {navItem.title}
                </LinkText>
              </StyledLink>
            ))}
            {props.attendee?.registration_form_response_id && (
              <>
                <GrayDivider />
                <StyledLink
                  to={props.myTripLink}
                  key={"my-trip"}
                  color="inherit"
                  onClick={() => {
                    setMenuOpen(false)
                  }}>
                  <LinkText
                    variant="text-base-plus"
                    active={props.myTripActive}>
                    My Trip
                  </LinkText>
                </StyledLink>
                <LinkText
                  onClick={async () => {
                    let logoutRequest = (await dispatch(
                      deleteUserSignin()
                    )) as unknown as ApiAction
                    if (!logoutRequest.error) {
                      dispatch(push(props.homeLink))
                      await dispatch(getUserHome())
                    }
                  }}
                  variant="text-base-plus"
                  active={false}>
                  Log Out
                </LinkText>
                <CurrentUserContainer>
                  <Text
                    css={{color: flokTheme.colors.gray11}}
                    variant="text-sm-plus">
                    Signed in as
                  </Text>
                  <UserNameContainer>
                    <Avatar
                      label={props.attendee.first_name[0]}
                      round
                      color={"blue"}
                    />
                    <Text variant={"text-base-plus"}>
                      {props.attendee.email_address}
                    </Text>
                  </UserNameContainer>
                </CurrentUserContainer>
              </>
            )}
            {(!props.attendee ||
              !props.attendee.registration_form_response_id) && (
              <RegisterWrapper>
                <RegisterNowButton
                  fullWidth
                  to={props.registerLink}
                  text={"Register Now"}
                  color={theme.palette.primary.main}
                />
              </RegisterWrapper>
            )}
          </MobileMenu>
        )}
      </MobileSiteHeaderInnerContainer>
    </MobileSiteHeaderContainer>
  )
}
