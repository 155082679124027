import {PlusIcon} from "@radix-ui/react-icons"
import {Avatar} from "@summtech/flok-base/components/Avatar"
import {Badge} from "@summtech/flok-base/components/Badge"
import {Button as FlokButton} from "@summtech/flok-base/components/Button"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {FormField} from "@summtech/flok-base/components/FormField"
import {FormLabel} from "@summtech/flok-base/components/FormLabel"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {convertFromRaw, convertToRaw, EditorState} from "draft-js"
import {useFormik} from "formik"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {AppWysiwygEditor} from "../../../components/base/AppWysiwyg"
import {FileModel} from "../../../models/retreat"
import {
  LabelModel,
  TaskPriority,
  TaskPriorityName,
  TaskPriorityValues,
  TaskStatus,
  TaskStatusEnum,
  TaskStatusName,
  TaskStatusValues,
} from "../../../models/task"
import {useRetreat} from "../../../pages/misc/RetreatProvider"
import {RootState} from "../../../store"
import {ApiAction} from "../../../store/actions/api"
import {getUser} from "../../../store/actions/retreat"
import {
  deleteAssigneeToTask,
  deleteFileToTask,
  deleteLabelToTask,
  deleteTask,
  patchTask,
  postAssigneeToTask,
  postFileToTask,
  postLabel,
  postLabelToTask,
  postTask,
} from "../../../store/actions/task"
import {splitFileName} from "../../../utils/retreatUtils"
import AppModal from "../AppModal"
import {AppMultipleFilePicker} from "../AppMultipleFilePicker"
import SideDrawer, {SideDrawerFooter, SideDrawerHeader} from "../SideDrawer"

let StyledHeaderContainer = styled("div", {
  paddingBottom: "10px",
  borderBottom: `1px solid ${theme.colors.gray6}`,
})
let StyledFormContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBlock: "15px",
  marginInline: "20px",
})
let StyledForm = styled("form", {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
})

let StyledAutocompleteContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  whiteSpace: "nowrap",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "12px",
  [`& ${Text}`]: {
    width: "160px",
  },
})

let StyledWysiwygEditorContainer = styled("div", {
  display: "flex",
  width: "360px",
  minHeight: "80px",
  marginRight: "auto",
  border: `1px solid ${theme.colors.gray7}`,
  borderRadius: "11px",
})
let StyledFormLabel = styled(FormLabel, {
  width: "160px",
})
let StyledLabelModalFooter = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  justifyContent: "right",
})

const AttendeesMultiSelect = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
})
type TaskViewerProps = {
  open: boolean
  taskId?: number
  onClose: () => void
  retreatUsers: number[]
  labels: {
    [id: number]: LabelModel | undefined
  }
}

function compareMultiSelectValues(newVals: string[], oldVals: string[]) {
  let longerList = newVals.length > oldVals.length ? newVals : oldVals
  let shorterSet =
    newVals.length > oldVals.length ? new Set(oldVals) : new Set(newVals)
  let operator =
    newVals.length > oldVals.length ? "select-option" : "remove-option"
  let changedValues = longerList.filter((val) => !shorterSet.has(val))
  if (changedValues.length === 1) {
    return {operator: operator, changedValue: changedValues[0]}
  } else {
    throw Error("There's not a difference of only 1 value in the two lists")
  }
}
function TaskViewer(props: TaskViewerProps) {
  let {open, taskId, labels, retreatUsers} = props
  let [newLabelDialog, setNewLabelDialog] = useState(false)
  let [loadingAssignees, setLoadingAssignees] = useState(false)
  let [confirmingDelete, setConfirmingDelete] = useState(false)
  let dispatch = useDispatch()

  let assigneeOptions = useSelector((state: RootState) => {
    return state.retreat.users
  })
  let taskList = useSelector((state: RootState) => state.retreat.tasks)

  let [retreat, retreatIdx] = useRetreat()
  let task = taskId ? taskList[taskId] : undefined
  let formik = useFormik({
    initialValues: {
      title: task?.title ?? "",
      description: task?.description
        ? EditorState.createWithContent(convertFromRaw(task.description))
        : EditorState.createEmpty(),
      due_date: task?.due_date ?? undefined,
      assignees: task?.assignees ?? ([] as number[]),
      labels: task?.labels ?? ([] as number[]),
      priority: task?.priority ?? undefined,
      status: task?.status ?? TaskStatusEnum.TO_DO,
      files: task?.files ?? ([] as FileModel[]),
    },
    onSubmit: async (values) => {
      let formikFiles = [...(values.files ? values.files : [])]
      let formikAssignees = [...(values.assignees ? values.assignees : [])]
      let formikLabels = [...(values.labels ? values.labels : [])]
      let postValues = {
        ...values,
        files: [],
        assignees: [],
        labels: [],
        description: convertToRaw(values.description.getCurrentContent()),
      }
      let response = task
        ? ((await dispatch(
            patchTask(postValues, task.id)
          )) as unknown as ApiAction)
        : ((await dispatch(
            postTask(postValues, retreat.id)
          )) as unknown as ApiAction)
      if (!response.error) {
        let filesToPost = task
          ? fileDifference(formikFiles, task?.files)
          : formikFiles
        let assigneesToPost = formikAssignees
        let labelsToPost = formikLabels
        await Promise.all(
          filesToPost.map(async (file) => {
            let fileResponse = (await dispatch(
              postFileToTask({
                task_id: response.payload.task.id,
                file_id: file.id,
              })
            )) as unknown as ApiAction
            if (!fileResponse.error) {
              dispatch({
                type: "ADD_FILE_TO_TASK",
                file: file,
                task_id: response.payload.task.id,
              })
            }
          }) &&
            assigneesToPost.map(async (assignee) => {
              await dispatch(
                postAssigneeToTask({
                  task_id: response.payload.task.id,
                  user_id: assignee,
                })
              )
            }) &&
            labelsToPost.map(async (label) => {
              await dispatch(
                postLabelToTask({
                  task_id: response.payload.task.id,
                  label_id: label,
                })
              )
            })
        )
        props.onClose()
      }
    },
  })
  let {resetForm} = formik

  useEffect(() => {
    async function loadAssignees(retreatUsers: number[]) {
      setLoadingAssignees(true)
      await Promise.all(retreatUsers.map((userId) => dispatch(getUser(userId))))
      setLoadingAssignees(false)
    }
    resetForm({
      values: {
        title: task?.title ?? "",
        description: task?.description
          ? EditorState.createWithContent(convertFromRaw(task.description))
          : EditorState.createEmpty(),
        due_date: task?.due_date ?? undefined,
        assignees: task?.assignees ?? ([] as number[]),
        labels: task?.labels ?? ([] as number[]),
        priority: task?.priority ?? undefined,
        status: task?.status ?? TaskStatusEnum.TO_DO,
        files: task?.files ?? ([] as FileModel[]),
      },
    })

    if (!loadingAssignees) {
      let missingAssignees = retreatUsers
        .map((userId) => (!assigneeOptions[userId] ? userId : undefined))
        .filter((id) => id)
      if (missingAssignees.length > 0) {
        loadAssignees(missingAssignees as number[])
      }
    }
  }, [
    task,
    resetForm,
    loadingAssignees,
    dispatch,
    assigneeOptions,
    retreatUsers,
  ])

  useEffect(() => {
    if (confirmingDelete) {
      setTimeout(() => setConfirmingDelete(false), 5000)
    }
  }, [confirmingDelete])

  function fileDifference(fileArr1: FileModel[], fileArr2: FileModel[]) {
    let fileMap: {[id: number]: true} = {}
    fileArr1.forEach((file) => {
      fileMap[file.id] = true
    })
    return fileArr2.filter((file) => !fileMap[file.id])
  }
  async function handleAssigneesChange(
    changeReason: string,
    changedOption: string,
    taskId: number
  ) {
    if (changeReason === "remove-option") {
      return dispatch(
        deleteAssigneeToTask({
          task_id: taskId,
          user_id: parseInt(changedOption),
        })
      )
    }
    if (changeReason === "select-option") {
      return dispatch(
        postAssigneeToTask({
          task_id: taskId,
          user_id: parseInt(changedOption),
        })
      )
    }
  }
  async function handleLabelsChange(
    changeReason: string,
    changedOption: string,
    taskId: number
  ) {
    if (changeReason === "remove-option") {
      return dispatch(
        deleteLabelToTask({
          taskId: taskId,
          labelId: parseInt(changedOption),
        })
      )
    }
    if (changeReason === "select-option") {
      return dispatch(
        postLabelToTask({
          task_id: taskId,
          label_id: parseInt(changedOption),
        })
      )
    }
  }

  return (
    <SideDrawer open={open} onClose={props.onClose}>
      <StyledHeaderContainer>
        <SideDrawerHeader
          text={task ? "Edit Task" : "Add Task"}
          onClose={props.onClose}
        />
      </StyledHeaderContainer>
      <StyledFormContainer>
        <StyledForm onSubmit={formik.handleSubmit}>
          <FormField
            id="title"
            error={!!formik.errors.title}
            hint={formik.errors.title}
            type={"textfield"}
            value={formik.values.title}
            label={"Name"}
            placeholder="Task Title"
            required
            inline
            onChange={(e) => {
              formik.setFieldValue("title", e.target.value)
            }}
            onBlur={() => {
              if (task?.id && !formik.errors.title) {
                dispatch(patchTask({title: formik.values.title}, task.id))
              }
            }}
          />
          <FormField
            type="select"
            label="Status"
            inline
            value={formik.values.status}
            onChange={(newValue) => {
              formik.setFieldValue("status", newValue)
              if (task) {
                dispatch(
                  patchTask(
                    {
                      status: newValue as TaskStatus,
                    },
                    task.id
                  )
                )
              }
            }}>
            {TaskStatusValues.map((status) => {
              return (
                <SelectItem
                  value={status}
                  label={<Badge label={TaskStatusName[status]} />}
                />
              )
            })}
          </FormField>
          <FormField
            renderSelectedOptions={(values, options) => {
              let firstValue = options.find(
                (option) => option.value === values[0]
              )
              return (
                <AttendeesMultiSelect>
                  {values.length > 0 && (
                    <Avatar
                      color={"blue"}
                      round
                      label={
                        values.length === 1
                          ? firstValue && firstValue.label
                            ? ((firstValue.label ?? firstValue) as string)[0]
                            : ""
                          : values.length.toString()
                      }
                    />
                  )}
                  {values.map((value, i) => {
                    const option = options.find(
                      (option) => option.value === value
                    )
                    return (
                      <Text variant={"text-sm"}>
                        {option?.label}
                        {i === values.length - 1 ? undefined : ", "}
                      </Text>
                    )
                  })}
                </AttendeesMultiSelect>
              )
            }}
            inline
            type="multiselect"
            label="Assignees"
            selectedValues={
              formik.values.assignees.map((assignee) =>
                assignee.toString()
              ) as string[]
            }
            onChange={(newVals) => {
              try {
                let change = compareMultiSelectValues(
                  newVals,
                  formik.values.assignees.map((assignee) => assignee.toString())
                )
                if (task) {
                  handleAssigneesChange(
                    change.operator,
                    change.changedValue as string,
                    task.id
                  )
                }
                formik.setFieldValue("assignees", newVals)
              } catch {
                console.log(
                  "There was an error adding/removing a value from the multiselect"
                )
              }
            }}
            options={Object.keys(assigneeOptions).map((userId) => {
              return {
                value: userId,
                label: `${assigneeOptions[parseInt(userId)]?.first_name} ${
                  assigneeOptions[parseInt(userId)]?.last_name
                }`,
              }
            })}></FormField>

          <FormField
            id="due_date"
            value={formik.values.due_date}
            required
            label={"Due Date"}
            inline
            type="datetime"
            onChange={(newVal) => {
              formik.setFieldValue("due_date", newVal)
              if (task) dispatch(patchTask({due_date: newVal}, task.id))
            }}
          />

          <FormField
            type="select"
            label="Priority"
            value={formik.values.priority}
            inline
            onChange={(newValue) => {
              formik.setFieldValue("priority", newValue)
              if (task) {
                dispatch(
                  patchTask(
                    {priority: (newValue as TaskPriority) ?? null},
                    task.id
                  )
                )
              }
            }}>
            {TaskPriorityValues.map((priority) => {
              return (
                <SelectItem
                  value={priority}
                  label={<Badge label={TaskPriorityName[priority]} />}
                />
              )
            })}
          </FormField>
          <FormField
            renderSelectedOptions={(values, options) => {
              return (
                <AttendeesMultiSelect>
                  {values.map((value) => {
                    let option = options.find(
                      (option) => option.value === value
                    )
                    return <Badge label={option?.label as string} />
                  })}
                </AttendeesMultiSelect>
              )
            }}
            inline
            type="multiselect"
            label="Labels"
            options={Object.keys(labels).map((labelId) => {
              return {
                value: labelId,
                label: `${labels[parseInt(labelId)]?.text}`,
              }
            })}
            selectedValues={formik.values.labels.map((label) =>
              label.toString()
            )}
            additionalOptions={
              <DropdownItem
                text="Add Label"
                startIcon={<PlusIcon />}
                onClick={() => {
                  setNewLabelDialog(true)
                }}
              />
            }
            onChange={(newVals) => {
              try {
                let change = compareMultiSelectValues(
                  newVals,
                  formik.values.labels.map((label) => label.toString())
                )
                if (task) {
                  handleLabelsChange(
                    change.operator,
                    change.changedValue as string,
                    task.id
                  )
                }
                formik.setFieldValue("labels", newVals)
              } catch {
                console.log(
                  "There was an error adding/removing a value from the multiselect"
                )
              }
            }}></FormField>

          <StyledAutocompleteContainer>
            <StyledFormLabel text="Description" />
            <StyledWysiwygEditorContainer
              onBlur={() => {
                if (task?.id && !formik.errors.description) {
                  dispatch(
                    patchTask(
                      {
                        description: convertToRaw(
                          formik.values.description.getCurrentContent()
                        ),
                      },
                      task.id
                    )
                  )
                }
              }}>
              <AppWysiwygEditor
                disableToolbar
                editorState={formik.values.description}
                onEditorStateChange={(val) => {
                  formik.setFieldValue("description", val)
                }}
              />
            </StyledWysiwygEditorContainer>
          </StyledAutocompleteContainer>
          <AppMultipleFilePicker
            accepts="image/png, image/jpg, application/pdf, image/jpeg"
            label="Attachments"
            files={formik.values.files?.map((file) => {
              return {
                id: file.id,
                path: file.file_url,
                displayName: splitFileName(file.file_url) ?? "unknown-file",
              }
            })}
            handleChange={async (file) => {
              if (props.taskId) {
                let response = (await dispatch(
                  postFileToTask({
                    task_id: props.taskId ?? -1,
                    file_id: file.id,
                  })
                )) as unknown as ApiAction
                if (!response.error) {
                  dispatch({
                    type: "ADD_FILE_TO_TASK",
                    file: file,
                    task_id: props.taskId,
                  })
                }
              } else {
                formik.setFieldValue("files", [
                  ...(formik.values.files ? formik.values.files : []),
                  file,
                ])
              }
            }}
            onDelete={
              props.taskId
                ? (fileId) => {
                    dispatch(deleteFileToTask(fileId, props.taskId as number))
                  }
                : (fileId) => {
                    if (formik.values.files) {
                      let files = [...formik.values.files]
                      let index = formik.values.files.findIndex((arrFile) => {
                        return arrFile.id === fileId
                      })
                      if (index !== -1) {
                        files.splice(index, 1)
                      }
                      formik.setFieldValue("files", files)
                    }
                  }
            }
          />
        </StyledForm>
        <LabelFormModal
          onSuccess={(label) => {
            if (task) {
              return dispatch(
                postLabelToTask({
                  task_id: task.id,
                  label_id: label.id,
                })
              ) as unknown as ApiAction
            } else {
              return formik.setFieldValue("labels", [
                ...(formik.values.labels ? formik.values.labels : []),
                label.id,
              ])
            }
          }}
          open={newLabelDialog}
          onClose={() => setNewLabelDialog(false)}
          retreatId={retreat.id}
        />
      </StyledFormContainer>
      {task ? (
        <SideDrawerFooter>
          <FlokButton
            color={"critical"}
            type="button"
            text={confirmingDelete ? "Are you sure?" : "Delete"}
            variant={confirmingDelete ? "solid" : "outline"}
            onClick={async (e) => {
              e.preventDefault()
              if (confirmingDelete) {
                let response = (await dispatch(
                  deleteTask(task?.id as number, task?.retreat_id as number)
                )) as unknown as ApiAction
                if (!response.error) {
                  props.onClose()
                }
              } else {
                setConfirmingDelete(true)
              }
            }}
          />
          {/* <FlokButton
            text="Save Changes"
            color={"brand"}
            variant={"solid"}
            onClick={() => props.onClose()}
            // disabled={_.isEqual(formik.values, formik.initialValues)}
          /> */}
        </SideDrawerFooter>
      ) : (
        <SideDrawerFooter>
          <FlokButton
            color="brand"
            onClick={props.onClose}
            variant="outline"
            text="Cancel"></FlokButton>
          <FlokButton
            color="brand"
            onClick={formik.submitForm}
            variant="solid"
            text="Create Task"
          />
        </SideDrawerFooter>
      )}
    </SideDrawer>
  )
}

export default TaskViewer

export function LabelFormModal(props: {
  open: boolean
  onClose: () => void
  onSuccess: (label: LabelModel) => void
  retreatId: number
}) {
  let [newLabel, setNewLabel] = useState({
    text: "",
    id: 0,
  })
  let dispatch = useDispatch()
  async function handleNewLabel(
    text: string,
    retreat_id: number,
    color?: string
  ) {
    let response = (await dispatch(
      postLabel({text, retreat_id, color})
    )) as unknown as ApiAction
    if (!response.error) {
      await props.onSuccess(response.payload.label)
      props.onClose()
    }
  }
  return (
    <AppModal open={props.open} onClose={props.onClose}>
      <div style={{padding: "20px"}}>
        <div style={{marginBlock: "10px"}}>
          <FormField
            fullWidth
            placeholder="Label Title"
            inline
            label="Create new label"
            type="textfield"
            value={newLabel.text}
            id="new-label"
            required
            onChange={(e) => setNewLabel({...newLabel, text: e.target.value})}
          />
        </div>
        <StyledLabelModalFooter>
          <FlokButton
            variant="outline"
            color="brand"
            text="Cancel"
            onClick={props.onClose}
          />
          <FlokButton
            variant="solid"
            color="brand"
            text="Create Label"
            onClick={() =>
              newLabel.text &&
              handleNewLabel(newLabel.text, props.retreatId, undefined)
            }
          />
        </StyledLabelModalFooter>
      </div>
    </AppModal>
  )
}
