import AppConfirmationModal from "../base/ConfirmationModal"

export default function ConfirmCancelRequestedModal(props: {
  open: boolean
  onSubmit: () => void
  onClose: () => void
}) {
  return (
    <AppConfirmationModal
      open={props.open}
      title="Cancel request?"
      text="You will not be able to request any more hotels after you have submitted"
      onSubmit={props.onSubmit}
      onClose={props.onClose}
    />
  )
}
