import {Badge} from "@summtech/flok-base/components/Badge"
import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {ComponentProps, useState} from "react"
import {useSelector} from "react-redux"
import {
  RetreatAttendeeModel,
  RetreatTripLeg,
  RetreatTripModel,
  TripLegTypeEnum,
} from "../../models/retreat"
import {RootState} from "../../store"
import AppModal from "../app/AppModal"
import {FlightStatusBadge} from "../app/attendees/StatusBadge"
import FlightDetailsForm from "./FlightDetailsForm"
import FlightDisplay from "./FlightDisplay"
import {FlightFormModal} from "./FlightForm"

const DetailsTable = styled("div", {
  display: "flex",
  flexDirection: "column",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: theme.colors.gray6,
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  backgroundColor: theme.colors.white,
})

const DetailsHeader = styled("div", {
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: theme.colors.gray6,
  padding: "16px",
})

const DetailsBody = styled("div", {
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: theme.colors.gray6,
  padding: "12px 16px",
  gap: "16px",
  display: "flex",
  flexDirection: "row",
  width: "100%",
})

const DetailsBodyColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  gap: "4px",
  flex: 1,
})
const BodyColumnHeader = styled(Text, {
  color: theme.colors.gray11,
})

const DetailsFooter = styled("div", {
  padding: "12px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "12px",
})
const EditButton = styled(Button, {
  marginLeft: "auto",
  marginRight: "auto",
})
const DoneButton = styled(Button, {
  marginLeft: "auto",
})
const NoFlightsBox = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexDirection: "column",
  textAlign: "center",
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

const DetailsModalFooter = styled(AppModal.Footer, {
  justifyContent: "space-between",
  borderTopWidth: "0px",
})

const DetailsModal = styled(AppModal, {
  "@bp2": {
    width: "600px",
  },
})

export const renderFlightStatusBadge = (
  val: "BOOKED" | "OPT_OUT" | "PENDING"
) => {
  if (val === "BOOKED") {
    return <Badge color="success" label={"Booked"} />
  } else if (val === "OPT_OUT") {
    return <Badge color="gray" label={"Opt'd Out"} />
  } else {
    return <Badge color="critical" label={"To Book"} />
  }
}
export default function FlightDetailsTable(props: {
  attendee: RetreatAttendeeModel
  receiptRestricted?: boolean
}) {
  let [editing, setEditing] = useState(false)
  if (!props.attendee.travel) {
    return <></>
  }
  return (
    <DetailsTable>
      <DetailsModal
        open={editing}
        onClose={() => {
          setEditing(false)
        }}>
        <AppModal.Header>Edit Travel Details</AppModal.Header>
        <AppModal.Body noPadding>
          <DetailsBody>
            <FlightDetailsForm
              attendee={props.attendee}
              receiptRestricted={props.receiptRestricted}
            />
          </DetailsBody>
        </AppModal.Body>
        <DetailsModalFooter>
          <DoneButton
            text="Done"
            color="brand"
            onClick={() => {
              setEditing(false)
            }}
          />
        </DetailsModalFooter>
      </DetailsModal>
      <DetailsHeader>
        <Text variant="heading-sm">Travel Details</Text>
      </DetailsHeader>

      <DetailsBody>
        <DetailsBodyColumn>
          <BodyColumnHeader variant="text-sm">Status</BodyColumnHeader>
          <FlightStatusBadge status={props.attendee.flight_status} />
        </DetailsBodyColumn>
        <DetailsBodyColumn>
          <BodyColumnHeader variant="text-sm">Costs</BodyColumnHeader>
          <Text variant="text-sm">
            {props.attendee.travel.cost != null
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(props.attendee.travel.cost)
              : "N/A"}
          </Text>
        </DetailsBodyColumn>
        <DetailsBodyColumn>
          <BodyColumnHeader variant="text-sm">Receipts</BodyColumnHeader>
          <Text variant="text-sm"> {props.attendee.receipts.length}</Text>
        </DetailsBodyColumn>
      </DetailsBody>

      <DetailsFooter>
        <EditButton
          text="Edit Details"
          color="brand"
          onClick={() => {
            setEditing(true)
          }}
        />
      </DetailsFooter>
    </DetailsTable>
  )
}

type FlightsTableProps = {
  header: string
  trip: RetreatTripModel
  noFlightsMessage: string
}

const AddTripLegButton = styled("div", {
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  flexDirection: "row",
  flex: 1,
})
const FlightCardContainer = styled("div", {
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: theme.colors.gray6,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "12px",
  padding: "12px 20px",
})

export function FlightsTable(props: FlightsTableProps) {
  const [flightFormOpen, setFlightFormOpen] = useState<number | boolean>(false)
  const closeModal = () => {
    setFlightFormOpen(false)
  }
  let flights = useSelector((state: RootState) => {
    let tripFlights: {[id: number]: RetreatTripLeg | undefined} = {}
    props.trip.trip_leg_ids.forEach((id) => {
      tripFlights[id] = state.retreat.flights[id]
    })
    return tripFlights
  })
  return (
    <DetailsTable>
      <FlightFormModal
        tripId={props.trip.id}
        onClose={closeModal}
        onCancel={closeModal}
        open={!!flightFormOpen}
        flightId={
          typeof flightFormOpen === "number" ? flightFormOpen : undefined
        }
        onDelete={closeModal}
        onSuccess={closeModal}
      />
      <DetailsHeader>
        <Text variant="heading-sm">{props.header}</Text>
      </DetailsHeader>

      {props.trip.trip_leg_ids.map((leg_id) => {
        let leg =
          flights[leg_id] ??
          props.trip.trip_legs.find((leg) => leg.id === leg_id)
        if (leg) {
          let flightDisplayProps: ComponentProps<typeof FlightDisplay>
          let buttonProps: ComponentProps<typeof Button>
          switch (leg.trip_leg_type) {
            case TripLegTypeEnum.TRAIN:
              buttonProps = {
                text: "Edit Train",
                onClick: () => {
                  setFlightFormOpen(leg!.id)
                },
              }
              flightDisplayProps = {
                departureLocation: leg.dep_station ?? "",
                departureTime: leg.dep_datetime ?? "",
                arrivalLocation: leg.arr_station ?? "",
                arrivalTime: leg.dep_datetime ?? "",
                travelCompany: leg.railway || "Unknown railway",
                travelNumber: leg.train_num || "Unknown train #",
              }
              break
            case TripLegTypeEnum.FLIGHT:
            default:
              buttonProps = {
                text: "Edit Flight",
                onClick: () => {
                  setFlightFormOpen(leg!.id)
                },
              }
              flightDisplayProps = {
                travelCompany: leg.airline || "Unknown airline",
                arrivalLocation: leg.arr_airport ?? "",
                departureLocation: leg.dep_airport ?? "",
                departureTime: leg.dep_datetime ?? "",
                arrivalTime: leg.arr_datetime ?? "",
                travelNumber: leg.flight_num || "Unknown flight #",
              }
          }
          return (
            <FlightCardContainer>
              <FlightDisplay
                alt
                type={leg.trip_leg_type}
                {...flightDisplayProps}
              />
              <Button color="gray" variant={"outline"} {...buttonProps} />
            </FlightCardContainer>
          )
        }
      })}

      <DetailsFooter>
        {props.trip.trip_leg_ids.length === 0 && (
          <NoFlightsBox>
            <Text variant="text-sm-plus">No trip legs added yet.</Text>
            <GrayText variant="text-sm">{props.noFlightsMessage}</GrayText>
          </NoFlightsBox>
        )}
        <AddTripLegButton>
          <Button
            text="Add Trip"
            color="brand"
            onClick={() => {
              setFlightFormOpen(true)
            }}
          />
        </AddTripLegButton>
      </DetailsFooter>
    </DetailsTable>
  )
}
