import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext"

export function InitializePlugin(props: {
  initialEditorState: string | undefined
}) {
  let [editor] = useLexicalComposerContext()
  const editorState = editor.parseEditorState(
    props.initialEditorState ??
      '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}'
  )
  editor.setEditorState(editorState)
  return null
}
