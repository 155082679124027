import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list"
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext"
import {
  $createHeadingNode,
  $isHeadingNode,
  HeadingTagType,
} from "@lexical/rich-text"
import {$setBlocksType} from "@lexical/selection"
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from "@lexical/utils"
import {
  FontBoldIcon,
  FontItalicIcon,
  PlusIcon,
  TextAlignCenterIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
  UnderlineIcon,
} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {ButtonGroup} from "@summtech/flok-base/components/ButtonGroup"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Select} from "@summtech/flok-base/components/Select"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {styled} from "@summtech/flok-base/stitches.config"
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  DEPRECATED_$isGridSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
} from "lexical"
import {useCallback, useEffect, useState} from "react"
import {useLexicalListCommands} from "./lexicalUtils"
import {BUTTON_COMMAND} from "./plugins/ButtonPlugin"

const ToolbarContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  // fix
  marginLeft: "40px",
  marginTop: "-40px",
})
const StyledSelectWrapper = styled("div", {
  [`& ${Select}`]: {
    width: "128px",
  },
})

const FontButtonsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "4px",
})

export default function LexicalToolbar() {
  const [editor] = useLexicalComposerContext()
  const [activeEditor, setActiveEditor] = useState(editor)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [blockType, setBlockType] = useState("")

  useLexicalListCommands(editor)
  // Lexical code
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      if (
        $isRangeSelection(selection) ||
        DEPRECATED_$isGridSelection(selection)
      ) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        if (
          $isRangeSelection(selection) ||
          DEPRECATED_$isGridSelection(selection)
        ) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize))
        }
      })
    }
  }
  const formatBulletList = () => {
    if (blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatNumberedList = () => {
    if (blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat("bold"))
      setIsItalic(selection.hasFormat("italic"))
      setIsStrikethrough(selection.hasFormat("strikethrough"))
      setIsUnderline(selection.hasFormat("underline"))
    }

    // Lexical code
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = activeEditor.getElementByKey(elementKey)

      if (elementDOM !== null) {
        // setSelectedElementKey(elementKey)
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          )
          const type = parentList
            ? parentList.getListType()
            : element.getListType()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()
          setBlockType(type)
        }
      }
    }
  }, [editor])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateToolbar()
        })
      })
    )
  }, [updateToolbar, editor])

  return (
    <ToolbarContainer>
      <StyledSelectWrapper>
        <Select
          value={blockType}
          onChange={(newValue) => {
            editor.update(() => {
              if (newValue === "h1" || newValue === "h2" || newValue === "h3") {
                formatHeading(newValue)
              } else if (newValue === "paragraph") {
                formatParagraph()
              } else if (newValue === "bullet") {
                formatBulletList()
              } else if (newValue === "number") {
                formatNumberedList()
              }
            })
          }}>
          <SelectItem value="paragraph" label="Normal" />
          <SelectItem value="h1" label="Heading 1" />
          <SelectItem value="h2" label="Heading 2" />
          <SelectItem value="h3" label="Heading 3" />
          <SelectItem value="bullet" label="Bullet List" />
          <SelectItem value="number" label="Number List" />
        </Select>
      </StyledSelectWrapper>
      <FontButtonsContainer>
        <ButtonGroup>
          <IconButton
            color={isBold ? "brand" : "gray"}
            variant="outline"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")
            }}>
            <FontBoldIcon />
          </IconButton>
          <IconButton
            color={isItalic ? "brand" : "gray"}
            variant="outline"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
            }}>
            <FontItalicIcon />
          </IconButton>

          <IconButton
            color={isUnderline ? "brand" : "gray"}
            variant="outline"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
            }}>
            <UnderlineIcon />
          </IconButton>
        </ButtonGroup>
      </FontButtonsContainer>
      <ButtonGroup>
        <IconButton
          color={"gray"}
          variant="outline"
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left")
          }}>
          <TextAlignLeftIcon />
        </IconButton>
        <IconButton
          color={"gray"}
          variant="outline"
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center")
          }}>
          <TextAlignCenterIcon />
        </IconButton>
        <IconButton
          color={"gray"}
          variant="outline"
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right")
          }}>
          <TextAlignRightIcon />
        </IconButton>
      </ButtonGroup>
      <Dropdown
        button={
          <Button startIcon={<PlusIcon />} color={"gray"} variant="outline" />
        }>
        <DropdownItem
          text={"Button"}
          onClick={() => {
            editor.dispatchCommand(BUTTON_COMMAND, {
              text: "Button",
              link: "",
            })
          }}
        />
      </Dropdown>
    </ToolbarContainer>
  )
}
