import {UploadIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useDispatch} from "react-redux"
import config, {IMAGE_SERVER_BASE_URL_KEY} from "../../config"
import {FileModel} from "../../models/retreat"
import {enqueueSnackbar} from "../../notistack-lib/actions"

type AppUploadLargeImageProps = {
  handleChange: (file: FileModel) => void
  id: string
  type?: string
  accepts?: string
}

const UploadImageButton = styled(Button, {
  "&:hover": {
    pointerEvents: "auto",
  },
  "&:active": {
    pointerEvents: "none",
  },
})
const UploadImageOuterButton = styled("label", {
  width: "100%",
  height: "100%",
  aspectRatio: "16/9",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.colors.gray2,
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: theme.colors.gray6,
  borderRadius: theme.shape.borderRadius,
  cursor: "pointer",
})

export default function AppUploadImageLargeButton(
  props: AppUploadLargeImageProps
) {
  let dispatch = useDispatch()

  return (
    <UploadImageOuterButton htmlFor="file">
      <UploadImageButton text="Upload Image" startIcon={<UploadIcon />} />
      <input
        id="file"
        type="file"
        accept={props.accepts ? props.accepts : "application/pdf"}
        hidden
        onChange={(e) => {
          if (e.target && e.target.files && e.target.files[0]) {
            let data = new FormData()
            data.append("file", e.target.files[0])
            fetch(
              `${config.get(IMAGE_SERVER_BASE_URL_KEY)}/api/files${
                props.type ? "?type=" + props.type : ""
              }`,
              {
                body: data,
                method: "POST",
                mode: "cors",
              }
            )
              .then((res) => res.json())
              .then(async (resdata) => {
                await props.handleChange(resdata.file)
              })
              .catch((error) => {
                dispatch(
                  enqueueSnackbar({
                    message: "Oops, something went wrong",
                    options: {
                      variant: "error",
                    },
                  })
                )
              })
          }
        }}
      />
    </UploadImageOuterButton>
  )
}
