import {useQuery} from "@tanstack/react-query"
import {AxiosError} from "axios"
import {getUserHome} from "../api/user"
import LoadingPage from "./misc/LoadingPage"
import RedirectPage from "./misc/RedirectPage"

/**
 * Redirects to the getting-started form,
 * or your most recent active retreat if present
 */
export default function HomeRoutingPage() {
  let userQuery = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserHome(),
    retry: (failureCount, error: AxiosError) => {
      if (failureCount > 3) {
        return false
      } else if (error?.request?.status === 401) {
        return false
      }
      return true
    },
  })
  let user = userQuery.isSuccess ? userQuery.data.user : undefined
  let defaultIdx = user?.default_retreat_id
    ? user.users_retreats.findIndex(
        (ur) => ur.retreat_id === user?.default_retreat_id
      )
    : 0
  if (userQuery.error && !userQuery.isInitialLoading) {
    return (
      <RedirectPage pageName="RetreatHomePage" pathParams={{retreatIdx: "0"}} />
    )
  }
  if (!user) {
    return <LoadingPage />
  } else {
    return (
      <RedirectPage
        pageName="RetreatHomePage"
        pathParams={{retreatIdx: defaultIdx.toString()}}
      />
    )
  }
}
