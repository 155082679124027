import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {CalendarDayEventModel, ItineraryEventModel} from "../../models/retreat"
import {sortEvents} from "../pages/ItineraryBuilderPage"
import CalendarEvent from "./CalendarEvent"

const AllDayWrapper = styled("div", {
  height: "30px",
})
const DayContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  borderLeftStyle: "solid",
  borderLeftWidth: "1px",
  borderLeftColor: theme.colors.gray6,
  variants: {
    last: {
      true: {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: theme.colors.gray6,
      },
    },
  },
})

const HeaderContainer = styled("div", {
  height: "$$height",
  marginLeft: "auto",
  marginRight: "auto",
  paddingBottom: "8px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

const InnerDayContainer = styled("div", {
  display: "flex",
  width: "240px",
  gap: "0px",
})

const Relative = styled("div", {
  position: "relative",
})
const InnerBox = styled("div", {
  height: "100%",
  position: "relative",
  top: "0px",
  left: "0px",
  right: "0px",
})

const Hour = styled("div", {
  top: "$$top",
  borderBottom: "$$borderBottom",
  borderTop: "$$borderTop",

  height: "60px",
  width: "240px",
  margin: "0px",
  padding: "0px",
  border: `0.5px solid ${theme.colors.gray6}`,
})

export default function CalendarDay(props: {
  events: CalendarDayEventModel[]
  title?: string
  subtitle?: string
  width?: number
  viewer?: boolean
  startHour: number
  last?: boolean
  allDayEvents: number
}) {
  let width = 235

  function getTopMargin(time: string, beginning: number) {
    let [hour, minute] = time.split(":")
    let timeNumber = parseInt(hour) + parseInt(minute) / 60
    return `${(timeNumber - beginning) * 60}px`
  }
  function getHeight(start: string, end: string, beginning: number) {
    if (!end) {
      return "30px"
    }
    let [startHour, startMinute] = start.split(":")
    let [endHour, endMinute] = end.split(":")
    let timeNumber =
      parseInt(endHour) -
      parseInt(startHour) +
      (parseInt(endMinute) - parseInt(startMinute)) / 60
    return `${timeNumber * 60}px`
  }

  function differenceInHours(dateString1: string, dateString2: string) {
    // must pass in dates in string format
    let date1 = new Date(`December 14, 2026 ${dateString1}`)
    let date2 = new Date(`December 14, 2026 ${dateString2}`)
    let difference = date1.getTime() - date2.getTime()
    let differenceInHours = Math.abs(difference / (1000 * 3600))
    return differenceInHours
  }

  function isOverlapping(events: CalendarDayEventModel[]) {
    let eventMap: any[] = []
    events = sortEvents(events as ItineraryEventModel[]).filter(
      (event) => event.start_time
    )
    events.forEach((event, i) => {
      let previousEvent = eventMap[i - 1]
      if (previousEvent) {
        let lengthOfPreviousEvent = differenceInHours(
          previousEvent.start_time,
          previousEvent.end_time
        )
        let timeBetweenStarts = differenceInHours(
          previousEvent.start_time,
          event.start_time as string
        )
        if (lengthOfPreviousEvent > timeBetweenStarts) {
          eventMap.push({...event, overlap: previousEvent.overlap + 1})
        } else {
          eventMap.push({...event, overlap: 0})
        }
      } else {
        eventMap.push({...event, overlap: 0})
      }
    })
    return eventMap
  }

  return (
    <DayContainer last={props.last}>
      {props.title && (
        <HeaderContainer css={{$$height: `${50 + props.allDayEvents * 30}px`}}>
          <Text variant="text-sm-plus">{props.title}</Text>
          <GrayText variant="text-sm">{props.subtitle}</GrayText>
          {props.events
            .filter((e) => e.all_day)
            .map((event, i) => {
              return (
                <AllDayWrapper>
                  <CalendarEvent
                    label={event.label}
                    relative
                    key={i}
                    location={event.location}
                    onClick={event.onClick}
                    width={200}
                    marginLeft={0}
                    height={"30px"}
                    title={event.title}
                    top={"0px"}
                  />
                </AllDayWrapper>
              )
            })}
        </HeaderContainer>
      )}
      <InnerDayContainer>
        <Relative>
          <InnerBox>
            {isOverlapping(props.events)
              .filter((e) => e?.start_time && !e?.all_day)
              .map((event) => {
                return (
                  <CalendarEvent
                    label={event.label}
                    key={event.id}
                    location={event.location}
                    onClick={event.onClick}
                    width={
                      width / (event.overlap + 1) + (event.overlap ? 24 : 0)
                    }
                    marginLeft={
                      event.overlap * (width / (event.overlap + 1)) +
                      2.5 -
                      (event.overlap ? 24 : 0)
                    }
                    height={getHeight(
                      event.start_time,
                      event.end_time,
                      props.startHour
                    )}
                    title={event.title}
                    top={getTopMargin(event.start_time, props.startHour)}
                  />
                )
              })}
            {[...Array(24 - props.startHour)].map((u, i) => {
              return (
                <Hour
                  css={{
                    $$borderBottom:
                      i % 2 === 0
                        ? "0px solid black"
                        : `0.5px solid ${theme.colors.gray6}`,
                    $$borderTop:
                      i === 0
                        ? `1px solid ${theme.colors.gray6}`
                        : `0.5px solid ${theme.colors.gray6}`,
                    $$top: `${30 * i}px`,
                  }}
                  style={{
                    borderBottom:
                      i % 2 === 0
                        ? "0px solid black"
                        : `0.5px solid ${theme.colors.gray6}`,
                    borderTop:
                      i === 0
                        ? `1px solid ${theme.colors.gray6}`
                        : `0.5px solid ${theme.colors.gray6}`,
                    top: `${30 * i}px`,
                  }}
                />
              )
            })}
          </InnerBox>
        </Relative>
      </InnerDayContainer>
    </DayContainer>
  )
}
