import {PlusIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {styled} from "@summtech/flok-base/stitches.config"
import {ComponentProps, useState} from "react"
import {useSelector} from "react-redux"
import {
  RetreatTripLeg,
  RetreatTripModel,
  TripLegTypeEnum,
} from "../../models/retreat"
import {RootState} from "../../store"
import FlightDisplay from "./FlightDisplay"
import {FlightForm} from "./FlightForm"
import InlineFlightDisplay, {
  InlightFlightFormContainer,
} from "./InlineFlightDisplay"

type TripDisplayInlineProps = {
  trip: RetreatTripModel
  alt?: boolean
}

const TripDisplayInlineContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
})

const StyledButton = styled(Button, {
  justifyContent: "center",
})

export default function TripDisplayInline(props: TripDisplayInlineProps) {
  let flights = useSelector((state: RootState) => {
    let tripFlights: {[id: number]: RetreatTripLeg | undefined} = {}
    props.trip.trip_leg_ids.forEach((id) => {
      tripFlights[id] = state.retreat.flights[id]
    })
    return tripFlights
  })
  const [adding, setAdding] = useState(false)
  return (
    <TripDisplayInlineContainer>
      {props.trip &&
        props.trip.trip_leg_ids.map((leg_id) => {
          let leg =
            flights[leg_id] ??
            props.trip.trip_legs.find((leg) => leg.id === leg_id)
          if (leg) {
            let flightDisplayProps: ComponentProps<typeof FlightDisplay>

            switch (leg.trip_leg_type) {
              case TripLegTypeEnum.TRAIN:
                flightDisplayProps = {
                  departureLocation: leg.dep_station ?? "",
                  departureTime: leg.dep_datetime ?? "",
                  arrivalLocation: leg.arr_station ?? "",
                  arrivalTime: leg.dep_datetime ?? "",
                  travelCompany: leg.railway || "Unknown railway",
                  travelNumber: leg.train_num || "Unknown train #",
                }
                break
              case TripLegTypeEnum.FLIGHT:
              default:
                flightDisplayProps = {
                  travelCompany: leg.airline || "Unknown airline",
                  arrivalLocation: leg.arr_airport ?? "",
                  departureLocation: leg.dep_airport ?? "",
                  departureTime: leg.dep_datetime ?? "",
                  arrivalTime: leg.arr_datetime ?? "",
                  travelNumber: leg.flight_num || "Unknown flight #",
                }
            }
            return (
              <InlineFlightDisplay
                type={leg.trip_leg_type}
                {...flightDisplayProps}
                tripId={props.trip.id}
                flightId={leg.id}
              />
            )
          }
        })}

      {adding && (
        <InlightFlightFormContainer>
          <FlightForm
            tripId={props.trip.id}
            onCancel={() => setAdding(false)}
            onSuccess={() => setAdding(false)}
          />
        </InlightFlightFormContainer>
      )}
      {!adding && (
        <StyledButton
          onClick={() => setAdding(true)}
          text="Add Trip"
          startIcon={<PlusIcon />}
          fullWidth
          variant="outline"
          color="gray"
        />
      )}
    </TripDisplayInlineContainer>
  )
}
