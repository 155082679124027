import {createContext, PropsWithChildren, useContext, useState} from "react"

const SidebarContext = createContext<{
  sidebarOpen: boolean
  openSidebar: (() => void) | undefined
  closeSidebar: (() => void) | undefined
}>({sidebarOpen: false, openSidebar: undefined, closeSidebar: undefined})

export function useSidebar() {
  const {sidebarOpen, openSidebar, closeSidebar} = useContext(SidebarContext)
  if (openSidebar === undefined || closeSidebar === undefined) {
    throw Error("useSidebar must be used within a SidebarProvider")
  }
  return {sidebarOpen, openSidebar, closeSidebar}
}

export function SidebarProvider(props: PropsWithChildren<{}>) {
  let [sidebarOpen, setSidebarOpen] = useState(false)
  return (
    <SidebarContext.Provider
      value={{
        sidebarOpen,
        openSidebar: () => setSidebarOpen(true),
        closeSidebar: () => setSidebarOpen(false),
      }}>
      {props.children}
    </SidebarContext.Provider>
  )
}
