import {CheckIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {FormLabel} from "@summtech/flok-base/components/FormLabel"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useState} from "react"
import {AppRoutes} from "../../../Stack"
import AppModal, {AppModalProps} from "../AppModal"

const ShareBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "640px",
})

const ShareMiddle = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  marginTop: "12px",
  marginBottom: "8px",
  alignItems: "center",
})

const FakeTextField = styled("div", {
  padding: "8px 12px",
  border: `1px solid ${theme.colors.gray6}`,
  borderRadius: theme.shape.borderRadius,
  width: "500px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
})

const GrayText = styled(Text, {
  color: theme.colors.gray11,
})
export default function ShareAttendeeReportModal(
  props: {reportGuid: string} & AppModalProps
) {
  const reportLink = new URL(
    AppRoutes.getPath("ShareReportPage", {
      guid: props.reportGuid,
    }),
    window.location.origin
  ).href

  let [copied, setCopied] = useState(false)
  let [buttonTimeout, setButtonTimeout] = useState<NodeJS.Timeout | undefined>(
    undefined
  )
  return (
    <AppModal open={props.open} onClose={props.onClose}>
      <AppModal.Header>Share View</AppModal.Header>
      <AppModal.Body>
        <ShareBody>
          <FormLabel text="Direct Link" />
          <ShareMiddle>
            <FakeTextField>{reportLink}</FakeTextField>
            <Button
              startIcon={copied ? <CheckIcon /> : undefined}
              variant={"solid"}
              color={"brand"}
              text={copied ? "Copied" : "Copy Link"}
              onClick={() => {
                navigator.clipboard.writeText(reportLink)
                setCopied(true)
                if (buttonTimeout) {
                  clearTimeout(buttonTimeout)
                  setButtonTimeout(undefined)
                }
                setButtonTimeout(setTimeout(() => setCopied(false), 2000))
              }}
            />
          </ShareMiddle>
          <GrayText variant={"text-sm"}>
            Everyone with this link is able to see the data in this view. They
            are not able to edit the data.
          </GrayText>
        </ShareBody>
      </AppModal.Body>
    </AppModal>
  )
}
