import {useQueries} from "@tanstack/react-query"
import {ComponentProps} from "react"
import {getFormQuestionOption} from "../../../api/form"
import {FormQuestionModel} from "../../../models/form"
import FormQuestionBase, {QuestionCardBase} from "./FormQuestionBase"

type FormQuestionProps = {
  question: FormQuestionModel
  value?: string
  onChange?: (newVal: string) => void
  options?: string[]
  errorMessage?: string
}
export default function FormQuestionViewer(
  props: FormQuestionProps &
    Omit<ComponentProps<typeof QuestionCardBase>, keyof FormQuestionProps>
) {
  let {question, value, onChange, errorMessage, ...otherProps} = props
  let questionOptionQueries = useQueries({
    queries: props.question.select_options.map((optionId) => ({
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      queryFn: () => getFormQuestionOption(optionId),
      queryKey: ["questions-options", optionId],
    })),
  })
  let options = questionOptionQueries
    .filter((optionQuery) => optionQuery.status === "success")
    .map((questionQuery) => questionQuery.data!.select_option.option)
  return (
    <FormQuestionBase
      title={question.title}
      description={question.description}
      required={question.required}
      type={question.type}
      onChange={onChange}
      options={options}
      value={value}
      errorMessage={errorMessage}
      minDate={question.min_date}
      maxDate={question.max_date}
      {...otherProps}
    />
  )
}
