import {CheckCircledIcon, CircleIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {css, styled, theme} from "@summtech/flok-base/stitches.config"
import {ComponentProps, ReactElement} from "react"

const StyledSurveyList = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  background: "$white",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "$gray6",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
  borderRadius: "6px",
})

export function SurveysList(props: ComponentProps<typeof StyledSurveyList>) {
  return <StyledSurveyList {...props} />
}

const ItemMain = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "0px",
  gap: "8px",
  flexGrow: 1,
})
const ItemIconCss = css({
  width: "16px",
  height: "20px",
})
const ItemText = styled("div", {
  [`& > ${Text}`]: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
})
const ItemAction = styled("div")
const SurveyListItem = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "16px",
  gap: "10px",
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: "$gray6",
  width: "100%",
})
SurveysList.Item = (
  props: Omit<ComponentProps<typeof SurveyListItem>, "children"> & {
    action: ReactElement<ComponentProps<typeof Button>>
    title: string
    completed?: boolean
  }
) => {
  return (
    <SurveyListItem {...props}>
      <ItemMain>
        {props.completed ? (
          <CheckCircledIcon
            className={ItemIconCss()}
            color={theme.colors.green11.toString()}
          />
        ) : (
          <CircleIcon className={ItemIconCss()} />
        )}
        <ItemText>
          <Text variant="text-sm-plus">{props.title || "Untitled Survey"}</Text>
          <Text variant="text-sm" css={{color: "$gray11"}}>
            {props.completed ? "Completed" : "Not completed"}
          </Text>
        </ItemText>
      </ItemMain>
      <ItemAction>{props.action}</ItemAction>
    </SurveyListItem>
  )
}
