import {CaretDownIcon, MagicWandIcon, PlusIcon} from "@radix-ui/react-icons"
import {Badge} from "@summtech/flok-base/components/Badge"
import {Button} from "@summtech/flok-base/components/Button"
import {ButtonGroup} from "@summtech/flok-base/components/ButtonGroup"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import SearchBar from "@summtech/flok-base/components/SearchBar"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {Table, VisibilityState} from "@tanstack/react-table"
import {push} from "connected-react-router"
import _ from "lodash"
import {useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Route, Switch, useRouteMatch} from "react-router-dom"
import {getRetreatAttendees, patchAttendeeReport} from "../../api/attendee"
import {
  postAttendeeGroup,
  postAttendeeGroupFilter,
  postAudience,
} from "../../api/communication"
import PageBody from "../../components/page/PageBody"
import {
  AttendeeGroupModel,
  AudienceModel,
  LocalFilterModel,
} from "../../models/communication"
import {
  AttendeeReportModel,
  RetreatAttendeeModel,
  RetreatColumnModel,
} from "../../models/retreat"
import {AppRoutes} from "../../Stack"
import {RootState} from "../../store"
import {useAttendeesTable} from "../../utils/attendeeUtils"
import AppModal from "../app/AppModal"
import {AddAttendeeModalV2} from "../app/attendees/AddAttendeeModalV2"
import AttendeeColumnForm from "../app/attendees/AttendeeColumnForm"
import AttendeeReportModal from "../app/attendees/AttendeeReportModal"
import AttendeesColumnOptions from "../app/attendees/AttendeesColumnOptions"
import {BatchUploadModalV2} from "../app/attendees/BatchUploadModalV2"
import ManageAttendeesTable from "../app/attendees/ManageAttendeesTable"
import {useRetreatV2} from "../app/common/RetreatProviderV2"
import {AudienceFilterBuilder} from "../communications/AudienceModal"
import {AttendeePage} from "./AttendeePage"
let MainBody = styled("div", {
  backgroundColor: theme.colors.white,
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: "20px",
  overflow: "hidden",
})
let TitleContainer = styled("div", {
  width: "100%",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "4px",
  padding: "6px 12px",
})
export default function ManageAttendeesPage() {
  let [retreat, retreatIdx] = useRetreatV2()
  let queryClient = useQueryClient()
  let attendeesQuery = useQuery({
    enabled: retreat.id != null,
    queryFn: () => getRetreatAttendees(retreat.id),
    queryKey: ["attendees", {retreatId: retreat.id}],
    onSuccess: (data) => {
      data.attendees.forEach((attendee) => {
        queryClient.setQueryData(["attendees", attendee.id], {
          attendee: attendee,
        })
      })
    },
  })
  let router = useRouteMatch<{
    retreatIdx: string
    columnId?: string
  }>()
  let columnId = router.params.columnId

  let columns = useSelector((state: RootState) => {
    return Object.values(state.retreat.attendeeColumns).filter((column) => {
      if (column && retreat.column_ids.indexOf(column.id) !== -1) {
        return true
      } else {
        return false
      }
    }) as RetreatColumnModel[]
  })
  let startingVisibility = {
    first_name: true,
    last_name: true,
    email_address: true,
    info_status: true,
    hotel_check_in: false,
    hotel_check_out: false,

    dietary_prefs: false,
    notes: false,
    flight_status: false,
    flight_arrival_time: false,
    flight_arrival_airport: false,
    flight_arrival_number: false,
    flight_arrival_type: false,
    flight_arrival_airline: false,
    flight_departure_time: false,
    flight_departure_airport: false,
    flight_departure_number: false,
    flight_departure_type: false,
    flight_departure_airline: false,
    flight_cost: false,
    ...(columns.length > 0
      ? columns.reduce((prev, column) => {
          return {...prev, [column.id]: false}
        }, {})
      : {}),
  }
  let [tableVisibilityState, setTableVisibilityState] =
    useState<VisibilityState>(startingVisibility)

  let unsavedVisibility = !_.isEqual(startingVisibility, tableVisibilityState)
  let [localFilterState, setLocalFilterState] = useState<LocalFilterModel[][]>(
    []
  )
  let [searchString, setSearchString] = useState("")
  let table = useAttendeesTable({
    attendees: attendeesQuery.isSuccess ? attendeesQuery.data.attendees : [],
    columnFilters: localFilterState.map((groupFilterState) => {
      return groupFilterState.filter((filter) => filter.field && filter.value)
    }),
    columnVisibility: tableVisibilityState,
    globalFilter: searchString,
  })
  let [addReportOpen, setAddReportOpen] = useState(false)

  let postAudienceMutation = useMutation({
    mutationFn: (variables: {audience: Partial<AudienceModel>}) =>
      postAudience(variables.audience),
  })
  let postAttendeeGroupMutation = useMutation({
    mutationFn: (variables: {attendeeGroup: Partial<AttendeeGroupModel>}) =>
      postAttendeeGroup(variables.attendeeGroup),
  })
  let postAttendeeGroupFilterMutation = useMutation({
    mutationFn: postAttendeeGroupFilter,
  })
  let patchAttendeeReportMutation = useMutation({
    mutationFn: (variables: {
      reportId: number
      attendeeReport: Partial<AttendeeReportModel>
    }) => patchAttendeeReport(variables.reportId, variables.attendeeReport),
  })
  let dispatch = useDispatch()
  let [batchUploadOpen, setBatchUploadOpen] = useState(false)
  let [addAttendeeOpen, setAddAttendeeOpen] = useState(false)
  let searchBarRef = useRef<HTMLInputElement>(null)

  return (
    <PageBody appBar>
      <MainBody>
        <AttendeeReportModal
          open={addReportOpen}
          onClose={() => setAddReportOpen(false)}
          onPost={async (report) => {
            let audienceId: number | undefined = undefined
            if (localFilterState.length > 0) {
              let audienceResponse = await postAudienceMutation.mutateAsync({
                audience: {
                  retreat_id: retreat.id,
                  is_user_group: false,
                  selection_type: "FILTERS",
                },
              })
              if (audienceResponse.retreat_audience) {
                audienceId = audienceResponse.retreat_audience.id
                localFilterState.forEach(async (filterGroup, index) => {
                  let defaultFilters = filterGroup.filter((filter) => {
                    return isNaN(parseInt(filter.field))
                  })
                  let attendeeGroupReponse =
                    await postAttendeeGroupMutation.mutateAsync({
                      attendeeGroup: {
                        audience_id: audienceResponse.retreat_audience.id,
                        default_filters: defaultFilters,
                      },
                    })
                  if (attendeeGroupReponse.attendee_group) {
                    let filtersToCreate = localFilterState[index]
                    filtersToCreate
                      .filter((filter) => !isNaN(parseInt(filter.field)))
                      .forEach(async (filter) => {
                        await postAttendeeGroupFilterMutation.mutateAsync({
                          column_id: parseInt(filter.field),
                          value: filter.value,
                          operator_type: filter.operator_type,
                          attendee_group_id:
                            attendeeGroupReponse.attendee_group.id,
                        })
                      })
                  }
                })
              }
            }
            await patchAttendeeReportMutation.mutateAsync({
              reportId: report.id,
              attendeeReport: {
                ...(audienceId && {audience_id: audienceId}),
                column_visibility: table.getState().columnVisibility,
                column_sort: table.getState().sorting ?? null,
              },
            })
            dispatch(
              push(
                AppRoutes.getPath("ReportAttendeesPage", {
                  retreatIdx: retreatIdx.toString(),
                })
              )
            )
          }}
          retreatId={retreat.id}
        />
        <AttendeeColumnForm
          open={!!columnId}
          onClose={() => {
            dispatch(
              push(
                AppRoutes.getPath("RetreatAttendeesPage", {
                  retreatIdx: retreatIdx.toString(),
                })
              )
            )
          }}
          columnId={columnId ? parseInt(columnId) : undefined}
          onSuccess={() => {
            dispatch(
              push(
                AppRoutes.getPath("RetreatAttendeesPage", {
                  retreatIdx: retreatIdx.toString(),
                })
              )
            )
          }}
        />
        <TitleContainer>
          <Text variant="title-lg">Attendees</Text>
          <ButtonGroup>
            <Button
              variant={"solid"}
              onClick={() => {
                setAddAttendeeOpen(true)
              }}
              color="brand"
              text="Add Attendee"
              css={{borderRight: `1px solid ${theme.colors.blue11}`}}
            />
            <Dropdown
              portal
              button={
                <Button
                  variant={"solid"}
                  color="brand"
                  startIcon={<CaretDownIcon />}
                />
              }>
              <DropdownItem
                text="Upload Attendees"
                onClick={() => {
                  setBatchUploadOpen(true)
                }}
              />
            </Dropdown>
            <BatchUploadModalV2
              open={batchUploadOpen}
              onClose={() => {
                setBatchUploadOpen(false)
              }}
            />
            <AddAttendeeModalV2
              open={addAttendeeOpen}
              onClose={() => {
                setAddAttendeeOpen(false)
              }}
            />
          </ButtonGroup>
        </TitleContainer>
        <ManageAttendeesTableToolbar
          searchRef={searchBarRef}
          showCreateReport={
            retreat.attendee_table_v2 &&
            (localFilterState
              .flatMap((item) => item)
              .filter((filter) => filter.field && filter.value[0]).length > 0 ||
              unsavedVisibility ||
              table.getState().sorting.length > 0)
          }
          onClickCreateReport={() => {
            setAddReportOpen(true)
          }}
          setSearchString={setSearchString}
          filterState={localFilterState}
          setFilterState={setLocalFilterState}
          setColumnVisibility={setTableVisibilityState}
          table={table}
        />
        <ManageAttendeesTable
          onEditColumn={(colId) => {
            dispatch(
              push(
                AppRoutes.getPath("RetreatAttendeeColumnPage", {
                  retreatIdx: retreatIdx.toString(),
                  columnId: colId,
                })
              )
            )
          }}
          resetSearch={() => {
            setSearchString("")
            setLocalFilterState([])
            if (searchBarRef.current) {
              searchBarRef.current.value = ""
            }
          }}
          table={table}
          getAttendeeRedirect={(attendeeId) => {
            return AppRoutes.getPath("RetreatAttendeePage", {
              retreatIdx: retreatIdx.toString(),
              attendeeId: attendeeId.toString(),
            })
          }}
          setColumnVisibility={setTableVisibilityState}
        />
        <Switch>
          <Route
            path={[
              AppRoutes.getPath("RetreatAttendeePage"),
              AppRoutes.getPath("RetreatAttendeeFlightsPage"),
              AppRoutes.getPath("RetreatAttendeeRegResponsePage"),
            ]}
            exact>
            <AttendeePage
              pageNames={{
                flightsPage: "RetreatAttendeeFlightsPage",
                formResponsesPage: "RetreatAttendeeRegResponsePage",
                profilePage: "RetreatAttendeePage",
              }}
              onBack={() => {
                dispatch(
                  push(
                    AppRoutes.getPath("RetreatAttendeesPage", {
                      retreatIdx: retreatIdx.toString(),
                    })
                  )
                )
              }}
            />
          </Route>
        </Switch>
      </MainBody>
    </PageBody>
  )
}

const StyledToolbarContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "12px",
})
const StyledSearchBar = styled(SearchBar, {
  marginRight: "auto",
  width: "100%",
  maxWidth: 360,
})
const ActionsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
})
const FilterBody = styled("div", {
  padding: "20px",
})

const VerticalBar = styled("div", {
  width: "1px",
  height: "24px",
  background: theme.colors.gray6,
})

function ManageAttendeesTableToolbar(props: {
  setSearchString: (searchString: string) => void
  filterState: LocalFilterModel[][]
  setFilterState: (filterState: LocalFilterModel[][]) => void
  table: Table<RetreatAttendeeModel>
  setColumnVisibility: (visibility: VisibilityState) => void
  showCreateReport: boolean
  onClickCreateReport: () => void
  searchRef: React.Ref<HTMLInputElement>
}) {
  const [filtersOpen, setFiltersOpen] = useState(false)
  return (
    <StyledToolbarContainer>
      <StyledSearchBar
        inputRef={props.searchRef}
        placeholder="Search by name or email"
        onChange={_.debounce(
          async (e) => props.setSearchString(e.target.value),
          500
        )}
      />
      <ActionsContainer>
        <Button
          text={"Filter"}
          onClick={() => {
            setFiltersOpen(true)
          }}
          variant={"outline"}
          color={"gray"}
          startIcon={<MagicWandIcon />}
          endIcon={
            props.filterState.length > 0 ? (
              <Badge
                color="brand"
                label={props.filterState.length.toString()}
              />
            ) : undefined
          }
        />
        <AppModal
          fullWidth
          open={filtersOpen}
          onClose={() => {
            setFiltersOpen(false)
          }}>
          <AppModal.Header>Filters</AppModal.Header>
          <FilterBody>
            <AudienceFilterBuilder
              filterState={props.filterState}
              onChangeFilterState={props.setFilterState}
            />
          </FilterBody>
        </AppModal>

        <AttendeesColumnOptions
          values={[
            ...props.table
              .getVisibleLeafColumns()
              .filter((header) => header.getIsVisible())
              .map((header) => header.id),
            ...(props.table
              .getVisibleLeafColumns()
              .filter((col) => col.id.startsWith("flight_arrival_")).length > 0
              ? ["flight_arrival"]
              : []),
            ...(props.table
              .getVisibleLeafColumns()
              .filter((col) => col.id.startsWith("flight_departure_")).length >
            0
              ? ["flight_departure"]
              : []),
          ]}
          onChange={(colIds) => {
            props.table.setColumnVisibility((oldVisibilityState) => {
              let columnVisibility: Record<string, boolean> = props.table
                .getAllLeafColumns()
                .reduce<{
                  [key: string]: boolean
                }>((prev, col) => {
                  return {
                    ...prev,
                    [col.id]: colIds.includes(col.id) ? true : false,
                  }
                }, {})

              // Custom grouped
              if (colIds.includes("flight_arrival")) {
                columnVisibility = {
                  ...columnVisibility,
                  flight_arrival_type: true,
                  flight_arrival_time: true,
                  flight_arrival_airport: true,
                  flight_arrival_number: true,
                  flight_arrival_airline: true,
                }
              } else {
                columnVisibility = {
                  ...columnVisibility,
                  flight_arrival_type: false,
                  flight_arrival_time: false,
                  flight_arrival_airport: false,
                  flight_arrival_number: false,
                  flight_arrival_airline: false,
                }
              }
              if (colIds.includes("flight_departure")) {
                columnVisibility = {
                  ...columnVisibility,
                  flight_departure_type: true,
                  flight_departure_time: true,
                  flight_departure_airport: true,
                  flight_departure_number: true,
                  flight_departure_airline: true,
                }
              } else {
                columnVisibility = {
                  ...columnVisibility,
                  flight_departure_type: false,
                  flight_departure_time: false,
                  flight_departure_airport: false,
                  flight_departure_number: false,
                  flight_departure_airline: false,
                }
              }
              props.setColumnVisibility(columnVisibility)
              return columnVisibility
            })
          }}
          columnOptions={[
            {
              label: "Attendee Profile",
              options: [
                {label: "First Name", value: "first_name"},
                {label: "Last Name", value: "last_name"},
                {label: "Email", value: "email_address"},
                {label: "Status", value: "info_status"},
                {label: "Hotel Check In", value: "hotel_check_in"},
                {label: "Hotel Check Out", value: "hotel_check_out"},
                {label: "Dietary Preferences", value: "dietary_prefs"},
                {label: "Notes", value: "notes"},
              ],
            },
            {
              label: "Travel",
              options: [
                {label: "Travel Status", value: "flight_status"},
                {label: "Travel Cost", value: "flight_cost"},
                {
                  label: "Arrival Trip",
                  value: "flight_arrival",
                },
                {
                  label: "Departure Trip",
                  value: "flight_departure",
                },
              ],
            },
            ...(props.table
              .getAllColumns()
              .filter((col) => !isNaN(parseInt(col.id))).length
              ? [
                  {
                    label: "Form Responses",
                    options: props.table
                      .getAllColumns()
                      .filter(
                        (col) =>
                          !isNaN(parseInt(col.id)) &&
                          typeof col.columnDef.header === "string"
                      )
                      .map((col) => ({
                        label: col.columnDef.header! as string,
                        value: col.id,
                      })),
                  },
                ]
              : []),
          ]}
        />
        {props.showCreateReport && (
          <>
            <VerticalBar />
            <Button
              variant={"outline"}
              color="gray"
              startIcon={<PlusIcon />}
              text="Create Report"
              onClick={props.onClickCreateReport}
            />
          </>
        )}
      </ActionsContainer>
    </StyledToolbarContainer>
  )
}
