// THIS FILE IS DEPRECATED AND ONLY KEPT TO SUPPORT ATTENDEE TABLE V1
import {Action} from "redux"
import {QuestionApiResponse} from "../../models/api"
import {FormQuestionModel, FormResponseModel} from "../../models/form"
import {ApiAction} from "../actions/api"
import {
  GET_FORM_QUESTION_SUCCESS,
  GET_FORM_RESPONSES_SUCCESS,
} from "../actions/form"

export type FormState = {
  formQuestions: {
    [id: number]: FormQuestionModel | undefined
  }
  formResponses: {
    [id: number]: FormResponseModel | undefined
  }
}

const initialState: FormState = {
  formQuestions: {},
  formResponses: {},
}

export default function formReducer(
  state: FormState = initialState,
  action: Action
): FormState {
  switch (action.type) {
    case GET_FORM_QUESTION_SUCCESS:
      let question = ((action as ApiAction).payload as QuestionApiResponse)
        .form_question
      return {
        ...state,
        formQuestions: {...state.formQuestions, [question.id]: question},
      }
    case GET_FORM_RESPONSES_SUCCESS:
      let formResponses = (
        (action as ApiAction).payload as {form_responses: FormResponseModel[]}
      ).form_responses
      let newResponses = formResponses.reduce(
        (last: any, curr: FormResponseModel) => {
          return {...last, [curr.id]: curr}
        },
        {}
      )
      return {
        ...state,
        formResponses: {
          ...state.formResponses,
          ...newResponses,
        },
      }
    default:
      return state
  }
}
