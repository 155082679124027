import {Cross1Icon} from "@radix-ui/react-icons"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {PropsWithChildren} from "react"

let PageBackground = styled("div", {
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  right: 0,
  position: "fixed",
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.56)",
  alignItems: "right",
  justifyContent: "right",
  display: "flex",
})
let DrawerContainer = styled("div", {
  width: "95%",
  maxWidth: "600px",
  height: "100%",
  zIndex: 5,
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  borderLeft: `1px solid ${theme.colors.gray6}`,
  background: theme.colors.white,
})
const DrawerHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "12px 20px 0px",
  justifyContent: "space-between",
  alignItems: "center",
})
let DrawerFooter = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "right",
  alignSelf: "center",
  marginTop: "auto",
  gap: "8px",
  paddingBlock: "12px",
  paddingInline: "20px",
  borderTop: `1px solid ${theme.colors.gray6}`,
})
type SideDrawerProps = PropsWithChildren<{
  open: boolean
  onClose?: () => void
}>

function SideDrawer(props: SideDrawerProps) {
  let {open, onClose, children} = props
  return open ? (
    <PageBackground onClick={onClose}>
      <DrawerContainer
        onClick={(e) => {
          e.stopPropagation()
        }}>
        {children}
      </DrawerContainer>
    </PageBackground>
  ) : (
    <></>
  )
}

type SideDrawerHeaderProps = {
  text: string
  onClose?: () => void
}
export function SideDrawerHeader(props: SideDrawerHeaderProps) {
  return (
    <DrawerHeader>
      <Text variant={"heading-sm"}>{props.text} </Text>
      {props.onClose && (
        <IconButton
          variant="ghost"
          onClick={() => {
            props.onClose && props.onClose()
          }}>
          <Cross1Icon />
        </IconButton>
      )}
    </DrawerHeader>
  )
}

export function SideDrawerFooter(props: PropsWithChildren<{}>) {
  return <DrawerFooter>{props.children}</DrawerFooter>
}
export default SideDrawer
