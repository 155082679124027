import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import React, {ComponentProps} from "react"

type FormHeaderProps = {title: string; description?: string} & ComponentProps<
  typeof FormHeaderContainer
>
export default function FormHeader(props: FormHeaderProps) {
  let {title, description, ...otherProps} = props
  return (
    <FormHeaderContainer {...otherProps}>
      <Text variant="title-3xl">{title}</Text>
      {description && (
        <Text variant="text-base" css={{color: "$gray11"}}>
          {description}
        </Text>
      )}
    </FormHeaderContainer>
  )
}

const FormHeaderContainer = styled("div", {
  textAlign: "center",
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
})
