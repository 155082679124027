import {FormField} from "@summtech/flok-base/components/FormField"
import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {useMutation, useQueryClient} from "@tanstack/react-query"
import {useState} from "react"
import {postRegistrationLive} from "../../api/retreat"
import {AppRoutes} from "../../Stack"
import AppLinkButton from "../app/AppLinkButton"
import {useRetreatV2} from "../app/common/RetreatProviderV2"
import ConfirmationModal from "../app/ConfirmationModal"

export default function RegistrationFormSettings(props: {retreatId: number}) {
  let [retreat, retreatIdx] = useRetreatV2()
  let queryClient = useQueryClient()
  let [goLiveModal, setGoLiveModal] = useState(false) // is open
  let goLiveMutation = useMutation({
    mutationFn: () => postRegistrationLive(props.retreatId),
    onSuccess: (data) => {
      queryClient.setQueryData(["retreats", data.retreat.id], data)
      setGoLiveModal(false)
    },
  })
  return (
    <>
      <Container>
        <Section>
          <FormField
            type="switch"
            inline
            checked={!!retreat.registration_live}
            onChange={() => {
              setGoLiveModal(true)
            }}
            disabled={!!retreat.registration_live}
            label="Registration active"
            hint="Attendees receive a sign up email when registration is activated. This action can not be undone."
          />
        </Section>
        <Section>
          <Text variant="heading-sm">Email Template</Text>
          <Text variant="text-sm" css={{color: "$gray11"}}>
            This email is sent to attendees when you activate registration.
          </Text>
          <AppLinkButton
            to={AppRoutes.getPath("EmailTemplatePage", {
              retreatIdx: retreatIdx.toString(),
              templateName: "registration",
            })}
            variant="outline"
            color="gray"
            text="Edit Email Template"
          />
        </Section>
      </Container>
      {/* Confirmation Modals */}
      <ConfirmationModal
        title="Are you sure you want to activate your registration page?"
        subtitle="This action cannot be undone."
        open={goLiveModal}
        onConfirm={() => {
          goLiveMutation.mutate()
        }}
        onClose={() => setGoLiveModal(false)}
      />
    </>
  )
}
const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
})
const Section = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "16px 20px",
  gap: "8px",
  "&:not(:last-child)": {
    borderBottom: "1px solid $gray6",
  },
})
