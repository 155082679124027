/**
 * Honestly not sure if this is the best component.
 */
import {PropsWithChildren, ReactElement} from "react"

type SwitchProps = {
  value: any // using any because no way to enforce children type with TS: https://stackoverflow.com/a/52732266
  children: ReactElement<CaseProps>[] | ReactElement<CaseProps> | undefined
}

/**
 * This component will render children with the props.value that matches the value in the SwitchRenderer props
 */
export function SwitchCases(props: SwitchProps) {
  let children: ReactElement<CaseProps>[] = []
  if (props.children) {
    if (!Array.isArray(props.children)) children = [props.children]
    else children = props.children
  }
  let activeCases = children.filter((child) => {
    let childValue = child?.props?.value
    if (childValue) {
      if (Array.isArray(childValue)) {
        return childValue.includes(props.value)
      } else {
        return childValue === props.value
      }
    }
    return false
  })
  return <>{activeCases}</>
}

type CaseProps = PropsWithChildren<{
  value: any | any[] // using any because no way to enforce children type with TS: https://stackoverflow.com/a/52732266
}>

const Case: React.FC<CaseProps> = (props: CaseProps) => {
  return <>{props.children}</>
}

SwitchCases.Case = Case
