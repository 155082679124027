import {RetreatModel} from "../models/retreat"
import {flokAxios} from "./index"

// RETREATS
export function getRetreat(retreatId: number) {
  return flokAxios<{retreat: RetreatModel}>({
    url: `/v1.0/retreats/${retreatId}`,
    method: "GET",
  })
}
export function patchRetreat(
  retreatId: number,
  updatedValues: Partial<
    Pick<
      RetreatModel,
      | "budget_link"
      | "itinerary_final_draft_link"
      | "lodging_final_end_date"
      | "lodging_final_start_date"
      | "lodging_final_hotel_id"
      | "lodging_final_destination"
      | "lodging_final_contract_url"
      | "retreat_name"
      | "request_for_proposal_id"
      | "attendees_registration_form_id"
      | "hide_group_flights"
      | "require_flight_receipts"
      | "registration_email_template_id"
      | "flights_email_template_id"
      | "lodging_has_submitted_hotels"
    >
  >
) {
  return flokAxios<{retreat: RetreatModel}>({
    url: `/v1.0/retreats/${retreatId}`,
    data: JSON.stringify(updatedValues),
    method: "PATCH",
  })
}

export function postRegistrationLive(retreatId: number) {
  return flokAxios<{retreat: RetreatModel}>({
    url: `/v1.0/retreats/${retreatId}/go-live`,
    method: "POST",
  })
}

export function postRetreatSurvey(retreatId: number, formId: number) {
  return flokAxios<string>({
    url: `/v1.0/retreats/${retreatId}/surveys`,
    data: JSON.stringify({form_id: formId}),
    method: "POST",
  })
}

export function postRetreat(retreatName: string) {
  return flokAxios<{retreat: RetreatModel}>({
    url: `/v1.0/retreats`,
    data: JSON.stringify({retreat_name: retreatName}),
    method: "POST",
  })
}

// END RETREATS
