import {useMediaQuery} from "@material-ui/core"
import {
  ArrowLeftIcon,
  CaretDownIcon,
  CheckIcon,
  PlusIcon,
  TrashIcon,
} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {FormField} from "@summtech/flok-base/components/FormField"
import {FormLabel} from "@summtech/flok-base/components/FormLabel"
import {Select} from "@summtech/flok-base/components/Select"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Tab} from "@summtech/flok-base/components/Tab"
import {TabContent} from "@summtech/flok-base/components/TabContent"
import {TabsList} from "@summtech/flok-base/components/TabsList"
import {TabsWrapper} from "@summtech/flok-base/components/TabsWrapper"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {push} from "connected-react-router"
import {useFormik} from "formik"
import {useEffect, useState} from "react"
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd"
import {useDispatch} from "react-redux"
import * as yup from "yup"
import ColorPicker from "../../components/attendee-site/ColorPicker"
import {ImageModel} from "../../models"
import {FormBlockTypeName, FormBlockTypeValues} from "../../models/retreat"
import {enqueueSnackbar} from "../../notistack-lib/actions"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../Stack"
import {ApiAction} from "../../store/actions/api"
import {
  deletePage,
  patchPage,
  patchWebsite,
  postBlock,
  postPage,
  postReorderBlock,
} from "../../store/actions/retreat"
import {FlokTheme} from "../../theme"
import {
  splitFileName,
  useAttendeeLandingPage,
  useAttendeeLandingPages,
  useAttendeeLandingWebsite,
} from "../../utils/retreatUtils"
import AppImageFilePicker from "../app/AppImageFilePicker"
import ConfirmationModal from "../app/ConfirmationModal"
import {useSidebar} from "../nav/SidebarProvider"
import PageBuilderSideNavBlock from "./PageBuilderSideNavBlock"
import UploadImageWithTemplate from "./UploadImageWithTemplate"

const SiteSideNavWrapper = styled("div", {
  background: theme.colors.white,
  width: "288px",
  display: "flex",
  flexDirection: "column",
  padding: "16px 0px",
  borderRightStyle: "solid",
  borderRightColor: theme.colors.gray6,
  borderRightWidth: "1px",
  height: "100%",
  overflow: "auto",
})

const SideNavTop = styled("div", {
  display: "flex",
  padding: "16px 12px 0px 12px",
})

const SideNavSection = styled("div", {
  display: "flex",

  flexDirection: "column",
  borderBottomStyle: "solid",
  borderBottomColor: theme.colors.gray6,
  borderBottomWidth: "1px",
})

const TabsSection = styled(SideNavSection, {
  padding: "0px 24px 0px 20px",
})

const SideNavTab = styled("div", {
  display: "flex",
  flexDirection: "column",
})

const AddSection = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "6px 8px",
  gap: "8px",
  borderRadius: theme.shape.borderRadius,
  flex: 1,
  marginRight: "12px",
  cursor: "pointer",
  "&:hover": {
    background: theme.colors.gray4,
  },
  marginLeft: "23px",
})

type SiteSideNavTabType = "pages" | "design" | "settings"

export default function SiteSideNav(props: {
  tabs: SiteSideNavTabType[]
  pageId: number
  defaultTab: SiteSideNavTabType
}) {
  let {openSidebar, closeSidebar, sidebarOpen} = useSidebar()
  const isSmallScreen = useMediaQuery((theme: FlokTheme) =>
    theme.breakpoints.down("sm")
  )
  return (
    <SiteSideNavWrapper>
      {!isSmallScreen && (
        <SideNavTop>
          <Button
            variant="ghost"
            text="Back"
            startIcon={<ArrowLeftIcon />}
            onClick={() => {
              if (sidebarOpen) {
                closeSidebar()
              } else {
                openSidebar()
              }
            }}
          />
        </SideNavTop>
      )}
      <TabsWrapper onValueChange={() => {}} defaultValue={props.defaultTab}>
        <TabsSection>
          <TabsList>
            {props.tabs.map((tab) => {
              return (
                <Tab
                  value={tab}
                  text={tab.charAt(0).toUpperCase() + tab.slice(1)}
                />
              )
            })}
          </TabsList>
        </TabsSection>
        {props.tabs.map((tab) => {
          switch (tab) {
            case "pages":
              return <PagesSideNavTab pageId={props.pageId} />
            case "design":
              return <DesignSideNavTab />
            case "settings":
              return <SettingsSideNavTab />
            default:
              return <></>
          }
        })}
      </TabsWrapper>
    </SiteSideNavWrapper>
  )
}

const PageSection = styled(SideNavSection, {
  padding: "20px 24px",
  gap: "24px",
})

const DropdownButton = styled(Button, {
  justifyContent: "space-between",
  height: "36px",
})

const SiteBlocksSection = styled(SideNavSection, {
  gap: "12px",
})
const SiteBlocksSectionHeader = styled("div", {
  padding: "20px 24px 0px 24px",
})
const SiteBlocksContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  marginBottom: "20px",
})
const DeleteContainer = styled("div", {
  padding: "16px 12px",
})

function PagesSideNavTab(props: {pageId: number}) {
  let [retreat, retreatIdx] = useRetreat()
  let [website] = useAttendeeLandingWebsite(retreat.attendees_website_id ?? -1)
  let [page, loadingPage] = useAttendeeLandingPage(props.pageId)
  let [allPages] = useAttendeeLandingPages(website?.page_ids ?? [])
  let dispatch = useDispatch()
  let pageNameFormik = useFormik({
    initialValues: {
      title: page?.title ?? "",
    },
    onSubmit: (values) => {
      page && dispatch(patchPage(page.id, values))
    },
    validationSchema: yup.object({
      title: yup
        .string()
        .required()
        .matches(
          /^[aA-zZ\s0-9]+$/,
          "Can only contain letters, numbers, or spaces"
        ),
    }),
    enableReinitialize: true,
  })
  let publishedFormik = useFormik({
    initialValues: {
      published: page?.published ?? false,
    },
    onSubmit: (values) => {
      page && dispatch(patchPage(page.id, values))
    },
    enableReinitialize: true,
  })
  const deletablePage = page?.title.toLowerCase() !== "home"

  const editablePageName = page?.title.toLowerCase() !== "home"

  let [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false)
  return (
    <TabContent value={"pages"}>
      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        onClose={() => setDeleteConfirmationModalOpen(false)}
        title="Are you sure you wish to delete this page?"
        subtitle="This action cannot be undone."
        confirmText="Yes, delete"
        onConfirm={async () => {
          let deleteResult = (await dispatch(
            deletePage(page?.id ?? -1)
          )) as unknown as ApiAction
          if (!deleteResult.error) {
            setDeleteConfirmationModalOpen(false)
            dispatch(
              push(
                AppRoutes.getPath("LandingPageGeneratorPage", {
                  retreatIdx: retreatIdx.toString(),
                  currentPageId: website?.page_ids[0].toString() ?? "0",
                })
              )
            )
          }
        }}
      />
      <SideNavTab>
        <PageSection>
          <Dropdown
            portal
            button={
              <DropdownButton
                variant="outline"
                endIcon={<CaretDownIcon />}
                fullWidth
                text={page?.title}
              />
            }>
            {allPages.map((websitePage) => {
              return (
                <DropdownItem
                  startIcon={
                    websitePage.title === page?.title ? (
                      <CheckIcon />
                    ) : (
                      <div style={{height: "15px", width: "15px"}} />
                    )
                  }
                  text={websitePage.title}
                  onClick={() => {
                    dispatch(
                      push(
                        AppRoutes.getPath("LandingPageGeneratorPage", {
                          retreatIdx: retreatIdx.toString(),
                          currentPageId: websitePage.id.toString(),
                        })
                      )
                    )
                  }}
                />
              )
            })}
            <DropdownItem
              text={"Add Page"}
              onClick={async () => {
                let result = (await dispatch(
                  postPage({
                    title: `Page ${website!.page_ids.length + 1}`,
                    website_id: website!.id,
                    published: false,
                  })
                )) as unknown as ApiAction
                if (!result.error) {
                  dispatch(
                    push(
                      AppRoutes.getPath("LandingPageGeneratorPage", {
                        retreatIdx: retreatIdx.toString(),
                        currentPageId: result.payload.page.id.toString(),
                      })
                    )
                  )
                }
              }}
              startIcon={<PlusIcon />}
            />
          </Dropdown>
          <FormField
            type="textfield"
            value={pageNameFormik.values.title}
            id={`title`}
            onChange={pageNameFormik.handleChange}
            label="Page Name"
            fullWidth
            disabled={!editablePageName}
            onBlur={() => {
              pageNameFormik.submitForm()
            }}
          />
          <FormField
            type="switch"
            inline
            checked={publishedFormik.values.published}
            disabled={!deletablePage}
            onChange={(checked) => {
              publishedFormik.setFieldValue("published", checked)
              page && dispatch(patchPage(page.id, {published: checked}))
            }}
            label="Published"
            tooltip="When active, everyone with a link can view this page."
          />
        </PageSection>
        <SiteBlocksSection>
          <SiteBlocksSectionHeader>
            <Text variant="heading-sm">Sections</Text>
          </SiteBlocksSectionHeader>

          <SiteBlocksContainer>
            {page && (
              <DragDropContext
                onDragEnd={async (result: any) => {
                  if (result && result.destination && page) {
                    const items = page.block_ids
                    const [reorderedItem] = items.splice(result.source.index, 1)
                    items.splice(result.destination.index, 0, reorderedItem)

                    let prevQuestionId: number | undefined =
                      items[result.destination.index - 1]
                    let response = (await dispatch(
                      postReorderBlock(
                        parseInt(result.draggableId),
                        page.id,
                        prevQuestionId
                      )
                    )) as unknown as ApiAction
                    if (response.error) {
                      dispatch(
                        enqueueSnackbar({
                          message: "Something went wrong.",
                          options: {variant: "error"},
                        })
                      )
                    }
                  }
                }}>
                <Droppable droppableId="questions">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}>
                      {page!.block_ids.map((blockId, index) => {
                        return (
                          <Draggable
                            key={blockId}
                            draggableId={blockId.toString()}
                            index={index}>
                            {(provided, snapshot) => (
                              <div
                                {...provided.draggableProps}
                                ref={provided.innerRef}>
                                <div>
                                  <PageBuilderSideNavBlock
                                    key={blockId}
                                    blockId={blockId}
                                    dragHandleProps={provided.dragHandleProps}
                                    isDragging={snapshot.isDragging}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            <Dropdown
              button={
                <AddSection>
                  <div style={{height: "15px", width: "15px"}}>
                    <PlusIcon />
                  </div>
                  <Text variant="text-sm-plus">Add Section</Text>
                </AddSection>
              }>
              {FormBlockTypeValues.map((type) => (
                <DropdownItem
                  onClick={() =>
                    dispatch(postBlock({page_id: page!.id, type: type}))
                  }
                  text={FormBlockTypeName[type] ?? type}></DropdownItem>
              ))}
            </Dropdown>
          </SiteBlocksContainer>
        </SiteBlocksSection>
        {deletablePage && (
          <DeleteContainer>
            <Button
              onClick={() => {
                setDeleteConfirmationModalOpen(true)
              }}
              startIcon={<TrashIcon />}
              text="Delete Page"
              variant="ghost"
              color="critical"></Button>
          </DeleteContainer>
        )}
      </SideNavTab>
    </TabContent>
  )
}

const StyleImageSection = styled(SideNavSection, {
  padding: "20px 24px",
  gap: "20px",
})
const TemplatedImageUpload = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
})

const ColorPickerSection = styled(SideNavSection, {
  padding: "20px 24px",
  gap: "20px",
})
const ColorPickerWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
})

function DesignSideNavTab() {
  let dispatch = useDispatch()
  let [retreat, retreatIdx] = useRetreat()
  let [website] = useAttendeeLandingWebsite(retreat.attendees_website_id ?? -1)
  let logoFormik = useFormik({
    initialValues: {
      logo_image_id: website?.logo_image?.id ?? -1,
    },
    onSubmit: (values) => {
      if (values.logo_image_id !== -1 && website) {
        dispatch(patchWebsite(website.id, values))
      }
    },
    enableReinitialize: true,
  })
  let bannerFormik = useFormik({
    initialValues: {
      banner_image_id: website?.banner_image?.id ?? -1,
    },
    onSubmit: (values) => {
      if (values.banner_image_id !== -1 && website) {
        dispatch(patchWebsite(website.id, values))
      }
    },
    enableReinitialize: true,
  })
  let primaryColorFormik = useFormik({
    initialValues: {
      primary_color: website?.primary_color ?? "#4C4FF5",
    },
    onSubmit: (values) => {
      if (website) {
        dispatch(patchWebsite(website.id, values))
      }
    },
    enableReinitialize: true,
  })
  let backgroundColorFormik = useFormik({
    initialValues: {
      background_color: website?.background_color ?? "#FFFFFF",
    },
    onSubmit: (values) => {
      if (website) {
        dispatch(patchWebsite(website.id, values))
      }
    },
    enableReinitialize: true,
  })
  let textColorFormik = useFormik({
    initialValues: {
      text_color: website?.text_color ?? "#000000",
    },
    onSubmit: (values) => {
      if (website) {
        dispatch(patchWebsite(website.id, values))
      }
    },
    enableReinitialize: true,
  })
  let imageHolder: {[key: number]: ImageModel} = {}
  if (website?.banner_image) {
    imageHolder[website?.banner_image.id] = website?.banner_image
  }
  if (website?.logo_image) {
    imageHolder[website?.logo_image.id] = website?.logo_image
  }
  let [images, setImages] = useState<{[key: number]: ImageModel}>(imageHolder)
  let [bannerImageType, setBannerImageType] = useState<"template" | "custom">(
    "template"
  )

  useEffect(() => {
    if (website?.logo_image) {
      setImages((images) => {
        return {
          ...images,
          [website!.logo_image!.id]: website!.logo_image as ImageModel,
        }
      })
    }
    if (website?.banner_image) {
      setImages((images) => {
        return {
          ...images,
          [website!.banner_image!.id]: website!.banner_image as ImageModel,
        }
      })
    }
  }, [website])

  return (
    <TabContent value={"design"}>
      <SideNavTab>
        <StyleImageSection>
          <AppImageFilePicker
            label={"Logo"}
            fullWidth
            handleClear={() => {
              logoFormik.setFieldValue("logo_image_id", -1)
              if (website) {
                dispatch(patchWebsite(website.id, {logo_image_id: null}))
              }
            }}
            handleChange={(image) => {
              logoFormik.setFieldValue("logo_image_id", image.id)
              setImages({...images, [image.id]: image})
              if (website) {
                dispatch(patchWebsite(website.id, {logo_image_id: image.id}))
              }
            }}
            id={"logo-image"}
            file={
              images[logoFormik.values.logo_image_id]
                ? splitFileName(
                    images[logoFormik.values.logo_image_id].image_url
                  )
                : undefined
            }
          />
          <TemplatedImageUpload>
            <FormLabel text="Banner Image" />
            <Select
              value={bannerImageType}
              onChange={(value) =>
                setBannerImageType(value as "template" | "custom")
              }
              fullWidth>
              <SelectItem label="From template" value="template" />
              <SelectItem label="Upload custom file" value="custom" />
            </Select>
            {bannerImageType === "template" ? (
              <UploadImageWithTemplate
                type="BANNER"
                handleChange={(file) => {
                  bannerFormik.setFieldValue("banner_image_id", file.id)
                  if (website) {
                    dispatch(
                      patchWebsite(website.id, {
                        banner_image_id: file.id,
                      })
                    )
                  }
                }}
                id={"banner-image"}
                value={images[bannerFormik.values.banner_image_id] ?? undefined}
              />
            ) : (
              <AppImageFilePicker
                handleClear={() => {
                  bannerFormik.setFieldValue("banner_image_id", -1)
                  if (website) {
                    dispatch(patchWebsite(website.id, {banner_image_id: null}))
                  }
                }}
                fullWidth
                handleChange={(image) => {
                  bannerFormik.setFieldValue("banner_image_id", image.id)
                  setImages({...images, [image.id]: image})
                  if (website) {
                    dispatch(
                      patchWebsite(website.id, {
                        banner_image_id: image.id,
                      })
                    )
                  }
                }}
                id={"banner-image"}
                file={
                  images[bannerFormik.values.banner_image_id]
                    ? splitFileName(
                        images[bannerFormik.values.banner_image_id].image_url
                      )
                    : undefined
                }
              />
            )}
          </TemplatedImageUpload>
        </StyleImageSection>
        <ColorPickerSection>
          <ColorPickerWrapper>
            <FormLabel text="Text Color"></FormLabel>
            <ColorPicker
              selectedColor={textColorFormik.values.text_color as string}
              setSelectedColor={(newColor) => {
                textColorFormik.setFieldValue("text_color", newColor)
              }}
              onCompleteChange={(newColor) => {
                dispatch(patchWebsite(website!.id, {text_color: newColor}))
              }}
              colors={["#000000", "#00007B"]}
            />
          </ColorPickerWrapper>
          <ColorPickerWrapper>
            <FormLabel text="Background Color"></FormLabel>
            <ColorPicker
              noPicker
              selectedColor={
                backgroundColorFormik.values.background_color as string
              }
              setSelectedColor={(newColor) => {
                backgroundColorFormik.setFieldValue(
                  "background_color",
                  newColor
                )
              }}
              onCompleteChange={(newColor) => {
                dispatch(
                  patchWebsite(website!.id, {
                    background_color: newColor,
                  })
                )
              }}
              colors={["#FFFFFF", "#F8F8F8", "#B6D7E4", "#F5F5DE"]}
            />
          </ColorPickerWrapper>
          <ColorPickerWrapper>
            <FormLabel text="Primary Color"></FormLabel>
            <ColorPicker
              selectedColor={primaryColorFormik.values.primary_color as string}
              setSelectedColor={(newColor) => {
                primaryColorFormik.setFieldValue("primary_color", newColor)
              }}
              onCompleteChange={(newColor) => {
                dispatch(
                  patchWebsite(website!.id, {
                    primary_color: newColor,
                  })
                )
              }}
              colors={["#4C4FF5", "#CD2B31", "#20026E", "#18794E"]}
            />
          </ColorPickerWrapper>
        </ColorPickerSection>
      </SideNavTab>
    </TabContent>
  )
}

const SettingsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  padding: "20px 24px",
})

const SettingsText = styled(Text, {
  color: theme.colors.gray11,
})

function SettingsSideNavTab() {
  let dispatch = useDispatch()
  let [retreat, retreatIdx] = useRetreat()
  let [website] = useAttendeeLandingWebsite(retreat.attendees_website_id ?? -1)

  let siteNameFormik = useFormik({
    initialValues: {
      name: website?.name ?? "",
    },
    onSubmit: (values) => {
      if (website) {
        dispatch(patchWebsite(website.id, values))
      }
    },
    enableReinitialize: true,
  })
  return (
    <TabContent value={"settings"}>
      <SettingsContainer>
        <FormField
          value={siteNameFormik.values.name}
          id="name"
          onChange={siteNameFormik.handleChange}
          onBlur={() => {
            siteNameFormik.handleSubmit()
          }}
          disabled={retreat.registration_live}
          type="textfield"
          label="Website Name"
          fullWidth></FormField>
        <SettingsText variant="text-sm">
          Website Name cannot be changed once registration is live.
        </SettingsText>
      </SettingsContainer>
    </TabContent>
  )
}
