import {
  Button,
  makeStyles,
  MobileStepper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons"
import {PropsWithChildren, ReactChild} from "react"
import {FlokTheme} from "../../theme"
import AppStepFormStep from "./AppStepFormStep"

let useStyles = makeStyles((theme) => ({
  outerForm: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: (props: {imgRight?: boolean}) =>
      props.imgRight ? "row-reverse" : "row",
  },
  img: {
    height: "100%",
    width: "40%",
    objectFit: "cover",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  stepBody: {
    flex: 1,
    overflow: "auto",
  },
  overallForm: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  stepper: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 16,
    paddingTop: 16,
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  offsetButton: {
    width: 71.9835 /*To offset button*/,
  },
}))
export default function AppStepForm(
  props: PropsWithChildren<{
    activeStep: number
    handleNext: () => void
    handleBack: () => void
    onSubmit: () => void
  }>
) {
  let theme = useTheme()

  let children = props.children as unknown as ReactChild[]
  let steps = children.filter((child) => {
    return isStep(child)
  })
  let nonSteps = children.filter((child) => {
    return !isStep(child)
  })
  let activeStepComponent = steps[props.activeStep]

  let classes = useStyles({
    ...props,
    ...(typeof activeStepComponent === "object" && activeStepComponent.props),
  })

  function isStep(child: ReactChild) {
    return (
      typeof child === "object" &&
      child.props &&
      child.props._TYPE === AppStepFormStep.defaultProps._TYPE
    )
  }

  const isSmallScreen = useMediaQuery((theme: FlokTheme) =>
    theme.breakpoints.down("sm")
  )

  return (
    <div className={classes.overallForm}>
      <div className={classes.outerForm}>
        {!isSmallScreen &&
          typeof activeStepComponent === "object" &&
          activeStepComponent.props.img && (
            <img
              src={activeStepComponent.props.img}
              className={classes.img}
              alt="hotel"
            />
          )}
        <div className={classes.body}>
          {nonSteps}
          <div className={classes.stepBody}>
            {steps && steps[props.activeStep]}
          </div>

          <MobileStepper
            elevation={5}
            className={classes.stepper}
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={props.activeStep}
            nextButton={
              props.activeStep === steps.length - 1 ? (
                <Button
                  onClick={() => props.onSubmit()}
                  variant="contained"
                  color="primary">
                  Submit
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    if (typeof activeStepComponent === "object") {
                      activeStepComponent.props.handleNext()
                    }
                  }}
                  color="primary">
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft htmlColor={theme.palette.primary.main} />
                  ) : (
                    <KeyboardArrowRight
                      htmlColor={theme.palette.primary.main}
                    />
                  )}
                </Button>
              )
            }
            backButton={
              props.activeStep !== 0 ? (
                <Button
                  onClick={props.handleBack}
                  disabled={props.activeStep === 0}
                  color="primary">
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight
                      htmlColor={theme.palette.primary.main}
                    />
                  ) : (
                    <KeyboardArrowLeft htmlColor={theme.palette.primary.main} />
                  )}
                  Back
                </Button>
              ) : (
                <div className={classes.offsetButton} />
              )
            }
          />
        </div>
      </div>
    </div>
  )
}
