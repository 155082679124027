import {Avatar} from "@summtech/flok-base/components/Avatar"
import {Badge} from "@summtech/flok-base/components/Badge"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useSelector} from "react-redux"
import {
  TaskModel,
  TaskPriority,
  TaskPriorityBadge,
  TaskPriorityName,
  TaskStatus,
  TaskStatusIcon,
  TaskStatusName,
} from "../../../models/task"
import {RootState} from "../../../store"

let StyledTitleContainer = styled("div", {
  minWidth: "300px",
  height: "32px",
  paddingBlock: "25px",
  paddingLeft: "9px",
  display: "inline-flex",
  alignItems: "center",
})
let StyledStatusContainer = styled("div", {
  width: "90px",
  height: "32px",
  paddingBlock: "25px",
  display: "inline-flex",
  alignItems: "center",
  gap: "6px",
})

let StyledAssigneesContainer = styled("div", {
  width: "200px",
  height: "32px",
  paddingBlock: "25px",
  display: "inline-flex",
  alignItems: "center",
  gap: "6px",
})
let StyledDueDateContainer = styled("div", {
  width: "110px",
  height: "32px",
  paddingBlock: "25px",
  display: "inline-flex",
  alignItems: "center",
})
let StyledPriorityContainer = styled("div", {
  width: "70px",
  height: "32px",
  paddingBlock: "25px",
  display: "inline-flex",
  alignItems: "center",
})
let StyledLabelsContainer = styled("div", {
  width: "120px",
  height: "32px",
  paddingBlock: "25px",
  display: "inline-flex",
  alignItems: "center",
  gap: "4px",
})
let StyledGroupHeaderRow = styled("td", {
  paddingBlock: "16px",
  backgroundColor: theme.colors.gray2,
  borderBottom: `1px solid ${theme.colors.gray6}`,
  alignItems: "center",
  paddingLeft: "9px",
  gap: "4px",
})
export function getGroupHeaderRow(
  key: keyof TaskModel,
  header: string | number,
  colSpan: number
) {
  if (key === ("status" as keyof TaskModel)) {
    return (
      <tr>
        <StyledGroupHeaderRow colSpan={colSpan}>
          <Text variant="text-base">
            {!!TaskStatusName[header as keyof typeof TaskStatusName]
              ? TaskStatusName[header as keyof typeof TaskStatusName]
              : "Not Defined"}
          </Text>
        </StyledGroupHeaderRow>
      </tr>
    )
  }
  if (key === ("priority" as keyof TaskModel)) {
    return (
      <tr>
        <StyledGroupHeaderRow colSpan={colSpan}>
          <Text variant="text-base">
            {!!TaskPriorityName[header as keyof typeof TaskPriorityName]
              ? TaskPriorityName[header as keyof typeof TaskPriorityName]
              : "No Priority"}
          </Text>
        </StyledGroupHeaderRow>
      </tr>
    )
  } else {
    return <></>
  }
}
export function TitleLabel(props: {title: string}) {
  return (
    <StyledTitleContainer>
      <Text variant="text-base">{props.title}</Text>
    </StyledTitleContainer>
  )
}

export function StatusLabel(props: {status: TaskStatus}) {
  return (
    <StyledStatusContainer>
      {TaskStatusIcon[props.status as keyof typeof TaskStatusIcon]}
      <Text variant="text-sm-plus">
        {TaskStatusName[props.status as keyof typeof TaskStatusName]}
      </Text>
    </StyledStatusContainer>
  )
}
export function AssigneesLabel(props: {assigneeIds: number[]}) {
  let assigneeOptions = useSelector((state: RootState) => {
    return state.retreat.users
  })
  let assignee = assigneeOptions[props.assigneeIds[0]]

  return props.assigneeIds.length && assignee ? (
    <StyledAssigneesContainer>
      <Avatar
        color="blue"
        round={true}
        label={
          props.assigneeIds.length > 1
            ? props.assigneeIds.length.toString()
            : (assignee.first_name?.[0] as string)
        }
      />
      <Text variant="text-sm-plus">{`${assignee.first_name} ${assignee.last_name}`}</Text>
      {props.assigneeIds.length > 1 ? (
        <Text variant="text-xs">{` +${props.assigneeIds.length - 1}`}</Text>
      ) : (
        <></>
      )}
    </StyledAssigneesContainer>
  ) : (
    <StyledAssigneesContainer />
  )
}
export function DueDateLabel(props: {dueDate: Date}) {
  const dateFormatShort = (date: Date) =>
    new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      timeZone: "UTC",
    })
  return (
    <StyledDueDateContainer>
      {props.dueDate ? (
        <Text variant="text-base">{dateFormatShort(props.dueDate)}</Text>
      ) : (
        <></>
      )}
    </StyledDueDateContainer>
  )
}

export function PriorityLabel(props: {priority: TaskPriority}) {
  return (
    <StyledPriorityContainer>
      {props.priority !== undefined ? (
        TaskPriorityBadge[props.priority as keyof typeof TaskPriorityBadge]
      ) : (
        <></>
      )}
    </StyledPriorityContainer>
  )
}
export function LabelsLabel(props: {labelIds: number[]}) {
  let labelOptions = useSelector((state: RootState) => {
    return state.retreat.labels
  })
  let label = labelOptions[props.labelIds[0]]?.text

  return (
    <StyledLabelsContainer>
      {props.labelIds.length && label ? (
        <>
          <Badge label={`${label}`} textVariant="text-sm-plus" color="gray" />
          <Text variant="text-xs">
            {props.labelIds.length > 1 ? `+${props.labelIds.length - 1}` : ""}
          </Text>
        </>
      ) : (
        <></>
      )}
    </StyledLabelsContainer>
  )
}
