import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {useQueries, useQuery, useQueryClient} from "@tanstack/react-query"
import {push} from "connected-react-router"
import {useEffect} from "react"
import {useDispatch} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import {AppRoutes} from "../../../Stack"
import {getForm, getFormResponses} from "../../../api/form"
import PageBody from "../../../components/page/PageBody"
import PageContainer from "../../../components/page/PageContainer"
import {FormResponseModel, FormStatusEnum} from "../../../models/form"
import LoadingPage from "../../../pages/misc/LoadingPage"
import NotFound404Page from "../../../pages/misc/NotFound404Page"
import {instantiateAttendeeTrips} from "../../../store/actions/retreat"
import {replaceDashes} from "../../../utils"
import {
  useAttendeeLandingWebsiteName,
  useMyAttendee,
  useRetreat,
} from "../../../utils/retreatUtils"
import {useLoggedInUser} from "../../../utils/userUtils"
import AppLinkButton from "../../app/AppLinkButton"
import FlightDetailsTable, {
  FlightsTable,
} from "../../flights/FlightDetailsTable"
import {SurveysList} from "../../forms/SurveysList"
import AttendeeSite from "../../sites/AttendeeSite"
import FormResponseTable from "../../sites/FormResponseTable"
import MyTripTable from "../../sites/MyTripTable"
import SitePageHeader from "../../sites/SitePageHeader"

const FlexColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  width: "100%",
  maxWidth: "800px",
  marginInline: "auto",
})
const Section = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "48px",
  width: "100%",
  marginTop: "24px",
})

export default function AttendeeSiteMyTripPage() {
  let dispatch = useDispatch()
  let router = useRouteMatch<{websiteName: string}>()
  let {websiteName} = router.params
  let [website, websiteLoading] = useAttendeeLandingWebsiteName(
    replaceDashes(websiteName)
  )
  let [retreat, retreatLoading] = useRetreat(website?.retreat_id ?? -1)
  let [user, userLoading] = useLoggedInUser()
  let [attendee] = useMyAttendee(retreat != null ? retreat.id : -1)
  let queryClient = useQueryClient()

  let forms = useQueries({
    queries:
      retreat?.surveys.map((survey) => ({
        queryKey: ["forms", survey.id],
        queryFn: () => getForm(survey.id),
      })) ?? [],
  })
  let liveSurveys = forms
    .filter(
      (formQuery) =>
        formQuery.status === "success" &&
        formQuery.data?.form.status !== FormStatusEnum.DRAFT
    )
    .map((formQuery) => formQuery.data!.form)
  let formResponses = useQuery({
    staleTime: 0,
    enabled: user?.id != null,
    queryFn: () => getFormResponses(user?.id),
    queryKey: ["form-responses", {user_id: user?.id}],
    onSuccess: (data) => {
      data.form_responses.forEach((formResponse) => {
        queryClient.setQueryData(["form-responses", formResponse.id], {
          form_response: formResponse,
        })
      })
    },
  })

  useEffect(() => {
    if (!userLoading && !user) {
      dispatch(
        push(
          AppRoutes.getPath(
            "SigninPage",
            {
              websiteName: websiteName,
            },
            {
              next: encodeURIComponent(
                AppRoutes.getPath("AttendeeSiteMyTripPage", {
                  websiteName: websiteName,
                })
              ),
              "login-type": "attendee",
              "login-site": websiteName,
            }
          )
        )
      )
    }
  }, [user, userLoading, dispatch, websiteName])

  return websiteLoading || retreatLoading || !retreat || userLoading ? (
    <LoadingPage />
  ) : !website || !attendee ? (
    <NotFound404Page />
  ) : (
    <PageContainer>
      <PageBody>
        <AttendeeSite
          retreat={retreat}
          website={website}
          hideLogo
          activePage="my-trip"
          pageBody={
            <>
              <FlexColumn>
                <SitePageHeader
                  title="Your Trip"
                  subtitle="Manage your personal and travel details."
                />
                <MyTripTable attendee={attendee} retreat={retreat} />
                {liveSurveys.length > 0 && (
                  <Section>
                    <Text variant="title-2xl">Surveys</Text>
                    <SurveysList>
                      {liveSurveys.map((form, i) => {
                        let formResponse: FormResponseModel | undefined =
                          undefined
                        if (formResponses.status === "success") {
                          formResponse =
                            formResponses.data.form_responses.filter(
                              (response) => response.form_id === form.id
                            )[0]
                        }
                        let completed = formResponse != null
                        return (
                          <SurveysList.Item
                            key={form.id}
                            title={form.title}
                            completed={completed}
                            action={
                              completed ? (
                                <AppLinkButton
                                  variant="outline"
                                  text="View Response"
                                  to={AppRoutes.getPath(
                                    "AttendeeSiteSurveyPage",
                                    {websiteName, formId: form.id.toString()}
                                  )}
                                />
                              ) : (
                                <AppLinkButton
                                  variant="solid"
                                  color="brand"
                                  text="Complete Survey"
                                  to={AppRoutes.getPath(
                                    "AttendeeSiteSurveyPage",
                                    {websiteName, formId: form.id.toString()}
                                  )}
                                />
                              )
                            }
                          />
                        )
                      })}
                    </SurveysList>
                  </Section>
                )}
                <Section>
                  <Text variant="title-2xl">Details</Text>
                  {attendee.registration_form_response_id == null ? (
                    <AppLinkButton
                      variant="solid"
                      color="brand"
                      text="Register Now"
                      to={AppRoutes.getPath("AttendeeSiteFormPage", {
                        websiteName,
                      })}
                    />
                  ) : (
                    <FormResponseTable attendee={attendee} retreat={retreat} />
                  )}
                </Section>
                <Section>
                  <Text variant="title-2xl">Travel</Text>
                  {(!attendee.travel || !attendee.travel.arr_trip) &&
                    attendee && (
                      <Button
                        variant="solid"
                        text="Add travel details"
                        color="brand"
                        onClick={() => {
                          dispatch(instantiateAttendeeTrips(attendee!.id))
                        }}></Button>
                    )}
                  <FlightDetailsTable
                    attendee={attendee}
                    receiptRestricted={retreat.require_flight_receipts}
                  />

                  {attendee.travel?.arr_trip && (
                    <FlightsTable
                      noFlightsMessage="Please add your arrival travel details once booked. Don't
                    forget to add the costs and receipts in the travel details section
                    above."
                      trip={attendee.travel.arr_trip}
                      header="Arrival Trip"
                    />
                  )}
                  {attendee.travel?.dep_trip && (
                    <FlightsTable
                      noFlightsMessage="Please add your departure travel details once booked. Don't
                    forget to add the costs and receipts in the travel details section
                    above."
                      trip={attendee.travel.dep_trip}
                      header="Departure Trip"
                    />
                  )}
                </Section>
              </FlexColumn>
            </>
          }></AttendeeSite>
      </PageBody>
    </PageContainer>
  )
}
