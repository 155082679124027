import {AirplanemodeActive, TrainRounded} from "@material-ui/icons"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {TripLegType, TripLegTypeEnum} from "../../models/retreat"

const FlightDisplayContainer = styled("div", {
  background: theme.colors.white,
  borderColor: theme.colors.gray7,
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "row",
  padding: "12px 20px",
  width: "100%",
  gap: "8px",
  alignItems: "center",
  variants: {
    alt: {
      true: {
        border: "none",
        alignItems: "start",
        padding: "0px",
      },
    },
    clickable: {
      true: {
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.colors.gray2,
        },
      },
    },
  },
  defaultVariants: {
    alt: false,
  },
})
const LeftContainer = styled("div", {
  border: theme.colors.gray7,
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  gap: "44px",
})
const MiddleContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  variants: {
    alt: {
      true: {
        marginTop: "5px",
      },
    },
  },
})
const Circle = styled("div", {
  height: "8px",
  width: "8px",
  borderRadius: "50%",
  backgroundColor: theme.colors.gray7,
})
const Line = styled("div", {
  width: "1px",
  backgroundColor: theme.colors.gray7,
  height: "56px",
})

const RightContainer = styled("div", {
  border: theme.colors.gray7,
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  gap: "12px",
  variants: {
    alt: {
      true: {
        gap: "4px",
      },
    },
  },
})
const IconContainer = styled("div", {
  marginLeft: "auto",
})
const DateText = styled(Text, {
  color: theme.colors.gray11,
})
const AirlineText = styled(Text, {
  color: theme.colors.gray11,
})

const AirlineTextContainer = styled("div", {
  variants: {
    alt: {
      true: {
        background: theme.colors.gray3,
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        padding: "0px 3px",
      },
    },
  },
})

type FlightDisplayProps = {
  departureLocation: string // airport, train station
  departureTime: string
  arrivalLocation: string // airport, train station
  arrivalTime: string
  travelCompany: string // airline, railway company
  travelNumber: string // flight number, train number
  type?: TripLegType
  alt?: boolean
  onClick?: () => void
}
export default function FlightDisplay(props: FlightDisplayProps) {
  let dep_datetime = props.departureTime
    ? new Date(props.departureTime)
    : undefined
  let arr_datetime = props.arrivalTime ? new Date(props.arrivalTime) : undefined

  return (
    <FlightDisplayContainer
      alt={props.alt}
      onClick={props.onClick}
      clickable={!!props.onClick}>
      {!props.alt && (
        <LeftContainer>
          <DateText variant="text-sm-plus">
            {new Intl.DateTimeFormat(undefined, {
              dateStyle: "short",
              timeStyle: "short",
            }).format(dep_datetime)}
          </DateText>
          <DateText variant="text-sm-plus">
            {new Intl.DateTimeFormat(undefined, {
              dateStyle: "short",
              timeStyle: "short",
            }).format(arr_datetime)}
          </DateText>
        </LeftContainer>
      )}
      <MiddleContainer alt={props.alt}>
        <Circle />
        <Line />
        <Circle />
      </MiddleContainer>

      <RightContainer alt={props.alt}>
        <Text variant="text-sm-plus">{props.departureLocation}</Text>
        {props.alt && (
          <DateText variant="text-sm-plus">
            {new Intl.DateTimeFormat(undefined, {
              dateStyle: "short",
              timeStyle: "short",
            }).format(dep_datetime)}
          </DateText>
        )}
        <AirlineTextContainer alt={props.alt}>
          <AirlineText variant="text-sm">
            {props.type === TripLegTypeEnum.TRAIN ? (
              <TrainRounded
                style={{color: `${theme.colors.gray11}`, height: "13px"}}
              />
            ) : (
              <AirplanemodeActive
                style={{color: `${theme.colors.gray11}`, height: "13px"}}
              />
            )}
            {props.travelCompany} · {props.travelNumber}
          </AirlineText>
        </AirlineTextContainer>

        <Text variant="text-sm-plus">{props.arrivalLocation}</Text>
        {props.alt && (
          <DateText variant="text-sm-plus">
            {new Intl.DateTimeFormat(undefined, {
              dateStyle: "short",
              timeStyle: "short",
            }).format(arr_datetime)}
          </DateText>
        )}
      </RightContainer>
    </FlightDisplayContainer>
  )
}
