import App404Message from "../../components/base/App404Message"
import PageContainer from "../../components/page/PageContainer"

type NotFound404PageProps = {}

export default function NotFound404Page(props: NotFound404PageProps) {
  return (
    <PageContainer>
      <App404Message />
    </PageContainer>
  )
}
