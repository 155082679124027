import {UploadIcon} from "@radix-ui/react-icons"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import Papa from "papaparse"
import {PropsWithChildren} from "react"
import * as XLSX from "xlsx"

let StyledInputContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  alignContent: "center",
  padding: "8px 12px",
  width: "fit-content",
  background: theme.colors.blue9,
  borderColor: theme.colors.blue7,
  color: theme.colors.white,
  borderRadius: theme.shape.borderRadius,
  border: "none",
  cursor: "pointer",
  gap: "6px",
  "&:disabled": {
    opacity: 0.5,
    cursor: "not-allowed",
  },
  minWidth: "100px",

  justifyContent: "center",
  marginBlock: "15px",
})
let StyledLabel = styled("label", {
  width: "fit-content",
  height: "fit-content",
  padding: "-10px",
})
type FileUploadButtonProps = PropsWithChildren<{
  onUpload: (data: string[][]) => void
  hideUploadIcon: boolean
  text?: string
}>
export function FileUploadButton(props: FileUploadButtonProps) {
  let {children} = props
  function handleCsvUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target && e.target.files && e.target.files[0]) {
      Papa.parse(e.target.files[0], {
        complete: function (results) {
          props.onUpload(results.data as unknown as string[][])
        },
      })
    }
  }
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    var files = e.target.files
    if (files) {
      let f = files[0]
      var reader = new FileReader()
      reader.onload = function (e) {
        var data = e!.target!.result
        let readedData = XLSX.read(data, {type: "binary"})
        const wsname = readedData.SheetNames[0]
        const ws = readedData.Sheets[wsname]

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, {header: 1})
        props.onUpload(dataParse as unknown as string[][])
      }
      reader.readAsBinaryString(f)
    }
  }
  return (
    <StyledLabel htmlFor="file-upload">
      <input
        type="file"
        hidden
        accept=".xlsx, .csv"
        id="file-upload"
        onChange={(e) => {
          if (e.target && e.target.files && e.target.files[0]) {
            if (e.target.files[0].type === "text/csv") {
              handleCsvUpload(e)
            } else {
              handleUpload(e)
            }
          }
        }}
      />
      {children ? (
        children
      ) : (
        <StyledInputContainer>
          <UploadIcon />
          <Text variant={"text-sm-plus"}>Upload File</Text>
        </StyledInputContainer>
      )}
    </StyledLabel>
  )
}
