import {ArrowLeftIcon, ChevronDownIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {DatetimePicker} from "@summtech/flok-base/components/DatetimePicker"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {Select} from "@summtech/flok-base/components/Select"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Tab} from "@summtech/flok-base/components/Tab"
import {TabsList} from "@summtech/flok-base/components/TabsList"
import {TabsWrapper} from "@summtech/flok-base/components/TabsWrapper"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {push} from "connected-react-router"
import {useFormik} from "formik"
import {EditorState, LexicalEditor} from "lexical"
import {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import * as yup from "yup"
import BeforeUnload from "../../components/base/BeforeUnload"
import PageBody from "../../components/page/PageBody"
import {
  RetreatEmailModel,
  RetreatEmailTrigger,
} from "../../models/communication"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../Stack"
import {ApiAction} from "../../store/actions/api"
import {
  patchRetreatEmail,
  previewAudienceAttendees,
} from "../../store/actions/retreat"
import {useRetreatEmail} from "../../utils/retreatUtils"
import AudienceModal from "../communications/AudienceModal"
import {LexicalWYSIWYGEditor} from "../lexical/LexicalWYSIWYGEditor"
import {useSidebar} from "../nav/SidebarProvider"

const OverallBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  background: theme.colors.gray4,
  gap: "20px",
  alignItems: "center",
  width: "100%",
})

const TopBar = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  padding: "16px 32px",
  background: theme.colors.white,
  borderBottom: `1px solid ${theme.colors.gray6}`,
})

const ButtonCollection = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  marginLeft: "auto",
})

const BackWrapper = styled("div", {
  marginRight: "auto",
})

const FlexContainer = styled("div", {
  flex: 1,
  justifyContent: "center",
  display: "flex",
})

const EmailPortal = styled("div", {
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: theme.colors.gray6,
  borderRadius: theme.shape.borderRadius,
  overflow: "auto",
  background: theme.colors.white,
  boxShadow:
    "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 8px 10px -6px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
})
const PortalHeader = styled("div", {
  height: "52px",
  width: "100%",
  borderBottomStyle: "solid",
  borderBottomWidth: "1px",
  borderBottomColor: theme.colors.gray6,
  display: "flex",
  flexDirection: "row",
  padding: "12px 20px",
  alignItems: "center",
  justifyContent: "left",
})
const PortalHeaderButtons = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
})
const PortalHeaderButton = styled("div", {
  height: "10px",
  width: "10px",
  borderRadius: "60px",
  borderStyle: "solid",
  borderWidth: "0.5px",
  variants: {
    color: {
      red: {
        background: "#ED6A5E",
        borderColor: "#CF594E",
      },
      yellow: {
        background: "#F6C351",
        borderColor: "#D79F3E",
      },
      green: {
        background: "#61C455",
        borderColor: "#52A63D",
      },
    },
  },
})
const EmailPortalBody = styled("div", {
  display: "flex",
  flexDirection: "column",

  width: "962px",
  minHeight: "600px",
  padding: "20px",
})

const ScheduleButton = styled(Button, {
  minWidth: "100px",
  justifyContent: "flex-end",
})

function getSendButtonStatus(email: RetreatEmailModel) {
  if (email.type === "IMMEDIATE") {
    if (email.status === "DRAFT") {
      return {text: "Send Email", disabled: false}
    } else {
      return {text: "Email Sent", disabled: true}
    }
  } else if (email.type === "SCHEDULED") {
    if (email.status === "DRAFT") {
      return {text: "Schedule Email", disabled: false}
    } else {
      return {
        text: email.scheduled_sent ? "Email Sent" : "Email Scheduled",
        disabled: true,
      }
    }
  } else if (email.type === "AUTOMATED") {
    if (email.status === "DRAFT") {
      return {text: "Automate Email", disabled: false}
    } else {
      return {
        text: "Email Automated",
        disabled: true,
      }
    }
  } else return {text: "Send Email", disabled: false}
}
export default function EmailGenerator() {
  const [tabValue, setTabValue] = useState<"COMPOSE" | "PREVIEW">("COMPOSE")
  let dispatch = useDispatch()
  let [, retreatIdx] = useRetreat()

  let router = useRouteMatch<{
    retreatIdx: string
    emailId: string
  }>()

  let emailId = router.params.emailId ? parseInt(router.params.emailId) : -1

  let [email] = useRetreatEmail(emailId)
  let [unsaved, setUnsaved] = useState(false)
  let {closeSidebar} = useSidebar()
  useEffect(() => {
    closeSidebar()
  }, [])

  const editorStateRef = useRef<EditorState | undefined>(undefined)
  const initialEditorState = editorStateRef.current
    ? JSON.stringify(editorStateRef.current)
    : email?.content

  return (
    <PageBody appBar>
      <BeforeUnload
        when={true}
        message="Are you sure you wish to leave without saving your changes?"
      />
      <OverallBody>
        <TopBar>
          <FlexContainer>
            <BackWrapper>
              <Button
                text="Back"
                startIcon={<ArrowLeftIcon />}
                variant="outline"
                color="gray"
                onClick={() => {
                  dispatch(
                    push(
                      AppRoutes.getPath("CommunicationHomePage", {
                        retreatIdx: retreatIdx.toString(),
                      })
                    )
                  )
                }}
              />
            </BackWrapper>
          </FlexContainer>
          <FlexContainer>
            <TabsWrapper
              value={tabValue}
              onValueChange={(newVal) => {
                setTabValue(newVal as "COMPOSE" | "PREVIEW")
              }}>
              <TabsList>
                <Tab value="COMPOSE" text={"Compose"} />
                <Tab value="PREVIEW" text={"Preview"} />
              </TabsList>
            </TabsWrapper>
          </FlexContainer>
          <FlexContainer>
            <ButtonCollection>
              {!(email?.type === "IMMEDIATE" && email.status === "ACTIVE") &&
                !email?.scheduled_sent && (
                  <Button
                    text={
                      email?.status === "ACTIVE" ? "Update Email" : "Save Draft"
                    }
                    variant="outline"
                    color="gray"
                    onClick={() => {
                      if (email) {
                        dispatch(
                          patchRetreatEmail(email.id, {
                            content: JSON.stringify(editorStateRef.current),
                          })
                        )
                      }
                      setUnsaved(false)
                    }}
                  />
                )}
              {email && (
                <Button
                  text={getSendButtonStatus(email).text}
                  variant="solid"
                  onClick={() => {
                    dispatch(
                      patchRetreatEmail(email!.id, {
                        status: "ACTIVE",
                        content: JSON.stringify(editorStateRef.current),
                      })
                    )
                  }}
                  color="brand"
                  disabled={getSendButtonStatus(email).disabled}
                />
              )}
            </ButtonCollection>
          </FlexContainer>
        </TopBar>

        {tabValue === "COMPOSE" && (
          <EmailComposer
            onChange={(editorState, editor) => {
              editorStateRef.current = editorState
            }}
            initialEditorState={initialEditorState}
          />
        )}
        {tabValue === "PREVIEW" && (
          <EmailPreview content={initialEditorState} />
        )}
      </OverallBody>
    </PageBody>
  )
}
const ComposerWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  border: `1px solid ${theme.colors.gray6}`,
  borderRadius: theme.shape.borderRadius,
  background: theme.colors.white,
  width: "640px",
  minHeight: "500px",
})

const LexicalWrapper = styled("div", {
  height: "100%",
  width: "100%",
  padding: "12px 12px 40px 12px",
})

const ComposerLine = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "8px",
  borderBottom: `1px solid ${theme.colors.gray6}`,
})
const LineTitle = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "84px",
  padding: "8px 12px",
  [`& ${Button}`]: {},
})
const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

const SubjectInput = styled("input", {
  border: "none",
  width: "100%",
  height: "36px",
  padding: "8px 12px",
  "&:hover": {
    backgroundColor: theme.colors.gray4,
  },
  borderRadius: theme.shape.borderRadius,
  outline: "none",
})

const emailScheduleTypeToText = {
  IMMEDIATE: "Send now",
  SCHEDULED: "Send at specific date and time",
  AUTOMATED: "Send after event",
}

const ScheduleContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
})
const TextHolder = styled("div", {
  marginLeft: "10px",
})
function EmailComposer(props: {
  initialEditorState: string | undefined
  onChange: (editorState: EditorState, editor: LexicalEditor) => void
}) {
  let router = useRouteMatch<{
    retreatIdx: string
    emailId: string
  }>()

  let emailId = router.params.emailId ? parseInt(router.params.emailId) : -1

  let [email, emailLoading] = useRetreatEmail(emailId)
  let [numberOfRecipients, setNumberOfRecipients] = useState<
    number | undefined
  >(undefined)
  let dispatch = useDispatch()
  useEffect(() => {
    async function previewAttendees(audience_id: number) {
      let response = (await dispatch(
        previewAudienceAttendees(audience_id)
      )) as unknown as ApiAction
      if (!response.error) {
        setNumberOfRecipients(response.payload.attendee_ids.length)
      }
    }
    if (email?.audience_id) {
      previewAttendees(email.audience_id)
    }
  }, [email?.audience_id, dispatch])
  let subjectFormik = useFormik({
    initialValues: {
      subject: email?.subject ?? "",
    },
    onSubmit: (values) => {},
    validationSchema: yup.object({
      title: yup.string().required(),
    }),
    enableReinitialize: true,
  })

  let [, retreatIdx] = useRetreat()
  let scheduleFormik = useFormik({
    initialValues: {
      type: email?.type ?? "",
      scheduled_date_time: email?.scheduled_date_time ?? "",
      trigger: email?.trigger ?? "",
    },
    onSubmit: (values) => {},
    enableReinitialize: true,
  })
  const [audienceModalOpen, setAudienceModalOpen] = useState(false)

  return email ? (
    <ComposerWrapper>
      <ComposerLine>
        <LineTitle>
          <GrayText variant="text-sm-plus">From</GrayText>
        </LineTitle>
        <TextHolder>
          <Text variant={"text-sm-plus"}>accounts@goflok.com</Text>
        </TextHolder>
      </ComposerLine>
      <ComposerLine>
        <LineTitle>
          <GrayText variant="text-sm-plus">Delivery</GrayText>
        </LineTitle>
        <ScheduleContainer>
          <Dropdown
            portal
            button={
              <ScheduleButton
                variant="ghost"
                endIcon={<ChevronDownIcon />}
                text={
                  scheduleFormik.values.type
                    ? emailScheduleTypeToText[
                        scheduleFormik.values
                          .type as keyof typeof emailScheduleTypeToText
                      ]
                    : ""
                }
              />
            }>
            <DropdownItem
              text={emailScheduleTypeToText["IMMEDIATE"]}
              onClick={() => {
                scheduleFormik.setFieldValue("type", "IMMEDIATE")
                dispatch(
                  patchRetreatEmail(email!.id, {
                    type: "IMMEDIATE",
                  })
                )
              }}></DropdownItem>
            <DropdownItem
              text={emailScheduleTypeToText["SCHEDULED"]}
              onClick={() => {
                scheduleFormik.setFieldValue("type", "SCHEDULED")
                dispatch(
                  patchRetreatEmail(email!.id, {
                    type: "SCHEDULED",
                  })
                )
              }}></DropdownItem>
            <DropdownItem
              text={emailScheduleTypeToText["AUTOMATED"]}
              onClick={() => {
                scheduleFormik.setFieldValue("type", "AUTOMATED")
                dispatch(
                  patchRetreatEmail(email!.id, {
                    type: "AUTOMATED",
                  })
                )
              }}></DropdownItem>
          </Dropdown>
          {scheduleFormik.values.type === "SCHEDULED" && (
            <DatetimePicker
              time
              value={scheduleFormik.values.scheduled_date_time}
              onChange={(newVal) => {
                scheduleFormik.setFieldValue("scheduled_date_time", newVal)
                dispatch(
                  patchRetreatEmail(email!.id, {
                    scheduled_date_time: newVal,
                  })
                )
              }}
            />
          )}
          {scheduleFormik.values.type === "AUTOMATED" && (
            <>
              <Select
                value={
                  scheduleFormik.values.trigger ===
                  "ATTENDEE_ADDED_AND_EXISTING"
                    ? "ATTENDEE_ADDED"
                    : scheduleFormik.values.trigger
                }
                onChange={(newVal) => {
                  scheduleFormik.setFieldValue("trigger", newVal)
                  dispatch(
                    patchRetreatEmail(email!.id, {
                      trigger: newVal as RetreatEmailTrigger,
                    })
                  )
                }}>
                <SelectItem value="ATTENDEE_ADDED" label="Attendee added" />
                <SelectItem
                  value="ATTENDEE_REG_FORM_SUBMIT"
                  label="Attendee has registered"
                />
              </Select>
              {(scheduleFormik.values.trigger === "ATTENDEE_ADDED" ||
                scheduleFormik.values.trigger ===
                  "ATTENDEE_ADDED_AND_EXISTING") && (
                <Text variant={"text-xs"}>
                  This message will be sent to all existing attendees within
                  audience
                </Text>
              )}
            </>
          )}
        </ScheduleContainer>
      </ComposerLine>
      <ComposerLine>
        <LineTitle>
          <GrayText variant="text-sm-plus">To</GrayText>
        </LineTitle>
        <Button
          endIcon={<ChevronDownIcon />}
          text={
            numberOfRecipients === undefined
              ? "Select recipients"
              : `Custom (${numberOfRecipients} recipients)`
          }
          variant={"ghost"}
          onClick={() => {
            setAudienceModalOpen(true)
          }}
        />
        <AudienceModal
          open={audienceModalOpen}
          onClose={async () => {
            setAudienceModalOpen(false)
            if (email?.audience_id) {
              let response = (await dispatch(
                previewAudienceAttendees(email?.audience_id)
              )) as unknown as ApiAction
              if (!response.error) {
                setNumberOfRecipients(response.payload.attendee_ids.length)
              }
            }
          }}
          email={email}
        />
      </ComposerLine>
      <ComposerLine>
        <LineTitle>
          <GrayText variant="text-sm-plus">Subject</GrayText>
        </LineTitle>
        <SubjectInput
          onBlur={() => {
            email &&
              dispatch(
                patchRetreatEmail(email.id, {
                  subject: subjectFormik.values.subject,
                })
              )
          }}
          id="subject"
          onChange={subjectFormik.handleChange}
          value={subjectFormik.values.subject}
        />
      </ComposerLine>
      <LexicalWrapper>
        <LexicalWYSIWYGEditor
          onChange={props.onChange}
          initialEditorState={props.initialEditorState}
        />
      </LexicalWrapper>
    </ComposerWrapper>
  ) : (
    <></>
  )
}

function EmailPreview(props: {content: string | undefined}) {
  return (
    <EmailPortal>
      <PortalHeader>
        <PortalHeaderButtons>
          <PortalHeaderButton color="red" />
          <PortalHeaderButton color="yellow" />
          <PortalHeaderButton color="green" />
        </PortalHeaderButtons>
      </PortalHeader>
      <EmailPortalBody>
        <LexicalWYSIWYGEditor
          onChange={() => {}}
          readOnly
          initialEditorState={props.content}
        />
      </EmailPortalBody>
    </EmailPortal>
  )
}
