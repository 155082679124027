import {AttendeeBatchUploadApiResponse} from "../models/api"
import {
  AttendeeReportModel,
  ColumnAnswerModel,
  RetreatAttendeeModel,
} from "../models/retreat"
import {flokAxios} from "./index"

export function patchColumnAnswer(columnAnswerId: number, value: string) {
  return flokAxios<{column_answer: ColumnAnswerModel}>({
    url: `/v1.0/column-answers/${columnAnswerId}`,
    method: "PATCH",
    data: JSON.stringify({
      value: value,
    }),
  })
}

export function getColumnAnswer(columnAnswerId: number) {
  return flokAxios<{column_answer: ColumnAnswerModel}>({
    url: `/v1.0/column-answers/${columnAnswerId}`,
    method: "GET",
  })
}

export function postColumnAnswer(
  attendeeId: number,
  columnId: number,
  value: string
) {
  return flokAxios<{column_answer: ColumnAnswerModel}>({
    url: `/v1.0/column-answers`,
    method: "POST",
    data: JSON.stringify({
      value: value,
      attendee_id: attendeeId,
      column_id: columnId,
    }),
  })
}

export function getColumnAnswers(retreatId: number) {
  return flokAxios<{column_answers: ColumnAnswerModel[]}>({
    url: `/v1.0/column-answers?retreat_id=${retreatId}`,
    method: "GET",
  })
}

export function getRetreatAttendees(retreatId: number) {
  return flokAxios<{attendees: RetreatAttendeeModel[]}>({
    url: `/v1.0/retreats/${retreatId}/attendees`,
    method: "GET",
  })
}

export function patchAttendeeReport(
  reportId: number,
  report: Partial<AttendeeReportModel>
) {
  return flokAxios<{column_answer: ColumnAnswerModel}>({
    url: `/v1.0/attendee-reports/${reportId}`,
    method: "PATCH",
    data: JSON.stringify(report),
  })
}

export function postAttendeesBatch(
  attendees: Pick<
    RetreatAttendeeModel,
    "first_name" | "last_name" | "retreat_id" | "email_address"
  >[]
) {
  return flokAxios<AttendeeBatchUploadApiResponse>({
    url: `/v1.0/attendees/batch`,
    method: "POST",
    data: JSON.stringify({attendees: attendees}),
  })
}

export function postAttendee(
  attendee: Pick<
    RetreatAttendeeModel,
    "first_name" | "last_name" | "email_address"
  >,
  retreatId: number
) {
  return flokAxios<AttendeeBatchUploadApiResponse>({
    url: `/v1.0/retreats/${retreatId}/attendees`,
    method: "POST",
    data: JSON.stringify(attendee),
  })
}
