import {makeStyles, Typography} from "@material-ui/core"

let useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "baseline",
    "& > *:nth-child(2)": {
      borderLeft: `solid 2px ${theme.palette.text.primary}`,
      marginLeft: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
}))

type App404MessageProps = {}
export default function App404Message(props: App404MessageProps) {
  let classes = useStyles(props)
  return (
    <div className={classes.root}>
      <Typography variant={"h1"}>404</Typography>
      <Typography variant={"h3"}>
        The page you're looking for can't be found
      </Typography>
    </div>
  )
}
