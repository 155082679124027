import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {RetreatModel} from "../../../models/retreat"
import {useItinerary} from "../../../utils/itineraryUtils"
import SiteItinerary from "../SiteItinerary"

const ItineraryPlaceHolder = styled("div", {
  width: "100%",
  height: "200px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})
export default function ItineraryBlock(props: {retreat?: RetreatModel}) {
  let [itinerary] = useItinerary(props.retreat?.itinerary_id ?? -1)
  return itinerary ? (
    <SiteItinerary itinerary={itinerary} />
  ) : (
    <ItineraryPlaceHolder>
      <Text>
        Once you create an itinerary an attendee view will appear here!
      </Text>
    </ItineraryPlaceHolder>
  )
}
