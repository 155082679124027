import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DotsVerticalIcon,
  DownloadIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import SearchBar from "@summtech/flok-base/components/SearchBar"
import {Text} from "@summtech/flok-base/components/Text"
import {css, styled, theme} from "@summtech/flok-base/stitches.config"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table"
import _ from "lodash"
import {useEffect, useRef, useState} from "react"
import {CSVLink} from "react-csv"
import {useDispatch, useSelector} from "react-redux"
import {Link} from "react-router-dom"
import {ScrollSync, ScrollSyncPane} from "react-scroll-sync"
import {FormResponseModel} from "../../../models/form"
import {
  AttendeeInfoStatus,
  RetreatAttendeeModel,
  TripLegTypeEnum,
} from "../../../models/retreat"
import {RootState} from "../../../store"
import {getFormQuestion, getFormResponses} from "../../../store/actions/form"
import {formatCurrency, getDatetimeString} from "../../../utils"
import AttendeesColumnOptions from "./AttendeesColumnOptions"
import {
  FlightStatusBadge,
  RegistrationStatusBadge,
  TripLegTypeBadge,
} from "./StatusBadge"

export type AttendeesTableProps = {
  attendees: RetreatAttendeeModel[]
  activeView?: "all-attendees" | "form-responses" | "flights"
  getAttendeeRedirect: (attendeeId: number) => string
}

export function AttendeesTable(props: AttendeesTableProps) {
  // TO CLEANUP
  let dispatch = useDispatch()
  let responseIds = props.attendees.map(
    (attendee) => attendee.registration_form_response_id
  )
  let flights = useSelector((state: RootState) => {
    return state.retreat.flights
  })
  let formResponses: {[id: number]: FormResponseModel} = useSelector(
    (state: RootState) => {
      return Object.assign(
        {},
        ...Object.entries(state.form.formResponses)
          .filter((entry) => {
            if (responseIds.indexOf(parseInt(entry[0])) !== -1) {
              return entry
            } else return false
          })
          .map((entry) => ({[entry[0]]: entry[1]}))
      )
    }
  )
  let questions = useSelector((state: RootState) => {
    return state.form.formQuestions
  })

  let [loadingResponses, setLoadingResponses] = useState(false)
  let missingResponses = props.attendees.reduce((prev, attendee) => {
    let ret: {[key: number]: undefined} = {}
    if (
      attendee.registration_form_response_id &&
      !formResponses[attendee.registration_form_response_id]
    ) {
      ret[attendee.registration_form_response_id] = undefined
    }
    return {...prev, ...ret}
  }, {})
  useEffect(() => {
    async function loadResponses() {
      setLoadingResponses(true)
      await dispatch(
        getFormResponses(
          Object.keys(missingResponses).map((id) => parseInt(id))
        )
      )
      setLoadingResponses(false)
    }
    if (Object.keys(missingResponses).length && !loadingResponses) {
      loadResponses()
    }
  }, [dispatch, loadingResponses, missingResponses])

  let questionIds = Object.values(formResponses)
    .map((response) => response.answers)
    .flat()
    .reduce((prev, answer) => {
      let ret: {[key: number]: undefined} = {}
      ret[answer.form_question_id] = undefined
      return {...prev, ...ret}
    }, {})

  let [loadingQuestions, setLoadingQuestions] = useState(false)
  let missingQuestions = Object.keys(questionIds).filter(
    (id) => !questions[parseInt(id)]
  )
  useEffect(() => {
    async function loadQuestions() {
      setLoadingQuestions(true)
      await Promise.all(
        missingQuestions.slice(0, 10).map((id) => {
          return dispatch(getFormQuestion(parseInt(id)))
        })
      )
      setLoadingQuestions(false)
    }
    if (missingQuestions.length && !loadingQuestions) {
      loadQuestions()
    }
  }, [dispatch, loadingQuestions, missingQuestions])
  // END TO CLEANUP
  // VISIBLE COLUMNS
  // let visibleColumns = table.getVisibleLeafColumns().map((col) => col.id)
  // END VISIBLE COLUMNS

  let [searchString, setSearchString] = useState("")
  let columnHelper = createColumnHelper<RetreatAttendeeModel>()
  let options: TableOptions<RetreatAttendeeModel> = {
    defaultColumn: {
      enableGlobalFilter: false,
      enableSorting: false,
      size: 150,
    },
    globalFilterFn: "includesString",
    initialState: {
      columnVisibility: {id: false},
      pagination: {
        pageSize: 50,
      },
    },
    state: {
      globalFilter: searchString,
    },
    columns: [
      columnHelper.accessor("first_name", {
        header: "First Name",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor("last_name", {
        header: "Last Name",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor("email_address", {
        header: "Email",
        enableGlobalFilter: true,
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor("info_status", {
        cell: (props) => {
          let registrationStatus = props.getValue() as AttendeeInfoStatus
          return <RegistrationStatusBadge status={registrationStatus} />
        },
        header: "Registration Status",
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor("hotel_check_in", {
        header: "Hotel Check In",
        enableSorting: true,
        sortingFn: "datetime",
      }),
      columnHelper.accessor("hotel_check_out", {
        header: "Hotel Check Out",
        enableSorting: true,
        sortingFn: "datetime",
      }),
      columnHelper.accessor("dietary_prefs", {
        header: "Dietary Preferences",
      }),
      columnHelper.accessor("notes", {
        header: "Notes",
      }),
      // Flights
      columnHelper.accessor("flight_status", {
        cell: (props) => {
          let flightStatus =
            props.getValue() as RetreatAttendeeModel["flight_status"]
          return <FlightStatusBadge status={flightStatus} />
        },
        header: "Travel status",
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor((attendee) => attendee.travel?.cost, {
        id: "flight_cost",
        cell: (props) => {
          let cost = props.getValue()
          if (cost != null) {
            return formatCurrency(cost, "USD", 2)
          } else {
            return <></>
          }
        },
        header: "Travel cost",
        enableSorting: true,
        sortingFn: "alphanumeric",
      }),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            return (
              flights[tripLegIds[tripLegIds.length - 1]]?.trip_leg_type ??
              TripLegTypeEnum.FLIGHT
            )
          }
        },
        {
          cell: (props) => {
            let type = props.getValue()
            if (type) {
              return <TripLegTypeBadge tripLegType={type} />
            } else {
              return <></>
            }
          },
          id: "flight_arrival_type",
          header: "Arrival type",
          sortingFn: "alphanumeric",
          filterFn: "arrIncludesSome",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegsIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegsIds && tripLegsIds.length) {
            return getDatetimeString(
              flights[tripLegsIds[tripLegsIds.length - 1]]?.arr_datetime
            )
          }
        },
        {
          id: "flight_arrival_time",
          header: "Arrival Time",
          enableSorting: true,
          sortingFn: "datetime",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegsIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegsIds && tripLegsIds.length) {
            let tripLeg = flights[tripLegsIds[tripLegsIds.length - 1]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.arr_station
            } else {
              return tripLeg?.arr_airport
            }
          }
        },
        {
          id: "flight_arrival_airport",
          header: "Arrival Location",
          enableSorting: true,
          sortingFn: "alphanumeric",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegsIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegsIds && tripLegsIds.length) {
            let tripLeg = flights[tripLegsIds[tripLegsIds.length - 1]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.train_num
            } else {
              return tripLeg?.flight_num
            }
          }
        },
        {
          id: "flight_arrival_number",
          header: "Arrival #",
          enableSorting: true,
          sortingFn: "alphanumeric",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegsIds = attendee.travel?.arr_trip?.trip_leg_ids
          if (tripLegsIds && tripLegsIds.length) {
            let tripLeg = flights[tripLegsIds[tripLegsIds.length - 1]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.railway
            } else {
              return tripLeg?.airline
            }
          }
        },
        {
          id: "flight_arrival_airline",
          header: "Arrival Airline",
          enableSorting: true,
          sortingFn: "alphanumeric",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegIds && tripLegIds.length) {
            return (
              flights[tripLegIds[0]]?.trip_leg_type ?? TripLegTypeEnum.FLIGHT
            )
          }
        },
        {
          cell: (props) => {
            let type = props.getValue()
            if (type) {
              return <TripLegTypeBadge tripLegType={type} />
            } else {
              return <></>
            }
          },
          id: "flight_departure_type",
          header: "Departure type",
          sortingFn: "alphanumeric",
          filterFn: "arrIncludesSome",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegsIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegsIds && tripLegsIds.length) {
            return getDatetimeString(flights[tripLegsIds[0]]?.dep_datetime)
          }
        },
        {
          id: "flight_departure_time",
          header: "Departure Time",
          enableSorting: true,
          sortingFn: "datetime",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegsIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegsIds && tripLegsIds.length) {
            let tripLeg = flights[tripLegsIds[0]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.dep_station
            } else {
              return tripLeg?.dep_airport
            }
          }
        },
        {
          id: "flight_departure_airport",
          header: "Departure Location",
          enableSorting: true,
          sortingFn: "alphanumeric",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegsIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegsIds && tripLegsIds.length) {
            let tripLeg = flights[tripLegsIds[0]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.train_num
            } else {
              return tripLeg?.flight_num
            }
          }
        },
        {
          id: "flight_departure_number",
          header: "Departure #",
          enableSorting: true,
          sortingFn: "alphanumeric",
        }
      ),
      columnHelper.accessor(
        (attendee) => {
          let tripLegsIds = attendee.travel?.dep_trip?.trip_leg_ids
          if (tripLegsIds && tripLegsIds.length) {
            let tripLeg = flights[tripLegsIds[0]]
            if (tripLeg && tripLeg.trip_leg_type === TripLegTypeEnum.TRAIN) {
              return tripLeg?.railway
            } else {
              return tripLeg?.airline
            }
          }
        },
        {
          id: "flight_departure_airline",
          header: "Departure Airline",
          enableSorting: true,
          sortingFn: "alphanumeric",
        }
      ),
      // Form responses
      ...Object.keys(questionIds)
        .filter((questionId) => questions[parseInt(questionId)])
        .map((questionId) =>
          columnHelper.accessor(
            (attendee) => {
              if (attendee.registration_form_response_id) {
                let formResponse =
                  formResponses[attendee.registration_form_response_id]
                if (formResponse) {
                  let answer = formResponse.answers.find(
                    (answer) => answer.form_question_id === parseInt(questionId)
                  )
                  return answer?.answer
                }
              }
            },
            {
              header: questions[parseInt(questionId)]?.title,
              id: `question-${questionId}`,
              enableSorting: true,
              sortingFn: "alphanumeric",
            }
          )
        ),
    ],
    data: props.attendees,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: true,
  }
  const table = useReactTable(options)

  function getRowLabelForCsv(value: string, key: string) {
    if (key === "info_status") {
      switch (value) {
        case "CREATED":
          return "Not registered"
        case "INFO_ENTERED":
          return "Registered"
        case "NOT_ATTENDING":
          return "Declined"
        case "CANCELLED":
          return "Cancelled"
        default:
          return value
      }
    }
    if (key === "flight_status") {
      switch (value) {
        case "OPT_OUT":
          return "Opted Out"
        case "BOOKED":
          return "Booked"
        case "PENDING":
          return "Pending"
        default:
          return value
      }
    }
    if (key === "flight_departure_type" || key === "flight_arrival_type") {
      switch (value) {
        case "TRAIN":
          return "Train"
        case "FLIGHT":
          return "Flight"
        default:
          return value
      }
    } else {
      return value
    }
  }

  // NEED A BETTER METHOD THAN THESE TWO, IT USES TO MUCH RESOURCES
  let rowsToExport = table.getPrePaginationRowModel().rows.map((row) => {
    let attendeeInfo: any = {}
    row
      .getVisibleCells()
      .forEach(
        (cell) =>
          (attendeeInfo[cell.column.id] = getRowLabelForCsv(
            cell.getContext().getValue() as string,
            cell.column.id
          ) as string)
      )
    return attendeeInfo
  })
  let headersToExport = table.getHeaderGroups().map((headerGroup) => {
    return headerGroup.headers
      .filter((header) => header.depth === 1 && header.column.getIsVisible())
      .map((header) => {
        let headerObject = {
          label: header.column.columnDef.header as string,
          key: header.id,
        }
        return headerObject
      })
  })[0]
  let numColumns = table.getAllColumns().length

  useEffect(() => {
    function defaultCol(col: string) {
      return [
        "first_name",
        "last_name",
        "email_address",
        "info_status",
      ].includes(col)
    }
    function roomingCol(col: string) {
      return (
        defaultCol(col) || ["hotel_check_in", "hotel_check_out"].includes(col)
      )
    }
    function registrationCol(col: string) {
      return defaultCol(col) || col.startsWith("question-")
    }
    function flightsCol(col: string) {
      return defaultCol(col) || col.startsWith("flight_")
    }
    let fn =
      props.activeView === "form-responses"
        ? registrationCol
        : props.activeView === "flights"
        ? flightsCol
        : roomingCol

    table.setColumnVisibility((oldVisibilityState) => {
      return table.getAllLeafColumns().reduce<{
        [key: string]: boolean
      }>((prev, col) => {
        return {
          ...prev,
          [col.id]: fn(col.id),
        }
      }, {})
    })

    // @ts-ignore, this should be fixed but no good way to update the columns to hide by default
  }, [numColumns, props.activeView])
  let exportRef = useRef<
    CSVLink & HTMLAnchorElement & {link: HTMLAnchorElement}
  >(null)

  return (
    <StyledTableContainer>
      <StyledTableOptionsContainer>
        <StyledSearchBar
          placeholder="Search by name or email"
          onChange={_.debounce(
            async (e) => setSearchString(e.target.value),
            500
          )}
        />
        <StyledButtonsContainer>
          {/* <Button
            text={"Filters"}
            variant={"outline"}
            color={"gray"}
            endIcon={<MixerHorizontalIcon />}
          /> */}
          <CSVLink
            filename={"attendees-export.csv"}
            ref={exportRef}
            style={{display: "none"}}
            data={rowsToExport}
            headers={headersToExport}
          />
          <AttendeesColumnOptions
            values={[
              ...table
                .getVisibleLeafColumns()
                .filter((header) => header.getIsVisible())
                .map((header) => header.id),
              ...(table
                .getVisibleLeafColumns()
                .filter((col) => col.id.startsWith("flight_arrival_")).length >
              0
                ? ["flight_arrival"]
                : []),
              ...(table
                .getVisibleLeafColumns()
                .filter((col) => col.id.startsWith("flight_departure_"))
                .length > 0
                ? ["flight_departure"]
                : []),
            ]}
            onChange={(colIds) => {
              table.setColumnVisibility((oldVisibilityState) => {
                let columnVisibility: Record<string, boolean> = table
                  .getAllLeafColumns()
                  .reduce<{
                    [key: string]: boolean
                  }>((prev, col) => {
                    return {
                      ...prev,
                      [col.id]: colIds.includes(col.id) ? true : false,
                    }
                  }, {})

                // Custom grouped
                if (colIds.includes("flight_arrival")) {
                  columnVisibility = {
                    ...columnVisibility,
                    flight_arrival_type: true,
                    flight_arrival_time: true,
                    flight_arrival_airport: true,
                    flight_arrival_number: true,
                    flight_arrival_airline: true,
                  }
                } else {
                  columnVisibility = {
                    ...columnVisibility,
                    flight_arrival_type: false,
                    flight_arrival_time: false,
                    flight_arrival_airport: false,
                    flight_arrival_number: false,
                    flight_arrival_airline: false,
                  }
                }
                if (colIds.includes("flight_departure")) {
                  columnVisibility = {
                    ...columnVisibility,
                    flight_departure_type: true,
                    flight_departure_time: true,
                    flight_departure_airport: true,
                    flight_departure_number: true,
                    flight_departure_airline: true,
                  }
                } else {
                  columnVisibility = {
                    ...columnVisibility,
                    flight_departure_type: false,
                    flight_departure_time: false,
                    flight_departure_airport: false,
                    flight_departure_number: false,
                    flight_departure_airline: false,
                  }
                }

                return columnVisibility
              })
            }}
            columnOptions={[
              {
                label: "Attendee Profile",
                options: [
                  {label: "First Name", value: "first_name"},
                  {label: "Last Name", value: "last_name"},
                  {label: "Email", value: "email_address"},
                  {label: "Status", value: "info_status"},
                  {label: "Hotel Check In", value: "hotel_check_in"},
                  {label: "Hotel Check Out", value: "hotel_check_out"},
                  {label: "Dietary Preferences", value: "dietary_prefs"},
                  {label: "Notes", value: "notes"},
                ],
              },
              {
                label: "Travel",
                options: [
                  {label: "Travel Status", value: "flight_status"},
                  {label: "Travel Cost", value: "flight_cost"},
                  {
                    label: "Arrival Trip",
                    value: "flight_arrival",
                  },
                  {
                    label: "Departure Trip",
                    value: "flight_departure",
                  },
                ],
              },
              ...(table
                .getAllColumns()
                .filter((col) => col.id.startsWith("question-")).length
                ? [
                    {
                      label: "Form Responses",
                      options: table
                        .getAllColumns()
                        .filter(
                          (col) =>
                            col.id.startsWith("question-") &&
                            typeof col.columnDef.header === "string"
                        )
                        .map((col) => ({
                          label: col.columnDef.header! as string,
                          value: col.id,
                        })),
                    },
                  ]
                : []),
            ]}
          />
          <Dropdown
            portal
            button={
              <Button
                css={{height: "36px"}}
                startIcon={<DotsVerticalIcon />}
                variant="outline"
                color="gray"
              />
            }>
            <DropdownItem
              text="Export to CSV"
              onClick={() => {
                if (exportRef.current?.link) exportRef.current.link.click()
              }}
              startIcon={<DownloadIcon />}
            />
          </Dropdown>
          {/* <IconButton variant="outline" children={<GearIcon />} />           */}
        </StyledButtonsContainer>
      </StyledTableOptionsContainer>
      <ScrollSync>
        <ScrollSyncPane>
          <StyledTableOverflowWrapper>
            <ScrollSyncPane>
              <StyledTableHeadWrapper>
                <StyledTable>
                  <StyledTableHead>
                    <tr>
                      {table.getHeaderGroups().map((headerGroup) =>
                        headerGroup.headers
                          .filter((header) => header.depth === 1)
                          .map((header) => (
                            // <Tooltip
                            //   key={header.id}
                            //   text={
                            //     flexRender(
                            //       header.column.columnDef.header,
                            //       header.getContext()
                            //     ) as string
                            //   }>
                            <StyledTableHeaderCell
                              colSpan={header.colSpan}
                              gridLines
                              onClick={
                                header.column.getCanSort()
                                  ? () => header.column.toggleSorting()
                                  : undefined
                              }
                              css={{
                                $$width: `${header.column.columnDef.size}px`,
                                cursor: header.column.getCanSort()
                                  ? "pointer"
                                  : "inherit",
                              }}>
                              <span>
                                <Text variant="text-sm-plus">
                                  {header.isPlaceholder
                                    ? "\u00A0"
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </Text>
                                {header.column.getCanSort() &&
                                header.column.getIsSorted() === "asc" ? (
                                  <TriangleUpIcon
                                    className={StyledSortIconCss()}
                                  />
                                ) : header.column.getIsSorted() === "desc" ? (
                                  <TriangleDownIcon
                                    className={StyledSortIconCss()}
                                  />
                                ) : (
                                  <></>
                                )}
                              </span>
                            </StyledTableHeaderCell>
                            // </Tooltip>
                          ))
                      )}
                    </tr>
                  </StyledTableHead>
                </StyledTable>
              </StyledTableHeadWrapper>
            </ScrollSyncPane>

            <StyledTable>
              <StyledTableHead>
                <tr>
                  {table.getHeaderGroups().map((headerGroup) =>
                    headerGroup.headers
                      .filter((header) => header.depth === 1)
                      .map((header) => (
                        // <Tooltip
                        //   key={header.id}
                        //   text={
                        //     flexRender(
                        //       header.column.columnDef.header,
                        //       header.getContext()
                        //     ) as string
                        //   }>
                        <StyledTableHeaderCell
                          colSpan={header.colSpan}
                          gridLines
                          onClick={
                            header.column.getCanSort()
                              ? () => header.column.toggleSorting()
                              : undefined
                          }
                          css={{
                            $$width: `${header.column.columnDef.size}px`,
                            cursor: header.column.getCanSort()
                              ? "pointer"
                              : "inherit",
                          }}>
                          <span>
                            <Text variant="text-sm-plus">
                              {header.isPlaceholder
                                ? "\u00A0"
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </Text>
                            {header.column.getCanSort() &&
                            header.column.getIsSorted() === "asc" ? (
                              <TriangleUpIcon className={StyledSortIconCss()} />
                            ) : header.column.getIsSorted() === "desc" ? (
                              <TriangleDownIcon
                                className={StyledSortIconCss()}
                              />
                            ) : (
                              <></>
                            )}
                          </span>
                        </StyledTableHeaderCell>
                        // </Tooltip>
                      ))
                  )}
                </tr>
              </StyledTableHead>
              <StyledTableBody>
                {table.getRowModel().rows.map((row) => (
                  <StyledTableBodyRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      // <Tooltip
                      //   key={cell.id}
                      //   text={
                      //     flexRender(
                      //       cell.column.columnDef.cell,
                      //       cell.getContext()
                      //     ) as string
                      //   }>
                      <StyledTableDataCell
                        gridLines
                        css={{$$width: `${cell.column.columnDef.size}px`}}>
                        <Link
                          to={props.getAttendeeRedirect(row.original.id)}
                          style={{textDecoration: "none", color: "inherit"}}>
                          <Text variant="text-sm">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Text>
                        </Link>
                      </StyledTableDataCell>
                      // </Tooltip>
                    ))}
                  </StyledTableBodyRow>
                ))}
              </StyledTableBody>
            </StyledTable>
            <TableFooter
              rowCount={table.getFilteredRowModel().rows.length}
              currentPage={table.getState().pagination.pageIndex + 1}
              pageCount={table.getPageCount()}
              pageSize={table.getState().pagination.pageSize}
              onNextPage={table.getCanNextPage() ? table.nextPage : undefined}
              onPreviousPage={
                table.getCanPreviousPage() ? table.previousPage : undefined
              }
            />
          </StyledTableOverflowWrapper>
        </ScrollSyncPane>
      </ScrollSync>
    </StyledTableContainer>
  )
}

const StyledTableContainer = styled("div", {
  width: "100%",
  overflow: "hidden",
  heigth: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
})

let StyledTableOptionsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  width: "100%",
  paddingBlock: "10px",
  paddingInline: "12px",
  alignItems: "center",
  gap: "30px",
})
let StyledButtonsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  marginLeft: "auto",
  gap: "8px",
  alignItems: "center",
})
let StyledSearchBar = styled(SearchBar, {
  marginRight: "auto",
  width: "100%",
  maxWidth: 360,
})

const StyledTableOverflowWrapper = styled("div", {
  flex: 1,
  width: "100%",
  overflow: "auto",
  marginBottom: "50px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
})

const StyledTable = styled("table", {
  minWidth: "100%",
  borderCollapse: "collapse",
  borderSpacing: 2,
  borderLeft: "none",
  borderRight: "none",
  whiteSpace: "nowrap",
  textAlign: "left",
})

const StyledTableHeadWrapper = styled("div", {
  position: "absolute",
  width: "100%",
  overflow: "inherit",
  "&::-webkit-scrollbar": {
    display: "none",
  },
})
const StyledTableHead = styled("thead", {
  backgroundColor: theme.colors.gray2,
  color: theme.colors.gray11,
})

const StyledTableBody = styled("tbody", {})
const StyledTableBodyRow = styled("tr", {
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.colors.gray2,
  },
})

const StyledCellCss = css({
  maxWidth: "$$width",
  minWidth: "$$width",
  borderBottom: `solid 1px ${theme.colors.gray6}`,
  "& *": {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  variants: {
    gridLines: {
      true: {
        "&:not(:last-child)": {
          borderRight: `solid 1px ${theme.colors.gray6}`,
        },
      },
    },
  },
})

const StyledTableHeaderCell = styled("th", StyledCellCss, {
  backgroundColor: theme.colors.gray2,
  padding: "10px",
  borderTop: `solid 1px ${theme.colors.gray6}`,
  "& > span": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
})

const StyledSortIconCss = css({flexShrink: 0})
const StyledTableDataCell = styled("td", StyledCellCss, {
  "& a": {
    display: "block",
    padding: "10px",
  },
})

type TableFooterProps = {
  rowCount: number
  pageSize: number
  pageCount: number
  currentPage: number
  onNextPage?: () => void
  onPreviousPage?: () => void
}
function TableFooter(props: TableFooterProps) {
  let startRowI = (props.currentPage - 1) * props.pageSize + 1
  let endRowI = startRowI + props.pageSize - 1
  if (endRowI > props.rowCount) {
    endRowI = props.rowCount
  }
  return (
    <StyledFooter>
      <StyledRowCount>
        <Text variant="text-base">
          {startRowI}
          &nbsp;-&nbsp;
          {endRowI}
        </Text>
        <Text variant="text-sm">&nbsp;of&nbsp;</Text>
        <Text variant="text-base">{props.rowCount}</Text>
      </StyledRowCount>
      <StyledPaginationContainer>
        <IconButton
          variant="outline"
          disabled={props.onPreviousPage == null}
          onClick={props.onPreviousPage}>
          <ChevronLeftIcon />
        </IconButton>
        <StyledPageDisplay>
          <Text variant="text-base">{props.currentPage}</Text>
          &nbsp;<Text variant="text-sm">of</Text>&nbsp;
          <Text variant="text-base">{props.pageCount}</Text>
        </StyledPageDisplay>
        <IconButton
          variant="outline"
          disabled={props.onNextPage == null}
          onClick={props.onNextPage}>
          <ChevronRightIcon />
        </IconButton>
      </StyledPaginationContainer>
    </StyledFooter>
  )
}

const StyledFooter = styled("div", {
  display: "flex",
  width: "100%",
  bottom: "0",
  alignItems: "center",
  backgroundColor: theme.colors.gray2,
  borderTop: `solid 1px ${theme.colors.gray6}`,
  position: "absolute",
  height: "50px",
  paddingInline: "5px",
})

const StyledRowCount = styled("div", {
  display: "flex",
  alignItems: "baseline",
})

const StyledPaginationContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "10px",
  marginLeft: "auto",
})

const StyledPageDisplay = styled("div", {
  display: "flex",
  alignItems: "baseline",
})
