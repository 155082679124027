import {styled} from "@summtech/flok-base/stitches.config"
import {useQueries, useQuery} from "@tanstack/react-query"
import {getForm, getFormQuestion} from "../../api/form"
import {FormHeaderBuilder} from "./headers"
import {FormQuestionBuilder} from "./questions"
import {FormAddQuestionButton} from "./questions/FormAddQuestionButton"

const FormBuilderContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
})
const AddQuestionButtonContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
})

type FormBuilderProps = {
  formId: number
}
export default function FormBuilder(props: FormBuilderProps) {
  let formQuery = useQuery({
    queryFn: () => getForm(props.formId),
    queryKey: ["forms", props.formId],
  })
  let formQuestionsQueries = useQueries({
    queries:
      formQuery.data?.form.questions.map((questionId) => ({
        queryFn: () => getFormQuestion(questionId),
        queryKey: ["forms-questions", questionId],
      })) ?? [],
  })

  return (
    <FormBuilderContainer>
      {formQuery.status === "success" && (
        <FormHeaderBuilder form={formQuery.data!.form} />
      )}

      {formQuestionsQueries
        .filter((query) => query.status === "success")
        .map((query, index) => {
          return <FormQuestionBuilder question={query.data!.form_question} />
        })}

      <AddQuestionButtonContainer>
        <FormAddQuestionButton formId={props.formId} />
      </AddQuestionButtonContainer>
    </FormBuilderContainer>
  )
}
