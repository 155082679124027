import {CodeNode} from "@lexical/code"
import {LinkNode} from "@lexical/link"
import {ListItemNode, ListNode} from "@lexical/list"
import {TRANSFORMERS} from "@lexical/markdown"
import {LexicalComposer} from "@lexical/react/LexicalComposer"
import {ContentEditable} from "@lexical/react/LexicalContentEditable"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import {MarkdownShortcutPlugin} from "@lexical/react/LexicalMarkdownShortcutPlugin"
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin"
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin"
import {HeadingNode, QuoteNode} from "@lexical/rich-text"
import {EditorState, LexicalEditor} from "lexical"
import "../lexical/EditorCss.css"
import LexicalToolbar from "./LexicalToolbar"
import {ButtonNode} from "./nodes/ButtonNode"
import {ButtonPlugin} from "./plugins/ButtonPlugin"
import {InitializePlugin} from "./plugins/InitializePlugin"

const exampleTheme = {
  ltr: "ltr",
  paragraph: "editor-paragraph",
  editor: "editor",
  image: "editor-image",
  link: "editor-link",
  text: {
    bold: "editor-textBold",
    code: "editor-textCode",
    italic: "editor-textItalic",
    strikethrough: "editor-textStrikethrough",
    subscript: "editor-textSubscript",
    superscript: "editor-textSuperscript",
    underline: "editor-textUnderline",
    underlineStrikethrough: "editor-textUnderlineStrikethrough",
  },
}

const EDITOR_NODES = [
  CodeNode,
  HeadingNode,
  LinkNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  ButtonNode,
]

export function LexicalWYSIWYGEditor(props: {
  initialEditorState: string | undefined
  onChange: (editorState: EditorState, editor: LexicalEditor) => void
  readOnly?: boolean
}) {
  let editable = !props.readOnly
  const initialConfig = {
    namespace: "Lexical-Editor",
    nodes: EDITOR_NODES,
    onError: (error: Error) => {
      throw error
    },
    editable: editable,
    theme: exampleTheme,
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        contentEditable={<ContentEditable className="editable-box" />}
        placeholder={<></>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <OnChangePlugin onChange={props.onChange} />
      <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
      {editable ? <LexicalToolbar /> : <></>}
      <InitializePlugin initialEditorState={props.initialEditorState} />
      <ButtonPlugin />
    </LexicalComposer>
  )
}
