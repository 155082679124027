import {Badge} from "@summtech/flok-base/components/Badge"
import {FormField} from "@summtech/flok-base/components/FormField"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {styled} from "@summtech/flok-base/stitches.config"
import {useFormik} from "formik"
import {useDispatch} from "react-redux"
import {RetreatAttendeeModel} from "../../models/retreat"
import {ApiAction} from "../../store/actions/api"
import {
  deleteReceiptToAttendee,
  patchAttendee,
  patchAttendeeTravel,
  postReceiptToAttendee,
} from "../../store/actions/retreat"
import {splitFileName} from "../../utils/retreatUtils"
import {AppMultipleFilePicker} from "../app/AppMultipleFilePicker"

const FormContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  width: "100%",
})

export default function FlightDetailsForm(props: {
  attendee: RetreatAttendeeModel
  receiptRestricted?: boolean
}) {
  let dispatch = useDispatch()
  let statusFormik = useFormik({
    initialValues: {
      flight_status: props.attendee.flight_status,
    },
    onSubmit: (values) => {},
  })
  let costFormik = useFormik({
    initialValues: {
      cost: props.attendee.travel?.cost ?? 0,
    },
    onSubmit: (values) => {},
  })
  return (
    <FormContainer>
      <FormField
        type="select"
        label="Status"
        value={statusFormik.values.flight_status}
        fullWidth
        onChange={async (value) => {
          statusFormik.setFieldValue("flight_status", value)
          let response = (await dispatch(
            patchAttendee(props.attendee.id, {
              flight_status: value as "PENDING" | "OPT_OUT" | "BOOKED",
            })
          )) as unknown as ApiAction
          if (response.error) {
            statusFormik.resetForm()
          }
        }}
        inline>
        <SelectItem
          label={<Badge label="Booked" color="success" />}
          value="BOOKED"
          disabled={
            props.receiptRestricted && props.attendee.receipts.length === 0
          }
          tooltip={
            props.receiptRestricted && props.attendee.receipts.length === 0
              ? "Can't mark flights as booked until receipt is submitted"
              : undefined
          }
        />
        <SelectItem
          label={<Badge color="gray" label={"Opted Out"} />}
          value="OPT_OUT"
        />
        <SelectItem
          label={<Badge color="critical" label={"To Book"} />}
          value="PENDING"
        />
      </FormField>
      <FormField
        fullWidth
        onValueChange={(newVal) => {
          costFormik.setFieldValue("cost", newVal)
        }}
        onBlur={() => {
          let response = dispatch(
            patchAttendeeTravel(props.attendee.id, {
              cost: costFormik.values.cost ?? null,
            })
          ) as unknown as ApiAction
          if (response.error) {
            costFormik.resetForm()
          }
        }}
        inline
        value={costFormik.values.cost}
        type="currency"
        label="Travel Costs"
      />
      <AppMultipleFilePicker
        label="Receipts"
        type="RECEIPT"
        files={props.attendee.receipts.map((receipt) => {
          return {
            path: receipt.file_url,
            displayName: splitFileName(receipt.file_url) ?? "unknown",
            id: receipt.id,
          }
        })}
        onDelete={(id: number) => {
          dispatch(deleteReceiptToAttendee(id, props.attendee.id))
        }}
        handleChange={async (file) => {
          let response = (await dispatch(
            postReceiptToAttendee({
              attendee_id: props.attendee.id,
              file_id: file.id,
            })
          )) as unknown as ApiAction
          if (!response.error) {
            dispatch({
              type: "ADD_RECEIPT_TO_ATTENDEE",
              receipt: file,
              attendee_id: props.attendee.id,
            })
          }
        }}
      />
    </FormContainer>
  )
}
