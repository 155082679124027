import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext"
import {
  ChevronDownIcon,
  DesktopIcon,
  Link1Icon,
  TextAlignCenterIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
  TrashIcon,
} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {ButtonGroup} from "@summtech/flok-base/components/ButtonGroup"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Popover} from "@summtech/flok-base/components/Popover"
import {Text} from "@summtech/flok-base/components/Text"
import {Textfield} from "@summtech/flok-base/components/Textfield"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {DecoratorNode, NodeKey} from "lexical"
import {ReactNode} from "react"
import * as yup from "yup"
import {useRetreat} from "../../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../../Stack"
import {titleToNavigation} from "../../../utils"
import {
  useAttendeeLandingPages,
  useAttendeeLandingWebsite,
} from "../../../utils/retreatUtils"

import "../../lexical/EditorCss.css"
import {
  ALIGN_BUTTON_COMMAND,
  ALTER_BUTTON_LINK_COMMAND,
  ALTER_BUTTON_LINK_TYPE_COMMAND,
  ALTER_BUTTON_PAGE_ID_COMMAND,
  ALTER_BUTTON_TEXT_COMMAND,
  DELETE_BUTTON_COMMAND,
} from "../plugins/ButtonPlugin"

export type ButtonLinkType = "PAGE" | "EXTERNAL"
const ButtonLinkTypeToText = {
  PAGE: "Landing Page",
  EXTERNAL: "Custom Link",
}

const ButtonLinkTypeToIcon = {
  EXTERNAL: <Link1Icon />,
  PAGE: <DesktopIcon />,
}
export type SerializedButtonNode = {
  type: string
  link: string
  text: string
  alignment: "left" | "center" | "right"
  page_id?: string
  link_type?: ButtonLinkType
  key: string
  version: number
}
export class ButtonNode extends DecoratorNode<ReactNode> {
  __link: string
  __text: string
  __alignment: "left" | "center" | "right"
  __page_id?: string
  __link_type?: ButtonLinkType
  static getType(): string {
    return "button"
  }

  static clone(node: ButtonNode): ButtonNode {
    return new ButtonNode(
      node.__id,
      node.__link,
      node.__text,
      node.__alignment,
      node.__link_type,
      node.__page_id
    )
  }

  constructor(
    id: string,
    link: string,
    text: string,
    alignment: "left" | "right" | "center",
    link_type?: ButtonLinkType,
    page_id?: string,
    key?: NodeKey
  ) {
    super(key)
    this.__id = id
    this.__link = link
    this.__text = text
    this.__alignment = alignment
    this.__page_id = page_id
    this.__link_type = link_type
  }

  setLink(link: string) {
    const self = this.getWritable()
    self.__link = link
  }
  getLink() {
    const self = this.getLatest()
    return self.__link
  }

  setLinkType(linkType: ButtonLinkType) {
    const self = this.getWritable()
    self.__link_type = linkType
  }
  getLinkType() {
    const self = this.getLatest()
    return self.__link_type
  }

  setPageId(pageId: string) {
    const self = this.getWritable()
    self.__page_id = pageId
  }
  getPageId() {
    const self = this.getLatest()
    return self.__page_id
  }

  setText(text: string) {
    const self = this.getWritable()
    self.__text = text
  }
  getText() {
    const self = this.getLatest()
    return self.__text
  }

  setAlignment(alignment: "left" | "center" | "right") {
    const self = this.getWritable()
    self.__alignment = alignment
  }
  getAlignment() {
    const self = this.getLatest()
    return self.__alignment
  }
  getKey() {
    const self = this.getLatest()
    return self.__key
  }
  createDOM(): HTMLElement {
    return document.createElement("div")
  }

  updateDOM(): false {
    return false
  }
  append(child: ReactNode): void {
    const self = this.getWritable()
    self.setChildren([...self.getChildren(), child])
  }

  decorate(): ReactNode {
    return (
      <ButtonNodeDecorator
        linkType={this.getLinkType()}
        pageId={this.getPageId()}
        link={this.getLink()}
        text={this.getText()}
        alignment={this.getAlignment()}
        nodeKey={this.__key}
      />
    )
  }
  isKeyboardSelectable(): boolean {
    return true
  }
  exportJSON(): SerializedButtonNode {
    return {
      type: "button",
      link: this.getLink(),
      text: this.getText(),
      alignment: this.getAlignment(),
      page_id: this.getPageId(),
      link_type: this.getLinkType(),
      key: this.getKey(),
      version: 1,
    }
  }
  static importJSON(serializedNode: SerializedButtonNode): ButtonNode {
    const node = $createButtonNode("button")
    node.setLink(serializedNode.link)
    node.setText(serializedNode.text)
    node.setAlignment(serializedNode.alignment)
    if (serializedNode.link_type) {
      node.setLinkType(serializedNode.link_type)
    }
    if (serializedNode.page_id) {
      node.setPageId(serializedNode.page_id)
    }
    return node
  }
}

export function $createButtonNode(id: string): ButtonNode {
  return new ButtonNode(id, "", "Button", "left", "PAGE")
}

export function $isButtonNode(node: any): boolean {
  return node instanceof ButtonNode
}

const Divider = styled("div", {
  width: "2px",
  height: "26px",
  border: `1px solid ${theme.colors.gray8}`,
})

const ButtonToolbar = styled("div", {
  width: "fit-content",
  padding: "7px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  border: `1px solid ${theme.colors.gray6}`,
  position: "relative",
  background: theme.colors.gray3,
  borderRadius: `${theme.shape.borderRadius}`,
  gap: "6px",
})
const ButtonWrapper = styled("div", {
  width: "fit-content",
  position: "relative",
  "& input": {
    width: "175px",
  },
  "& button": {
    maxWidth: "175px",
  },
  variants: {
    alignment: {
      left: {
        marginRight: "auto",
      },
      center: {
        marginRight: "auto",
        marginLeft: "auto",
      },
      right: {
        marginLeft: "auto",
      },
    },
  },
})

const FlexBox = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
})

const RedTrash = styled(TrashIcon, {
  color: theme.colors.red10,
})

const StyledA = styled("a", {
  textDecoration: "none",
})

export function ButtonNodeDecorator(props: {
  text: string
  link: string
  nodeKey: string
  alignment: "right" | "center" | "left"
  linkType: ButtonLinkType | undefined
  pageId: string | undefined
}) {
  const [editor] = useLexicalComposerContext()
  const yupObject = yup.object().shape({
    link: yup.string().url(),
  })
  const validate = (value: string) => {
    try {
      yupObject.validateSync({link: value})
      return false
    } catch (e: any) {
      return true
    }
  }
  let [retreat] = useRetreat()
  let [website] = useAttendeeLandingWebsite(retreat.attendees_website_id ?? -1)
  let [pages] = useAttendeeLandingPages(website?.page_ids ?? [])
  return (
    <ButtonWrapper alignment={props.alignment}>
      {editor.isEditable() ? (
        <Popover
          trigger={<Button text={props.text} color="brand" />}
          openDelay={100}
          closeDelay={100000}>
          <ButtonToolbar>
            <Textfield
              value={props.text}
              onChange={(e) => {
                editor.dispatchCommand(ALTER_BUTTON_TEXT_COMMAND, {
                  key: props.nodeKey,
                  text: e.target.value,
                })
              }}
            />
            <Divider />

            <Dropdown
              button={
                <Button
                  startIcon={
                    props.linkType
                      ? ButtonLinkTypeToIcon[props.linkType]
                      : undefined
                  }
                  color="gray"
                  variant={"outline"}
                  text={
                    props.linkType
                      ? ButtonLinkTypeToText[props.linkType]
                      : "Select link type"
                  }
                  endIcon={<ChevronDownIcon />}
                />
              }
              portal>
              <DropdownItem
                onClick={() => {
                  editor.dispatchCommand(ALTER_BUTTON_LINK_TYPE_COMMAND, {
                    key: props.nodeKey,
                    linkType: "EXTERNAL",
                  })
                }}
                text={
                  <FlexBox>
                    {ButtonLinkTypeToIcon["EXTERNAL"]}
                    <Text variant={"text-sm-plus"}>
                      {ButtonLinkTypeToText["EXTERNAL"]}
                    </Text>
                  </FlexBox>
                }
              />
              <DropdownItem
                onClick={() => {
                  editor.dispatchCommand(ALTER_BUTTON_LINK_TYPE_COMMAND, {
                    key: props.nodeKey,
                    linkType: "PAGE",
                  })
                }}
                text={
                  <FlexBox>
                    {ButtonLinkTypeToIcon["PAGE"]}
                    <Text variant={"text-sm-plus"}>
                      {ButtonLinkTypeToText["PAGE"]}
                    </Text>
                  </FlexBox>
                }
              />
            </Dropdown>
            {props.linkType === "EXTERNAL" && (
              <Textfield
                error={!!validate(props.link)}
                value={props.link}
                placeholder="https://example.com"
                onChange={async (e) => {
                  editor.dispatchCommand(ALTER_BUTTON_LINK_COMMAND, {
                    key: props.nodeKey,
                    link: e.target.value,
                  })
                }}
              />
            )}

            {props.linkType === "PAGE" && (
              <Dropdown
                button={
                  <Button
                    color="gray"
                    variant={"outline"}
                    text={
                      props.pageId
                        ? props.pageId === "REGISTRATION"
                          ? "Registration"
                          : pages.find(
                              (page) => page.id.toString() === props.pageId
                            )?.title
                        : "Select page"
                    }
                    endIcon={<ChevronDownIcon />}
                  />
                }>
                {pages.map((page) => {
                  return (
                    <DropdownItem
                      text={page.title}
                      onClick={() => {
                        editor.dispatchCommand(ALTER_BUTTON_PAGE_ID_COMMAND, {
                          key: props.nodeKey,
                          pageId: page.id.toString(),
                        })
                      }}
                    />
                  )
                })}
                <DropdownItem
                  onClick={() => {
                    editor.dispatchCommand(ALTER_BUTTON_PAGE_ID_COMMAND, {
                      key: props.nodeKey,
                      pageId: "REGISTRATION",
                    })
                  }}
                  text={"Registration"}
                />
              </Dropdown>
            )}
            <Divider />
            <ButtonGroup>
              <IconButton
                color={"gray"}
                variant="outline"
                onClick={() => {
                  editor.dispatchCommand(ALIGN_BUTTON_COMMAND, {
                    key: props.nodeKey,
                    alignment: "left",
                  })
                }}>
                <TextAlignLeftIcon />
              </IconButton>
              <IconButton
                color={"gray"}
                variant="outline"
                onClick={() => {
                  editor.dispatchCommand(ALIGN_BUTTON_COMMAND, {
                    key: props.nodeKey,
                    alignment: "center",
                  })
                }}>
                <TextAlignCenterIcon />
              </IconButton>
              <IconButton
                color={"gray"}
                variant="outline"
                onClick={() => {
                  editor.dispatchCommand(ALIGN_BUTTON_COMMAND, {
                    key: props.nodeKey,
                    alignment: "right",
                  })
                }}>
                <TextAlignRightIcon />
              </IconButton>
            </ButtonGroup>
            <IconButton
              color={"gray"}
              variant="outline"
              onClick={() => {
                editor.dispatchCommand(DELETE_BUTTON_COMMAND, {
                  key: props.nodeKey,
                })
              }}>
              <RedTrash />
            </IconButton>
          </ButtonToolbar>
        </Popover>
      ) : props.linkType === "PAGE" && website ? (
        <StyledA
          href={AppRoutes.getPath("AttendeeSitePage", {
            websiteName: titleToNavigation(website.name),
            pageName:
              props.pageId === "REGISTRATION"
                ? "registration"
                : titleToNavigation(
                    pages.find((page) => page.id.toString() === props.pageId)
                      ?.title ?? "home"
                  ),
          })}
          target="landing-page">
          <Button text={props.text} color="brand" />
        </StyledA>
      ) : (
        <StyledA href={props.link} target="landing-page">
          <Button text={props.text} color="brand" />
        </StyledA>
      )}
    </ButtonWrapper>
  )
}
