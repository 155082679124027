import {ComponentProps} from "react"
import {FormQuestionSnapshotModel} from "../../../models/form"
import FormQuestionBase, {QuestionCardBase} from "./FormQuestionBase"

type FormQuestionResponseViewerProps = {
  questionSnapshot: FormQuestionSnapshotModel
  value?: string
}
export default function FormQuestionResponseViewer(
  props: FormQuestionResponseViewerProps &
    Omit<
      ComponentProps<typeof QuestionCardBase>,
      keyof FormQuestionResponseViewerProps | "onChange"
    >
) {
  let {questionSnapshot, value, ...otherProps} = props
  let options = questionSnapshot.select_options_snapshot.map(
    (optionSnapshot) => optionSnapshot.option
  )
  return (
    <FormQuestionBase
      title={questionSnapshot.title}
      description={questionSnapshot.description}
      type={questionSnapshot.type}
      options={options}
      value={value}
      disabled
      required={questionSnapshot.required}
      {...otherProps}
    />
  )
}
