import {Badge} from "@summtech/flok-base/components/Badge"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {push} from "connected-react-router"
import {useDispatch} from "react-redux"
import * as timeago from "timeago.js"
import {ActivityModel, ActivityToDisplay} from "../../models/activity"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../Stack"
import {seeActivity} from "../../store/actions/retreat"
import {useUser} from "../../utils/retreatUtils"
import {useLoggedInUser} from "../../utils/userUtils"
import NotificationItem from "./NotificationItem"

const ActivityItemContainer = styled(DropdownItem, {
  width: "100%",
  variants: {
    seen: {
      true: {},
      false: {
        background: theme.colors.blue2,
        "&:hover": {
          background: theme.colors.blue4,
        },
      },
    },
  },
})

export default function ActivityItem(props: {activity: ActivityModel}) {
  let [activityUser] = useUser(props.activity.user_id)
  let [user] = useLoggedInUser()

  let [, retreatIdx] = useRetreat()
  let dispatch = useDispatch()

  function getOnClick(activity: ActivityModel) {
    switch (activity.type) {
      case "ATTENDEE_PATCH":
      case "ATTENDEE_POST":
      case "ATTENDEE_FLIGHTS_PATCH":
      case "ATTENDEE_FORM_RESPONSE_POST":
      case "ATTENDEE_FLIGHTS_POST":
        return () => {
          dispatch(seeActivity(activity.id))
          dispatch(
            push(
              AppRoutes.getPath("RetreatAttendeePage", {
                retreatIdx: retreatIdx.toString(),
                attendeeId: activity.activity_vars.attendee_id.toString(),
              })
            )
          )
        }
      case "ATTENDEE_LANDING_PAGE_PATCH":
      case "ATTENDEE_LANDING_PAGE_POST":
        return () => {
          dispatch(seeActivity(activity.id))
          dispatch(
            push(
              AppRoutes.getPath("LandingPageGeneratorPage", {
                retreatIdx: retreatIdx.toString(),
                currentPageId: activity.activity_vars.page_id.toString(),
              })
            )
          )
        }
      case "ITINERARY_EVENT_DELETE":
        return () => {
          dispatch(seeActivity(activity.id))
          dispatch(
            push(
              AppRoutes.getPath("ItineraryPage", {
                retreatIdx: retreatIdx.toString(),
              })
            )
          )
        }

      case "ITINERARY_EVENT_PATCH":
      case "ITINERARY_EVENT_POST":
        return () => {
          dispatch(seeActivity(activity.id))
          dispatch(
            push(
              AppRoutes.getPath("ItineraryEventPage", {
                retreatIdx: retreatIdx.toString(),
                eventId: activity.activity_vars.event_id.toString(),
              })
            )
          )
        }
      case "REG_FORM_QUESTION_DELETE":
      case "REG_FORM_QUESTION_PATCH":
      case "REG_FORM_QUESTION_POST":
        return () => {
          dispatch(seeActivity(activity.id))
          dispatch(
            push(
              AppRoutes.getPath("RetreatRegistrationFormBuilderPage", {
                retreatIdx: retreatIdx.toString(),
              })
            )
          )
        }
      case "COMMENT_POST":
        return () => {
          dispatch(seeActivity(activity.id))
          if (activity.site_location === "ITINERARY") {
            if (activity.activity_vars.event_id) {
              dispatch(
                push(
                  AppRoutes.getPath("ItineraryEventPage", {
                    retreatIdx: retreatIdx.toString(),
                    eventId: activity.activity_vars.event_id.toString(),
                  })
                )
              )
            } else {
              dispatch(
                push(
                  AppRoutes.getPath("ItineraryPage", {
                    retreatIdx: retreatIdx.toString(),
                  })
                )
              )
            }
          }
        }
      default:
        return () => {}
    }
  }
  return (
    <ActivityItemContainer
      seen={props.activity.seen}
      onClick={getOnClick(props.activity)}
      text={
        <NotificationItem
          onDone={() => {
            dispatch(seeActivity(props.activity.id))
          }}
          timeAgo={timeago.format(new Date(props.activity.created_at))}
          avatar={
            <Badge
              label={
                activityUser?.first_name ? activityUser?.first_name[0] : "J"
              }
              color="brand"
            />
          }
          title={
            user && activityUser
              ? ActivityToDisplay[props.activity.type](
                  props.activity,
                  user,
                  activityUser
                ).title
              : "Unknown"
          }
          subtitle={
            user && activityUser
              ? ActivityToDisplay[props.activity.type](
                  props.activity,
                  user,
                  activityUser
                ).subtitle
              : "Unknown"
          }
        />
      }></ActivityItemContainer>
  )
}
