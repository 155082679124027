import {
    insertList, INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND
} from "@lexical/list";
import { COMMAND_PRIORITY_LOW, LexicalEditor } from "lexical";

export function useLexicalListCommands(editor: LexicalEditor){
  editor.registerCommand(
    INSERT_UNORDERED_LIST_COMMAND,
    () => {
      insertList(editor, "bullet")
      return true
    },
    COMMAND_PRIORITY_LOW
  )
  editor.registerCommand(
    INSERT_ORDERED_LIST_COMMAND,
    () => {
      insertList(editor, "number")
      return true
    },
    COMMAND_PRIORITY_LOW
  ) 

}