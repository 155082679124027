import {flokAxios} from "."
import {UserModel, UserToRetreatModel} from "../models/user"

export function getUserHome() {
  return flokAxios<{user: UserModel}>({
    url: "/v1.0/user/home",
    method: "GET",
  })
}

export function patchUser(
  userId: number,
  user: Pick<UserModel, "default_retreat_id">
) {
  return flokAxios<{user: UserModel}>({
    url: `/v1.0/users/${userId}`,
    method: "PATCH",
    data: JSON.stringify(user),
  })
}

export function patchUserToRetreat(
  userId: number,
  retreatId: number,
  userToRetreat: Pick<UserToRetreatModel, "archived">
) {
  return flokAxios<{user_to_retreat: UserToRetreatModel}>({
    url: `/v1.0/users/${userId}/retreats/${retreatId}`,
    method: "PATCH",
    data: JSON.stringify(userToRetreat),
  })
}

export function postUser(
  user: Pick<UserModel, "email" | "first_name" | "last_name"> & {
    retreat_id: number
  }
) {
  return flokAxios<{user: UserModel}>({
    url: `/v1.0/users`,
    method: "POST",
    data: JSON.stringify(user),
  })
}
