import {CircularProgress} from "@material-ui/core"
import {Avatar} from "@summtech/flok-base/components/Avatar"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import * as timeago from "timeago.js"
import {AppWysiwygViewer} from "../../components/base/AppWysiwyg"
import {useComment, useUser} from "../../utils/retreatUtils"
import {useLoggedInUser} from "../../utils/userUtils"

const FlexColumn = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  width: "100%",
})
const LoadingBox = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "150px",
})
const Flex = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  width: "100%",
  alignItems: "center",
})

const CommentContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  width: "100%",
  [`${Avatar}`]: {
    height: "25px",
    width: "25px",
  },
})
const GrayText = styled(Text, {
  color: theme.colors.gray11,
})

const ViewerBox = styled("div", {
  color: "$gray11",
  display: "flex",
  width: "100%",
  "& div p:first-child": {
    marginTop: 2,
  },
  "& div p:last-child": {
    marginBottom: 2,
  },
})

export default function Comment(props: {id: number; icon: boolean}) {
  let [comment, commentLoading] = useComment(props.id)
  let [user, userLoading] = useUser(comment?.created_by_user_id ?? -1)
  let [currentUser] = useLoggedInUser()
  let name = `${user?.first_name} ${user?.last_name}`
  let screenName =
    currentUser?.id === comment?.created_by_user_id ?? -1 ? "You" : name
  const CommentBody = comment ? (
    <FlexColumn>
      <Flex>
        <Text variant="text-sm-plus" css={{color: "$gray12"}}>
          {screenName}
        </Text>
        <GrayText variant="text-sm">
          {timeago.format(new Date(comment.created_at))}
        </GrayText>
      </Flex>
      <ViewerBox>
        <AppWysiwygViewer content={comment.comment_text} />
      </ViewerBox>
    </FlexColumn>
  ) : (
    <></>
  )

  if (commentLoading || userLoading) {
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    )
  }
  if (comment) {
    return props.icon ? (
      <CommentContainer>
        <Avatar label={name[0]} round color="blue" /> {CommentBody}
      </CommentContainer>
    ) : (
      <>{CommentBody}</>
    )
  }
  return <></>
}
