import {Button} from "@summtech/flok-base/components/Button"
import {Tooltip} from "@summtech/flok-base/components/Tooltip"
import {styled} from "@summtech/flok-base/stitches.config"
import {Link as ReactRouterLink} from "react-router-dom"

const StyledLink = styled(ReactRouterLink, {
  textDecoration: "none",
})
type AppLinkButtonProps = React.ComponentProps<typeof Button> & {
  to: string
  target?: string
  tooltip?: string
}
export default function AppLinkButton(props: AppLinkButtonProps) {
  let {to, target, ...btnProps} = {...props}
  return props.disabled ? (
    props.tooltip ? (
      <Tooltip text={props.tooltip}>
        <Button {...btnProps} />
      </Tooltip>
    ) : (
      <Button {...btnProps} />
    )
  ) : (
    <StyledLink to={props.to} target={props.target}>
      {props.tooltip ? (
        <Tooltip text={props.tooltip}>
          <Button {...btnProps} />
        </Tooltip>
      ) : (
        <Button {...btnProps} />
      )}
    </StyledLink>
  )
}
