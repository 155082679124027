import {Badge} from "@summtech/flok-base/components/Badge"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {RetreatAttendeeModel, RetreatModel} from "../../models/retreat"
import {useHotelFromId} from "../../utils/lodgingUtils"
import {getRetreatName} from "../../utils/retreatUtils"
import {renderFlightStatusBadge} from "../flights/FlightDetailsTable"

const StyledTable = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  border: `1px solid ${theme.colors.gray6}`,
  borderRadius: theme.shape.borderRadius,
  background: theme.colors.white,
})
const TopRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  borderBottom: `1px solid ${theme.colors.gray6}`,
  padding: "12px 16px",
  gap: "24px",
})
const HotelImg = styled("img", {
  width: "48px",
  height: "48px",
  borderRadius: theme.shape.borderRadius,
  marginLeft: "0.5px",
})

const TextContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
})
const GrayText = styled(Text, {
  color: theme.colors.gray11,
})
const BottomText = styled(GrayText, {
  marginTop: "2px",
})

const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",

  padding: "16px",
  gap: "16px",
  alignItems: "center",
})

const MiddleRow = styled(Row, {
  borderBottom: `1px solid ${theme.colors.gray6}`,
})

const RowHeader = styled(GrayText, {
  width: "96px",
})

const renderTripStatusBadge = (
  val: "CREATED" | "INFO_ENTERED" | "NOT_ATTENDING" | "CANCELLED"
) => {
  if (val === "INFO_ENTERED") {
    return <Badge color="success" label={"Signed Up"} />
  } else if (val === "NOT_ATTENDING" || val === "CANCELLED") {
    return <Badge color="gray" label={"Not Attending"} />
  } else {
    return <Badge color="critical" label={"Pending"} />
  }
}
function dateFormat(date: Date | undefined) {
  if (date === undefined) {
    return ""
  }
  let dateFormatter = Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
    timeZone: "UTC",
  })
  return dateFormatter.format(date)
}
export default function MyTripTable(props: {
  attendee: RetreatAttendeeModel
  retreat: RetreatModel
}) {
  let [hotel] = useHotelFromId(props.retreat.lodging_final_hotel_id ?? -1)

  return (
    <StyledTable>
      {hotel && (
        <TopRow>
          <HotelImg src={hotel?.spotlight_img.image_url} />
          <TextContainer>
            <Text variant="heading-base">{getRetreatName(props.retreat)}</Text>
            <GrayText variant="text-sm">{hotel?.name}</GrayText>
            {props.retreat.lodging_final_start_date &&
              props.retreat.lodging_final_end_date && (
                <BottomText variant="text-sm">
                  {dateFormat(new Date(props.retreat.lodging_final_start_date))}{" "}
                  - {dateFormat(new Date(props.retreat.lodging_final_end_date))}
                </BottomText>
              )}
          </TextContainer>
        </TopRow>
      )}
      <MiddleRow>
        <RowHeader variant="text-sm-plus">Status</RowHeader>
        {renderTripStatusBadge(props.attendee.info_status)}
      </MiddleRow>
      <Row>
        <RowHeader variant="text-sm-plus">Travel</RowHeader>
        {renderFlightStatusBadge(props.attendee.flight_status)}
      </Row>
    </StyledTable>
  )
}
