import {ArrowTopRightIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {convertToRaw} from "draft-js"
import {useFormik} from "formik"
import _ from "lodash"
import {useEffect} from "react"
import {useDispatch} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import SiteFooter from "../../components/attendee-site/website/SiteFooter"
import AppLoadingScreen from "../../components/base/AppLoadingScreen"
import {createEditorState} from "../../components/base/AppWysiwyg"
import BeforeUnload from "../../components/base/BeforeUnload"
import {default as FlokPageBody} from "../../components/page/PageBody"
import {
  AccordionBlockContentModel,
  AttendeeLandingWebsiteBlockModel,
  FormikAttendeeLandingWebsiteBlockModel,
} from "../../models/retreat"
import RedirectPage from "../../pages/misc/RedirectPage"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../Stack"
import {patchBlock} from "../../store/actions/retreat"
import {
  getRetreatName,
  useAttendeeLandingPage,
  useAttendeeLandingPageBlocks,
  useAttendeeLandingWebsite,
} from "../../utils/retreatUtils"
import AppLinkButton from "../app/AppLinkButton"
import {useSidebar} from "../nav/SidebarProvider"
import {PageBodyContainer} from "../sites/AttendeeSite"
import AttendeeSiteBuilderThemeProvider from "../sites/AttendeeSiteBuilderThemeProvider"
import {
  convertContentFromRaw,
  convertFormikToRaw,
} from "../sites/blocks/AccordionBlock"
import CreateAttendeeWebsite from "../sites/CreateAttendeeWebsite"
import {PageBlock} from "../sites/PageBlock"
import SiteSideNav from "../sites/SiteBuilderTabs"
import SiteHeader from "../sites/SiteHeader"

const OverallPage = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100%",
  backgroundColor: theme.colors.white,
})
const PageBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "20px 32px",
  flex: 1,
  height: "100%",
  gap: "24px",
})
const Header = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
})
const HeaderButtons = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "12px",
})

const SitePortal = styled("div", {
  flex: 1,
  width: "100%",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: theme.colors.gray6,
  borderRadius: theme.shape.borderRadius,
  overflow: "auto",
  background: theme.colors.white,
  boxShadow:
    "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 8px 10px -6px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
})
const PortalHeader = styled("div", {
  height: "52px",
  width: "100%",
  borderBottomStyle: "solid",
  borderBottomWidth: "1px",
  borderBottomColor: theme.colors.gray6,
  display: "flex",
  flexDirection: "row",
  padding: "12px 20px",
  alignItems: "center",
  justifyContent: "space-between",
})
const PortalHeaderButtons = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
})
const PortalHeaderBalancer = styled("div", {
  width: "46px",
})
const PortalHeaderUrl = styled("div", {
  width: "400px",
  background: theme.colors.gray3,
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  justifyContent: "center",
  textOverflow: "ellipsis",
  overflow: "hidden",
  padding: "0px 5px",
  [`${Text}`]: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
})
const PortalHeaderButton = styled("div", {
  height: "10px",
  width: "10px",
  borderRadius: "60px",
  borderStyle: "solid",
  borderWidth: "0.5px",
  variants: {
    color: {
      red: {
        background: "#ED6A5E",
        borderColor: "#CF594E",
      },
      yellow: {
        background: "#F6C351",
        borderColor: "#D79F3E",
      },
      green: {
        background: "#61C455",
        borderColor: "#52A63D",
      },
    },
  },
})

const SiteBannerImg = styled("img", {
  width: "100%",
  maxHeight: "325px",
  objectFit: "cover",
})
const SitePortalBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
})

const NoSiteContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  background: theme.colors.white,
  padding: "20px 32px",
  justifyContent: "center",
  alignItems: "center",
})
const NoSiteHeader = styled("div", {
  width: "100%",
  marginBottom: "24px",
})
const NoSiteButtonContainer = styled("div", {
  marginLeft: "auto",
  marginRight: "auto",
})

export default function SiteBuilderPage() {
  let [retreat, retreatIdx] = useRetreat()
  let router = useRouteMatch<{
    retreatIdx: string
    currentPageId: string
  }>()
  let currentPageId = router.params.currentPageId
  let dispatch = useDispatch()

  let [website, loadingWebsite] = useAttendeeLandingWebsite(
    retreat.attendees_website_id ?? -1
  )
  let [page] = useAttendeeLandingPage(parseInt(currentPageId))
  let [blocks] = useAttendeeLandingPageBlocks(page?.block_ids ?? [])
  let blockMap: {[id: number]: AttendeeLandingWebsiteBlockModel} = {}
  blocks.forEach((block) => {
    blockMap[block.id] = block
  })

  let blocksFormikInitialValues: {
    [id: number]: FormikAttendeeLandingWebsiteBlockModel
  } = {}
  if (page) {
    page.block_ids.forEach((id) => {
      blocksFormikInitialValues[id] =
        {} as FormikAttendeeLandingWebsiteBlockModel
      let block = blockMap[id]
      if (block?.type === "WYSIWYG") {
        blocksFormikInitialValues[id] = {
          ...block,
          type: "WYSIWYG",
          content: createEditorState(block.content),
        }
      } else if (block?.type === "ACCORDION") {
        blocksFormikInitialValues[id] = {
          ...block,
          ...convertContentFromRaw(block.content as AccordionBlockContentModel),
          type: "ACCORDION",
        } as unknown as FormikAttendeeLandingWebsiteBlockModel
      } else if (block?.type === "ITINERARY" || block?.type === "FLIGHTS") {
      } else {
        blocksFormikInitialValues[id] = {...block}
      }
    })
  }
  let blocksFormik = useFormik({
    initialValues: blocksFormikInitialValues,
    onSubmit: (values) => {
      Object.entries(values).forEach(([blockId, block]) => {
        if (
          block.type === "WYSIWYG" &&
          isBlockUnsaved(block, blocksFormikInitialValues[parseInt(blockId)]) &&
          block.content
        ) {
          dispatch(
            patchBlock(parseInt(blockId), {
              content: convertToRaw(block.content.getCurrentContent()),
            })
          )
        } else if (
          block.type === "ACCORDION" &&
          blocksFormikInitialValues[parseInt(blockId)] &&
          isBlockUnsaved(block, {
            ...convertContentFromRaw(
              blocksFormikInitialValues[parseInt(blockId)]
                .content as unknown as AccordionBlockContentModel
            ),
            type: "ACCORDION",
          } as unknown as FormikAttendeeLandingWebsiteBlockModel)
        ) {
          dispatch(
            patchBlock(parseInt(blockId), {
              content: convertFormikToRaw(block),
            })
          )
        } else if (
          block.type === "IMAGE" &&
          blocksFormikInitialValues[parseInt(blockId)] &&
          isBlockUnsaved(block, blocksFormikInitialValues[parseInt(blockId)])
        ) {
          dispatch(
            patchBlock(parseInt(blockId), {
              content: block.content,
            })
          )
        }
      })
    },
  })

  function isBlockUnsaved(
    block: FormikAttendeeLandingWebsiteBlockModel,
    initialBlock: FormikAttendeeLandingWebsiteBlockModel
  ) {
    if (
      block &&
      initialBlock &&
      block.type === "WYSIWYG" &&
      initialBlock.type === "WYSIWYG"
    ) {
      if (
        initialBlock?.content &&
        block?.content &&
        block.content.getCurrentContent
      ) {
        return !_.isEqual(
          convertToRaw(block.content.getCurrentContent()),
          convertToRaw(initialBlock.content.getCurrentContent())
        )
      } else return false
    } else if (block && block.type === "ACCORDION") {
      return !_.isEqual(
        convertFormikToRaw(block),
        convertFormikToRaw(initialBlock)
      )
    } else if (block && block.type === "IMAGE") {
      return !_.isEqual(block, initialBlock)
    } else return false
  }
  let unsaved = false
  page?.block_ids.forEach((blockId) => {
    if (
      isBlockUnsaved(
        blocksFormik.values[blockId],
        blocksFormik.initialValues[blockId]
      )
    ) {
      unsaved = true
    }
  })

  let {closeSidebar} = useSidebar()
  useEffect(() => {
    if (retreat.attendees_website_id) {
      closeSidebar()
    }
  }, [])

  if (!website) {
    return (
      <FlokPageBody appBar>
        <NoSiteContainer>
          {loadingWebsite ? (
            <AppLoadingScreen />
          ) : (
            <CreateAttendeeWebsite
              onSuccessfulSubmit={() => {
                closeSidebar()
              }}
            />
          )}
        </NoSiteContainer>
      </FlokPageBody>
    )
  }
  if (!currentPageId) {
    return (
      <RedirectPage
        pageName="LandingPageGeneratorPage"
        pathParams={{
          retreatIdx: retreatIdx.toString(),
          currentPageId: website.page_ids[0].toString(),
        }}
      />
    )
  }
  return (
    <FlokPageBody appBar>
      <BeforeUnload
        when={unsaved}
        message="Are you sure you wish to leave without saving your changes?"
      />
      <OverallPage>
        <SiteSideNav
          defaultTab="pages"
          tabs={["pages", "design", "settings"]}
          pageId={parseInt(currentPageId)}
        />
        <PageBody>
          <Header>
            <Text variant="heading-lg">{getRetreatName(retreat)}</Text>
            <HeaderButtons>
              <AppLinkButton
                tooltip={
                  !page?.published
                    ? "You cannot view an unpublished page"
                    : undefined
                }
                startIcon={<ArrowTopRightIcon />}
                text="View Page"
                color="gray"
                variant="outline"
                disabled={!page?.published}
                target={"__blank"}
                to={AppRoutes.getPath("AttendeeSitePage", {
                  websiteName: website.name,
                  pageName: page?.title ?? "",
                })}
              />

              {/* TODO, figure out saving */}
              <Button
                text="Save Changes"
                color={"brand"}
                disabled={!unsaved}
                onClick={() => {
                  blocksFormik.submitForm()
                }}
              />
            </HeaderButtons>
          </Header>
          {website && (
            <SitePortal>
              <PortalHeader>
                <PortalHeaderButtons>
                  <PortalHeaderButton color="red" />
                  <PortalHeaderButton color="yellow" />
                  <PortalHeaderButton color="green" />
                </PortalHeaderButtons>
                <PortalHeaderUrl>
                  <Text>
                    {
                      new URL(
                        AppRoutes.getPath("AttendeeSitePage", {
                          websiteName: website.name,
                          pageName: page?.title ?? "",
                        }),
                        window.location.origin
                      ).href
                    }
                  </Text>
                </PortalHeaderUrl>
                <PortalHeaderBalancer />
              </PortalHeader>
              <AttendeeSiteBuilderThemeProvider
                websiteId={retreat.attendees_website_id ?? -1}>
                <SitePortalBody
                  style={{background: website?.background_color ?? "#EFF1F7"}}>
                  <SiteHeader
                    retreatIdx={retreatIdx}
                    activePage={page?.title ?? "home"}
                    retreat={retreat}
                    website={website}
                    buildTo={(pageId) => {
                      if (pageId) {
                        return AppRoutes.getPath("LandingPageGeneratorPage", {
                          retreatIdx: retreatIdx.toString(),
                          currentPageId: pageId.toString(),
                        })
                      } else {
                        return AppRoutes.getPath("LandingPageGeneratorHome", {
                          retreatIdx: retreatIdx.toString(),
                        })
                      }
                    }}
                  />
                  {website?.banner_image && (
                    <SiteBannerImg
                      src={website.banner_image.image_url}
                      alt={website.banner_image.alt}
                    />
                  )}
                  {page && (
                    <PageBodyContainer
                      as={"form"}
                      id="my-form"
                      onSubmit={blocksFormik.handleSubmit}>
                      {page.block_ids.map((blockId) => {
                        return (
                          <PageBlock
                            retreat={retreat}
                            editable={true}
                            key={blockId}
                            blockId={blockId}
                            formik={blocksFormik}
                            block={blockMap[blockId]}
                          />
                        )
                      })}
                    </PageBodyContainer>
                  )}

                  <SiteFooter />
                </SitePortalBody>
              </AttendeeSiteBuilderThemeProvider>
            </SitePortal>
          )}
        </PageBody>
      </OverallPage>
    </FlokPageBody>
  )
}
