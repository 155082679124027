import {PlusIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {styled} from "@summtech/flok-base/stitches.config"
import {useMutation, useQueryClient} from "@tanstack/react-query"
import {postFormQuestion} from "../../../api/form"
import AppLoadingScreen from "../../../components/base/AppLoadingScreen"
import {
  FormModel,
  FormQuestionTypeName,
  FormQuestionTypeValues,
} from "../../../models/form"

type AddNewQuestionButtonProps = {
  formId: number
}
const LoadingQuestionContainer = styled("div", {
  width: "100%",
  height: "100px",
  position: "relative",
})
export function FormAddQuestionButton(props: AddNewQuestionButtonProps) {
  let queryClient = useQueryClient()

  const postQuestionMutation = useMutation({
    mutationFn: postFormQuestion,
    onSuccess: (data) => {
      queryClient.setQueryData(["forms-questions", data.form_question.id], data)
      queryClient.setQueryData(
        ["forms", props.formId],
        (oldData?: {form: FormModel}) => {
          if (oldData) {
            return {
              form: {
                ...oldData.form,
                questions: [...oldData.form.questions, data.form_question.id],
              },
            }
          }
        }
      )
    },
  })

  return postQuestionMutation.status === "loading" ? (
    <LoadingQuestionContainer>
      <AppLoadingScreen />
    </LoadingQuestionContainer>
  ) : (
    <>
      <Dropdown
        button={
          <Button text="Add Question" startIcon={<PlusIcon />} color="brand" />
        }>
        {FormQuestionTypeValues.map((type) => (
          <DropdownItem
            text={FormQuestionTypeName[type] ?? type}
            onClick={() =>
              postQuestionMutation.mutate({type, form_id: props.formId})
            }></DropdownItem>
        ))}
      </Dropdown>
    </>
  )
}
