import {flokAxios} from "."
import {
  AttendeeGroupFilterModel,
  AttendeeGroupModel,
  AudienceModel,
  BackendLocalFilterModel,
} from "../models/communication"

export function getAudience(audienceId: number) {
  return flokAxios<{retreat_audience: AudienceModel}>({
    url: `/v1.0/audiences/${audienceId}`,
    method: "GET",
  })
}
export function postAudience(audience: Partial<AudienceModel>) {
  return flokAxios<{retreat_audience: AudienceModel}>({
    url: `/v1.0/audiences`,
    method: "POST",
    data: JSON.stringify(audience),
  })
}

export function patchAudience(
  audienceId: number,
  audience: Partial<AudienceModel>
) {
  return flokAxios<{retreat_audience: AudienceModel}>({
    url: `/v1.0/audiences/${audienceId}`,
    method: "PATCH",
    data: JSON.stringify(audience),
  })
}

export function deleteAudience(audienceId: number) {
  return flokAxios<string>({
    url: `/v1.0/audiences/${audienceId}`,
    method: "DELETE",
  })
}

export function postAudienceToAttendee(audienceId: number, attendeeId: number) {
  return flokAxios<{attendee_group_filter: AttendeeGroupFilterModel}>({
    url: `/v1.0/audiences/attendees`,
    method: "POST",
    data: JSON.stringify({
      audience_id: audienceId,
      attendee_id: attendeeId,
    }),
  })
}

export function deleteAudienceToAttendee(
  audienceId: number,
  attendeeId: number
) {
  return flokAxios<{attendee_group_filter: AttendeeGroupFilterModel}>({
    url: `/v1.0/audiences/${audienceId}/attendees/${attendeeId}`,
    method: "DELETE",
  })
}

export function getAttendeeGroup(attendeeGroupId: number) {
  return flokAxios<{attendee_group: AttendeeGroupModel}>({
    url: `/v1.0/attendee-groups/${attendeeGroupId}`,
    method: "GET",
  })
}
export function postAttendeeGroup(attendeeGroup: Partial<AttendeeGroupModel>) {
  return flokAxios<{attendee_group: AttendeeGroupModel}>({
    url: `/v1.0/attendee-groups`,
    method: "POST",
    data: JSON.stringify(attendeeGroup),
  })
}

export function patchAttendeeGroup(
  groupId: number,
  attendeeGroup: Partial<AttendeeGroupModel>
) {
  return flokAxios<{attendee_group: AttendeeGroupModel}>({
    url: `/v1.0/attendee-groups/${groupId}`,
    method: "PATCH",
    data: JSON.stringify(attendeeGroup),
  })
}

export function deleteAttendeeGroup(groupId: number) {
  return flokAxios<string>({
    url: `/v1.0/attendee-groups/${groupId}`,
    method: "DELETE",
  })
}

export function getAttendeeGroupFilter(filterId: number) {
  return flokAxios<{attendee_group_filter: AttendeeGroupFilterModel}>({
    url: `/v1.0/attendee-group-filters/${filterId}`,
    method: "GET",
  })
}

export function postAttendeeGroupFilter(
  attendeeGroupFilter: Partial<AttendeeGroupFilterModel>
) {
  return flokAxios<{attendee_group_filter: AttendeeGroupFilterModel}>({
    url: `/v1.0/attendee-group-filters`,
    method: "POST",
    data: JSON.stringify(attendeeGroupFilter),
  })
}

export function patchAttendeeGroupFilter(
  filterId: number,
  attendeeGroupFilter: Partial<AttendeeGroupFilterModel>
) {
  return flokAxios<{attendee_group_filter: AttendeeGroupFilterModel}>({
    url: `/v1.0/attendee-group-filters/${filterId}`,
    method: "PATCH",
    data: JSON.stringify(attendeeGroupFilter),
  })
}

export function deleteAttendeeGroupFilter(groupFilterId: number) {
  return flokAxios<string>({
    url: `/v1.0/attendee-group-filters/${groupFilterId}`,
    method: "DELETE",
  })
}

export function getAttendeesFromFilters(
  filters: BackendLocalFilterModel[][],
  retreatId: number
) {
  return flokAxios<{attendee_ids: number[]}>({
    url: `/v1.0/filters/get-attendees`,
    method: "POST",
    data: JSON.stringify({filters: filters, retreat_id: retreatId}),
  })
}
