import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {useFormik} from "formik"
import {useState} from "react"
import {useDispatch} from "react-redux"
import {useRetreat} from "../../pages/misc/RetreatProvider"
import {postItinerary} from "../../store/actions/retreat"

const StyledForm = styled("form", {
  display: "flex",
  flexDirection: "column",
  width: "320px",
})

const StyledImage = styled("img", {
  width: "100%",
  marginBottom: "32px",
})
const StyledHeader = styled(Text, {
  marginBottom: "12px",
})
const StyledSubHeader = styled(Text, {
  marginBottom: "20px",
  color: theme.colors.gray11,
})
const StyledButton = styled(Button, {
  marginTop: "32px",
  marginLeft: "auto",
  marginRight: "auto",
})
export default function CreateItinerary() {
  let dispatch = useDispatch()
  let [retreat] = useRetreat()
  let [loadingCreateItinerary, setLoadingCreateItinerary] = useState(false)
  let formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      async function postNewItinerary() {
        setLoadingCreateItinerary(true)
        await dispatch(postItinerary({number_of_days: 4}, retreat.id))
        setLoadingCreateItinerary(false)
      }
      postNewItinerary()
    },
  })
  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <StyledImage src="https://flok-b32d43c.s3.amazonaws.com/CreateItinerary.png" />
      <StyledHeader variant="heading-lg">Create Itinerary</StyledHeader>
      <StyledSubHeader>
        The itinerary is where you can create schedule and plan activities to do
        during the retreat
      </StyledSubHeader>
      <StyledButton
        text="Create Itinerary"
        color="brand"
        type="submit"
        disabled={loadingCreateItinerary}
      />
    </StyledForm>
  )
}
