import * as RadixCheckbox from "@radix-ui/react-checkbox"
import {CaretDownIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Checkbox} from "@summtech/flok-base/components/Checkbox"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {styled, theme} from "@summtech/flok-base/stitches.config"

let StyledDropDownOptions = styled("div", {
  paddingInline: "2px",
  paddingBlock: "4px",
})
let StyledGroupContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingBlock: "5px",
  gap: "10px",

  borderBottom: `1px solid ${theme.colors.gray6}`,
})

let StyledChildContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "10px",
  paddingBlock: "5px",
  marginLeft: "10px",
})

type Column = {
  label: string
  value: string
  disabled?: boolean
}

type ColumnGroup = {
  label: string
  options: Column[]
}
type AttendeesColumnOptionsProps = {
  values: string[]
  onChange: (newVals: string[]) => void
  columnOptions: ColumnGroup[]
}
function AttendeesColumnOptions(props: AttendeesColumnOptionsProps) {
  let {values, onChange, columnOptions} = props

  function handleColumnCheckedChange(value: string) {
    values.includes(value)
      ? onChange([...values.filter((item) => item !== value)])
      : onChange([...values, value])
  }
  function handleGroupCheckedChange(
    newValues: string[],
    checked: RadixCheckbox.CheckedState
  ) {
    checked === true || checked === "indeterminate"
      ? onChange([...values.filter((item) => !newValues.includes(item))])
      : newValues.some((val) => values.includes(val))
      ? onChange([...values.filter((item) => newValues.includes(item))])
      : onChange([...values.concat(newValues)])
  }

  return (
    <Dropdown
      portal
      button={
        <Button
          text={"Columns"}
          variant={"outline"}
          color={"gray"}
          endIcon={<CaretDownIcon />}
        />
      }>
      <StyledDropDownOptions>
        {columnOptions.map((columnGroup) => {
          let innerOptions = columnGroup.options?.map((option) => option.value)
          let isChecked: RadixCheckbox.CheckedState = innerOptions.every((v) =>
            values.includes(v)
          )
            ? true
            : innerOptions.some((val) => values.includes(val))
            ? "indeterminate"
            : false
          return (
            <StyledGroupContainer>
              <Checkbox
                label={columnGroup.label}
                checked={isChecked}
                onCheckedChange={() =>
                  handleGroupCheckedChange(innerOptions, isChecked)
                }
              />
              <StyledChildContainer>
                {columnGroup.options &&
                  columnGroup.options.map((column) => {
                    return (
                      <Checkbox
                        label={column.label}
                        checked={values.includes(column.value)}
                        onCheckedChange={() =>
                          handleColumnCheckedChange(column.value)
                        }
                      />
                    )
                  })}
              </StyledChildContainer>
            </StyledGroupContainer>
          )
        })}
      </StyledDropDownOptions>
    </Dropdown>
  )
}

export default AttendeesColumnOptions
