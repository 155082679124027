import {FormField} from "@summtech/flok-base/components/FormField"
import {styled} from "@summtech/flok-base/stitches.config"
import {ComponentProps} from "react"
import {FormQuestionType, FormQuestionTypeEnum} from "../../../models/form"
import {SwitchCases} from "../../app/SwitchCases"

export const QuestionCardBase = styled("div", {
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "16px 20px",
  gap: "16px",
  background: "$white",
  border: "1px solid $gray6",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
  borderRadius: "6px",
  variants: {
    error: {
      true: {
        borderColor: "$red11",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    noBorder: {
      true: {
        border: "unset",
        boxShadow: "unset",
      },
    },
    noPadding: {
      true: {
        padding: "unset",
      },
    },
  },
})

type FormQuestionBaseProps = {
  title: string
  description?: string
  required?: boolean
  type: FormQuestionType
  value?: string
  onChange?: (newVal: string) => void
  options?: string[]
  disabled?: true
  errorMessage?: string
  hintPrefix?: string // will cover case in FormQuestionBuilder where hint should read: 2 rules * Required
  displayOptional?: boolean // will show both "Optional" and "Required" hints (default is only show: "Required")
  minDate?: string // for date fields
  maxDate?: string // for date fields
}
export default function FormQuestionBase(
  props: FormQuestionBaseProps &
    Omit<ComponentProps<typeof QuestionCardBase>, keyof FormQuestionBaseProps>
) {
  let {
    title,
    description,
    type,
    required,
    value,
    onChange,
    options,
    disabled,
    errorMessage,
    ...otherProps
  } = props

  // Build the hint for the formfield
  let hintRequired = props.required
    ? "Required"
    : props.displayOptional
    ? "Optional"
    : ""
  let hint = `${props.hintPrefix ? props.hintPrefix : ""}${
    hintRequired && props.hintPrefix ? " · " : ""
  }${hintRequired}`

  const formFieldProps = {
    fullWidth: true,
    label: title ?? "",
    hint: hint,
    tooltip: description || undefined,
    disabled: disabled,
    errorMessage,
  }

  const dateTimeFieldProps = {
    ...formFieldProps,
    minDate: props.minDate,
    maxDate: props.maxDate,
  }
  let optionsField = (options ?? []).map((option) => ({
    label: option,
    value: option,
  }))
  const optionFormFieldProps = {
    ...formFieldProps,
    options: optionsField,
  }
  return (
    <QuestionCardBase {...otherProps} error={!!errorMessage}>
      <SwitchCases value={type}>
        <SwitchCases.Case value={FormQuestionTypeEnum.DATE}>
          <FormField
            {...dateTimeFieldProps}
            type="datetime"
            value={value}
            onChange={props.onChange}
          />
        </SwitchCases.Case>
        <SwitchCases.Case value={FormQuestionTypeEnum.DATETIME}>
          <FormField
            {...dateTimeFieldProps}
            type="datetime"
            time
            value={value}
            onChange={props.onChange}
          />
        </SwitchCases.Case>
        <SwitchCases.Case value={FormQuestionTypeEnum.LONG_ANSWER}>
          <FormField
            {...formFieldProps}
            type="textarea"
            label={title}
            value={value}
            onChange={(e) => {
              if (onChange) onChange(e.target.value)
            }}
          />
        </SwitchCases.Case>
        <SwitchCases.Case value={FormQuestionTypeEnum.SHORT_ANSWER}>
          <FormField
            {...formFieldProps}
            type="textfield"
            value={value}
            onChange={(e) => {
              if (onChange) onChange(e.target.value)
            }}
          />
        </SwitchCases.Case>
        <SwitchCases.Case value={FormQuestionTypeEnum.SINGLE_SELECT}>
          <FormField
            {...optionFormFieldProps}
            type="radio"
            value={value}
            onValueChange={onChange}
          />
        </SwitchCases.Case>
        <SwitchCases.Case value={FormQuestionTypeEnum.MULTI_SELECT}>
          <FormField
            {...optionFormFieldProps}
            type="checkbox"
            value={value ? value.split(",") : []}
            onChange={(newVals) => {
              if (onChange) onChange(newVals.join(","))
            }}
          />
        </SwitchCases.Case>
      </SwitchCases>
    </QuestionCardBase>
  )
}
