import {
  CaretDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DotsVerticalIcon,
  DownloadIcon,
  MagicWandIcon,
  Pencil2Icon,
  Share2Icon,
  TrashIcon,
} from "@radix-ui/react-icons"
import {Badge} from "@summtech/flok-base/components/Badge"
import {Button} from "@summtech/flok-base/components/Button"
import {ButtonGroup} from "@summtech/flok-base/components/ButtonGroup"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import SearchBar from "@summtech/flok-base/components/SearchBar"
import {Text} from "@summtech/flok-base/components/Text"
import {css, styled, theme} from "@summtech/flok-base/stitches.config"
import {useQueries, useQuery, useQueryClient} from "@tanstack/react-query"
import {Row, RowData, Table, VisibilityState} from "@tanstack/react-table"
import {push} from "connected-react-router"
import _ from "lodash"
import {useEffect, useRef, useState} from "react"
import {CSVLink} from "react-csv"
import {useDispatch, useSelector} from "react-redux"
import {useRouteMatch} from "react-router-dom"
import {getColumnAnswer, getColumnAnswers} from "../../../api/attendee"
import {
  DefaultFilterModel,
  LocalFilterModel,
} from "../../../models/communication"
import {
  ColumnAnswerModel,
  RetreatAttendeeModel,
  RetreatColumnModel,
} from "../../../models/retreat"
import {enqueueSnackbar} from "../../../notistack-lib/actions"
import {useRetreat} from "../../../pages/misc/RetreatProvider"
import {AppRoutes} from "../../../Stack"
import {RootState} from "../../../store"
import {ApiAction} from "../../../store/actions/api"
import {
  deleteAttendeeColumn,
  deleteAttendeeGroup,
  deleteAttendeeGroupFilter,
  deleteAttendeeReport,
  patchAttendeeGroup,
  patchAttendeeGroupFilter,
  patchAttendeeReport,
  postAttendeeGroup,
  postAttendeeGroupFilter,
  postRetreatAudience,
} from "../../../store/actions/retreat"
import {getDatetimeString} from "../../../utils"
import {
  tanStackFilterRowsFn,
  useAttendeesTable,
} from "../../../utils/attendeeUtils"
import {
  useAttendeeGroupFilters,
  useAttendeeGroups,
  useRetreatAudience,
  useRetreatAudiences,
} from "../../../utils/retreatUtils"
import {AudienceFilterBuilder} from "../../communications/AudienceModal"
import AppModal from "../AppModal"
import ConfirmationModal from "../ConfirmationModal"
import AttendeeColumnForm from "./AttendeeColumnForm"
import AttendeeReportModal from "./AttendeeReportModal"
import AttendeesColumnOptions from "./AttendeesColumnOptions"
import ManageAttendeesTable from "./ManageAttendeesTable"
import ShareAttendeeReportModal from "./ShareAttendeeReportModal"

const DeleteItem = styled(DropdownItem, {
  color: theme.colors.red10,
  borderTop: `1px solid ${theme.colors.gray6}`,
})
const VerticalBar = styled("div", {
  width: "1px",
  height: "24px",
  background: theme.colors.gray6,
})

export function getTableFilteredRows(table: Table<any>) {
  return () => {
    const rowModel = table.getPreFilteredRowModel()
    let masterSetRows: Set<Row<any>> = new Set()
    let masterSetFlatRows: Set<Row<any>> = new Set()
    let masterRowsById: {[id: number]: Row<any>} = {}

    if (
      !table.options.meta?.columnFilters ||
      table.options.meta.columnFilters.length === 0
    ) {
      return tanStackFilterRowsFn(
        table,
        rowModel,
        [],
        table.getState().globalFilter
      )
    }

    table.options.meta!.columnFilters.forEach((groupFilters: any) => {
      let currentRowModel = tanStackFilterRowsFn(
        table,
        rowModel,
        groupFilters.map((filter: LocalFilterModel) => {
          return {
            ...filter,
            id: filter.field,
            value:
              filter.operator_type === "arrIncludesAll"
                ? filter.value
                : filter.value[0],
          }
        }),
        table.getState().globalFilter
      )
      currentRowModel.rows.forEach((row) => masterSetRows.add(row))
      currentRowModel.flatRows.forEach((row) => masterSetFlatRows.add(row))
      masterRowsById = {...masterRowsById, ...currentRowModel.rowsById}
    })
    let newRowModel = {
      rows: Array.from(masterSetRows),
      flatRows: Array.from(masterSetFlatRows),
      rowsById: masterRowsById,
    }
    return newRowModel
  }
}

declare module "@tanstack/table-core" {
  interface TableMeta<TData extends RowData> {
    columnFilters: (DefaultFilterModel & {id?: number})[][]
  }
}
export type AttendeesTableProps = {
  attendees: RetreatAttendeeModel[]
  activeView?: "all-attendees" | "form-responses" | "flights" | string
  getAttendeeRedirect: (attendeeId: number) => string
  addReportOpen: boolean
  onCloseAddReport: () => void
  setActiveView: (view: string) => void
}

export function getRowLabelForCsv(value: string, key: string) {
  if (key === "info_status") {
    switch (value) {
      case "CREATED":
        return "Not registered"
      case "INFO_ENTERED":
        return "Registered"
      case "NOT_ATTENDING":
        return "Declined"
      case "CANCELLED":
        return "Cancelled"
      default:
        return value
    }
  } else if (key === "flight_status") {
    switch (value) {
      case "OPT_OUT":
        return "Opted Out"
      case "BOOKED":
        return "Booked"
      case "PENDING":
        return "Pending"
      default:
        return value
    }
  } else if (key === "flight_departure_type" || key === "flight_arrival_type") {
    switch (value) {
      case "TRAIN":
        return "Train"
      case "FLIGHT":
        return "Flight"
      default:
        return value
    }
  } else if (key === "registration_form_response_created_at") {
    return getDatetimeString(value, true)
  } else {
    return value
  }
}
export function AttendeesTable(props: AttendeesTableProps) {
  // TO CLEANUP
  let dispatch = useDispatch()
  let [retreat, retreatIdx] = useRetreat()
  let queryClient = useQueryClient()
  let columnAnswersQuery = useQuery({
    queryKey: ["column-answers", {retreatId: retreat.id}],
    queryFn: () => getColumnAnswers(retreat.id),
    onSuccess: (data) => {
      data.column_answers.forEach((columnAnswer) => {
        queryClient.setQueryData(["column-answers", columnAnswer.id], {
          column_answer: columnAnswer,
        })
      })
    },
  })

  let columnAnswersQueries = useQueries({
    queries: props.attendees
      .flatMap((attendee) =>
        Object.values(attendee.column_id_to_answer_id ?? {})
      )
      .map((answerId) => ({
        enabled: columnAnswersQuery.isSuccess,
        queryKey: ["column-answers", answerId],
        queryFn: () => getColumnAnswer(answerId),
      })),
  })

  let columnAnswerMap = columnAnswersQueries
    .filter((query) => query.isSuccess)
    .reduce((prev, query) => {
      return {
        ...prev,
        [query.data!.column_answer.id]: query.data!.column_answer,
      }
    }, {} as {[id: number]: ColumnAnswerModel})

  let columns = useSelector((state: RootState) => {
    return Object.values(state.retreat.attendeeColumns).filter((column) => {
      if (column && retreat.column_ids.indexOf(column.id) !== -1) {
        return true
      } else {
        return false
      }
    }) as RetreatColumnModel[]
  })

  let attendeeReports = useSelector((state: RootState) => {
    return state.retreat.attendeeReports
  })
  let [confirmDeleteColumnOpen, setConfirmDeleteColumnOpen] = useState<
    false | number
  >(false)

  let [editingReportId, setEditingReportId] = useState<number | undefined>(
    undefined
  )

  let [tableVisibilityState, setTableVisibilityState] =
    useState<VisibilityState>({
      first_name: true,
      last_name: true,
      email_address: true,
      info_status: true,
      dietary_prefs: false,
      notes: false,
      flight_status: false,
      flight_arrival_time: false,
      flight_arrival_airport: false,
      flight_arrival_number: false,
      flight_arrival_type: false,
      flight_arrival_airline: false,
      flight_departure_time: false,
      flight_departure_airport: false,
      flight_departure_number: false,
      flight_departure_type: false,
      flight_departure_airline: false,
      registration_form_response_created_at: false,
      ...(columns.length > 0
        ? columns.reduce((prev, column) => {
            return {...prev, [column.id]: false}
          }, {})
        : {}),
    })

  let [audience] = useRetreatAudience(
    attendeeReports[parseInt(props.activeView ?? "-1")]?.audience_id ?? -1
  )
  let [attendeeGroups, attendeeGroupsLoading] = useAttendeeGroups(
    audience?.attendee_group_ids ?? []
  )
  let [allFilters, allFiltersLoading] = useAttendeeGroupFilters(
    attendeeGroups.flatMap((group) => group.filter_ids)
  )
  let [localFilterState, setLocalFilterState] = useState<LocalFilterModel[][]>(
    []
  )
  let filters = attendeeGroups.map((group) => {
    let normalForGroup = group.filter_ids.map((id) => {
      let filter = allFilters.find((fil) => fil.id === id)
      return {
        ...filter,
        field: (filter?.column_id ?? -1).toString(),
        attendee_group_id: filter?.attendee_group_id,
      } as LocalFilterModel
    })
    let defaultForGroup = group.default_filters.map((filter) => {
      return {...filter, attendee_group_id: group.id}
    })
    return normalForGroup.concat(defaultForGroup)
  })

  useEffect(() => {
    setLocalFilterState(filters)
  }, [attendeeGroupsLoading, allFiltersLoading, props.activeView])

  // preload all report filters
  let [allAudiences] = useRetreatAudiences(
    retreat.attendee_reports
      .filter((report) => report.audience_id)
      .map((report) => {
        return report.audience_id as number
      })
  )
  let [allAttendeeGroups] = useAttendeeGroups(
    allAudiences.flatMap((audience) => {
      return audience?.attendee_group_ids ?? []
    })
  )
  useAttendeeGroupFilters(
    allAttendeeGroups.flatMap((group) => group.filter_ids)
  )

  let [searchString, setSearchString] = useState("")

  const table = useAttendeesTable({
    attendees: props.attendees,
    columnFilters: localFilterState.map((groupFilterState) => {
      return groupFilterState.filter((filter) => filter.field && filter.value)
    }),
    columnVisibility: tableVisibilityState,
    globalFilter: searchString,
  })

  // NEED A BETTER METHOD THAN THESE TWO, IT USES TOO MUCH RESOURCES
  let rowsToExport = table.getPrePaginationRowModel().rows.map((row) => {
    let attendeeInfo: any = {}
    row
      .getVisibleCells()
      .forEach(
        (cell) =>
          (attendeeInfo[cell.column.id] = getRowLabelForCsv(
            cell.getContext().getValue() as string,
            cell.column.id
          ) as string)
      )
    return attendeeInfo
  })

  let headersToExport = table.getHeaderGroups().map((headerGroup) => {
    return headerGroup.headers
      .filter((header) => header.depth === 1 && header.column.getIsVisible())
      .map((header) => {
        let headerObject = {
          label: header.column.columnDef.header as string,
          key: header.id,
        }
        return headerObject
      })
  })[0]
  let router = useRouteMatch<{
    retreatIdx: string
    columnId?: string
  }>()
  let columnId = router.params.columnId
  let [addColumnOpen, setAddColumnOpen] = useState(false)
  let columnFormOpen = !!columnId || addColumnOpen
  let [filtersOpen, setFiltersOpen] = useState(false)

  const resetTable = () => {
    function defaultCol(col: string) {
      return [
        "first_name",
        "last_name",
        "email_address",
        "info_status",
      ].includes(col)
    }
    function roomingCol(col: string) {
      return (
        defaultCol(col) || ["hotel_check_in", "hotel_check_out"].includes(col)
      )
    }
    function registrationCol(col: string) {
      return defaultCol(col) || !isNaN(parseInt(col))
    }
    function flightsCol(col: string) {
      return defaultCol(col) || col.startsWith("flight_")
    }
    let fn =
      props.activeView === "form-responses"
        ? registrationCol
        : props.activeView === "flights"
        ? flightsCol
        : roomingCol

    setTableVisibilityState(
      // @ts-ignore
      table.getAllLeafColumns().reduce<{
        [key: string]: boolean
      }>((prev, col) => {
        return {
          ...prev,
          [col.id]: fn(col.id),
        }
      }, {})
    )
    if (
      props.activeView !== "all-attendees" &&
      props.activeView !== "flights" &&
      props.activeView !== "form-responses" &&
      props.activeView
    ) {
      let report = attendeeReports[parseInt(props.activeView)]
      if (report) {
        setTableVisibilityState(
          // @ts-ignore
          table.getAllLeafColumns().reduce<{
            [key: string]: boolean
          }>((prev, col) => {
            return {
              ...prev,
              [col.id]: report!.column_visibility[col.id] ?? false,
            }
          }, {})
        )

        if (report.column_sort) {
          table.setSorting(report.column_sort)
        }
      }
    } else {
      table.setSorting([])
    }
  }
  useEffect(resetTable, [props.activeView])
  let [shareReportOpen, setShareReportOpen] = useState(false)
  const isDefaultView =
    props.activeView === "all-attendees" ||
    props.activeView === "form-responses" ||
    props.activeView === "flights"

  async function createReportAudience() {
    let response = (await dispatch(
      postRetreatAudience(retreat.id, "FILTERS")
    )) as unknown as ApiAction
    if (!response.error) {
      await dispatch(
        patchAttendeeReport(
          {
            audience_id: response.payload.retreat_audience.id,
          },
          parseInt(props.activeView ?? "-1")
        )
      )
    }
    return response
  }

  async function createOrReturnAudienceId() {
    let audienceId = audience?.id
    if (!audience) {
      let response = await createReportAudience()
      if (!response.error) {
        audienceId = response.payload.retreat_audience.id
      }
    }
    return audienceId as number
  }

  async function createOrReturnGroupId(
    audienceId: number,
    group: LocalFilterModel[],
    index: number
  ) {
    let groupId = group[0]?.attendee_group_id
    let defaultFilters = group.filter((filter) => {
      return isNaN(parseInt(filter.field))
    })
    if (!groupId) {
      let response = (await dispatch(
        postAttendeeGroup({
          audience_id: audienceId,
          default_filters: defaultFilters,
        })
      )) as unknown as ApiAction
      if (!response.error) {
        let localFilterStateCopy = [...localFilterState]
        localFilterStateCopy[index] = localFilterStateCopy[index].map(
          (filter) => {
            if (isNaN(parseInt(filter.field))) {
              return {
                ...filter,
                attendee_group_id: response.payload.attendee_group.id,
              }
            } else {
              return filter
            }
          }
        )
        setLocalFilterState(localFilterStateCopy)
        groupId = response.payload.attendee_group.id
      }
    } else {
      await dispatch(
        patchAttendeeGroup(groupId, {
          default_filters: defaultFilters,
        })
      )
    }
    return groupId as number
  }

  async function postOrPatchFilter(filter: LocalFilterModel, groupId: number) {
    if (filter.id) {
      dispatch(
        patchAttendeeGroupFilter(filter.id, {
          column_id: parseInt(filter.field),
          value: filter.value,
          operator_type: filter.operator_type,
        })
      )
    } else {
      dispatch(
        postAttendeeGroupFilter({
          column_id: parseInt(filter.field),
          value: filter.value,
          operator_type: filter.operator_type,
          attendee_group_id: groupId,
        })
      )
    }
  }

  let activeReport =
    props.activeView && attendeeReports[parseInt(props.activeView)]

  let searchBarRef = useRef<HTMLInputElement>(null)

  let exportRef = useRef<
    CSVLink & HTMLAnchorElement & {link: HTMLAnchorElement}
  >(null)
  return (
    <StyledTableContainer>
      {activeReport && !isDefaultView && (
        <ShareAttendeeReportModal
          reportGuid={activeReport.guid}
          open={shareReportOpen}
          onClose={() => {
            setShareReportOpen(false)
          }}
        />
      )}
      <StyledTableOptionsContainer>
        <StyledSearchBar
          inputRef={searchBarRef}
          placeholder="Search by name or email"
          onChange={_.debounce(
            async (e) => setSearchString(e.target.value),
            500
          )}
        />
        <StyledButtonsContainer>
          <Button
            text={"Filter"}
            onClick={() => {
              setFiltersOpen(true)
            }}
            variant={"outline"}
            color={"gray"}
            startIcon={<MagicWandIcon />}
            endIcon={
              localFilterState.length > 0 ? (
                <Badge
                  color="brand"
                  label={localFilterState.length.toString()}
                />
              ) : undefined
            }
          />
          <AppModal
            fullWidth
            open={filtersOpen}
            onClose={() => {
              setFiltersOpen(false)
            }}>
            <AppModal.Header>Filters</AppModal.Header>
            <AppModal.Body>
              <AudienceFilterBuilder
                filterState={localFilterState}
                onChangeFilterState={setLocalFilterState}
              />
            </AppModal.Body>
            <AppModal.Footer>
              <Button
                text="Done"
                color="brand"
                variant="solid"
                onClick={() => setFiltersOpen(false)}
              />
            </AppModal.Footer>
          </AppModal>
          <ConfirmationModal
            onClose={() => {
              setConfirmDeleteColumnOpen(false)
            }}
            title={"Are you sure you wish to delete this column?"}
            open={confirmDeleteColumnOpen !== false}
            subtitle={"This action cannot be undone."}
            onConfirm={async () => {
              let response = (await dispatch(
                deleteAttendeeColumn(
                  confirmDeleteColumnOpen as number,
                  retreat.id
                )
              )) as unknown as ApiAction
              if (!response.error) {
                setConfirmDeleteColumnOpen(false)
              }
            }}
            confirmText={"Delete"}
          />
          <AttendeeReportModal
            open={props.addReportOpen || editingReportId !== undefined}
            onClose={
              editingReportId
                ? () => {
                    setEditingReportId(undefined)
                  }
                : props.onCloseAddReport
            }
            onPost={props.onCloseAddReport}
            retreatId={retreat.id}
            reportId={editingReportId}
            onPatch={() => {
              setEditingReportId(undefined)
            }}
          />
          <CSVLink
            filename={"attendees-export.csv"}
            ref={exportRef}
            style={{display: "none"}}
            data={rowsToExport}
            headers={headersToExport}
          />
          <AttendeesColumnOptions
            values={[
              ...table
                .getVisibleLeafColumns()
                .filter((header) => header.getIsVisible())
                .map((header) => header.id),
              ...(table
                .getVisibleLeafColumns()
                .filter((col) => col.id.startsWith("flight_arrival_")).length >
              0
                ? ["flight_arrival"]
                : []),
              ...(table
                .getVisibleLeafColumns()
                .filter((col) => col.id.startsWith("flight_departure_"))
                .length > 0
                ? ["flight_departure"]
                : []),
            ]}
            onChange={(colIds) => {
              table.setColumnVisibility((oldVisibilityState) => {
                let columnVisibility: Record<string, boolean> = table
                  .getAllLeafColumns()
                  .reduce<{
                    [key: string]: boolean
                  }>((prev, col) => {
                    return {
                      ...prev,
                      [col.id]: colIds.includes(col.id) ? true : false,
                    }
                  }, {})

                // Custom grouped
                if (colIds.includes("flight_arrival")) {
                  columnVisibility = {
                    ...columnVisibility,
                    flight_arrival_type: true,
                    flight_arrival_time: true,
                    flight_arrival_airport: true,
                    flight_arrival_number: true,
                    flight_arrival_airline: true,
                  }
                } else {
                  columnVisibility = {
                    ...columnVisibility,
                    flight_arrival_type: false,
                    flight_arrival_time: false,
                    flight_arrival_airport: false,
                    flight_arrival_number: false,
                    flight_arrival_airline: false,
                  }
                }
                if (colIds.includes("flight_departure")) {
                  columnVisibility = {
                    ...columnVisibility,
                    flight_departure_type: true,
                    flight_departure_time: true,
                    flight_departure_airport: true,
                    flight_departure_number: true,
                    flight_departure_airline: true,
                  }
                } else {
                  columnVisibility = {
                    ...columnVisibility,
                    flight_departure_type: false,
                    flight_departure_time: false,
                    flight_departure_airport: false,
                    flight_departure_number: false,
                    flight_departure_airline: false,
                  }
                }
                // @ts-ignore
                setTableVisibilityState(columnVisibility)
                return columnVisibility
              })
            }}
            columnOptions={[
              {
                label: "Attendee Profile",
                options: [
                  {label: "First Name", value: "first_name"},
                  {label: "Last Name", value: "last_name"},
                  {label: "Email", value: "email_address"},
                  {label: "Status", value: "info_status"},
                  {label: "Hotel Check In", value: "hotel_check_in"},
                  {label: "Hotel Check Out", value: "hotel_check_out"},
                  {label: "Dietary Preferences", value: "dietary_prefs"},
                  {label: "Notes", value: "notes"},
                  {
                    label: "Registered At",
                    value: "registration_form_response_created_at",
                  },
                ],
              },
              {
                label: "Travel",
                options: [
                  {label: "Travel Status", value: "flight_status"},
                  {label: "Travel Cost", value: "flight_cost"},
                  {
                    label: "Arrival Trip",
                    value: "flight_arrival",
                  },
                  {
                    label: "Departure Trip",
                    value: "flight_departure",
                  },
                ],
              },
              ...(table
                .getAllColumns()
                .filter((col) => !isNaN(parseInt(col.id))).length
                ? [
                    {
                      label: "Form Responses",
                      options: table
                        .getAllColumns()
                        .filter(
                          (col) =>
                            !isNaN(parseInt(col.id)) &&
                            typeof col.columnDef.header === "string"
                        )
                        .map((col) => ({
                          label: col.columnDef.header! as string,
                          value: col.id,
                        })),
                    },
                  ]
                : []),
            ]}
          />

          <AttendeeColumnForm
            open={columnFormOpen}
            onClose={() => {
              setAddColumnOpen(false)
              dispatch(
                push(
                  AppRoutes.getPath("ReportAttendeesPage", {
                    retreatIdx: retreatIdx.toString(),
                  })
                )
              )
            }}
            columnId={columnId ? parseInt(columnId) : undefined}
            onSuccess={(column) => {
              if (!columnId) {
                setAddColumnOpen(false)
                setTableVisibilityState((oldVisibilityState) => ({
                  ...oldVisibilityState,
                  [column.id]: true,
                }))
              } else {
                dispatch(
                  push(
                    AppRoutes.getPath("ReportAttendeesPage", {
                      retreatIdx: retreatIdx.toString(),
                    })
                  )
                )
              }
            }}
          />
          <Dropdown
            portal
            button={
              <Button
                css={{height: "36px"}}
                startIcon={<DotsVerticalIcon />}
                variant="outline"
                color="gray"
              />
            }>
            {!isDefaultView && (
              <DropdownItem
                text="Rename"
                onClick={() => {
                  setEditingReportId(parseInt(props.activeView as string))
                }}
                startIcon={<Pencil2Icon />}
              />
            )}
            {!isDefaultView && (
              <DropdownItem
                text="Share Report"
                onClick={() => {
                  setShareReportOpen(true)
                }}
                startIcon={<Share2Icon />}
              />
            )}
            <DropdownItem
              text="Export to CSV"
              onClick={() => {
                if (exportRef.current?.link) exportRef.current.link.click()
              }}
              startIcon={<DownloadIcon />}
            />
            {!isDefaultView && (
              <DeleteItem
                startIcon={<TrashIcon />}
                text="Delete Report"
                onClick={async () => {
                  let index = retreat.attendee_report_ids.indexOf(
                    parseInt(props.activeView ?? "0")
                  )
                  let response = (await dispatch(
                    deleteAttendeeReport(
                      parseInt(props.activeView as string),
                      retreat.id
                    )
                  )) as unknown as ApiAction

                  if (!response.error) {
                    props.setActiveView(
                      retreat.attendee_report_ids[index - 1]
                        ? retreat.attendee_report_ids[index - 1].toString()
                        : "flights"
                    )
                  }
                }}
              />
            )}
          </Dropdown>
          {!(
            props.activeView === "all-attendees" ||
            props.activeView === "flights" ||
            props.activeView === "form-responses" ||
            (_.isEqual(
              attendeeReports[parseInt(props.activeView as string)]
                ?.column_sort,
              table.getState().sorting
            ) &&
              _.isEqual(
                attendeeReports[parseInt(props.activeView as string)]
                  ?.column_visibility,
                table.getState().columnVisibility
              ) &&
              _.isEqual(filters, localFilterState))
          ) && (
            <>
              <VerticalBar />
              <ButtonGroup>
                <Button
                  text="Update Report"
                  variant={"outline"}
                  onClick={async () => {
                    let audienceId = await createOrReturnAudienceId()
                    localFilterState.forEach(async (group, i) => {
                      let groupId = await createOrReturnGroupId(
                        audienceId,
                        group,
                        i
                      )
                      group
                        .filter((filter) => !isNaN(parseInt(filter.field)))
                        .forEach((filter) => {
                          postOrPatchFilter(filter, groupId)
                        })
                    })

                    let filtersLeft = localFilterState.flat()
                    allFilters.forEach((filter) => {
                      let item = filtersLeft.find(
                        (localFilter) => localFilter.id === filter.id
                      )
                      if (!item) {
                        dispatch(
                          deleteAttendeeGroupFilter(
                            filter.id,
                            filter.attendee_group_id
                          )
                        )
                      }
                    })
                    attendeeGroups.forEach((group) => {
                      let item = filtersLeft.find(
                        (filter) => filter.attendee_group_id === group.id
                      )
                      if (!item) {
                        dispatch(
                          deleteAttendeeGroup(group.id, group.audience_id)
                        )
                      }
                    })
                    let response = (await dispatch(
                      patchAttendeeReport(
                        {
                          column_visibility: table.getState().columnVisibility,
                          column_sort: table.getState().sorting ?? null,
                        },
                        parseInt(props.activeView as string)
                      )
                    )) as unknown as ApiAction
                    if (!response.error) {
                      dispatch(
                        enqueueSnackbar({
                          message: "Report updated",
                          options: {
                            variant: "success",
                          },
                        })
                      )
                    }
                  }}></Button>

                <Dropdown
                  portal
                  button={
                    <Button
                      onPointerDown={
                        props.activeView === "all-attendees" ||
                        props.activeView === "flights" ||
                        props.activeView === "form-responses"
                          ? (e) => {
                              e.preventDefault()
                            }
                          : undefined
                      }
                      startIcon={<CaretDownIcon />}
                      variant="outline"></Button>
                  }>
                  <DropdownItem
                    text="Reset Changes"
                    onClick={() => {
                      resetTable()
                      setLocalFilterState(filters)
                    }}
                    startIcon={<Pencil2Icon />}
                  />
                </Dropdown>
              </ButtonGroup>
            </>
          )}
        </StyledButtonsContainer>
      </StyledTableOptionsContainer>
      <ManageAttendeesTable
        addColumn
        onAddColumn={() => {
          setAddColumnOpen(true)
        }}
        onEditColumn={(colId) => {
          dispatch(
            push(
              AppRoutes.getPath("ReportAttendeeColumnPage", {
                retreatIdx: retreatIdx.toString(),
                columnId: colId,
              })
            )
          )
        }}
        getAttendeeRedirect={props.getAttendeeRedirect}
        resetSearch={() => {
          setLocalFilterState([])
          setSearchString("")
          if (searchBarRef.current) {
            searchBarRef.current.value = ""
          }
        }}
        setColumnVisibility={setTableVisibilityState}
        table={table}
      />
    </StyledTableContainer>
  )
}

const StyledTableContainer = styled("div", {
  width: "100%",
  overflow: "hidden",
  heigth: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
})

let StyledTableOptionsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  width: "100%",
  paddingBlock: "10px",
  paddingInline: "12px",
  alignItems: "center",
  gap: "30px",
})
let StyledButtonsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  marginLeft: "auto",
  gap: "8px",
  alignItems: "center",
  [`${Badge}`]: {
    height: "20px",
  },
})
let StyledSearchBar = styled(SearchBar, {
  marginRight: "auto",
  width: "100%",
  maxWidth: 360,
})

const StyledTableBodyRow = styled("tr", {
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.colors.gray2,
  },
})

export const StyledCellCss = css({
  maxWidth: "$$width",
  minWidth: "$$width",
  borderBottom: `solid 1px ${theme.colors.gray6}`,
  "& *": {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  variants: {
    gridLines: {
      true: {
        "&:not(:last-child)": {
          borderRight: `solid 1px ${theme.colors.gray6}`,
        },
      },
    },
  },
})

export const StyledTableHeaderCell = styled("th", StyledCellCss, {
  backgroundColor: theme.colors.gray2,
  padding: "10px",
  borderTop: `solid 1px ${theme.colors.gray6}`,
  "& > span": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "&:hover": {
    backgroundColor: theme.colors.gray6,
  },
})

export const StyledSortIconCss = css({flexShrink: 0})
const StyledTableDataCell = styled("td", StyledCellCss, {
  "& a": {
    display: "block",
    padding: "10px",
  },
})

type TableFooterProps = {
  rowCount: number
  pageSize: number
  pageCount: number
  currentPage: number
  onNextPage?: () => void
  onPreviousPage?: () => void
  absolute?: boolean
}
export function TableFooter(props: TableFooterProps) {
  let startRowI = (props.currentPage - 1) * props.pageSize + 1
  let endRowI = startRowI + props.pageSize - 1
  if (endRowI > props.rowCount) {
    endRowI = props.rowCount
  }
  return (
    <StyledFooter absolute={props.absolute}>
      <StyledRowCount>
        <Text variant="text-base">
          {startRowI}
          &nbsp;-&nbsp;
          {endRowI}
        </Text>
        <Text variant="text-sm">&nbsp;of&nbsp;</Text>
        <Text variant="text-base">{props.rowCount}</Text>
      </StyledRowCount>
      <StyledPaginationContainer>
        <IconButton
          // TODO fix props on IconButton
          // @ts-ignore
          type="button"
          variant="outline"
          disabled={props.onPreviousPage == null}
          onClick={props.onPreviousPage}>
          <ChevronLeftIcon />
        </IconButton>
        <StyledPageDisplay>
          <Text variant="text-base">{props.currentPage}</Text>
          &nbsp;<Text variant="text-sm">of</Text>&nbsp;
          <Text variant="text-base">{props.pageCount}</Text>
        </StyledPageDisplay>
        <IconButton
          // @ts-ignore
          type="button"
          variant="outline"
          disabled={props.onNextPage == null}
          onClick={props.onNextPage}>
          <ChevronRightIcon />
        </IconButton>
      </StyledPaginationContainer>
    </StyledFooter>
  )
}

const StyledFooter = styled("div", {
  display: "flex",
  width: "100%",
  bottom: "0",
  alignItems: "center",
  backgroundColor: theme.colors.gray2,
  borderTop: `solid 1px ${theme.colors.gray6}`,
  height: "50px",
  paddingInline: "5px",
  variants: {
    absolute: {
      true: {
        position: "absolute",
      },
    },
  },
})

const StyledRowCount = styled("div", {
  display: "flex",
  alignItems: "baseline",
})

const StyledPaginationContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "10px",
  marginLeft: "auto",
})

const StyledPageDisplay = styled("div", {
  display: "flex",
  alignItems: "baseline",
})

const MultiSelectCellContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "2px",
  flexWrap: "nowrap",
  overflow: "auto",
  alignItems: "center",
})
export function MultiSelectCell(props: {value: string[]}) {
  return (
    <MultiSelectCellContainer>
      <Badge label={props.value[0]} />
      {props.value.length - 1 ? `+ ${props.value.length - 1}` : ""}
    </MultiSelectCellContainer>
  )
}
