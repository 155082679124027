import {TrashIcon} from "@radix-ui/react-icons"
import {IconButton} from "@summtech/flok-base/components/IconButton"
import {Select} from "@summtech/flok-base/components/Select"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Text} from "@summtech/flok-base/components/Text"
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import {useFormik} from "formik"
import {ReactNode, useEffect} from "react"
import * as yup from "yup"
import {
  deleteFormQuestionRule,
  getForm,
  getFormQuestion,
  getFormQuestionOption,
  getFormQuestionRule,
  patchFormQuestionRule,
  postFormQuestionRule,
} from "../../../api/form"
import {
  FormQuestionModel,
  FormQuestionRuleModel,
  FormQuestionSelectOptionModel,
} from "../../../models/form"

type FormQuestionRuleSelectorProps = {
  index?: number | ReactNode
  questions: number[]
  dependsOnOptionId?: number
  dependsOnQuestionId?: number
  onUpdateOptionId: (optionId: number) => void
  onUpdateQuestionId: (questionId: number) => void
  onDeleteRule?: () => void
  onSuccess?: () => void
}

function FormQuestionRuleSelector(props: FormQuestionRuleSelectorProps) {
  let dependsQuestionQuery = useQuery({
    enabled: props.dependsOnQuestionId != null,
    queryFn: () => getFormQuestion(props.dependsOnQuestionId!),
    queryKey: ["forms-questions", props.dependsOnQuestionId],
  })

  let dependsQuestionOptionsQueries = useQueries({
    queries: (
      dependsQuestionQuery.data?.form_question.select_options ?? []
    ).map((optionId) => ({
      queryFn: () => getFormQuestionOption(optionId),
      queryKey: ["questions-options", optionId],
    })),
  })

  let possibleQuestionsQueries = useQueries({
    queries: props.questions.map((questionId) => ({
      queryFn: () => getFormQuestion(questionId),
      queryKey: ["forms-questions", questionId],
    })),
  })

  function getQuestionTitle(question: FormQuestionModel) {
    return question.title ? question.title : "Untitled question"
  }

  function getOptionName(option: FormQuestionSelectOptionModel) {
    return option.option ? option.option : "Untitled option"
  }

  return (
    <div style={{display: "flex", gap: "8px", alignItems: "center"}}>
      <Text variant="text-sm-plus">
        {props.index ? (
          typeof props.index === "number" ? (
            `${props.index}.`
          ) : (
            props.index
          )
        ) : (
          <>&nbsp;</>
        )}
      </Text>
      <Select
        value={
          props.dependsOnQuestionId
            ? props.dependsOnQuestionId.toString()
            : undefined
        }
        placeholder="Question"
        onChange={(value) => props.onUpdateQuestionId(parseInt(value))}>
        {[
          possibleQuestionsQueries
            .filter((query) => query.status === "success")
            .map((query) => {
              let question = query.data!.form_question
              if (question && !["SINGLE_SELECT"].includes(question.type)) {
                return undefined
              }
              return (
                <SelectItem
                  key={question.id}
                  value={question.id.toString()}
                  label={getQuestionTitle(question)}
                />
              )
            }),
        ]}
      </Select>
      <Text variant="text-sm-plus">is</Text>
      <Select
        placeholder={
          props.dependsOnQuestionId
            ? "Answer"
            : "Answer (select question first)"
        }
        disabled={!props.dependsOnOptionId}
        value={
          props.dependsOnOptionId
            ? props.dependsOnOptionId.toString()
            : undefined
        }
        onChange={(value) => props.onUpdateOptionId(parseInt(value))}>
        {dependsQuestionOptionsQueries
          .filter((query) => query.isSuccess)
          .map((query) => {
            let selectOption = query.data!.select_option
            return (
              <SelectItem
                value={selectOption.id.toString()}
                key={selectOption.id}
                label={getOptionName(selectOption)}
              />
            )
          })}
      </Select>
      <IconButton
        variant="ghost"
        disabled={!props.onDeleteRule}
        onClick={props.onDeleteRule}>
        <TrashIcon />
      </IconButton>
    </div>
  )
}

export function NewQuestionRule(props: {
  questionId: number
  onDelete: () => void
  onAddSuccess: () => void
  index: number
}) {
  let queryClient = useQueryClient()
  let questionQuery = useQuery({
    queryKey: ["forms-questions", props.questionId],
    queryFn: () => getFormQuestion(props.questionId),
  })

  let formQuery = useQuery({
    enabled: questionQuery.data?.form_question.form_id != null,
    queryKey: ["forms", questionQuery.data?.form_question.form_id],
    queryFn: () => getForm(questionQuery.data!.form_question.form_id),
  })

  let postRuleMutation = useMutation({
    mutationFn: postFormQuestionRule,
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["questions-rules", data.form_question_rule.id],
        data
      )
      queryClient.invalidateQueries({
        queryKey: ["forms-questions", data.form_question_rule.form_question_id],
      })
      props.onAddSuccess()
    },
  })

  let formik = useFormik<
    Omit<FormQuestionRuleModel, "id" | "form_question_id">
  >({
    initialValues: {
      depends_on_form_question_id: -1,
      depends_on_select_option_id: -1,
    },
    validationSchema: yup.object({
      depends_on_form_question_id: yup.number().positive(),
      depends_on_select_options_id: yup.number().positive(),
    }),
    onSubmit: async (values) => {
      postRuleMutation.mutate({...values, form_question_id: props.questionId})
    },
  })
  let questions =
    formQuery.isSuccess && questionQuery.isSuccess
      ? formQuery.data.form.questions.slice(
          0,
          formQuery.data.form.questions.indexOf(
            questionQuery.data!.form_question.id
          ) === -1
            ? undefined
            : formQuery.data.form.questions.indexOf(
                questionQuery.data!.form_question.id
              )
        )
      : []
  let {submitForm} = formik
  useEffect(() => {
    submitForm()
  }, [formik.values.depends_on_select_option_id, submitForm])

  return (
    <FormQuestionRuleSelector
      index={props.index}
      questions={questions}
      dependsOnQuestionId={formik.values.depends_on_form_question_id}
      dependsOnOptionId={formik.values.depends_on_select_option_id}
      onUpdateQuestionId={(questionId) => {
        formik.setFieldValue("depends_on_form_question_id", questionId)
        formik.setFieldValue("depends_on_select_option_id", -1)
      }}
      onUpdateOptionId={(optionId) =>
        formik.setFieldValue("depends_on_select_option_id", optionId)
      }
    />
  )
}

export function EditQuestionRule(props: {ruleId: number; index: number}) {
  let queryClient = useQueryClient()
  let ruleQuery = useQuery({
    queryKey: ["questions-rules", props.ruleId],
    queryFn: () => getFormQuestionRule(props.ruleId),
  })

  let updateRuleMutation = useMutation({
    mutationFn: (args: Parameters<typeof patchFormQuestionRule>[1]) =>
      patchFormQuestionRule(props.ruleId, args),
    onSuccess: (data) => {
      queryClient.setQueryData(["questions-rules", props.ruleId], data)
    },
  })

  let deleteRuleMutation = useMutation({
    mutationFn: () => deleteFormQuestionRule(props.ruleId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "forms-questions",
          ruleQuery.data?.form_question_rule.form_question_id,
        ],
      })
      queryClient.removeQueries(["questions-rules", props.ruleId])
    },
  })

  let questionQuery = useQuery({
    enabled: ruleQuery.data?.form_question_rule.form_question_id != null,
    queryKey: [
      "forms-questions",
      ruleQuery.data?.form_question_rule.form_question_id,
    ],
    queryFn: () =>
      getFormQuestion(ruleQuery.data!.form_question_rule.form_question_id),
  })

  let formQuery = useQuery({
    enabled: questionQuery.data?.form_question.form_id != null,
    queryKey: ["forms", questionQuery.data?.form_question.form_id],
    queryFn: () => getForm(questionQuery.data!.form_question.form_id),
  })

  let formik = useFormik<
    Omit<FormQuestionRuleModel, "id" | "form_question_id">
  >({
    enableReinitialize: true,
    initialValues: {
      depends_on_form_question_id:
        ruleQuery.data?.form_question_rule.depends_on_form_question_id || -1,
      depends_on_select_option_id:
        ruleQuery.data?.form_question_rule.depends_on_select_option_id || -1,
    },
    validationSchema: yup.object({
      depends_on_form_question_id: yup.number().positive(),
      depends_on_select_options_id: yup.number().positive(),
    }),
    onSubmit: (values) => {
      if (formik.initialValues !== values) {
        updateRuleMutation.mutate(values)
      }
    },
  })
  let {submitForm} = formik
  useEffect(() => {
    submitForm()
  }, [formik.values.depends_on_select_option_id, submitForm])
  let questions =
    formQuery.isSuccess && questionQuery.isSuccess
      ? formQuery.data.form.questions.slice(
          0,
          formQuery.data.form.questions.indexOf(
            questionQuery.data!.form_question.id
          ) === -1
            ? undefined
            : formQuery.data.form.questions.indexOf(
                questionQuery.data!.form_question.id
              )
        )
      : []
  return (
    <FormQuestionRuleSelector
      index={props.index}
      onDeleteRule={deleteRuleMutation.mutate}
      questions={questions}
      dependsOnQuestionId={formik.values.depends_on_form_question_id}
      dependsOnOptionId={formik.values.depends_on_select_option_id}
      onUpdateQuestionId={(questionId) => {
        formik.setFieldValue("depends_on_form_question_id", questionId)
        formik.setFieldValue("depends_on_select_option_id", -1)
      }}
      onUpdateOptionId={(optionId) =>
        formik.setFieldValue("depends_on_select_option_id", optionId)
      }
    />
  )
}
