import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
} from "@material-ui/core"
import {Alert, AlertTitle} from "@material-ui/lab"

let useStyles = makeStyles((theme) => ({
  alertText: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
  },
  okButton: {
    marginLeft: "auto",
  },
}))

export default function AppAlertModal(props: {
  open: boolean
  severity?: "error" | "info" | "success" | "warning"
  title: string
  text: string
  onOk?: () => void
  onClose?: () => void
}) {
  let classes = useStyles()
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent>
        <Alert severity={props.severity ?? "warning"}>
          <AlertTitle>{props.title}</AlertTitle>
          <div className={classes.alertText}>{props.text}</div>
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button
          size="small"
          className={classes.okButton}
          variant="outlined"
          onClick={props.onOk}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
